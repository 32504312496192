<template>
<!-- 二次封装el-button 整合了loading和二次确认 减少了无谓的变量和代码 -->
  <el-button v-bind="$attrs" :loading="loadingStatus" @click="handleClick">
    <slot></slot>
  </el-button>
</template>

<script>
export default {
  name: 'CButton',
  props: {
    autoLoading: {
      type: Boolean,
      default: true
    },
    autoConfirm: {
      type: Boolean,
      default: false
    },
    confirmConfig: {
      type: Object,
      default: () => ({})
    },
    message: {
      type: String,
      default: '此操作将永久删除改数据，是否继续？'
    },
    content: String
  },
  data () {
    return {
      loadingStatus: false
    }
  },
  methods: {
    handleClick () {
      if (this.autoLoading) {
        this.loadingStatus = true
      }
      if (this.autoConfirm) {
        const {
          message = this.message,
          title = '提示',
          confirmButtonText = '确定',
          cancelButtonText = '取消',
          type = 'warning'
        } = this.confirmConfig
        this.$confirm(message, title, {
          confirmButtonText,
          cancelButtonText,
          type
        })
          .then(() => {
            this.$emit('confirm', () => {
              this.loadingStatus = false
            })
          })
          .catch(() => {
            this.$emit('cancel')
            this.loadingStatus = false
          })
      } else {
        // 定义一个回调函数 在父组件里面改变 loadingStaus
        this.$emit('click', () => {
          this.loadingStatus = false
        })
      }
      // 定义一个回调函数 在父组件里面改变 loadingStaus
    }
  },
  components: {}
}
</script>
<style lang='scss' scoped>
</style>
