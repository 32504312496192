<template>
  <div>
    <!-- 搜索条件 -->
    <div class="filter-box">
      <div class="row">
        <el-button
          style="margin-left: 10px"
          type="primary"
          @click="handlePageChange"
        >
          刷新
        </el-button>
        <span style="font-size: 14px; color: #ccc"
        >(共{{ tableData.length }}条计划)</span
        >
      </div>
    </div>
    <el-table
      :data="tmpDetailData"
      v-loading="recordDetailLoading"
      :span-method="objectSpanMethod"
      :border="true"
      @cell-click="handleDetailClick"
      :cell-class-name="handleCellStyle"
      height="800"
      style="width: 100%"
    >
      <el-table-column prop="advertiserName" label="账户名称" width="200">
      </el-table-column>
      <el-table-column prop="campaignName" label="广告组名称" width="180">
      </el-table-column>
      <el-table-column prop="adStatus" label="广告组状态" width="120">
        <template slot="header" slot-scope="scope">
          <el-dropdown @command="handleChangeAdStatus">
            <span class="el-dropdown-link">
              广告组状态<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="enable">批量启用</el-dropdown-item>
              <el-dropdown-item command="delete">批量删除</el-dropdown-item>
              <el-dropdown-item command="disable">批量暂停</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
        <template slot-scope="scope">
          <el-checkbox
            v-if="
              scope.row.adStatus !== 'CAMPAIGN_STATUS_DELETE' &&
              scope.row.status === 3
            "
            v-model="scope.row.adStatusChecked"
            style="margin-right: 10px"
          ></el-checkbox>
          <span>{{
              scope.row.adStatus === "CAMPAIGN_STATUS_ENABLE"
                ? "启用"
                : scope.row.adStatus === "CAMPAIGN_STATUS_DISABLE"
                  ? "暂停"
                  : scope.row.adStatus === "CAMPAIGN_STATUS_DELETE"
                    ? "删除"
                    : "所有包含已删除"
            }}</span>
          <el-dropdown
            @command="(type) => handleChangeAdStatu(scope.row, type)"
          >
            <span class="el-dropdown-link">
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="enable">启用</el-dropdown-item>
              <el-dropdown-item command="delete">删除</el-dropdown-item>
              <el-dropdown-item command="disable">暂停</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="上传状态" width="100">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.status === 1" type="warning">待上传</el-tag>
          <el-popover
            v-else-if="scope.row.status === 2"
            placement="top"
            width="400"
            trigger="hover"
          >
            <p>{{ scope.row.failReason }}</p>
            <el-tag slot="reference" type="danger">失败</el-tag>
          </el-popover>
          <el-tag v-else-if="scope.row.status === 3" type="success"
          >成功
          </el-tag
          >
        </template>
      </el-table-column>
      <el-table-column prop="planName" label="计划名称"></el-table-column>
      <el-table-column prop="planStatus" label="上传状态">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.planStatus === 1" type="warning"
          >待上传
          </el-tag
          >
          <el-popover
            v-else-if="scope.row.planStatus === 2"
            placement="top"
            width="400"
            trigger="hover"
          >
            <p>{{ scope.row.planFailReason }}</p>
            <el-tag slot="reference" type="danger">失败</el-tag>
          </el-popover>
          <el-tag v-else-if="scope.row.planStatus === 3" type="success"
          >成功
          </el-tag
          >
        </template>
      </el-table-column>
      <el-table-column prop="putStatus" label="计划投放状态">
        <template slot="header" slot-scope="scope">
          <el-dropdown @command="handleChangePlanStatus">
            <span class="el-dropdown-link">
              计划状态<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="enable">批量启用</el-dropdown-item>
              <el-dropdown-item command="delete">批量删除</el-dropdown-item>
              <el-dropdown-item command="disable">批量暂停</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
        <template slot-scope="scope">
          <el-checkbox
            v-if="
              scope.row.putStatus !== 'AD_STATUS_DELETE' &&
              scope.row.planStatus === 3
            "
            v-model="scope.row.putStatusChecked"
            style="margin-right: 10px"
          ></el-checkbox>
          <span v-show>{{ scope.row.rejectReason }}</span>
          <span>{{ allPutStatus[scope.row.putStatus] }}</span>
          <el-dropdown
            v-if="
              scope.row.putStatus !== 'AD_STATUS_DELETE' &&
              scope.row.planStatus === 3
            "
            @command="(type) => handleChangePlanStatu(scope.row, type)"
          >
            <span class="el-dropdown-link">
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="enable">启用</el-dropdown-item>
              <el-dropdown-item command="delete">删除</el-dropdown-item>
              <el-dropdown-item command="disable">暂停</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <span
            v-if="hasRejectReason(scope.row)"
            class="proposal"
            @click="handleShowRejectReason(scope.row)"
          >审核建议</span
          >
        </template>
      </el-table-column>
      <el-table-column prop="type" label="创意类型">
        <template slot-scope="scope">
          <span>{{ scope.row.type === 1 ? "自定义" : "程序化" }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="creativeStatus" label="上传状态">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.creativeStatus === 1" type="warning"
          >待上传
          </el-tag
          >
          <el-popover
            v-else-if="scope.row.creativeStatus === 2"
            placement="top"
            width="400"
            trigger="hover"
          >
            <p>{{ scope.row.creativeFailReason }}</p>
            <el-tag slot="reference" type="danger">失败</el-tag>
          </el-popover>
          <el-tag v-else-if="scope.row.creativeStatus === 3" type="success"
          >成功
          </el-tag
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 广告组详情 -->
    <el-dialog
      class="ad-dialog"
      title="广告组详情"
      append-to-body
      v-loading="adDetailLoading"
      :visible.sync="showAdDetail"
      width="800px"
    >
      <div>
        <el-form label-width="160px">
          <el-form-item
            label-width="80"
            style="margin-left: 78px"
            prop=""
            label="广告组名称"
          >
            <div>
              <c-input
                placeholder="广告组名称"
                v-model="adParams.campaignName"
                style="width: 300px"
                show-word-limit
                maxlength="35"
              ></c-input>
            </div>
          </el-form-item>
          <el-form-item prop="" label="营销目的">
            <el-radio-group disabled v-model="adParams.marketingPurpose">
              <el-radio-button label="CONVERSION">行动转化</el-radio-button>
              <el-radio-button label="INTENTION">用户意向</el-radio-button>
              <el-radio-button label="ACKNOWLEDGE">品牌认知</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item prop="" label="推广目的">
            <el-radio-group disabled v-model="adParams.landingType">
              <el-radio-button label="LINK">销售线索收集</el-radio-button>
              <el-radio-button label="APP">应用推广</el-radio-button>
              <el-radio-button label="QUICK_APP">快应用</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item prop="" label="操作状态">
            <el-radio-group disabled v-model="adParams.operation">
              <el-radio-button label="enable">开启</el-radio-button>
              <el-radio-button label="disable">关闭</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item prop="" label="预算类型">
            <el-radio-group v-model="adParams.budgetMode">
              <el-radio-button label="BUDGET_MODE_INFINITE"
              >不限
              </el-radio-button
              >
              <el-radio-button label="BUDGET_MODE_DAY"
              >指定预算
              </el-radio-button
              >
            </el-radio-group>
          </el-form-item>
          <el-form-item
            v-if="adParams.budgetMode == 'BUDGET_MODE_DAY'"
            prop=""
            label="日预算"
          >
            <el-input
              :onlyNum="true"
              v-model="adParams.budget"
              style="width: 300px"
              placeholder="日预算不能低于300元,预算单次修改幅度不能低于100元"
            ></el-input>
          </el-form-item>
          <el-form-item prop="" label="上传状态">
            <el-radio-group disabled v-model="adParams.status">
              <el-radio-button label="1">待上传</el-radio-button>
              <el-radio-button label="2">失败</el-radio-button>
              <el-radio-button label="3">成功</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item v-if="adParams.status == '2'" label="失败原因">
            <p>{{ adParams.failReason }}</p>
          </el-form-item>
          <el-form-item prop="" label="广告组状态">
            <el-radio-group disabled v-model="adParams.adStatus">
              <el-radio-button label="CAMPAIGN_STATUS_ENABLE"
              >启用
              </el-radio-button
              >
              <el-radio-button label="CAMPAIGN_STATUS_DISABLE"
              >暂停
              </el-radio-button
              >
              <el-radio-button label=" CAMPAIGN_STATUS_DELETE"
              >删除
              </el-radio-button
              >
              <el-radio-button label="CAMPAIGN_STATUS_ALL"
              >所有包含已删除
              </el-radio-button
              >
              <el-radio-button label="CAMPAIGN_STATUS_NOT_DELETE"
              >所有不包含已删除
              </el-radio-button
              >
              <el-radio-button label="CAMPAIGN_STATUS_ADVERTISER_BUDGET_EXCEED"
              >超出广告主日预算
              </el-radio-button
              >
            </el-radio-group>
          </el-form-item>
        </el-form>
        <div class="button-list">
          <div>
            <el-button
              :loading="changeAdDetailLoading"
              @click="handleChangeAdDetail"
              type="primary"
            >修改
            </el-button
            >
            <el-button @click="showAdDetail = false">取消</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 计划详情 -->
    <el-drawer
      class="ad-dialog"
      title="计划详情"
      append-to-body
      v-loading="planDetailLoading"
      :visible.sync="showPlanDetail"
      destroy-on-close
      size="800px"
    >
      <div class="transfor-box">
        <div class="plan-dialog-container transform-container">
          <div class="plan-part">
            <h3>计划名称</h3>
            <div class="plan-name flex-row-start-center">
              <span class="required">计划名称</span>
              <div>
                <el-input
                  style="width: 400px"
                  v-model="planParams.name"
                  placeholder="计划名称"
                  show-word-limit
                  maxlength="35"
                ></el-input>
              </div>
            </div>
            <div class="plan-name flex-row-start-center">
              <span class="required">上传状态</span>
              <div>
                <el-tag v-if="planParams.status === 1" type="warning"
                >待上传
                </el-tag
                >
                <el-tag v-if="planParams.status === 2" type="danger"
                >失败
                </el-tag
                >
                <el-tag v-else-if="planParams.status === 3" type="success"
                >成功
                </el-tag
                >
              </div>
            </div>
            <div class="plan-name flex-row-start-center">
              <span class="required">投放状态</span>
              <div>
                <span>{{ allPutStatus[planParams.putStatus] }}</span>
              </div>
            </div>
            <div class="plan-name flex-row-start-center">
              <span class="required">投放状态</span>
              <div>
                <el-radio-group disabled v-model="planParams.operation">
                  <el-radio-button label="enable">开启</el-radio-button>
                  <el-radio-button label="disable">关闭</el-radio-button>
                </el-radio-group>
              </div>
            </div>
          </div>
          <div class="plan-part">
            <h3>投放范围</h3>
            <div class="flex-row-start-start">
              <span class="required" style="margin-top: 10px">投放范围</span>
              <div>
                <!-- <span class="simulation-checkbox">默认</span> -->
                <el-radio-group disabled v-model="planParams.deliveryRange">
                  <el-radio-button label="DEFAULT">默 认</el-radio-button>
                  <el-radio-button label="UNION">穿山甲</el-radio-button>
                  <el-radio-button label="UNIVERSAL">通投智选</el-radio-button>
                </el-radio-group>
              </div>
            </div>
            <div class="flex-row-start-start">
              <span class="required" style="margin-top: 10px">广告位大类</span>
              <div>
                <el-radio-group disabled v-model="planParams.inventoryCatalog">
                  <el-radio-button label="MANUAL">首选媒体</el-radio-button>
                  <el-radio-button label="SCENE">场景广告位</el-radio-button>
                  <el-radio-button label="SMART">优选广告位</el-radio-button>
                  <el-radio-button label="UNIVERSAL">通投智选</el-radio-button>
                </el-radio-group>
                <el-checkbox-group
                  style="margin-top: 10px"
                  v-if="planParams.inventoryCatalog === 'MANUAL'"
                  v-model="tmpInventoryType"
                >
                  <el-checkbox
                    disabled
                    v-for="(item, index) in inventoryCatalogList"
                    :key="index"
                    :label="item.value"
                  >{{ item.name }}
                  </el-checkbox
                  >
                </el-checkbox-group>
                <!-- 优选广告位时 -->
                <div
                  style="margin-top: 10px"
                  v-if="planParams.inventoryCatalog === 'SMART'"
                >
                  <span style="margin-right: 6px; font-size: 14px"
                  >使用优选</span
                  >
                  <el-radio
                    disabled
                    v-model="planParams.smartInventory"
                    label="SMART"
                  >是
                  </el-radio
                  >
                  <el-radio v-model="planParams.smartInventory" label="NORMAL"
                  >否
                  </el-radio
                  >
                </div>
              </div>
            </div>
            <div
              class="flex-row-start-start"
              v-if="planParams.deliveryRange === 'UNION'"
            >
              <span class="required" style="margin-top: 10px">投放形式</span>
              <div>
                <el-radio-group disabled v-model="planParams.unionVideoType">
                  <el-radio-button label="ORIGINAL_VIDEO"
                  >原生视频
                  </el-radio-button
                  >
                  <el-radio-button label="REWARDED_VIDEO"
                  >激励视频
                  </el-radio-button
                  >
                  <el-radio-button label="SPLASH_VIDEO"
                  >穿山甲开屏
                  </el-radio-button
                  >
                </el-radio-group>
              </div>
            </div>
            <!-- 场景广告位 -->
            <div
              class="flex-row-start-start"
              v-if="planParams.inventoryCatalog === 'SCENE'"
            >
              <span class="required" style="margin-top: 10px">场景广告位</span>
              <div>
                <el-radio-group disabled v-model="planParams.sceneInventory">
                  <el-radio-button label="VIDEO_SCENE"
                  >沉浸式竖版视频首选
                  </el-radio-button
                  >
                  <el-radio-button label="FEED_SCENE"
                  >信息流首选场景
                  </el-radio-button
                  >
                  <el-radio-button label="TAIL_SCENE"
                  >视频后贴和尾帧首选
                  </el-radio-button
                  >
                </el-radio-group>
              </div>
            </div>
          </div>
          <div class="plan-part">
            <h3>用户定向</h3>
            <!-- dingxiang  -->
            <userDirectional
              ref="userDirectional"
              v-if="showPlanDetail"
              :userData="userDirectionalData"
              :advertiserId="currentAdvertiserId"
            />
          </div>
          <div class="plan-part">
            <!-- <h3>投放目标</h3>
            <div class="flex-row-start-center">
              <span class="required">推广目的</span>
              <el-select
                disabled
                v-model="planParams.landingType"
                placeholder="选择推广目的"
                filterable
              >
                <el-option
                  v-for="item in landingTypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div> -->
            <!-- 下载方式 -->
            <div class="flex-row-start-center">
              <span class="required">下载方式</span>
              <el-radio-group disabled v-model="planParams.downloadType">
                <el-radio-button
                  :disabled="planParams.landingType === 'LINK'"
                  label="DOWNLOAD_URL"
                >下载链接
                </el-radio-button
                >
                <el-radio-button
                  :disabled="planParams.landingType === 'QUICK_APP'"
                  label="EXTERNAL_URL"
                >落地页链接
                </el-radio-button
                >
              </el-radio-group>
            </div>

            <!-- 应用类型 -->
            <div class="flex-row-start-center">
              <span class="required">应用类型</span>
              <el-radio-group disabled v-model="planParams.appType">
                <el-radio-button label="APP_ANDROID">Android</el-radio-button>
              </el-radio-group>
            </div>
            <!-- 多账号分别填写 -->
            <!-- 下载链接 -->
            <template v-if="planParams.landingType === 'QUICK_APP'">
              <div class="flex-row-start-center">
                <span class="required">链接</span>
                <el-input
                  disabled
                  v-model="planParams.webUrl"
                  style="width: 220px"
                  placeholder="无"
                  clearable
                ></el-input>
              </div>
              <div class="flex-row-start-center">
                <span class="required">应用名</span>
                <el-input
                  disabled
                  v-model="planParams.appName"
                  style="width: 220px"
                  clearable
                ></el-input>
              </div>
              <div class="flex-row-start-center">
                <span class="required">应用包名</span>
                <el-input
                  disabled
                  v-model="planParams.applyName"
                  style="width: 220px"
                  clearable
                ></el-input>
              </div>
              <div class="flex-row-start-center">
                <span class="required">监测链接</span>
                <el-input
                  disabled
                  v-model="planParams.url"
                  style="width: 220px"
                  clearable
                ></el-input>
              </div>
              <div class="flex-row-start-center">
                <span class="required">优化目标</span>
                <el-input
                  disabled
                  v-model="planParams.externalActionName"
                  style="width: 220px"
                  clearable
                ></el-input>
              </div>
              <div class="flex-row-start-center">
                <span>深度目标</span>
                <el-input
                  disabled
                  v-model="planParams.deepExternalActionName"
                  style="width: 220px"
                  clearable
                ></el-input>
              </div>
            </template>
            <template v-else>
              <div class="flex-row-start-center" v-if="planParams.downloadUrl">
                <span class="required">链接</span>
                <el-input
                  disabled
                  v-model="planParams.downloadUrl"
                  style="width: 220px"
                  placeholder="无"
                  clearable
                ></el-input>
              </div>
              <div class="flex-row-start-center" v-if="planParams.webUrl">
                <span class="required">落地页</span>
                <el-input
                  disabled
                  v-model="planParams.webUrl"
                  style="width: 220px"
                  placeholder="无"
                  clearable
                ></el-input>
              </div>
              <div class="flex-row-start-center" v-if="planParams.appName">
                <span class="required">应用名</span>
                <el-input
                  disabled
                  v-model="planParams.appName"
                  style="width: 220px"
                  clearable
                ></el-input>
              </div>
              <div class="flex-row-start-center" v-if="planParams.applyName">
                <span class="required">应用包名</span>
                <el-input
                  disabled
                  v-model="planParams.applyName"
                  style="width: 220px"
                  clearable
                ></el-input>
              </div>
              <div class="flex-row-start-center" v-if="planParams.convertId">
                <span class="required">转化目标：</span>
                <el-input
                  disabled
                  v-model="planParams.convertId"
                  style="width: 220px"
                  clearable
                ></el-input>
              </div>
            </template>
            <p style="margin: 5px 0px 10px 125px">
              <el-checkbox
                v-model="planParams.downloadMode"
                true-label="APP_STORE_DELIVERY"
                false-label="DEFAULT"
              >优先从应用商店下载（请确保应用在应用商店上线）
              </el-checkbox
              >
            </p>
            <!-- 落地页链接 -->
            <!-- 直达链接 -->
            <div
              class="flex-row-start-center"
              v-if="planParams.landingType === 'QUICK_APP'"
            >
              <span class="required">直达链接</span>
              <div>
                <c-input
                  disabled
                  v-model="planParams.openUrl"
                  style="width: 400px"
                ></c-input>
              </div>
            </div>
          </div>
          <div class="plan-part">
            <h3>预算与出价</h3>
            <div class="flex-row-start-center">
              <span class="required">投放场景</span>
              <el-radio-group
                disabled
                @change="handleSmartBidTypeChange"
                v-model="planParams.smartBidType"
              >
                <el-radio-button label="SMART_BID_CUSTOM"
                >常规投放
                </el-radio-button
                >
                <el-radio-button label="SMART_BID_CONSERVATIVE"
                >放量投放
                </el-radio-button
                >
              </el-radio-group>
            </div>
            <template v-if="planParams.smartBidType === 'SMART_BID_CUSTOM'">
              <div class="flex-row-start-center">
                <span class="required">竞价策略</span>
                <el-radio-group disabled v-model="planParams.flowControlMode">
                  <el-radio-button label="FLOW_CONTROL_MODE_FAST"
                  >优先跑量
                  </el-radio-button
                  >
                </el-radio-group>
              </div>
              <div class="flex-row-start-start">
                <span class="required" style="margin-top: 10px">预算</span>
                <div>
                  <el-select
                    disabled
                    style="width: 100px"
                    class="margin-right-ten"
                    v-model="planParams.budgetMode"
                    placeholder="预算"
                    filterable
                  >
                    <el-option
                      label="日预算"
                      value="BUDGET_MODE_DAY"
                    ></el-option>
                    <el-option
                      label="总预算"
                      value="BUDGET_MODE_TOTAL"
                    ></el-option>
                  </el-select>
                  <el-input
                    style="width: 370px"
                    v-model="planParams.budget"
                    placeholder="请输入预算，不少于300元，不超过9999999.99元"
                  ></el-input>
                  元
                </div>
              </div>
              <div class="flex-row-start-start">
                <span class="required" style="margin-top: 10px">投放时间</span>
                <div>
                  <el-radio-group v-model="planParams.scheduleType">
                    <el-radio-button label="SCHEDULE_FROM_NOW"
                    >从今天起长期投放
                    </el-radio-button
                    >
                    <el-radio-button label="SCHEDULE_START_END"
                    >设置开始和结束日期
                    </el-radio-button
                    >
                  </el-radio-group>
                  <p
                    style="margin-top: 20px"
                    v-if="planParams.scheduleType === 'SCHEDULE_START_END'"
                  >
                    <el-date-picker
                      v-model="planParams.daterange"
                      type="datetimerange"
                      format="yyyy-MM-dd HH:mm"
                      value-format="yyyy-MM-dd HH:mm"
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                    >
                    </el-date-picker>
                  </p>
                </div>
              </div>
              <div class="flex-row-start-start">
                <span class="required" style="margin-top: 10px">投放时段</span>
                <div>
                  <el-radio-group v-model="planParams.scheduleTimeTmp">
                    <el-radio-button :label="1">不限</el-radio-button>
                    <el-radio-button :label="2">指定时间段</el-radio-button>
                  </el-radio-group>
                  <div
                    class="time-range"
                    v-if="planParams.scheduleTimeTmp === 2"
                  >
                    <custom-schedule
                      v-if="planParams.scheduleTimeTmp === 2"
                      :inputData="tmpscheduleTime"
                      @returnData="handleGetScheduleTime"
                    />
                  </div>
                </div>
              </div>
              <div class="flex-row-start-center">
                <span class="required">付费方式</span>
                <el-radio-group disabled v-model="planParams.pricing">
                  <el-radio-button label="PRICING_OCPM"
                  >按展示付费(oCPM)
                  </el-radio-button
                  >
                </el-radio-group>
              </div>
              <div class="flex-row-start-start">
                <span class="required" style="margin-top: 10px">出价</span>
                <div style="width: 80%">
                  <el-input
                    v-model="planParams.cpaBid"
                    placeholder="取值范围：0.1-10000元"
                    style="width: 200px"
                  >
                  </el-input>
                  <span style="line-height: 32px; padding-left: 5px">元</span>
                </div>
              </div>
            </template>
            <!--放量投放  -->
            <template v-else>
              <div class="flex-row-start-start">
                <span class="required" style="margin-top: 10px">日预算</span>
                <div>
                  <el-input
                    style="width: 300px"
                    v-model="planParams.budget"
                    placeholder="不少于300元"
                  ></el-input>
                  元
                </div>
              </div>
              <div class="flex-row-start-start">
                <span></span>
                <div>
                  <p style="margin-top: 5px">
                    <el-checkbox
                      v-model="planParams.flowControlMode"
                      true-label="FLOW_CONTROL_MODE_SMOOTH"
                      false-label="FLOW_CONTROL_MODE_BALANCE"
                    >通过学习期后，尝试优化转化成本
                    </el-checkbox
                    >
                  </p>
                </div>
              </div>
              <div class="flex-row-start-start">
                <span class="required" style="margin-top: 10px">投放时间</span>
                <div>
                  <el-radio-group v-model="planParams.scheduleType">
                    <el-radio-button label="SCHEDULE_FROM_NOW"
                    >从今天起长期投放
                    </el-radio-button
                    >
                    <el-radio-button label="SCHEDULE_START_END"
                    >设置开始和结束日期
                    </el-radio-button
                    >
                  </el-radio-group>
                  <p
                    style="margin-top: 20px"
                    v-if="planParams.scheduleType === 'SCHEDULE_START_END'"
                  >
                    <el-date-picker
                      v-model="planParams.daterange"
                      type="datetimerange"
                      format="yyyy-MM-dd HH:mm"
                      value-format="yyyy-MM-dd HH:mm"
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                    >
                    </el-date-picker>
                  </p>
                </div>
              </div>
              <div class="flex-row-start-start">
                <span class="required" style="margin-top: 10px">投放时段</span>
                <div>
                  <el-radio-group v-model="planParams.scheduleTimeTmp">
                    <el-radio-button :label="1">不限</el-radio-button>
                    <el-radio-button :label="2">指定时间段</el-radio-button>
                  </el-radio-group>
                  <div
                    class="time-range"
                    v-if="planParams.scheduleTimeTmp === 2"
                  >
                    <custom-schedule @returnData="handleGetScheduleTime"/>
                  </div>
                </div>
              </div>
              <div class="flex-row-start-start">
                <span></span>
                <p>
                  <el-checkbox
                    v-model="planParams.adjustCpa"
                    :true-label="1"
                    :false-label="0"
                  >我的预期成本不在此范围内，我需要在此基础上调整
                  </el-checkbox
                  >
                </p>
              </div>
              <div class="flex-row-start-start" v-if="planParams.adjustCpa">
                <span
                  :class="{ required: planParams.adjustCpa === '1' }"
                  style="margin-top: 10px"
                >出价</span
                >
                <div style="width: 80%">
                  <el-input
                    v-model="planParams.cpaBid"
                    placeholder="0.1-10000"
                    style="width: 100px"
                  >
                  </el-input>
                  <span style="line-height: 32px; padding-left: 5px">元</span>
                </div>
              </div>
            </template>
          </div>
        </div>
        <!-- footer -->
        <div class="button-list change-target-footer">
          <div>
            <el-button
              :loading="changePlanDetailLoading"
              type="primary"
              @click="handleChangePlanDetail"
            >修改
            </el-button
            >
            <el-button @click="showPlanDetail = false">取消</el-button>
          </div>
        </div>
      </div>
    </el-drawer>
    <!-- 创意详情 -->
    <el-dialog
      v-loading="creativeLoading"
      title="创意详情"
      append-to-body
      width="930px"
      top="20px"
      v-dialogDrag
      :visible.sync="showCreativeDetail"
    >
      <div class="abse"></div>
      <div
        class="originality flex-row-between-start"
        style="height: 720px; overflow: auto"
      >
        <div>
          <section>
            <h3>制作创意</h3>
            <div class="margin-top:20px">
              <div class="per-part flex-row-start-center">
                <span class="required">创意方式</span>
                <el-radio-group disabled v-model="creativeParams.type">
                  <el-radio-button :label="1">自定义创意</el-radio-button>
                  <el-radio-button :label="2">程序化创意</el-radio-button>
                </el-radio-group>
              </div>
            </div>
          </section>
          <section>
            <h3>创意分类</h3>
            <div>
              <div class="per-part flex-row-start-start">
                <span class="required" style="margin-top: 10px">创意分类</span>
                <div>
                  <el-cascader
                    disabled
                    style="width: 280px"
                    :options="cascaderData"
                    :props="casProps"
                    v-model="creativeParams.thirdIndustryId"
                  ></el-cascader>
                </div>
              </div>
              <div
                class="per-part flex-row-start-center"
                style="margin-bottom: 0"
              >
                <span class="required">创意标签</span>
              </div>
              <div class="per-part flex-row-start-start">
                <el-card class="selected-box" style="width: 600px">
                  <div slot="header" class="selected-header">
                    <span>已选</span>
                  </div>
                  <div class="selected-container">
                    <el-tag
                      :key="index"
                      v-for="(tag, index) in categorys"
                      :disable-transitions="false"
                    >
                      {{ tag }}
                    </el-tag>
                  </div>
                </el-card>
              </div>
            </div>
          </section>
          <section>
            <h3>创意信息</h3>
            <div>
              <div class="per-part flex-row-start-center">
                <span :class="{ required: adResourceReq }">广告来源</span>
                <el-input
                  disabled
                  minLength="4"
                  maxlength="20"
                  show-word-limit
                  v-model="creativeParams.source"
                  style="width: 400px"
                  placeholder="4-20个字"
                ></el-input>
              </div>
              <div
                class="per-part flex-row-start-center"
                v-if="
                  creativeParams.landingType === 'APP' &&
                  creativeParams.appType === 'APP_ANDROID'
                "
              >
                <span>APP副标题</span>
                <el-input
                  disabled
                  maxlength="24"
                  minlength="4"
                  v-model="creativeParams.subTitle"
                  style="width: 400px"
                  placeholder="4到24个字"
                ></el-input>
              </div>
              <div
                class="per-part flex-row-start-center"
                v-if="creativeParams.type === 2"
              >
                <span class="required">自动生成视频素材</span>
                <el-radio-group
                  disabled
                  v-model="creativeParams.isPresentedVideo"
                >
                  <el-radio-button :label="0">不启用</el-radio-button>
                  <el-radio-button :label="1">启用</el-radio-button>
                </el-radio-group>
              </div>
              <div
                class="per-part flex-row-start-center"
                v-if="creativeParams.type === 2"
              >
                <span class="required">最优创意衍生计划</span>
                <el-radio-group
                  disabled
                  v-model="creativeParams.generateDerivedAd"
                >
                  <el-radio-button :label="0">不启用</el-radio-button>
                  <el-radio-button :label="1">启用</el-radio-button>
                </el-radio-group>
              </div>
              <div class="per-part flex-row-start-center">
                <span class="required">广告评论</span>
                <el-radio-group
                  disabled
                  v-model="creativeParams.isCommentDisable"
                >
                  <el-radio-button :label="1">关闭</el-radio-button>
                  <el-radio-button :label="0">开启</el-radio-button>
                </el-radio-group>
              </div>
              <div class="per-part flex-row-start-center">
                <span class="required">客户端下载视频</span>
                <el-radio-group
                  disabled
                  v-model="creativeParams.adDownloadStatus"
                >
                  <el-radio-button :label="1">关闭</el-radio-button>
                  <el-radio-button :label="0">开启</el-radio-button>
                </el-radio-group>
              </div>
              <div class="per-part flex-row-start-start">
                <span class="required">创意展现</span>
                <div>
                  <el-radio-group
                    disabled
                    v-model="creativeParams.creativeDisplayMode"
                  >
                    <el-radio-button label="CREATIVE_DISPLAY_MODE_CTR"
                    >优选模式
                    </el-radio-button
                    >
                    <el-radio-button label="CREATIVE_DISPLAY_MODE_RANDOM"
                    >轮播模式
                    </el-radio-button
                    >
                  </el-radio-group>
                  <p style="margin-top: 10px">
                    选择优选模式,系统会自动对效果好的创意进行展示量倾斜，创意效果越好展示量越高
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section>
            <h3>创意素材</h3>
            <div class="per-part flex-row-start-center cards-container">
              <div class="cards-item" v-for="(item, index) in materialList">
                <div class="item-body">
                  <div class="item-WH">
                    <el-tag>{{ item.widthAndHigh }}</el-tag>
                  </div>
                  <img
                    :src="
                      item.type == 2
                        ? item.url + '?vframe/jpg/offset/0'
                        : item.url
                    "
                    alt=""
                  />
                  <video
                    v-if="item.type == 2 && item.play"
                    autoplay
                    controls
                    :src="item.url"
                  ></video>
                  <i
                    v-if="item.type == 2"
                    @click="handleVideoPlay(item, index)"
                    slot="reference"
                    class="player iconfont icon-bofang"
                  ></i>
                  <p class="status">{{ imageMode[item.imageMode] }}</p>
                </div>
                <div class="item-footer">
                  <p class="name">{{ item.document }}</p>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </el-dialog>
    <!-- 计划审核建议 -->
    <el-drawer
      title="审核建议"
      :visible.sync="showRejectReason"
      size="800px"
      append-to-body
      :destroy-on-close="true"
    >
      <el-table :data="rejectData" style="width: 100%">
        <el-table-column prop="reject_item" label="审核内容" width="180">
          <template slot-scope="scope">
            <template v-if="!scope.row.reject_item"></template>
            <template v-else-if="scope.row.reject_item.includes('http')">
              <el-tag style="margin-bottom: 10px" type="danger">视频</el-tag>
              <br/>
              <el-popover placement="right" width="220px" trigger="hover">
                <img
                  v-if="scope.row.reject_item.includes('http')"
                  style="width: 220px; height: 340px"
                  :src="scope.row.reject_item"
                  alt=""
                />
                <img
                  slot="reference"
                  v-if="scope.row.reject_item.includes('http')"
                  style="width: 110px; height: 170px"
                  :src="scope.row.reject_item"
                  alt=""
                />
              </el-popover>
            </template>
            <template v-else>
              <el-tag type="danger">落地页</el-tag>
            </template>
          </template>
        </el-table-column>
        <el-table-column prop="reject_reason" label="审核建议">
          <template slot-scope="scope">
            <p
              style="margin-bottom: 6px"
              v-for="(item, index) in scope.row.reject_reason.split('\n')"
            >
              <!-- <span>{{ index + 1 }}</span> -->
              <span>{{ item }}</span>
            </p>
          </template>
        </el-table-column>
      </el-table>
    </el-drawer>
  </div>
</template>

<script>
import allPutStatus from "@/assets/js/putStatus.js";
import userDirectional from "../components/userDirectional";
import customSchedule from "@/components/custom/custom-schedule";
import {
  uploadAdGroupStatus,
  getUploadAdRecordDetail,
  uploadPlanGroupStatus,
  uploadAdGroupDetail,
  updateUploadAdGroupDetail,
  getUploadAdPlan,
  updateUploadAdPlan,
  getUploadAdCreative,
  getIndustryList,
} from "@/api/toutiao";

export default {
  props: {
    detailId: Number,
    detailType: {
      type: String,
      default: "ad",
    },
    landingType: String,
  },
  components: {
    customSchedule,
    userDirectional,
  },
  data() {
    return {
      showTemplate: false,
      recordPage: 1,
      recordPageSize: 15,
      recordTotal: 0,
      recordRowList: [
        {
          label: "ID",
          prop: "id",
        },
        {
          label: "账号类型",
          prop: "accountType",
          slot: true,
        },
        {
          label: "计划规则",
          prop: "planType",
          slot: true,
        },
        {
          label: "广告组名称",
          prop: "campaignName",
        },
        {
          label: "广告组预算",
          prop: "budget",
        },
        {
          label: "预算类型",
          prop: "budgetMode",
          slot: true,
        },
        {
          label: "广告组状态",
          prop: "operation",
          slot: true,
        },
        {
          label: "营销目的",
          prop: "marketingPurpose",
          slot: true,
        },
        {
          label: "创建时间",
          prop: "createdTime",
        },
      ],
      recordDetailRowList: [
        // {
        //   label: "账户id",
        //   prop: "advertiserId",
        // },
        {
          label: "账户名称",
          prop: "advertiserName",
          colWidth: "200px",
        },
        {
          label: "广告组名称",
          prop: "campaignName",
        },
        {
          label: "广告组状态",
          prop: "adStatus",
          slot: true,
          colWidth: "100px",
        },
        {
          label: "上传状态 ",
          prop: "status",
          slot: true,
          colWidth: "80px",
        },
        // {
        //   label: "失败原因",
        //   prop: "failReason",
        //   popover: true,
        // },
        {
          label: "计划名称",
          prop: "planName",
        },
        {
          label: "上传状态",
          prop: "planStatus",
          slot: true,
          colWidth: "80px",
        },

        {
          label: "计划投放状态",
          prop: "putStatus",
          slot: true,
        },
        {
          label: "创意类型",
          prop: "type",
          slot: true,
          colWidth: "80px",
        },
        {
          label: "上传状态",
          prop: "creativeStatus",
          slot: true,
          colWidth: "80px",
        },
        // {
        //   label: "失败原因",
        //   prop: "creativeFailReason",
        // },
      ],
      recordData: [],
      recordLoading: false,
      recordSearch: null,
      showRecordDetail: false,
      recordDetailLoading: false,
      allPutStatus,
      spanArr: [],
      spanArr2: [],
      pos: 0,
      pos2: 0,
      tableData: [],
      tmpDetailData: [],
      // 广告组详情
      showAdDetail: false,
      changeAdDetailLoading: false,
      adDetailData: null,
      adDetailLoading: false,
      currentAdId: null,
      adParams: {},
      // 计划组详情
      showPlanDetail: false,
      showRejectReason: false,
      changePlanDetailLoading: false,
      planDetailData: null,
      planDetailLoading: false,
      currentPlanId: null,
      currentPlanName: null,
      currentAdvertiserId: null,
      userDirectionalData: {},
      planParams: {},
      tmpInventoryType: [],
      inventoryCatalogList: [
        {
          name: "头条",
          value: "INVENTORY_FEED",
        },
        {
          name: "西瓜视频",
          value: "INVENTORY_VIDEO_FEED",
        },
        {
          name: "火山短视频",
          value: "INVENTORY_HOTSOON_FEED",
        },
        {
          name: "抖音短视频",
          value: "INVENTORY_AWEME_FEED",
        },
        {
          name: "番茄小说",
          value: "INVENTORY_TOMATO_NOVEL",
        },
        {
          name: "穿山甲",
          value: "INVENTORY_UNION_SLOT",
        },
      ],
      landingTypeList: [
        {
          label: "销售线索收集",
          value: "LINK",
        },
        {
          label: " 应用推广",
          value: "APP",
        },
        {
          label: "快应用",
          value: "QUICK_APP",
        },
      ],
      rejectData: [],
      // 创意详情
      currentCreativeId: null,
      showCreativeDetail: false,
      creativeParams: {},
      casProps: {
        value: "industry_id",
        label: "industry_name",
      },
      cascaderData: [],
      categorys: [],
      tmpcategoryString: null,
      adResourceReq: false,
      materialList: [],
      creativeLoading: false,
      imageMode: {
        CREATIVE_IMAGE_MODE_VIDEO_VERTICAL: "竖版视频",
        CREATIVE_IMAGE_MODE_VIDEO: "横板视频",
        CREATIVE_IMAGE_MODE_LARGE_VERTICAL: "竖版大图",
        CREATIVE_IMAGE_MODE_LARGE: "横板大图",
        CREATIVE_IMAGE_MODE_SMALL: "小图",
      },
      lastPlayIndex: 0,
      tmpscheduleTime: [],
    };
  },
  computed: {
    hasRejectReason() {
      return (data) => {
        if (!data.rejectReason) {
          return false;
        }
        let tmpReason = JSON.parse(data.rejectReason);
        if (!tmpReason.material.length && !tmpReason.title.length) {
          return false;
        } else {
          return true;
        }
      };
    },
  },
  methods: {
    // 切换投放场景时变化竞价策略
    handleSmartBidTypeChange(value) {
      if (value === "SMART_BID_CONSERVATIVE") {
        this.params.flowControlMode = "FLOW_CONTROL_MODE_SMOOTH";
      } else {
        this.params.flowControlMode = "FLOW_CONTROL_MODE_FAST";
      }
    },
    // 创意详情相关============
    handleVideoPlay(item, index) {
      this.$set(this.materialList[this.lastPlayIndex], "play", false);
      this.$set(this.materialList[index], "play", true);
      this.lastPlayIndex = index;
    },
    handleAddCategory() {
      if (!this.tmpcategoryString) {
        return;
      }
      this.categoryString = this.tmpcategoryString;
      let tmpcate = this.categoryString.includes(" ")
        ? this.categoryString.split(" ")
        : [this.categoryString];
      // 去重
      tmpcate = Array.from(new Set([...this.categorys, ...tmpcate]));
      this.categorys = tmpcate;
      this.tmpcategoryString = "";
    },
    // 还原标签
    handleRecoverTag() {
      // 还原标签
      this.categorys = this.creativeParams.adKeywords.split("$");
      let tmpstring = this.creativeParams.thirdIndustryId.toString();
      this.creativeParams.thirdIndustryId = [
        parseInt(tmpstring.substring(0, 4)),
        parseInt(tmpstring.substring(0, 6)),
        parseInt(tmpstring.substring(0, 8)),
      ];
      this.handleAddCategory();
      // 推广目的为非应用下载或者应用下载且download_type为"EXTERNAL_URL时"时广告来源必填
      if (
        this.creativeParams.landingType !== "APP" ||
        (this.creativeParams.landingType === "APP" &&
          this.creativeParams.downloadType === "EXTERNAL_URL")
      ) {
        this.adResourceReq = true;
      } else {
        this.adResourceReq = false;
      }
    },
    handleShowCreativeDetail() {
      this.showCreativeDetail = true;
      this.creativeLoading = true;
      getUploadAdCreative(this.currentCreativeId)
        .then((res) => {
          this.creativeParams = res;
          this.materialList = res.materialList;
          this.handleRecoverTag();
        })
        .finally(() => {
          this.creativeLoading = false;
        });
    },
    // 计划详情相关=============
    handleShowRejectReason(row) {
      if (!row.rejectReason) {
        return (this.rejectData = []);
      }
      let tmpresen = JSON.parse(row.rejectReason);
      this.rejectData = [...tmpresen.title, ...tmpresen.material];
      this.showRejectReason = true;
    },
    handleCellStyle(data) {
      if (
        data.columnIndex === 1 ||
        data.columnIndex === 4 ||
        data.columnIndex === 7
      ) {
        return "linkCell";
      }
    },
    handleGetScheduleTime(data) {
      this.planParams.scheduleTime = data;
    },
    handleChangePlanDetail() {
      this.$refs.userDirectional.handleSubmit();
      let tmpdata = this.$refs.userDirectional.tmpParams;
      if (!this.planParams.name) {
        return this.$message.error("请完善计划名称");
      }
      if (!this.planParams.budget || this.planParams.budget < 300) {
        return this.$message.error("请输入正确范围的预算");
      }
      // 投放时间
      if (this.planParams.scheduleType === "SCHEDULE_START_END") {
        if (
          !this.planParams.daterange ||
          this.planParams.daterange.length !== 2 ||
          this.planParams.daterange.includes("")
        ) {
          return this.$message.error("请设置投放时间");
        }
      }
      // 转化价
      if (
        this.planParams.smartBidType === "SMART_BID_CUSTOM" ||
        (this.planParams.smartBidType === "SMART_BID_CONSERVATIVE" &&
          this.planParams.adjustCpa === "1")
      ) {
        if (this.planParams.cpaBid < 0.1 || this.planParams.cpaBid > 10000) {
          return this.$message.error("请填写正确范围内的目标转化出价");
        }

        // else {
        //   this.planParams.bidMin = this.planParams.cpaBid;
        //   this.planParams.bidMax = this.planParams.cpaBid;
        // }
      }
      if (tmpdata) {
        let planTarget = {
          downloadMode: this.planParams.downloadMode,
        };
        // 直达链接
        if (this.planParams.openUrl) {
          planTarget.openUrl = this.planParams.openUrl;
        }
        let planBid = {
          flowControlMode: this.planParams.flowControlMode,
          budget: this.planParams.budget,
          scheduleType: this.planParams.scheduleType,
          cpaBid: this.planParams.cpaBid,
          // bidMin: this.planParams.bidMin,
          // bidMax: this.planParams.bidMax,
        };
        // 投放时间
        if (this.planParams.scheduleType === "SCHEDULE_START_END") {
          planBid.startTime = this.planParams.daterange[0];
          planBid.endTime = this.planParams.daterange[1];
        }
        let audience = {
          ...tmpdata,
        };
        this.changePlanDetailLoading = true;
        updateUploadAdPlan(this.currentPlanId, {
          name: this.planParams.name,
          planTarget,
          planBid,
          audience,
        })
          .then((res) => {
            this.$message.success("修改成功");
            this.showPlanDetail = false;
            this.handlePageChange();
          })
          .finally(() => {
            this.changePlanDetailLoading = false;
          });
      }
    },
    handleShowPlanDetail(id) {
      this.currentPlanId = id;
      this.planDetailLoading = true;
      getUploadAdPlan(id)
        .then((res) => {
          this.planDetailData = res;
          if (this.landingType === 'QUICK_APP') {
            const oeEvents = res.oeEvents;
            const externalActionName = oeEvents.externalAction ? oeEvents.externalAction === 'AD_CONVERT_TYPE_ACTIVE' ? '激活' : '激活且付费' : '';
            const deepExternalActionName = oeEvents.deepExternalAction ? oeEvents.deepExternalAction === 'AD_CONVERT_TYPE_ACTIVE' ? '激活' : '激活且付费' : '';
            this.planParams = {
              ...res.planBid,
              ...res.planRange,
              ...res.planTarget,
              ...res.oeEvents,
              externalActionName: externalActionName,
              deepExternalActionName: deepExternalActionName,
              landingType: this.landingType,
              name: res.name,
              status: res.status,
              putStatus: res.putStatus,
              operation: res.operation,
            };
          } else {
            this.planParams = {
              ...res.planBid,
              ...res.planRange,
              ...res.planTarget,
              landingType: this.landingType,
              name: res.name,
              status: res.status,
              putStatus: res.putStatus,
              operation: res.operation,
            };
          }
          // 还原定向包
          this.userDirectionalData = res.audience;
          // 还原广告大类checkbox
          if (this.planParams.inventoryType) {
            this.tmpInventoryType = this.planParams.inventoryType.split(",");
          }
          // 还原出价方式
          if (
            this.planParams.planBid &&
            (this.planParams.planBid.adjustCpa === "" ||
              !this.planParams.planBid.adjustCpa)
          ) {
            this.$set(this.planParams, "adjustCpa", 0);
            // this.params.adjustCpa = 0;
          }
          // 回显投放时间
          this.$set(this.planParams, "daterange", [
            this.planParams.startTime ? this.planParams.startTime : "",
            this.planParams.endTime ? this.planParams.endTime : "",
          ]);
          // 还原投放时段
          if (!this.planParams.scheduleTime) {
            this.$set(this.planParams, "scheduleTimeTmp", 1); //若不存在则默认不限
          } else {
            if (this.planParams.scheduleTime.includes("0")) {
              this.tmpscheduleTime = this.planParams.scheduleTime.split("");
              this.$set(this.planParams, "scheduleTimeTmp", 2);
            } else {
              this.$set(this.planParams, "scheduleTimeTmp", 1);
            }
          }
        })
        .finally(() => {
          this.planDetailLoading = false;
          this.showPlanDetail = true;
        });
    },
    // 广告组详情相关=========
    handleDetailClick(row, column, cell, event) {
      // 广告组详情
      if (column.property === "campaignName") {
        this.currentAdId = row.id;
        this.handleShowAdDetail(row.id);
      }
      if (column.property === "planName") {
        this.currentPlanId = row.planId;
        this.currentAdvertiserId = row.advertiserId;
        this.handleShowPlanDetail(row.planId);
      }
      if (column.property === "type") {
        this.currentCreativeId = row.creativeId;
        this.handleShowCreativeDetail();
      }
    },
    handleChangeAdDetail() {
      if (!this.adParams.campaignName) {
        return this.$message.error("广告组名不能为空");
      }
      if (this.adParams.budgetMode === "BUDGET_MODE_DAY") {
        if (this.adParams.budget < 300 || !this.adParams.budget) {
          return this.$message.error("预算必须大于300");
        }
      }
      this.changeAdDetailLoading = true;
      updateUploadAdGroupDetail(this.currentAdId, {
        campaignName: this.adParams.campaignName,
        budgetMode: this.adParams.budgetMode,
        budget:
          this.adParams.budgetMode === "BUDGET_MODE_DAY"
            ? this.adParams.budget
            : null,
      })
        .then((res) => {
          this.$message.success("修改成功");
          this.handlePageChange();
        })
        .finally(() => {
          this.showAdDetail = false;
          this.changeAdDetailLoading = false;
        });
    },

    handleShowAdDetail(id) {
      this.showAdDetail = true;
      this.adDetailLoading = true;
      uploadAdGroupDetail(id)
        .then((res) => {
          this.adDetailData = res;
          this.adParams = res;
        })
        .finally(() => {
          this.adDetailLoading = false;
        });
    },

    handlePageChange() {
      this.recordDetailLoading = true;
      if (this.detailType === "ad") {
        getUploadAdRecordDetail({
          adRuleId: this.detailId,
        })
          .then((res) => {
            this.tableData = res;
            this.getSpanArr(this.tableData);
            this.getSpanArr2(this.tableData);
            this.tmpDetailData = this.tableData.map((item) => ({
              ...item,
            }));
          })
          .finally(() => {
            this.recordDetailLoading = false;
          });
      } else {
        getUploadAdRecordDetail({
          adPlanId: this.detailId,
        })
          .then((res) => {
            this.tableData = res;
            this.getSpanArr(this.tableData);
            this.getSpanArr2(this.tableData);
            this.tmpDetailData = this.tableData.map((item) => ({
              ...item,
            }));
          })
          .finally(() => {
            this.recordDetailLoading = false;
          });
      }
    },
    // 修改单条计划状态
    handleChangePlanStatu(row, type) {
      this.recordDetailLoading = true;
      uploadPlanGroupStatus(row.planId, type)
        .then((res) => {
          this.$message.success("修改成功");
          this.handlePageChange();
        })
        .finally(() => {
          this.recordDetailLoading = false;
        });
    },
    // 批量修改计划状态
    handleChangePlanStatus(type) {
      this.$confirm("确定要批量修改计划状态?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let tmpArr = [];
        this.tmpDetailData.forEach((item) => {
          if (item.putStatusChecked) {
            tmpArr.push(item.planId);
          }
        });
        let setArr = new Set(tmpArr);
        tmpArr = [...setArr];
        if (!tmpArr.length) {
          return this.$message.error("请先勾选操作数据");
        }
        this.recordDetailLoading = true;
        uploadPlanGroupStatus(tmpArr.join(","), type)
          .then((res) => {
            this.$message.success("修改成功");
            this.handlePageChange();
          })
          .finally(() => {
            this.recordDetailLoading = false;
          });
      });
    },
    // 修改单个广告状态
    handleChangeAdStatu(row, type) {
      this.recordDetailLoading = true;
      uploadAdGroupStatus(row.id, type)
        .then((res) => {
          this.$message.success("修改成功");
          this.handlePageChange();
        })
        .finally(() => {
          this.recordDetailLoading = false;
        });
    },
    // 批量修改广告状态
    handleChangeAdStatus(type) {
      this.$confirm("确定要批量修改广告组状态?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let tmpArr = [];
        this.tmpDetailData.forEach((item) => {
          if (item.adStatusChecked) {
            tmpArr.push(item.id);
          }
        });
        let setArr = new Set(tmpArr);
        tmpArr = [...setArr];
        if (!tmpArr.length) {
          return this.$message.error("请先勾选操作数据");
        }
        this.recordDetailLoading = true;
        uploadAdGroupStatus(tmpArr.join(","), type)
          .then((res) => {
            this.$message.success("修改成功");
            this.handlePageChange();
          })
          .finally(() => {
            this.recordDetailLoading = false;
          });
      });
    },
    getSpanArr(data) {
      // spanArr和pos需要定义
      this.spanArr = [];
      // this.contentSpanArr = [];
      for (let i = 0; i < data.length; i++) {
        if (i === 0) {
          this.spanArr.push(1);
          this.pos = 0;
        } else {
          // 判断当前元素与上一个元素是否相同 (type要根据要合并的元素更改)
          if (data[i].advertiserId === data[i - 1].advertiserId) {
            this.spanArr[this.pos] += 1;
            this.spanArr.push(0);
          } else {
            this.spanArr.push(1);
            this.pos = i;
          }
        }
      }
    },
    getSpanArr2(data) {
      // spanArr和pos需要定义
      this.spanArr2 = [];
      // this.contentSpanArr = [];
      for (let i = 0; i < data.length; i++) {
        if (i === 0) {
          this.spanArr2.push(1);
          this.pos2 = 0;
        } else {
          // 判断当前元素与上一个元素是否相同 (type要根据要合并的元素更改)
          if (data[i].campaignName === data[i - 1].campaignName) {
            this.spanArr2[this.pos2] += 1;
            this.spanArr2.push(0);
          } else {
            this.spanArr2.push(1);
            this.pos2 = i;
          }
        }
      }
    },
    objectSpanMethod({rowIndex, columnIndex}) {
      if (columnIndex === 0) {
        const _row = this.spanArr[rowIndex];
        const _col = _row > 0 ? 1 : 0;
        return {
          rowspan: _row,
          colspan: _col,
        };
      }
      if (columnIndex > 0 && columnIndex < 4) {
        const _row = this.spanArr2[rowIndex];
        const _col = _row > 0 ? 1 : 0;
        return {
          rowspan: _row,
          colspan: _col,
        };
      }
    },
  },
  created() {
    this.handlePageChange();
    getIndustryList()
      .then((res) => {
        this.cascaderData = res;
      })
      .finally(() => {
      });
  },
};
</script>

<style lang='scss' scoped>
.button-list {
  // margin-left: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.plan-dialog-container {
  height: 700px;
  padding: 10px;
  overflow: auto;

  .plan-part {
    h3 {
      font-size: 20px;
      margin-bottom: 25px;
    }

    > div {
      margin-bottom: 20px;

      > span {
        width: 110px;
        text-align: right;
        margin-right: 15px;
      }
    }
  }

  .simulation-checkbox {
    border: 1px solid #32b81f !important;
    color: #32b81f !important;
    background-color: #f1fbf0 !important;
    padding: 5px 30px;
  }

  .normal-checkbox {
    padding: 5px 30px;
    background-color: #fff;
    color: #ccc;
    border: 1px solid #ccc;
  }

  .choose-target {
    margin-top: 10px;

    > p {
      padding: 4px 9px;
      border-radius: 4px;
      background: #f5f5f5;
      border: 1px solid #ccc;
      font-size: 14px;
    }
  }
}

.transfor-box {
  display: flex;
  flex-direction: column;
  height: 100%;

  .transform-container {
    flex: 1;
    max-height: 800px;
  }
}

.change-target-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

/deep/ .linkCell .cell {
  text-decoration: underline;
  text-underline-position: below;
  cursor: pointer;
}

// 创意
/deep/ .category-selector input {
  border-radius: 4px 0 0 4px !important;
}

h3 {
  font-size: 18px;
}

.per-part {
  margin-bottom: 20px;

  > span {
    width: 150px;
    text-align: right;
    margin-right: 10px;
  }
}

section {
  > div {
    margin-top: 20px;
  }
}

.tag-container {
  border: 1px solid #ccc;
  border-radius: 4px;

  > div {
    height: 500px;
  }

  .account {
    border-right: 1px solid #ccc;
    width: 200px;
    padding: 10px;

    p {
      line-height: 30px;
      cursor: pointer;
    }

    .active {
      color: #2fcba0;
    }
  }

  .choose-tag {
    padding: 10px;
    width: 600px;

    .selected-box {
      margin-left: 124px !important;
    }
  }
}

.selected-box {
  margin-left: 160px;

  /deep/ .el-card__header {
    padding: 4px !important;
  }

  /deep/ .el-card__body {
    padding: 10px;
    min-height: 48px;
  }

  .selected-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    span,
    button {
      padding: 5px !important;
    }
  }

  .selected-container {
    .el-tag {
      margin-top: 4px;
      margin-right: 6px;
    }
  }
}

.cards-container {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 0 20px;

  .cards-item {
    width: 260px;
    padding: 15px;
    height: 450px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-top: 10px;
    margin-right: 20px;

    .item-header {
      margin-left: auto;
      height: 20px;
    }

    .item-body {
      width: 200px;
      height: 370px;
      position: relative;

      .player {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        font-size: 48px;
        color: #ccc;
        cursor: pointer;
      }

      .item-WH {
        position: absolute;
        top: 4px;
        left: 4px;
        z-index: 3;
      }

      img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }

      video {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
      }

      p {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 2;
        color: #fff;
        height: 28px;
        line-height: 28px;
        font-size: 14px;
        text-align: center;
        background-color: #2f88ff;
      }
    }

    .item-footer {
      width: 100%;
      padding: 10px;

      p {
        line-height: 20px;
      }

      .url {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 12px;
      }

      .name {
        font-size: 14px;
        color: #000;
      }

      .id {
        color: #666;
        font-size: 12px;
      }
    }
  }

  .itemchecked {
    border: 1px solid #00bf8a;
  }
}

// 审核建议
.proposal {
  margin-left: 10px;
  font-size: 12px;
  cursor: pointer;
  color: #00bf8a;
}
</style>
