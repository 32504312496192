
<template>
<!-- 弃用 弃用 弃用-->
<!-- 改用 dynamic-table -->
<!-- 二次封装el-table 不支持多表头，自定义表头实现方式有待改善  -->
  <div class="custom-table">
    <div class="filter-list">
      <slot name="filter"></slot>
    </div>
    <!-- 暂不支持多表头，自定义表头实现方式有问题 -->
    <el-table
      v-loading="loading"
      style="width: 100%"
      :data="dataList"
      :height="height"
      :border='border'
      :span-method='arraySpanMethod'
      @sort-change="handleSortChange"
      @selection-change="handleSelectionChange"
    >

      <el-table-column v-if="isUseCheckbox" type="selection" width="55">
      </el-table-column>
      <el-table-column
        v-for="(item, index) in rowList"
        :key="index"
        :label="item.label"
        :prop="item.prop"
        :min-width="item.width"
        :sortable="item.sortable"
      >
      <!-- 自定义表头 -->
        <template slot='header' slot-scope="scope" >
          <template v-if='item.slotHeader'>
               <slot :name="`${item.prop}Header`"></slot>
          </template>
          <template v-else>
            {{item.label}}
          </template>
        </template>
        <template slot-scope="scope">
          <template
            v-if="
              scope.row[item.prop] !== undefined ||
              scope.row[item.prop] !== null ||
              !isNaN(scope.row[item.prop])
            "
          >
          <!-- 是否展示popover -->
            <template v-if="item.popover">
              <el-popover placement="right">
                <div class="origin-resource">
                  <h3>原素材</h3>
                  <img
                    v-if="item.mediaType === 'image'"
                    :src="scope.row[item.prop]"
                    alt=""
                  />
                  <video
                    muted
                    controls
                    v-else-if="item.mediaType === 'video'"
                    :src="scope.row[item.prop]"
                    alt=""
                  />
                  <audio
                    muted
                    controls
                    v-else-if="item.mediaType === 'audio'"
                    :src="scope.row[item.prop]"
                  ></audio>
                  <div v-else>{{ scope.row[item.prop] }}</div>
                </div>
                <div slot="reference" class="thumbnail">
                  <img
                    v-if="item.mediaType === 'image'"
                    :src="scope.row[item.prop]"
                    alt=""
                  />
                  <video
                    muted
                    controls
                    v-else-if="item.mediaType === 'video'"
                    :src="scope.row[item.prop]"
                    alt=""
                  />
                  <audio
                    muted
                    controls
                    v-else-if="item.mediaType === 'audio'"
                    :src="scope.row[item.prop]"
                  ></audio>
                  <div v-else class="line-overflow">{{ scope.row[item.prop] }}</div>
                </div>
              </el-popover>
            </template>
            <!-- 是否需要用a标签高亮 链接格式-->
            <template v-else-if="item.link">
              <span class="line-overflow">
                <a :href="scope.row[item.prop]" target="_blank">
                  {{ scope.row[item.prop] }}
                </a>
              </span>
            </template>
            <!-- 是否需要tag标签进行包裹 -->
            <template v-else-if="item.tag">
              <el-tag
                :type="getTagType(item.prop, scope.row[item.prop]).type"
                >{{ getTagType(item.prop, scope.row[item.prop]).text }}</el-tag
              >
            </template>
            <!-- 是否需要用switch标签进行包裹 -->
            <template v-else-if="item.switch">
              <el-switch
                @change="handleEvent(scope.row, scope.row[item.prop])"
                v-model="scope.row[item.prop]"
                :active-value="1"
                :inactive-value="0"
                active-text="开启"
                inactive-text="关闭"
              ></el-switch>
            </template>
            <!-- 自定义slot -->
            <template v-else-if='item.slot'>
              <slot :name='item.prop' :row='scope.row'></slot>
            </template>
            <!-- 其它普通字段展示 -->
            <template v-else>
              <div>{{scope.row[item.prop]}}</div>
            </template>
          </template>
          <!-- 没有相关字段 -->
          <template v-else>
            <div>--</div>
          </template>
        </template>
      </el-table-column>
      <!-- 操作栏自定义 -->
      <el-table-column
        label="操作"
        :width="operationWidth"
        v-if="isShowOperation"
        fixed="right"
      >
        <template slot-scope="scope">
          <slot name="buttonOperation" :row="scope.row"> </slot>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-if="isShowPagination"
      :total="total"
      :current-page="page"
      :page-size="pageSize"
      @current-change="handlePageChange"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  name: 'CTable',
  props: {
    // * 数据源
    dataList: {
      type: Array,
      default: () => []
    },
    border: {
      type: Boolean,
      default: false
    },
    // * 表格高度
    height: {
      type: String
      // default: "70vh",
    },
    mergeCells: {
      type: Boolean,
      default: false
    },
    // * 操作栏宽度
    operationWidth: {
      type: String,
      default: '120'
    },
    // *行列表数据
    rowList: {
      type: Array,
      default: () => []
    },
    // *表格是否展示多选框
    isUseCheckbox: {
      type: Boolean,
      default: false
    },
    total: {
      type: Number,
      default: () => 0
    },
    page: {
      type: Number,
      default: () => 1
    },
    pageSize: {
      type: Number,
      default: () => 15
    },
    loading: {
      type: Boolean,
      default: false
    },
    // !是否显示分页
    isShowPagination: {
      type: Boolean,
      default: true
    },
    // !表格是否显示操作栏
    isShowOperation: {
      type: Boolean,
      default: true
    },
    // ! 针对 在表格里面渲染tag标签是有用
    tagInfo: {
      type: Array,
      defualt: () => []
    },
    mergeCellInfo: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      selection: [],
      colorList: ['success', 'info', 'danger', 'waning', '']
    }
  },
  watch: {},
  mounted () {
  },
  methods: {
    handleSortChange (val) {
      this.$emit('sortChange', val)
    },
    handleSelectionChange (val) {
      this.$emit('selectChange', val)
    },
    handlePageChange (page) {
      this.$emit('pageChange', page)
    },
    handleEvent (info, val) {
      this.$emit('handleEvent', {
        info,
        val
      })
    },
    arraySpanMethod ({ row, column, rowIndex, columnIndex }) {
      if (!this.mergeCells) return
      const arr = Object.keys(this.mergeCellInfo)
      for (let i = 0; i < arr.length; i++) {
        const key = arr[i]
        const index = this.rowList.findIndex(item => item.prop === key)
        if (columnIndex === index) {
          if (rowIndex % this.dataList.length === 0) {
            return {
              colspan: 1,
              rowspan: this.dataList.length
            }
          } else {
            return {
              rowspan: 0,
              colspan: 0
            }
          }
        }
      }
      if (this.isShowOperation) {
        if (columnIndex === this.rowList.length) {
          if ((rowIndex % this.dataList.length === 0)) {
            return {
              colspan: 1,
              rowspan: this.dataList.length
            }
          } else {
            return {
              rowspan: 0,
              colspan: 0
            }
          }
        }
      }
    },
    getTagType (prop, val) {
      if (Array.isArray(this.tagInfo) && this.tagInfo.length > 0) {
        const tagList = this.tagInfo.find((item) => item.tagName === prop)
        let targetIndex
        if (typeof val === 'number') {
          targetIndex = val
        }
        if (typeof val === 'string') {
          targetIndex = tagList.tagVal.findIndex((item) => item.val === val)
        }
        return {
          type: this.colorList[targetIndex],
          text: tagList.tagVal[targetIndex].text
        }
      }
    }
  },
  components: {}
}
</script>
<style lang='scss' scoped>
.filter-list {
  margin-bottom: 20px;
}
/deep/.el-pagination {
  text-align: right;
}
.banner-img,
.banner-video {
  width: 100px;
}
.origin-source {
  max-width: 600px;
}
.thumbnail {
  width: 100%;
  img {
    width: 100%;
    object-fit: contain;
    max-width: 200px;
    max-height: 150px;
  }
}
.thumbnail-video {
  width: 100px;
}
.resource-video {
  max-width: 300px;
}
</style>
