<template>
  <div>
    <div class="flex-row-between-start">
      <div>
        <section class="user-orientation">
          <!-- <h3>用户定向</h3> -->
          <div class="per-part flex-row-start-start">
            <span style="margin-top: 10px" @click="handleReductionCity"
              >地域</span
            >
            <div>
              <div class="region-type">
                <el-radio-group v-model="params.district">
                  <el-radio-button label="NONE">不限</el-radio-button>
                  <el-radio-button label="CITY">按省市</el-radio-button>
                  <el-radio-button label="COUNTY">按区县</el-radio-button>
                  <el-radio-button label="BUSINESS_DISTRICT"
                    >按商圈</el-radio-button
                  >
                </el-radio-group>
              </div>
              <div
                class="region-select flex-row-start-start"
                v-if="params.district !== 'NONE'"
              >
                <div class="city-list">
                  <el-input
                    placeholder="输入关键字进行过滤"
                    v-model="filterText"
                    v-if="
                      !['BUSINESS_DISTRICT', 'NONE'].includes(params.district)
                    "
                  >
                  </el-input>
                  <el-cascader
                    v-else
                    v-model="params.test"
                    :options="options"
                    :props="{ label: 'name', value: 'id' }"
                    clearable
                    @change="handleCascaderChange"
                    style="width: 100%"
                  >
                  </el-cascader>
                  <el-checkbox
                    v-if="
                      !['NONE', 'COUNTY'].includes(params.district) &&
                      cityList.length > 0
                    "
                    style="margin-left: 23px; margin-top: 5px"
                    v-model="isCheckAll"
                    >全选</el-checkbox
                  >
                  <el-tree
                    class="city-tree"
                    ref="cityTree"
                    :style="getCityTreeStyle()"
                    show-checkbox
                    node-key="id"
                    check-on-click-node
                    :default-checked-keys="echoCityData"
                    :data="cityList"
                    :filter-node-method="filterNode"
                    @check="handleCheckChange"
                  >
                    <span slot-scope="{ node, data }">{{ data.name }}</span>
                  </el-tree>
                </div>
                <div class="city-select">
                  <div class="title-info">
                    <span>已选</span>
                    <span
                      style="font-weight: bold; color: #00bf8a; cursor: pointer"
                      @click="clearChooseCityList"
                      >清空</span
                    >
                  </div>
                  <div class="city-select-list">
                    <el-tag
                      style="margin-bottom: 5px"
                      v-for="item in chooseCityList"
                      :key="item.id"
                      closable
                      @close="closeChooseCityItem(item)"
                    >
                      {{ item.name }}
                    </el-tag>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="per-part flex-row-start-center">
            <span>性别</span>
            <el-radio-group v-model="params.gender">
              <el-radio-button label="NONE">不限</el-radio-button>
              <el-radio-button label="GENDER_MALE">男</el-radio-button>
              <el-radio-button label="GENDER_FEMALE">女</el-radio-button>
            </el-radio-group>
          </div>
          <div class="per-part flex-row-start-center">
            <span :class="{ required: this.params.district !== 'NONE' }"
              >位置类型用户</span
            >
            <el-radio-group v-model="params.locationType">
              <el-radio-button label="ALL">该地区所有用户</el-radio-button>
              <el-radio-button label="CURRENT">正在该地区</el-radio-button>
              <el-radio-button label="HOME">居住在该地区</el-radio-button>
              <el-radio-button label="TRAVEL">该地区旅行</el-radio-button>
            </el-radio-group>
          </div>
          <div class="per-part flex-row-start-center">
            <span>年龄</span>
            <el-checkbox-group
              @change="handleCheckout('out', params.age, 6, 'NONE')"
              v-model="params.age"
            >
              <el-checkbox-button
                label="NONE"
                @change="(e) => handleCheckout(e, params.age, 6, 'NONE')"
                >不限</el-checkbox-button
              >
              <el-checkbox-button label="AGE_BETWEEN_18_23"
                >18-23</el-checkbox-button
              >
              <el-checkbox-button label="AGE_BETWEEN_24_30"
                >24-30</el-checkbox-button
              >
              <el-checkbox-button label="AGE_BETWEEN_31_40"
                >31-40</el-checkbox-button
              >
              <el-checkbox-button label="AGE_BETWEEN_41_49"
                >41-49</el-checkbox-button
              >
              <el-checkbox-button label="AGE_ABOVE_50">50+</el-checkbox-button>
            </el-checkbox-group>
          </div>
          <div class="per-part flex-row-start-start">
            <span style="margin-top: 10px">自定义人群</span>
            <div>
              <el-radio-group v-model="isCustomCrowd">
                <el-radio-button label="NONE">不限</el-radio-button>
                <el-radio-button label="CUSTOM">自定义</el-radio-button>
              </el-radio-group>
              <div
                style="
                  display: flex;
                  justify-content: flex-start;
                  align-items: flex-start;
                "
                v-if="isCustomCrowd === 'CUSTOM'"
              >
                <dy-table
                  :dataList="customAudienceList"
                  class="audience-table"
                  :rowList="audienceRowList"
                  :border="true"
                  height="300px"
                >
                  <template #operation="{ row }">
                    <el-button
                      type="text"
                      size="mini"
                      class="button-mini"
                      @click="operationAudienceCrowd('include', row)"
                      >定向</el-button
                    >
                    <el-button
                      type="text"
                      size="mini"
                      class="button-mini"
                      @click="operationAudienceCrowd('exclude', row)"
                      >排除</el-button
                    >
                  </template>
                </dy-table>
                <div class="crowd-select">
                  <div class="flex-row-between-center">
                    <span>已选</span>
                    <span
                      style="font-weight: bold; color: #00bf8a; cursor: pointer"
                      @click="clearAudience"
                      >清空</span
                    >
                  </div>
                  <div class="flex-row-start-start" style="margin-top: 20px">
                    <span
                      style="margin-right: 20px; padding-bottom: 10px"
                      @click="retargetingTagsType = 'include'"
                      :class="{
                        'is-selected': retargetingTagsType === 'include',
                      }"
                      >定向({{ params.retargetingTagsInclude.length }})</span
                    >
                    <span
                      style="padding-bottom: 10px"
                      @click="retargetingTagsType = 'exclude'"
                      :class="{
                        'is-selected': retargetingTagsType === 'exclude',
                      }"
                      >排除({{ params.retargetingTagsExclude.length }})</span
                    >
                  </div>
                  <div class="crowd-list">
                    <template v-if="retargetingTagsType === 'include'">
                      <p
                        v-for="(item, index) in params.retargetingTagsInclude"
                        :key="item.custom_audience_id"
                      >
                        <el-tag
                          closable
                          @close="handleAudience('include', index)"
                        >
                          {{ item.name }}
                        </el-tag>
                      </p>
                    </template>
                    <template v-else :key="">
                      <p
                        v-for="(item, index) in params.retargetingTagsExclude"
                        :key="item.custom_audience_id"
                      >
                        <el-tag
                          closable
                          @close="handleAudience('exclude', index)"
                        >
                          {{ item.name }}
                        </el-tag>
                      </p>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="per-part flex-row-start-center">
            <span>兴趣行为</span>
            <el-radio-group v-model="params.interestActionMode">
              <el-radio-button label="UNLIMITED">不限</el-radio-button>
              <el-radio-button label="RECOMMEND">系统推荐</el-radio-button>
            </el-radio-group>
          </div>
          <!-- <div class="per-part flex-row-start-center">
              <span>抖音达人</span>
              <el-radio-group v-model="tikTok">
                <el-radio-button label="NONE">不限</el-radio-button>
              </el-radio-group>
            </div> -->
          <div class="per-part flex-row-start-center">
            <span>平台</span>
            <el-checkbox-group
              v-model="params.platform"
              @change="handleCheckout('out', params.platform, 4, 'NONE')"
            >
              <el-checkbox-button
                @change="(e) => handleCheckout(e, params.platform, 4, 'NONE')"
                label="NONE"
                >不限</el-checkbox-button
              >
              <el-checkbox-button
                :disabled="params.platform.includes('PC')"
                label="ANDROID"
                >安卓</el-checkbox-button
              >
              <el-checkbox-button
                :disabled="params.platform.includes('PC')"
                label="IOS"
                >苹果</el-checkbox-button
              >
              <el-checkbox-button label="PC">PC</el-checkbox-button>
            </el-checkbox-group>
          </div>
          <div class="per-part flex-row-start-center">
            <span>网络</span>
            <el-checkbox-group
              v-model="params.ac"
              @change="handleCheckout('out', params.ac, 5, 'NONE')"
            >
              <el-checkbox-button
                @change="(e) => handleCheckout(e, params.ac, 5, 'NONE')"
                label="NONE"
                >不限</el-checkbox-button
              >
              <el-checkbox-button label="WIFI">WiFi</el-checkbox-button>
              <el-checkbox-button label="2G">2G</el-checkbox-button>
              <el-checkbox-button label="3G">3G</el-checkbox-button>
              <el-checkbox-button label="4G">4G</el-checkbox-button>
            </el-checkbox-group>
          </div>
          <div class="per-part flex-row-start-center">
            <span>过滤已转化用户</span>
            <el-radio-group v-model="params.hideIfConverted">
              <el-radio-button label="NO_EXCLUDE">不过滤</el-radio-button>
              <el-radio-button label="AD">广告计划</el-radio-button>
              <el-radio-button label="CAMPAIGN">广告主</el-radio-button>
              <el-radio-button label="ADVERTISER">广告账户</el-radio-button>
              <el-radio-button label="APP">APP</el-radio-button>
              <el-radio-button label="CUSTOMER">公司账户</el-radio-button>
            </el-radio-group>
          </div>
          <div class="per-part flex-row-start-center">
            <span>已安装用户</span>
            <el-radio-group v-model="params.hideIfExists">
              <el-radio-button :label="0">不限</el-radio-button>
              <el-radio-button :label="1">过滤</el-radio-button>
              <el-radio-button :label="2">定向</el-radio-button>
            </el-radio-group>
          </div>
          <div class="per-part flex-row-start-start">
            <span style="margin-top: 10px">文章分类</span>
            <div>
              <el-radio-group v-model="articleType">
                <el-radio-button label="NONE">不限</el-radio-button>
                <el-radio-button label="CUSTOM">自定义</el-radio-button>
              </el-radio-group>
              <div
                class="flex-row-start-start"
                v-if="articleType === 'CUSTOM'"
                style="margin-top: 10px"
              >
                <div class="article-kinds">
                  <el-checkbox v-model="isCheckAllArticle" @change="change"
                    >全选</el-checkbox
                  >
                  <div class="tree-container">
                    <p v-for="(item, index) in articleList" :key="item.value">
                      <label
                        @click="handleArticleChange(item, index, 'article')"
                      >
                        <i
                          v-if="!item.checked"
                          class="iconfont icon-xuanze"
                        ></i>
                        <i
                          v-else
                          class="iconfont icon-xuanze2-copy"
                          style="color: #00bf8a"
                        ></i>
                        <span
                          :style="{
                            color: item.checked ? '#00bf8a' : 'inherit',
                          }"
                          >{{ item.label }}</span
                        >
                      </label>
                    </p>
                  </div>
                </div>
                <div class="article-selected">
                  <div class="flex-row-between-center title-info">
                    <span>已选</span>
                    <span
                      style="font-weight: bold; color: #00bf8a; cursor: pointer"
                      @click="clearArticle"
                      >清空</span
                    >
                  </div>
                  <div class="article-selected-container">
                    <p
                      v-for="(item, index) in articleList.filter(
                        (item) => item.checked
                      )"
                      :key="item.value"
                    >
                      <el-tag
                        closable
                        @close="handleArticleCancel(item, index, 'article')"
                        >{{ item.label }}</el-tag
                      >
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="per-part flex-row-start-center">
            <span>运营商</span>
            <el-checkbox-group
              @change="handleCheckout('out', params.carrier, 4, 'NONE')"
              v-model="params.carrier"
            >
              <el-checkbox-button
                @change="(e) => handleCheckout(e, params.carrier, 4, 'NONE')"
                label="NONE"
                >不限</el-checkbox-button
              >
              <el-checkbox-button label="MOBILE">移动</el-checkbox-button>
              <el-checkbox-button label="UNICOM">联通</el-checkbox-button>
              <el-checkbox-button label="TELCOM">电信</el-checkbox-button>
            </el-checkbox-group>
          </div>
          <div class="per-part flex-row-start-center">
            <span>新用户</span>
            <el-checkbox-group
              @change="handleCheckout('out', params.activateType, 4, 'NONE')"
              v-model="params.activateType"
            >
              <el-checkbox-button
                @change="
                  (e) => handleCheckout(e, params.activateType, 4, 'NONE')
                "
                label="NONE"
                >不限</el-checkbox-button
              >
              <el-checkbox-button label="WITH_IN_A_MONTH"
                >一个月以内</el-checkbox-button
              >
              <el-checkbox-button label="ONE_MONTH_2_THREE_MONTH"
                >一个月到三个月</el-checkbox-button
              >
              <el-checkbox-button label="THREE_MONTH_EAILIER"
                >三个月或更早</el-checkbox-button
              >
            </el-checkbox-group>
          </div>
          <div class="per-part flex-row-start-start">
            <span style="margin-top: 10px">手机品牌</span>
            <div>
              <el-radio-group v-model="chooseDeviceBrandType">
                <el-radio-button label="NONE">不限</el-radio-button>
                <el-radio-button label="CUSTOM">按品牌</el-radio-button>
              </el-radio-group>
              <div
                class="flex-row-start-start"
                v-if="chooseDeviceBrandType === 'CUSTOM'"
                style="margin-top: 10px"
              >
                <div class="article-kinds">
                  <el-checkbox
                    v-model="isCheckAllDeviceBound"
                    @change="
                      (val) => {
                        change(val, 'deviceBound');
                      }
                    "
                    >全选</el-checkbox
                  >
                  <div class="tree-container">
                    <p v-for="(item, index) in deviceBrand" :key="item.value">
                      <label
                        @click="handleArticleChange(item, index, 'deviceBound')"
                      >
                        <i
                          v-if="!item.checked"
                          class="iconfont icon-xuanze"
                        ></i>
                        <i
                          v-else
                          class="iconfont icon-xuanze2-copy"
                          style="color: #00bf8a"
                        ></i>
                        <span
                          :style="{
                            color: item.checked ? '#00bf8a' : 'inherit',
                          }"
                          >{{ item.label }}</span
                        >
                      </label>
                    </p>
                  </div>
                </div>
                <div class="article-selected">
                  <div class="flex-row-between-center title-info">
                    <span>已选</span>
                    <span
                      style="font-weight: bold; color: #00bf8a; cursor: pointer"
                      @click="clearArticle"
                      >清空</span
                    >
                  </div>
                  <div class="article-selected-container">
                    <p
                      v-for="(item, index) in deviceBrand.filter(
                        (item) => item.checked
                      )"
                      :key="item.value"
                    >
                      <el-tag
                        closable
                        @close="handleArticleCancel(item, index, 'deviceBound')"
                        >{{ item.label }}</el-tag
                      >
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="per-part flex-row-start-center">
            <span>手机价格</span>
            <el-radio-group v-model="params.launchPrice">
              <el-radio-button label="NONE">不限</el-radio-button>
              <el-radio-button label="CUSTOM">自定义</el-radio-button>
            </el-radio-group>
          </div>
          <div
            class="per-part flex-row-start-center"
            v-if="params.launchPrice === 'CUSTOM'"
          >
            <span></span>
            <div class="price-container">
              <priceSlider
                :price="phonePrice"
                :width="500"
                @change="handlePriceChange"
              />
            </div>
          </div>
          <div class="per-part flex-row-start-center">
            <span>智能放量</span>
            <el-radio-group v-model="params.autoExtendEnabled">
              <el-radio-button :label="0">不启用</el-radio-button>
              <el-radio-button :label="1">启用</el-radio-button>
            </el-radio-group>
          </div>
          <div
            class="per-part flex-row-start-center"
            v-if="params.autoExtendEnabled === 1"
            style="margin-bottom: 0"
          >
            <span></span>
            <div class="ai-largesse">
              <div class="title-info">
                <span>可放开定向</span>
                <span
                  style="font-weight: bold; color: #00bf8a; cursor: pointer"
                  @click="params.autoExtendTargets = []"
                  >清空</span
                >
              </div>
              <el-checkbox-group v-model="params.autoExtendTargets">
                <el-checkbox label="AGE">年龄</el-checkbox>
                <el-checkbox label="REGION">地域</el-checkbox>
                <el-checkbox label="GENDER">性别</el-checkbox>
                <el-checkbox label="INTEREST_ACTION">行为兴趣</el-checkbox>
                <el-checkbox label="CUSTOM_AUDIENCE">自定人群-定向</el-checkbox>
              </el-checkbox-group>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getAudience,
  getRegion,
  getCustomCrowd,
  addAudience,
  deleteAudience,
  editAudience,
} from "@/api/toutiao.js";
import priceSlider from "./priceSlider";
import { articleList, deviceBrand } from "@/views/small-plane/localData.js";
import axios from "axios";
export default {
  props: {
    advertiserId: Number,
    userData: Object,
  },
  data() {
    return {
      page: 1,
      total: 0,
      editId: null,
      search: "",
      type: null,
      options: [], // 商圈相关信息
      articleList,
      deviceBrand,
      cityList: [], // 城市数据
      dataList: [],
      pageSize: 9999,
      tikTok: "NONE", // 抖音达人
      loading: false,
      filterText: "",
      copyOptions: [], // 商圈相关信息副本
      copyCityList: [], // 城市数据副本
      isCheckAll: false, // 地域是否勾选全选
      chooseCityList: [],
      articleKeyWords: "",
      articleType: "NONE", // 文章分类
      isCustomCrowd: "NONE", // 自定义人群的类型
      customAudienceList: [], //  人群包数据
      isCheckAllArticle: false, // 是否全选文章类型
      isCheckAllDeviceBound: false, // 是否全选手机品牌
      chooseDeviceBrandType: "NONE",
      retargetingTagsType: "include", //
      echoCityData: [],
      tmpData: {},
      packList: [],
      tmpPackList: [],
      tagsIncludeString: [],
      tagsExcludeString: [],
      // 定向包参数
      params: {
        career: [], // 职业选项
        ac: ["NONE"],
        age: ["NONE"],
        gender: "NONE",
        hideIfExists: 0,
        district: "NONE", // 地域
        carrier: ["NONE"], //  运营商
        platform: ["NONE"], // 平台
        launchPrice: 0, //手机价格
        locationType: "ALL", //位置类型
        autoExtendEnabled: 0, // 智能放量
        autoExtendTargets: [], // 智能放量
        activateType: ["NONE"], // 新用户
        retargetingTagsExclude: [], // 定向人群
        retargetingTagsInclude: [], // 排除人群
        hideIfConverted: "AD", // 已过滤转化用户
        interestActionMode: "UNLIMITED", // 兴趣行为
      },
      audienceRowList: [
        {
          label: "名称",
          prop: "name",
          popover: true,
        },
        {
          label: "覆盖人数",
          prop: "cover_num",
          popover: true,
        },
        {
          label: "属性",
          prop: "tag",
        },
        {
          label: "操作",
          action: "operation",
          width: "150",
        },
      ],
      chooseAccountList: [
        {
          name: "乐佳奇-T1-40",
        },
        {
          name: "乐佳奇-T1-37",
        },
        {
          name: "乐佳奇-T1-36",
        },
        {
          name: "乐佳奇-T1-50",
        },
      ],
      rowList: [
        {
          label: "id",
          prop: "id",
        },
        {
          label: "定向包名称",
          prop: "name",
        },
        {
          label: "定向包描述",
          prop: "description",
        },
        {
          label: "平台",
          prop: "platform",
        },
        {
          label: "操作",
          action: "operation",
        },
      ],
      tmpParams: null,
      phonePrice: null,
    };
  },
  watch: {
    filterText(val) {
      this.$refs.cityTree.filter(val);
    },
    isCheckAll: {
      handler(newV) {
        if (newV) {
          const ids = this.cityList.map((item) => item.id);
          this.$refs.cityTree.setCheckedKeys(ids);
          this.$nextTick(() => {
            const nodes = this.$refs.cityTree.getCheckedNodes();
            if (this.params.district !== "BUSINESS_DISTRICT") {
              const firstLevelNodes = nodes.filter((item) => +item.level === 1);
              this.chooseCityList = firstLevelNodes;
            } else {
              // ! 防止重复数据添加
              nodes.forEach((node) => {
                const val = this.chooseCityList.find(
                  (key) => key.id === node.id
                );
                if (!val) {
                  this.chooseCityList.push(node);
                }
              });
            }
          });
        } else {
          this.$refs.cityTree.setCheckedKeys([]);
          if (this.params.district !== "BUSINESS_DISTRICT") {
            this.chooseCityList = [];
          } else {
            const nodes = this.cityList;
            nodes.forEach((item) => {
              const id = item.id;
              const targetIndex = this.chooseCityList.findIndex(
                (val) => val.id === id
              );
              if (targetIndex > -1) {
                this.chooseCityList.splice(targetIndex, 1);
              }
            });
          }
        }
      },
    },
    "params.district": {
      handler(newV) {
        this.chooseCityList = [];
        this.isCheckAll = false;
        const excludeCityIds = [50, 11, 12, 31];
        if (newV === "CITY") {
          const arr = JSON.parse(JSON.stringify(this.copyCityList));
          arr.forEach((item) => {
            if (excludeCityIds.includes(item.id)) {
              item.children = [];
            } else {
              for (let j = 0; j < item.children.length; j++) {
                const val = item.children[j];
                val.children = [];
              }
            }
          });
          this.cityList = arr;
        }
        if (newV === "COUNTY") {
          const arr = JSON.parse(JSON.stringify(this.copyCityList));
          arr.forEach((item) => {
            item.disabled = true;
            if (!excludeCityIds.includes(item.id)) {
              const children = item.children;
              if (children.length > 0) {
                for (let j = 0; j < children.length; j++) {
                  children[j].disabled = true;
                }
              }
            }
          });
          this.cityList = arr;
        }
        // 商圈
        if (newV === "BUSINESS_DISTRICT") {
          this.cityList = [];
          getRegion({
            regionType: newV,
          }).then((res) => {
            this.copyOptions = res;
            const arr = JSON.parse(JSON.stringify(this.copyOptions));
            arr.forEach((city) => {
              city.children.forEach((county) => {
                delete county.children;
              });
            });
            this.options = arr;
            // 回显商圈
            // this.params.test = [51, 510100];
            // this.handleCascaderChange(this.params.test);
          });
        }
      },
    },
    isCustomCrowd: {
      handler(newV) {
        if (newV === "CUSTOM") {
          this.customCrowd();
        }
      },
    },
  },
  components: {
    priceSlider,
  },
  // inject: ["templateDetial"],
  methods: {
    handlePriceChange(data) {
      this.phonePrice = data;
    },
    handleConfirm() {
      this.packList = [];
      this.params = {
        career: [], // 职业选项
        ac: ["NONE"],
        age: ["NONE"],
        gender: "NONE",
        hideIfExists: 0,
        district: "NONE", // 地域
        carrier: ["NONE"], //  运营商
        platform: ["NONE"], // 平台
        launchPrice: "NONE", //手机价格
        locationType: "ALL", //位置类型
        autoExtendEnabled: 0, // 智能放量
        autoExtendTargets: [], // 智能放量
        activateType: ["NONE"], // 新用户
        retargetingTagsExclude: [], // 定向人群
        retargetingTagsInclude: [], // 排除人群
        hideIfConverted: "AD", // 已过滤转化用户
        interestActionMode: "UNLIMITED", // 兴趣行为
      };
      this.tmpPackList.forEach((item) => {
        this.packList.push({
          id: item.id,
          name: item.name,
        });
      });
      // this.tmpData.audiences = this.packList;
    },
    handleEditPack(row) {
      // return;
      this.isEdit = true;
      this.params = {
        ...row,
      };
      if (row.autoExtendTargets) {
        this.$set(
          this.params,
          "autoExtendTargets",
          row.autoExtendTargets.split(",")
        );
      } else {
        this.$set(this.params, "autoExtendTargets", []);
      }
      if (row.age) {
        this.$set(this.params, "age", row.age.split(","));
      }
      // 自定义人群
      if (row.retargetingTagsInclude || row.retargetingTagsExclude) {
        this.isCustomCrowd = "CUSTOM";
        this.tagsIncludeString = row.retargetingTagsInclude
          ? row.retargetingTagsInclude.split(",")
          : [];
        this.tagsExcludeString = row.retargetingTagsExclude
          ? row.retargetingTagsExclude.split(",")
          : [];
        this.params.retargetingTagsInclude = [];
        this.params.retargetingTagsExclude = [];
      } else {
        this.isCustomCrowd = "NONE";
        this.tagsIncludeString = [];
        this.tagsExcludeString = [];
        this.params.retargetingTagsInclude = [];
        this.params.retargetingTagsExclude = [];
      }
      // 平台
      if (row.platform) {
        this.$set(this.params, "platform", row.platform.split(","));
      }
      //网络
      if (row.ac) {
        this.$set(this.params, "ac", row.ac.split(","));
      }
      if (!row.hideIfExists) {
        this.$set(this.params, "hideIfExists", 0);
      }
      // 运营商
      if (row.carrier) {
        this.$set(this.params, "carrier", row.carrier.split(","));
      }
      // 新用户
      if (row.activateType) {
        this.$set(this.params, "activateType", row.activateType.split(","));
      }
      // 手机品牌
      // 文章分类
      if (row.articleCategory !== "NONE") {
        this.articleType = "CUSTOM";
        let tmparticle = row.articleCategory.split(",");
        this.$set(this.params, "articleCategory", tmparticle);
        tmparticle.forEach((item) => {
          this.articleList.forEach((item2) => {
            if (item2.value == item) {
              return (item2.checked = true);
            }
          });
        });
      } else {
        this.articleType = "NONE";
      }
      // 手机品牌
      if (row.deviceBrand != "NONE" && row.deviceBrand != "") {
        this.chooseDeviceBrandType = "CUSTOM";
        let tmpdeviceBrand = row.deviceBrand.split(",");
        this.$set(this.params, "deviceBrand", tmpdeviceBrand);
        tmpdeviceBrand.forEach((item) => {
          this.deviceBrand.forEach((item2) => {
            if (item2.value == item) {
              return (item2.checked = true);
            }
          });
        });
      } else {
        this.chooseDeviceBrandType = "NONE";
      }
      // 手机价格
      if (row.launchPrice != "NONE") {
        this.phonePrice = row.launchPrice.split(",");
        this.params.launchPrice = "CUSTOM";
      } else {
        this.phonePrice = [0, 6000];
      }
      // 回显city
      if (row.city) {
        this.echoCityData = row.city.split(",");
      }
      // 回显商圈
      if (row.businessIds) {
        this.$set(this.params, "test", row.businessIds.split(","));
        this.handleCascaderChange(this.params.test);
      }
      setTimeout(() => {
        this.handleReductionCity();
      }, 500);
    },
    // 用于多选checkbox排他
    handleCheckout(value, arr, length, first) {
      if (value === true) {
        arr.splice(0, length);
        arr.push(first);
        return;
      }
      if (value === "out") {
        if (
          arr.includes(first) &&
          arr.length > 1 &&
          arr.length !== length - 1
        ) {
          let index = arr.findIndex((item) => item === first);
          arr.splice(index, 1);
        }
        if (arr.length === length - 1) {
          arr.splice(0, length);
          arr.push(first);
        }
      }
      if (arr.includes("PC")) {
        arr = ["PC"];
      }
    },
    initData() {
      axios.get("/customCity.json").then((res) => {
        this.cityList = JSON.parse(JSON.stringify(res.data));
        this.copyCityList = JSON.parse(JSON.stringify(res.data));
      });
      this.handleEditPack(this.userData);
    },
    addPackage() {
      this.isEdit = false;
      this.showAddDialog = true;
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    // 回显还原城市
    handleReductionCity() {
      if (!this.$refs.cityTree) {
        return;
      }
      let checkedNodes = this.$refs.cityTree.getCheckedNodes();
      this.chooseCityList = [];
      checkedNodes.forEach((item) => {
        this.chooseCityList.push({ ...item });
      });
    },
    handleSubmit() {
      this.tmpParams = null;
      // 地域选择
      if (this.params.district !== "NONE" && this.chooseCityList.length === 0) {
        return this.$message.error("请勾选地域");
      }
      if (this.params.district === "NONE") {
        this.params.city = [];
        this.params.businessIds = [];
      } else if (this.params.district === "CITY") {
        //城市
        let tmpCity = [];
        this.chooseCityList.forEach((item) => {
          if (item.children.length > 0) {
            item.children.forEach((item2) => {
              tmpCity.push(item2.id);
            });
          } else {
            tmpCity.push(item.id);
          }
        });
        this.params.city = tmpCity;
        this.params.businessIds = [];
      } else if (this.params.district === "COUNTY") {
        //区县
        let tmpCity = [];
        this.chooseCityList.forEach((item) => {
          tmpCity.push(item.id);
        });
        this.params.city = tmpCity;
        this.params.businessIds = [];
      } else {
        //商圈
        let businessIds = [];
        this.chooseCityList.forEach((item) => {
          businessIds.push(item.id);
        });
        this.params.city = null;
        this.params.businessIds = businessIds;
      }
      // 位置类型用户
      if (this.params.district !== "NONE" && !this.params.locationType) {
        return this.$message.error("请选择位置类型用户");
      }
      // 自定义人群
      if (this.isCustomCrowd === "NONE") {
        this.params.retargetingTagsInclude = [];
        this.params.retargetingTagsExclude = [];
      } else {
        if (
          this.params.retargetingTagsInclude.length === 0 &&
          this.params.retargetingTagsExclude.length === 0
        ) {
          return this.$message.error("请选择自定义人群");
        }
        let tmpInclude = this.params.retargetingTagsInclude.map(
          (item) => item.custom_audience_id
        );
        let tmpExclude = this.params.retargetingTagsExclude.map(
          (item) => item.custom_audience_id
        );
        this.tagsIncludeString = tmpInclude;
        this.tagsExcludeString = tmpExclude;
      }
      // 文章分类
      if (this.articleType !== "NONE") {
        if (!this.articleList.some((item) => item.checked)) {
          return this.$message.error("请选择文章分类项");
        } else {
          let tmpAricle = [];
          this.articleList.forEach((item) => {
            if (item.checked) {
              tmpAricle.push(item.value);
            }
          });
          this.params.articleCategory = tmpAricle;
        }
      } else {
        this.params.articleCategory = ["NONE"];
      }
      // 手机品牌
      if (this.chooseDeviceBrandType != "NONE") {
        if (!this.deviceBrand.some((item) => item.checked)) {
          return this.$message.error("请选择手机品牌");
        } else {
          let tmpBrand = [];
          this.deviceBrand.forEach((item) => {
            if (item.checked) {
              tmpBrand.push(item.value);
            }
          });
          this.params.deviceBrand = tmpBrand;
        }
      } else {
        this.params.deviceBrand = ["NONE"];
      }
      // 平台和手机品牌是否冲突
      if (
        this.params.deviceBrand[0] !== "NONE" &&
        this.params.platform[0] !== "NONE"
      ) {
        if (this.params.deviceBrand.length) {
          if (!this.params.platform.includes("ANDROID")) {
            return this.$message.error("选择手机品牌后平台必须选中android");
          }
        }
      }
      if (this.params.launchPrice != "NONE") {
        if (!this.phonePrice.length) {
          return this.$message.error("请选择手机价格");
        }
      }
      this.tmpParams = {
        name: this.params.name,
        description: this.params.description,
        district: this.params.district,
        // 地域
        city:
          this.params.city && this.params.city.length
            ? this.params.city.join(",")
            : null,
        businessIds:
          this.params.businessIds && this.params.businessIds.length
            ? this.params.businessIds.join(",")
            : null,
        gender: this.params.gender,
        // 人群
        retargetingTagsInclude: this.tagsIncludeString.length
          ? this.tagsIncludeString.join(",")
          : null,
        retargetingTagsExclude: this.tagsExcludeString.length
          ? this.tagsExcludeString.join(",")
          : null,
        locationType: this.params.locationType,
        age: this.params.age.join(","),
        interestActionMode: this.params.interestActionMode,
        platform: this.params.platform.join(","),
        ac: this.params.ac.join(","),
        hideIfConverted: this.params.hideIfConverted,
        hideIfExists: this.params.hideIfExists,
        articleCategory: this.params.articleCategory.join(","),
        carrier: this.params.carrier.join(","),
        activateType: this.params.activateType.join(","),
        deviceBrand: this.params.deviceBrand.join(","),
        launchPrice:
          this.params.launchPrice != "NONE"
            ? this.phonePrice.join(",")
            : "NONE",
        autoExtendEnabled: this.params.autoExtendEnabled,
        autoExtendTargets: this.params.autoExtendEnabled
          ? this.params.autoExtendTargets.join(",")
          : "",
      };
      return this.tmpParams;
    },
    handleCheckChange(curNodeData, allNodeData) {
      const { checkedKeys, checkedNodes, halfCheckedKeys, halfCheckedNodes } =
        allNodeData;
      const { id } = curNodeData;
      if (checkedKeys.find((item) => item === id)) {
        this.chooseCityList.push(curNodeData);
      } else {
        const targetIndex = this.chooseCityList.findIndex(
          (item) => item.id === id
        );
        if (targetIndex > -1) {
          this.chooseCityList.splice(targetIndex, 1);
        }
      }
      // * 去除半选状态的node
      if (halfCheckedKeys.length > 0) {
        for (let i = 0; i < halfCheckedKeys.length; i++) {
          const key = halfCheckedKeys[i];
          const index = this.chooseCityList.findIndex(
            (item) => item.id === key
          );
          if (index > -1) {
            this.chooseCityList.splice(index, 1);
          }
        }
      }
      // * 从半选状态中找到选中的节点
      halfCheckedNodes.forEach((node) => {
        if (node.children.length > 0) {
          const firstLevelChildren = node.children;
          firstLevelChildren.forEach((subNode) => {
            const id = subNode.id;
            const checkNode = checkedNodes.find((n) => n.id === id);
            if (checkNode) {
              if (
                !this.chooseCityList.find((item) => item.id === checkNode.id)
              ) {
                this.chooseCityList.push(subNode);
              }
            }
          });
        }
      });

      //*  判断有子节点的数据的node节点子节点是否全被选中 （全被选中就要合并字段)

      const containsChildrenNodeList = checkedNodes.filter(
        (item) => Array.isArray(item.children) && item.children.length > 0
      );
      containsChildrenNodeList.forEach((item, itemIndex) => {
        const children = item.children;
        if (
          children.every((child) =>
            checkedNodes.find((node) => node.id === child.id)
          )
        ) {
          children.forEach((child) => {
            const targetIndex = this.chooseCityList.findIndex(
              (i) => i.id === child.id
            );
            if (targetIndex > -1) {
              this.chooseCityList.splice(targetIndex, 1);
            }
          });

          // ! 收缩父级数据
          if (!this.chooseCityList.find((key) => key.id === item.id)) {
            this.chooseCityList.push(item);
          }
        }
      });
      // !再次合并数据
      for (let i = 0; i < this.chooseCityList.length; i++) {
        const item = this.chooseCityList[i];
        if (item.children && item.children.length > 0) {
          for (let j = 0; j < item.children.length; j++) {
            const val = item.children[j];
            const targetIndex = this.chooseCityList.findIndex(
              (i) => i.id === val.id
            );
            if (targetIndex > -1) {
              this.chooseCityList.splice(targetIndex, 1);
            }
          }
        }
      }
    },
    closeChooseCityItem(val) {
      this.$refs.cityTree.setChecked(val.id, false);
      const targetIndex = this.chooseCityList.findIndex(
        (item) => item.id === val.id
      );
      this.chooseCityList.splice(targetIndex, 1);
    },
    handleCascaderChange(val) {
      if (val.length > 0) {
        const city = this.copyOptions.find((item) => item.id === val[0]);
        const county = city.children.find((item) => item.id === val[1]);
        this.cityList = county.children;
        // ! 已选择的商圈
        const selectedBusinessDistrict = this.chooseCityList.filter((item) =>
          this.cityList.find((val) => val.id === item.id)
        );
        if (selectedBusinessDistrict.length > 0) {
          const ids = selectedBusinessDistrict.map((item) => item.id);
          this.$refs.cityTree.setCheckedKeys(ids);
        }
        if (selectedBusinessDistrict.length === this.cityList.length) {
          this.isCheckAll = true;
        }
      } else {
        this.cityList = [];
        this.isCheckAll = false;
      }
    },
    getCityTreeStyle() {
      return {
        height: this.params.district === "COUNTY" ? "230px" : "200px",
      };
    },
    customCrowd() {
      getCustomCrowd({
        page: 1,
        pageSize: 30,
        advertiserId: this.advertiserId,
      }).then((res) => {
        this.customAudienceList = res.custom_audience_list;
        // 还原定向
        let tmpinclude = [];
        let tmpexclude = [];
        if (this.tagsIncludeString.length !== 0) {
          this.tagsIncludeString.forEach((item) => {
            this.customAudienceList.forEach((item2) => {
              if (item2.custom_audience_id == item) {
                return tmpinclude.push(item2);
              }
            });
          });
        }
        // 还原排除
        if (this.tagsExcludeString.length !== 0) {
          this.tagsExcludeString.forEach((item) => {
            this.customAudienceList.forEach((item2) => {
              if (item2.custom_audience_id == item) {
                return tmpexclude.push(item2);
              }
            });
          });
        }
        this.$set(this.params, "retargetingTagsInclude", tmpinclude);
        this.$set(this.params, "retargetingTagsExclude", tmpexclude);
      });
    },
    operationAudienceCrowd(type, row) {
      if (type === "include") {
        if (
          this.params.retargetingTagsInclude.find(
            (item) => item.custom_audience_id === row.custom_audience_id
          )
        ) {
          return this.$message.warning("请勿重复添加定向人群!");
        }
        this.params.retargetingTagsInclude.push(row);
        this.$set(this.params.retargetingTagsInclude);
        const targetIndex = this.params.retargetingTagsExclude.findIndex(
          (item) => item.custom_audience_id === row.custom_audience_id
        );
        if (targetIndex > -1) {
          this.params.retargetingTagsExclude.splice(targetIndex, 1);
        }
        // 层级太深无法响应到页面 手动触发更新dom
        this.retargetingTagsType = "exclude";
        this.retargetingTagsType = "include";
      }
      if (type === "exclude") {
        if (
          this.params.retargetingTagsExclude.find(
            (item) => item.custom_audience_id === row.custom_audience_id
          )
        ) {
          return this.$message.warning("请勿重复添加排除人群!");
        }
        const targetIndex = this.params.retargetingTagsInclude.findIndex(
          (item) => item.custom_audience_id === row.custom_audience_id
        );
        if (targetIndex > -1) {
          this.params.retargetingTagsInclude.splice(targetIndex, 1);
        }
        this.params.retargetingTagsExclude.push(row);
        this.retargetingTagsType = "include";
        this.retargetingTagsType = "exclude";
      }
    },
    clearAudience() {
      this.retargetingTagsType === "include"
        ? (this.params.retargetingTagsInclude = [])
        : (this.params.retargetingTagsExclude = []);
    },
    handleAudience(type = "include", index) {
      if (type === "include") {
        this.params.retargetingTagsInclude.splice(index, 1);
      }
      if (type === "exclude") {
        this.params.retargetingTagsExclude.splice(index, 1);
      }
    },
    handleArticleChange(val, index, type) {
      val.checked = !val.checked;
      if (type === "article") {
        if (this.articleList.every((item) => item.checked)) {
          this.isCheckAllArticle = true;
        }
        if (this.articleList.some((item) => !item.checked)) {
          this.isCheckAllArticle = false;
        }
      }
      if (type === "deviceBound") {
        if (this.deviceBrand.every((item) => item.checked)) {
          this.isCheckAllDeviceBound = true;
        }
        if (this.deviceBrand.some((item) => !item.checked)) {
          this.isCheckAllDeviceBound = false;
        }
      }
    },
    handleArticleCancel(val, index, type) {
      val.checked = false;
      if (type === "deviceBound") {
        if (this.deviceBrand.some((item) => !item.checked)) {
          this.isCheckAllDeviceBound = false;
        }
        return;
      }
      if (type === "article") {
        if (this.articleList.some((item) => !item.checked)) {
          this.isCheckAllArticle = false;
        }
      }
    },
    clearArticle() {
      this.articleList.forEach((item) => {
        item.checked = false;
      });
      this.isCheckAllArticle = false;
    },
    change(val, type) {
      if (type === "deviceBound") {
        this.deviceBrand.forEach((item) => {
          item.checked = !!val;
        });
      } else {
        this.articleList.forEach((item) => {
          item.checked = !!val;
        });
      }
    },
    clearChooseCityList() {
      this.chooseCityList = [];
      this.$refs.cityTree.setCheckedKeys([]);
      this.isCheckAll = false;
    },
  },
  created() {
    // this.tmpData = this.templateDetial();
    this.initData();
  },
};
</script>

<style lang='scss' scoped>
h3 {
  font-size: 18px;
}
.per-part {
  margin-bottom: 20px;

  > span {
    width: 120px;
    text-align: right;
    margin-right: 10px;
  }
}
.choose-account {
  p {
    padding: 5px 10px;
    border: 1px solid #ccc;
    background: #f5f5f5;
    border-radius: 4px;
    font-size: 12px;
    margin-top: 10px;
  }
}
.button-list {
  padding-top: 20px;
  border-top: 1px solid #f5f5f5;
  padding-left: 130px;
}
.choose-tag {
  position: sticky;
  top: 0;
  width: 200px;
  margin-right: 20px;
  border: 1px solid #ecebeb;
  padding: 10px;
  .tag-list {
    > p {
      margin: 15px 0;
      > span:nth-of-type(1) {
        font-weight: bold;
      }
      .light-color {
        color: #ccc;
      }
    }
  }
}
.user-orientation {
  margin-top: 10px;
  // width:800px;
  .region-select {
    .city-list {
      height: 300px;
      margin-top: 10px;
      border: 1px solid #f5f5f5;
      border-radius: 6px;
      padding: 10px;
      margin-right: 20px;
      .city-tree {
        margin-top: 10px;

        overflow: auto;
      }
    }
    .city-select {
      margin-top: 10px;
      border: 1px solid #f5f5f5;
      border-radius: 6px;
      padding: 10px;
      height: 300px;
      width: 300px;
      // display: flex;
      .title-info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 30px;
        width: 100%;
      }
      .city-select-list {
        overflow: auto;
        max-height: 250px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
      }
    }
  }
}
.audience-table {
  margin-top: 20px;
  border: 1px solid #f5f5f5 !important;
  /deep/ .filter-list {
    margin: 0 !important;
  }
}
/deep/ .el-table {
  margin-top: -1px;
  margin-left: -1px;
}
.crowd-select {
  width: 200px;
  margin-top: 20px;
  margin-left: 20px;
  border: 1px solid #f5f5f5;
  border-radius: 4px;
  padding: 0 10px;
  height: 300px;
  > div:nth-of-type(1) {
    height: 56px;
    line-height: 56px;
  }
  .is-selected {
    border-bottom: 1px solid #00bf8a;
    color: #00bf8a;
  }
  .crowd-list {
    > p {
      margin: 10px 0;
    }
  }
}
.article-kinds {
  width: 200px;
  height: 300px;
  border: 1px solid #f5f5f5;
  border-radius: 4px;
  padding: 10px;
  .tree-container {
    margin-top: 10px;
    height: 238px;
    overflow: auto;
    > p {
      margin-bottom: 10px;
      > label {
        cursor: pointer;
        > span {
          padding-left: 10px;
        }
      }
    }
  }
}
.article-selected {
  width: 200px;
  margin-left: 10px;
  border: 1px solid #f5f5f5;
  border-radius: 4px;
  border-radius: 4px;
  height: 300px;
  .title-info {
    height: 36px;
    line-height: 36px;
    border-bottom: 1px solid #f5f5f5;
    padding: 0 10px;
  }
  .article-selected-container {
    max-height: 250px;
    overflow: auto;
    > p {
      margin: 10px;
    }
  }
}
.pack-container {
  .container {
    .account-item {
      border-radius: 4px;
      background: #e9ecf1;
      font-size: 13px;
      padding: 8px;
      margin: 10px;
      transition: all 0.2s;
      &:hover {
        color: #00bf8a;
      }
    }
  }
}
.ai-largesse {
  width: 200px;
  height: 200px;
  border: 1px solid #f5f5f5;
  border-radius: 4px;
  padding: 10px;
  padding-top: 0;
  .title-info {
    height: 36px;
    line-height: 36px;
    border-bottom: 1px solid #f5f5f5;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .el-checkbox {
    width: 100%;
  }
}
</style>