<template>
  <div>
    <el-drawer
      :title="`${type === 2 ? '百度':'头条'}今日创意`"
      size="90%"
      :visible.sync="show"
      append-to-body
      @open="handleInit"
      :before-close="() => $emit('close')"
    >
      <div>
        <div class="flex-between operation padding-bottom-20">
          <el-button
            class="margin-right-twentyFour"
            @click="handlePageChange(page)"
            >刷新数据</el-button
          >
          <el-input
            placeholder="搜索"
            v-model="params.search"
            clearable
            class="input-with-select margin-right-twentyFour"
            @keydown.native.enter="handlePageChange(1)"
            style="width: 200px"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="handlePageChange(1)"
            ></el-button>
          </el-input>
        </div>
        <el-table
          v-loading="loading"
          :data="tableData"
          height="calc(100vh - 150px)"
          style="width: 100%"
          @sort-change="handleSortChange"
        >
          <el-table-column sortable="custom" prop="id" label="ID" width="60">
          </el-table-column>
          <el-table-column
            sortable="custom"
            prop="title"
            min-width="160"
            label="标题"
          >
            <template slot-scope="scope">
              <p class="tip-text" @click="handleShowTime(scope.row)">
                {{ scope.row.title }}
              </p>
            </template>
          </el-table-column>
          <el-table-column
            sortable="custom"
            prop="cname"
            min-width="160"
            label="创意名称"
          >
          </el-table-column>
          <el-table-column
            sortable="custom"
            min-width="80"
            prop="costAmount"
            label="花费"
          >
          </el-table-column>
          <el-table-column
            sortable="custom"
            prop="clkPv"
            min-width="80"
            label="点击次数"
          >
          </el-table-column>
          <el-table-column
            sortable="custom"
            prop="expPv"
            min-width="80"
            label="曝光次数"
          >
          </el-table-column>
          <el-table-column
            sortable="custom"
            prop="followCost"
            min-width="80"
            label="点击均价"
          >
          </el-table-column>
          <el-table-column
            sortable="custom"
            prop="image"
            min-width="80"
            label="图片"
          >
            <template slot-scope="scope">
              <img
                v-if="scope.row.image"
                :src="JSON.parse(scope.row.image)[0]"
                style="width: 70px; height: 70px"
                alt=""
              />
              <img
                v-else
                src="https://h5.file.yayawx.cn/novel_put/2020121750082.png"
                style="width: 70px; height: 70px"
                alt=""
              />
            </template>
          </el-table-column>
          <el-table-column
            sortable="custom"
            prop="thousandDisplayPrice"
            min-width="120"
            label="千次曝光成本"
          >
          </el-table-column>
        </el-table>
        <!-- 普通分页 -->
        <el-pagination
          style="margin: 10px 0; text-align: right"
          background
          @current-change="handlePageChange"
          :current-page="page"
          :page-size="pageSize"
          layout="total, prev, pager, next, jumper"
          :total="totalPage"
        >
        </el-pagination>
      </div>
    </el-drawer>
    <!-- 百度创意分时统计 -->
    <ad-recharge
      :show="showTime"
      @close="showTime = false"
      :account-channel-id="todayAdData ? todayAdData.accountChannelId : null"
      :channel-name="todayAdData ? todayAdData.channelName : null"
      :todayAdData="todayAdData"
      :type='type'
    />
    <!-- <el-drawer
      title="百度创意分时（分日）统计"
      size="1100px"
      :visible.sync="showTime"
      :before-close="
        () => {
          showTime = false;
        }
      "
    >
      <div class="adFilter">
        <el-date-picker
          v-model="paramsTime"
          type="daterange"
          align="left"
          unlink-panels
          style="width: 240px"
          :clearable="false"
          range-separator="-"
          value-format="yyyy-MM-dd"
          @change="handlePageChangeTime"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
        <el-radio-group v-model="dataType" @change="handlePageChangeTime">
          <el-radio-button :label="1">分日查看</el-radio-button>
          <el-radio-button :label="2">分时查看</el-radio-button>
        </el-radio-group>
      </div>
      <div class="adGroup-cont">
        <div id="adGroupTime"></div>
        <el-table
          :data="dataList"
          height="50vh"
          style="width: 100%"
          show-summary
        >
          <el-table-column prop="date" label="时间" min-width="100">
          </el-table-column>
          <el-table-column prop="cost" label="花费(元)" min-width="100">
          </el-table-column>
          <el-table-column prop="viewCount" label="曝光量" min-width="80">
          </el-table-column>
          <el-table-column prop="validClickCount" label="点击量" min-width="80">
          </el-table-column>
          <el-table-column prop="ctrStr" label="点击率" min-width="80">
          </el-table-column>
          <el-table-column
            prop="conversionsCount"
            label="目标转化量"
            min-width="80"
          >
          </el-table-column>
          <el-table-column
            prop="conversionsCost"
            label="目标转化成本(元)"
            min-width="80"
          >
          </el-table-column>
          <el-table-column
            prop="conversionsRateStr"
            label="目标转化率"
            min-width="80"
          >
          </el-table-column>
          <el-table-column prop="orderRoi" label="下单ROI" min-width="80">
          </el-table-column>
          <el-table-column prop="orderAmount" label="下单金额" min-width="80">
          </el-table-column>
          <el-table-column
            prop="orderUnitPrice"
            label="下单客单价"
            min-width="80"
          >
          </el-table-column>
          <el-table-column
            prop="thousandDisplayPrice"
            label="千次曝光成本"
            min-width="80"
          >
          </el-table-column>
        </el-table>
      </div>
    </el-drawer> -->
  </div>
</template>

<script>
import { getBaiDuCreative, getBaiDuCreativeStats } from "@/api/account";
import { formatTime } from "@/assets/js/utils";
import { pickerOptions } from "@/assets/js/options";
import AdRecharge from "./todayAdCreatedRecharge";
const echarts = require("echarts/lib/echarts");
require("echarts/lib/chart/line");
require("echarts/lib/chart/bar");
require("echarts/lib/component/tooltip");
export default {
  name: "timeadGroup",
  props: {
    show: Boolean,
    baiduId: [Number, String],
    type:Number
  },
  components: {
    AdRecharge,
  },
  data() {
    return {
      params: {},
      loading: false,
      tableData: [],
      page: 1,
      pageSize: 15,
      totalPage: 0,
      sortName: null,
      sortDesc: null,
      // 今日创意分时相关
      showTime: false,
      currentId: null,
      dataType: 2,
      paramsTime: {},
      dataList: [],
      pickerOptions,
      myChart: null,
      todayAdData: {},
    };
  },
  created() {},
  watch: {},
  methods: {
    //
    handlePageChangeTime() {},
    //
    handleInitTime() {
      getBaiDuCreativeStats({
        dataType: this.dataType,
        beginDate: this.params.beginDate,
        endDate: this.params.endDate,
        creativeId: this.currentId,
      }).then((res) => {
        this.dataList = res.list;
        // this.setOption(res.graph)
      });
    },
    // 展示今日创意分时
    handleShowTime(data) {
      this.showTime = true;
      this.todayAdData = data;
      this.todayAdData._type = 1;
    },
    // 图形处理
    // 排序处理
    handleSortChange({ column, prop, order }) {
      this.sortName = prop;
      if (order === "descending") {
        this.sortDesc = true;
      } else if (order === "ascending") {
        this.sortDesc = false;
      } else {
        this.sortName = null;
        this.sortDesc = null;
      }
      this.params.sortName = this.sortName;
      this.params.sortDesc = this.sortDesc;
      this.handlePageChange(1);
    },
    handleInit() {
      this.loading = true;
      getBaiDuCreative({
        adgroupId: this.baiduId,
        page: this.page,
        pageSize: this.pageSize,
        sortDesc: this.sortDesc,
        sortName: this.sortName,
        search: this.params.search,
      })
        .then((res) => {
          this.tableData = res.list;
          this.totalPage = res.total;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handlePageChange(page) {
      this.page = page;
      this.handleInit();
    },
    handletest() {},
  },
};
</script>

<style scoped>
.operation {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: #fff;
  padding: 0 10px;
}
.tip-text {
  cursor: pointer;
  font-size: 12px;
  color: #999;
  line-height: normal;
  text-decoration: underline;
}
</style>
