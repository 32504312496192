import request from './config'

// 运营相关---------------》

// 运营决策者 今日投放、今日充值、今日消息
export function operateManagerPutRecharge(params) {
  return request.get('/operateManager/putRecharge', { params })
}

// 7日同时段对比图
export function operateManagerSevenDayData(params) {
  return request.get('/operateManager/sevenDayData', { params })
}

// 近15日D1回本率趋势
export function operateFifteenFirstReturn(params) {
  return request.get('/operateManager/fifteenFirstReturn', { params })
}

// 近15日充值构成
export function operateFifteenFifteenRechargeAmt(params) {
  return request.get('/operateManager/fifteenRechargeAmt', { params })
}

// 书籍、公众号、会员充值、客户类型充值分布消耗
export function operateFifteenBCVCCost(params) {
  return request.get('/operateManager/booksChannelVipCustomerTypeCost', { params })
}

// 累计回本、书籍roi、公众号roi
export function operateTotalReturnRoi(params) {
  return request.get('/operateManager/totalReturnRoi', { params })
}

// 月份roi
export function operateMonthRoi(params) {
  return request.get('/operateManager/monthRoi', { params })
}

// 月份roi
export function operateCustomerMessage(params) {
  return request.get('/operateManager/todayCustomerMessage', { params })
}

// 杜邦图
export function operateDuPont(params) {
  return request.get('/duPont', { params })
}

// 运营相关结束---------------》


export function getMonthRoi(params) {
  return request.get('/operateManager/monthRoi', { params })
}
//投手决策者看板
export function getPutManagerManagerStatistics(params) {
  return request.get('/manager', { params })
}

// 投手看板
export function getPutBoard(params){
  return request.get('/user',{params})
}

// *客户看板

// 基础数据
export function getBaseData(params){
  return request.get('/customerAnalysis',{params})
}

// 热力图

export function getHeatMapData(params){
  return request.get('/customerAnalysis/map',{params})
}

//分月粉丝增量概况
export function getFansInc(params){
  return request.get('/customerAnalysis/monthFansInc',{params})
}

