<template>
  <div class="preview-cont">
    <el-avatar
      src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"
      style="margin-right: 15px"
    ></el-avatar>
    <div style="flex: 1">
      <div class="head-tit">
        <span>{{ channelName }}</span>
        <span class="ad-remark"> 广告 <i class="iconfont iconarrow"></i></span>
      </div>
      <!-- <p>{{ desc }}</p> -->
      <p>{{ title }}</p>
      <div class="img-cont">
        <video
          v-if="JSON.parse(crtInfo) && JSON.parse(crtInfo).video"
          :src="JSON.parse(crtInfo).video"
          alt=""
          controls
          class="one-img"
        />
        <img
          :src="imgList"
          alt="advert"
          class="one-img"
          v-else-if="typeof imgList === 'string'"
        />
        <img
          :src="imgList[0]"
          alt="advert"
          class="one-img"
          v-else-if="imgList.length === 1"
        />
        <div class="img-list" v-else>
          <img
            v-for="(item, index) in imgList"
            :key="index"
            :src="item"
            alt="advert"
          />
        </div>
      </div>
      <div>
        <span class="link">了解公众号</span>
        <i class="iconfont iconlianjie" style="color: #999"></i>
      </div>
      <div class="flex-between">
        <span class="min-text">两分钟前</span>
        <span class="more">
          <i class="iconfont icongengduo"></i>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "preview",
  props: {
    channelName: String,
    crtInfo: String,
  },
  computed: {
    imgList() {
      const { crtInfo } = this;
      if (JSON.parse(crtInfo)) {
        const data = JSON.parse(crtInfo);
        return (
          data.image_list || data.image || data.image1 || data.image2 || []
        );
      } else {
        return [];
      }
    },
    title() {
      const { crtInfo } = this;
      if (JSON.parse(crtInfo)) {
        return JSON.parse(crtInfo).title;
      } else {
        return "";
      }
      // return crtInfo !== null ? JSON.parse(crtInfo).title : "";
    },
    desc() {
      const { crtInfo } = this;
      return crtInfo ? JSON.parse(crtInfo).description : "";
    },
  },
};
</script>

<style scoped>
.preview-cont {
  width: 300px;
  border: 1px solid #ececec;
  padding: 15px;
  display: flex;
}

.head-tit {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-weight: bold;
  color: #596d96;
  margin-bottom: 5px;
}

.ad-remark {
  display: inline-block;
  padding: 0 5px;
  font-size: 12px;
  color: #999;
  border: 1px solid #a0a0a0;
  border-radius: 4px;
}

.ad-remark .iconfont {
  font-size: 10px;
  color: #999;
}

.img-cont {
  margin-top: 10px;
}

.one-img {
  width: 213px;
  /* height: 130px;
  width: auto; */
  margin-bottom: 5px;
}

.img-list {
  display: flex;
  flex-wrap: wrap;
}

.img-list img {
  width: 60px;
  height: 60px;
  margin-right: 5px;
  margin-bottom: 5px;
  object-fit: cover;
}

.link {
  color: #596d96;
  font-size: 12px;
}

.min-text {
  font-size: 12px;
  color: #999;
}

.more {
  display: inline-block;
  background-color: #f7f7f7;
  padding: 0 8px;
  border-radius: 4px;
}

.more .iconfont {
  font-size: 10px;
  color: #576b95;
}
</style>
