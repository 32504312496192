<template>
  <el-popover
      placement="bottom"
      width="300"
      trigger="click">
    <el-form label-width="50px">
      <el-form-item label="团队">
        <el-cascader
            @change="handleDeptChange"
            clearable
            style="width: 100%"
            ref="dept"
            :props="treeProps"
            :value="params.deptId"
            :options="deptList"
        ></el-cascader>
      </el-form-item>
      <el-form-item label="用户">
        <el-select
            :disabled="canDisabled && params.deptId"
            style="width: 100%"
            :value="params.adminUserId"
            filterable
            clearable
            @change="handleUserChange"
            placeholder="用户名称">
          <el-option
              v-for="item in selectUserList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <el-input slot="reference" placeholder="团队 / 用户" clearable @clear="handleClear" style="width: 130px"
              :value="showText"/>
  </el-popover>
</template>

<script>
import { mapGetters } from 'vuex'
import { getAllTeam } from '../api/team'

export default {
  name: 'SelectDeptUser',
  props: {
    params: Object,
    canDisabled: Boolean
  },
  data () {
    return {
      deptList: [],
      treeProps: {
        checkStrictly: true,
        emitPath: false,
        value: 'id',
        label: 'name'
      }
    }
  },
  watch:{
     params:{
       handler(newV){
       }
     }
  },
  computed: {
    selectDept () {
      const { deptId } = this.params
      
      return deptId ? findTeam(deptId, this.deptList) : null
    },
    selectUser () {
      const { adminUserId } = this.params
      return adminUserId ? this.selectUserList.find(user => user.id === adminUserId) : null
    },

    showText () {
      const { selectDept, selectUser } = this
      return selectDept ? (selectUser ? selectDept.name + ' / ' + selectUser.name : selectDept.name) : (selectUser ? selectUser.name : '')
    },
    selectUserList () {
      const { selectDept } = this
      return selectDept ? (selectDept.userLists ? selectDept.userLists.map(user => {
        return {
          id: user.adminUserId,
          name: user.name
        }
      }) : []) : this.userList
    },
    ...mapGetters(['userList'])
  },
  mounted () {
    getAllTeam().then(res => {
      this.deptList = res
    })
  },
  methods: {
    handleClear () {
      this.$set(this.params, 'deptId', null)
      this.$set(this.params, 'adminUserId', null)
    },
    handleDeptChange (dept) {
      if (dept) {
        this.$emit('deptChange', this.$refs.dept.getCheckedNodes()[0].label)
      }
      this.$set(this.params, 'deptId', dept)
      this.$set(this.params, 'adminUserId', null)
    },
    handleUserChange (user) {
      if (user) {
        this.$emit('userChange', this.selectUserList.find(item => item.id === user).name)
      }
      this.$set(this.params, 'adminUserId', user)
    }
  }
}

function findTeam (deptId, deptList) {
  let select = null
  if (deptId) {
    _find(deptId, deptList)
    return select
  }

  function _find (deptId, deptList) {
    for (const dept of deptList) {
      if (dept.id === deptId) {
        select = dept
        break
      } else if (dept.children) {
        _find(deptId, dept.children)
      }
    }
  }
}
</script>

<style scoped>

</style>
