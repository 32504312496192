import request from './config'

export function getService(params) {
  return request.get('/send/list', {params})
}

export function getServiceDetail(serviceSendId) {
  return request.get(`/customer/message/${serviceSendId}`)
}

// 获取小说列表
export function getBookList(accountChannelId, params) {
  return request.get(`/book/list/${accountChannelId}`, {params})
}

// 获取小说章节列表
export function getBookChapter(accountChannelId, params) {
  return request.get(`/book/contents/${accountChannelId}`, {params})
}

// 获取阅文小说链接
export function getBookLink(params) {
  return request.get('/zzy/novelUrl', {params})
}

// 获取阅文页面列表
export function getPageList(accountChannelId) {
  return request.get(`/zzy/pageList/${accountChannelId}`)
}

// 获取阅文页面链接
export function getPageLink(params) {
  return request.get('/zzy/pageUrl', {params})
}

export function addServiceMessage({id, ...params}) {
  if (id) {
    return request.put(`/customer/message/${id}`, params)
  } else {
    return request.post('/customer/message', params)
  }
}

export function deleteMessage(id) {
  return request.delete(`/customer/message/${id}`)
}

export function sendServiceMessage(id) {
  return request.put(`/customer/message/send/${id}`)
}

export function getEstimateNum(params) {
  return request.get('/send/count', {params})
}

export function testSend(params) {
  return request.post('/customer/message/test', params)
}

// export function copyServiceMessage(serviceSendId,accountChannelIds) {
//   return request.put(`/copy/customer/message/${serviceSendId}`,{accountChannelIds})
// }
// 增加复制时间参数
export function copyServiceMessage(serviceSendId, params) {
  return request.put(`/copy/customer/message/${serviceSendId}`, params)
}

// 延迟回复
export function getDelayMessage(accountChannelId, remark) {
  return request.get('/service/tick', {params: {accountChannelId, remark}})
}

export function getDelayMessageDetail(id) {
  return request.get(`/service/tick/${id}`)
}

export function editDelayMessage({id, ...data}) {
  if (id) {
    return request.put(`/service/tick/${id}`, data)
  } else {
    return request.post('/service/tick', data)
  }
}

export function deleteDelayMessage(id) {
  return request.delete(`/service/tick/${id}`)
}

export function copyDelayMessage(serviceSendIds, accountChannelIds) {
  return request.post('/service/tick/copy', {serviceSendIds, accountChannelIds})
}

export function changeDelayStatus(serviceSendIds, status) {
  return request.put(`/service/tick/status/${status}`, {serviceSendIds})
}

// 标签群发
export function getSendTagList(accountChannelId, params) {
  return request.get(`/tag/all/${accountChannelId}`, {params})
}

export function getSendtTagDetatl(id) {
  return request.get(`/tag/${id}`)
}

export function editSendTag({id, ...data}) {
  if (id) {
    return request.put(`/tag/${id}`, data)
  } else {
    return request.post('/tag', data)
  }
}

export function deleteSendTag(id) {
  return request.delete(`/tag/${id}`)
}

export function copyTag(id, params) {
  return request.put(`admin/tag/copy/${id}?tick=${params.tick}`)
}

export function getMaterial(accountChannelId, params) {
  return request.get(`/getMaterial/${accountChannelId}`, {params})
}

export function testSendTag(params) {
  return request.post('/tag/send/test', params)
}

export function getSendTagNum(params) {
  return request.get('/tag/send/count', {params})
}

export function sendTag(id) {
  return request.put(`/tag/send/${id}`)
}

export function stopTag(id) {
  return request.put(`/tag/stop/${id}`)
}

// 小时群发
export function getHourMessage(accountChannelId, params) {
  return request.get(`/fixed/message/all/${accountChannelId}`, {params})
}

export function getHourMessageDetail(id) {
  return request.get(`/fixed/message/${id}`)
}

export function editHourMessage({id, ...data}) {
  if (id) {
    return request.put(`/fixed/message/${id}`, data)
  } else {
    return request.post('/fixed/message', data)
  }
}

export function changeHourStatus(ids, status) {
  return request.put(`/fixed/message/status/${ids}?status=${status}`)
}

export function deleteHourMessage(ids) {
  return request.delete(`/fixed/message/${ids}`)
}

// 获取当前公众号的测试用户
export function getAccountUser(accountChannelId, nickname) {
  return request.get(`/openIds/${accountChannelId}`, {params: {nickname}})
}

// 获取当前公众号平台活动
export function getAccountActivity(accountChannelId) {
  return request.get(`/zzy/platformActivity/${accountChannelId}`)
}

// 获取阳光书城活动类型
export function getSunshineActivity(accountChannelId, type) {
  return request.get(`/sunshine/activity/${accountChannelId}?type=${type}`)
}

// 获取七悦活动类型/sunshine/activity/{accountChannelId}
export function getQiYueActivity(accountChannelId, type) {
  return request.get(`/sunshine/activity/${accountChannelId}?type=${type}`)
}

// 复制小时发送
export function copyHourMsg(fixedIds, params) {
  return request.put(`/fixed/message/copy/${fixedIds}`, params)
}

// 预警提示-> 修改状态(确认状态信息)

export function changeTipsStatus(id) {
  return request.put(`/tip/warning/status/${id}`)
}

// 预警提示-> 查询预警提示

export function getTipItemList(params) {
  return request.get('/tip/warning', {params})
}

// 查询客服消息素材库列表

export function getSourceList(params) {
  return request.get('/customerMessage/source', {params})
}

// 新增素材

export function addSource(params) {
  return request.post('/customerMessage/source', params)
}

// 修改素材

export function editSource(id, params) {
  return request.put(`/customerMessage/source/${id}`, params)
}

// 删除素材

export function delSource(id) {
  return request.delete(`/customerMessage/source/${id}`)
}

// 查询已上传素材明细
export function getSourceDetailList(params) {
  return request.get('/customerMessage/source/detail', {params})
}

// 批量删除上传明细
export function delSourceDetailList(id) {
  return request.delete(`/customerMessage/source/detail/${id}`)
}

// 上传相关素材明细

export function uploadSourceDetail(params) {
  return request.post('/customerMessage/source/detail', params)
}

/**
 **
 改版客服消息及相关新增
 **
 **/

// 预计发送人数明细
export function getPlatformSendDetail(params) {
  return request.get('/send/detail', {params})
}

// 预计发送人数明细标签群发
export function getPlatformSendDetailTag(params) {
  return request.get('/tag/send/detail', {params})
}

/**
 *( (  \.---./  ) )
 * '.__/o   o\__.'
 *图文素材相关 -----by devT
 **/

// 查询素材
export function getPlatformTagSource(params) {
  return request.get('/tagSource', {params})
}

// 新增素材
export function addPlatformTagSource(params) {
  return request.post('/tagSource', params)
}

// 修改素材
export function changePlatformTagSource(id, params) {
  return request.put(`/tagSource/${id}`, params)
}

// 删除素材
export function deletePlatformTagSource(id) {
  return request.delete(`/tagSource/${id}`)
}

/**
 *end by devT
 **/

// 公众号图文列表

export function getPlatformTagNews(params) {
  return request.get('/tagNews/channel', {params})
}

// 公众号图文删除

export function delPlatformTagNews(id) {
  return request.delete(`/tagNews/channel/${id}`)
}

// 公众号图文复制

export function copyPlatformTagNews({id, accountChannelIds}) {
  return request.post(`/tagNews/channelCopy?id=${id}&accountChannelIds=${accountChannelIds}`)
}

// 标签图文列表(单个详情)

export function perTagNews(params) {
  return request.get('/tagNews', {params})
}

// 标签图文新增
export function addTagNews(params) {
  return request.post('/tagNews', params)
}

// 标签图文修改

export function editTagNews(id, params) {
  return request.put(`/tagNews/${id}`, params)
}

// 公众号图文上传

export function deployTagNews(params) {
  return request.get('/tagDeploy', {params})
}

// 获取平台账号的登录状态

export function getAccountStatus(id) {
  return request.get(`/login/isLogin/${id}`)
}

// 关键字查询

export function getKeyWord(params) {
  return request.get('/keyWord', {params})
}

// 新增关键字
export function addKeyWord(params) {
  return request.post('/keyWord', params)
}

// 修改关键字

export function updateKeyWord(id, params) {
  return request.put(`/keyWord/${id}`, params)
}

// 删除关键字

export function delKeyWord(id) {
  return request.delete(`/keyWord/${id}`)
}

// 批量修改状态
export function changeKeyWordStatus(ids, status) {
  return request.put(`/keyWord/status/${ids}?status=${status}`)
}

// 批量复制

export function copyKeyWord(ids, params) {
  return request.post(`/keyWord/copy/${ids}`, params)
}

// 手动刷新微信后台关键字回复
export function syncWXKeyWordData(accountChannelId) {
  return request.put(`/keyWord/handRefresh/${accountChannelId}`)
}

// * 书籍价格
// 查询

export function getExclusiveBook(params) {
  return request.get('/exclusiveBook', {params})
}

// 新增

export function addExclusiveBook(params) {
  return request.post('/exclusiveBook', params)
}

// 编辑
export function editExclusiveBook(id, params) {
  return request.put(`/exclusiveBook/${id}`, params)
}

// 删除

export function delExclusiveBook(ids) {
  return request.delete(`/exclusiveBook/${ids}`)
}

// 同步

export function syncExclusiveBookData(id) {
  return request.put(`/exclusiveBook/hand/${id}`)
}

// 上传本地素材
export function platformLocalUpload(accountChannelId, type, params) {
  return request.post(`/localUpload?accountChannelId=${accountChannelId}&type=${type}`, params)
}

// 微信选用素材并上传
export function platformLocalSelectUpload(params) {
  return request.post(`/thumb`, params)
}

/*模板消息废弃api----------- */

// 模板消息列表获取
export function getTemplateList(params) {
  return request.get('/template', {params})
}

// 模板消息删除
export function deleteTemplate(id) {
  return request.delete(`/template/${id}`)
}

// 模板消息测试发送
export function testSendTemplate(params) {
  return request.post('/template/test', params)
}

// 模板消息获取公众号模板
export function getWechatTemplate(id) {
  return request.get(`/template/remote/${id}`)
}

// 模板消息复制
export function copyTemplate(params) {
  return request.post('/template/copy', params)
}

// 模板消息明细
export function getTemplateDetail(id) {
  return request.get(`/template/${id}`)
}

// 模板消息新增
export function addTemplate(params) {
  return request.post('/template', params)
}

// 模板消息编辑
export function editTemplate(id, params) {
  return request.put(`/template/${id}`, params)
}

// 模板消息排序
export function sortTemplate(params) {
  return request.put('/template/order', params)
}

// 模板消息可发送人
export function getTemplageNum(params) {
  return request.get('/template/send/num', {params})
}

// 模板消息已发送完成
export function getTemplageFinish(params) {
  return request.get('/template/send/finish', {params})
}

// 重发消息管理消息
export function resendMessage(ids) {
  return request.put(`/tip/warning/resend/${ids}`)
}


// 开启草稿箱
export function openDraft(id) {
  return request.post(`admin/tag/draft/isOpen/${id}`)
}

/**
 * 小程序群发相关
 */


// 查询发送列表
export function getAppletSendList(params) {
  return request.get('/appletSend', {params})
}

// 停止发送
export function stopAppletSend(id) {
  return request.put(`/appletSend/stop/${id}`)
}

// 删除发送
export function deleteAppletSend(id) {
  return request.delete(`/appletSend/${id}`)
}

// 修改发送
export function changeAppletSend(id, params) {
  return request.put(`/appletSend/${id}`, params)
}

// 新增发送
export function addAppletSend(params) {
  return request.post(`/appletSend`, params)
}

// 复制发送
export function copyAppletSend(params) {
  return request.put(`/appletSend/copy`, params)
}

// 查询发送详情
export function detailAppletSend(id) {
  return request.get(`/appletSend/${id}`)
}

// 卡片列表
export function getAppletSendCardList(params) {
  return request.get(`/appletSendDetail`, {params})
}

// 新增卡片
export function addAppletSendCardList(params) {
  return request.post(`/appletSendDetail`, params)
}

// 修改卡片
export function changeAppletSendCardList(id, params) {
  return request.put(`/appletSendDetail/${id}`, params)
}

// 删除卡片
export function deleteAppletSendCardList(id) {
  return request.delete(`/appletSendDetail/${id}`)
}

// 新增批量客服信息
export function addCustomerBatch(params) {
  return request.post(`/customerBatch`, params)
}

// 删除批量客服信息
export function deleteCustomerBatch(id) {
  return request.delete(`/customerBatch/${id}`)
}

// 复制批量客服信息
export function copyCustomerBatch(id) {
  return request.post(`/customerBatch/copy/${id}`)
}

// 修改批量客服信息
export function updateCustomerBatch(id, params) {
  return request.put(`/customerBatch/${id}`, params)
}

// 批量客服信息列表
export function getCustomerBatchList(params) {
  return request.get(`/customerBatch`, {params})
}

// 批量客服信息详情
export function getCustomerBatchDetail(id) {
  return request.get(`/customerBatch/${id}`)
}

// 获取平台
export function checkPlatform(params) {
  return request.get(`/accountChannelCategory/check`, {params})
}
