// actions
import { getCookie } from '../assets/js/cookie'

export default {
  setUserInfo({ commit }, userInfo) {
    commit('setUserInfo', userInfo)
  },
  getUserInfo({ commit, state }) {
    const info = getCookie('userInfo')
    if (JSON.stringify(state.userInfo) === '{}' && info) {
      commit('setUserInfo', JSON.parse(info))
    }
  },
  setCollapsed({ commit }, collapse) {
    commit('setCollapsed', collapse)
  },
  setOfficialList({ commit }, list) {
    commit('setOfficialList', list)
  },
  setUserList({ commit }, list) {
    commit('setUserList', list)
  },
  addRouterHistory({ commit }, route) {
    commit('addRouterHistory', route)
  },
  delRouterHistory({ commit }, index) {
    commit('delRouterHistory', index)
  },
  resetHistory({ commit }, path) {
    commit('resetHistory', path)
  },
  delModuleSelectedItem({ state, commit }, item) {
    const targetIndex = state.moduleSelectedList.findIndex(
      module => module.tagNumber === item.tagNumber
    )
    if (targetIndex > -1) {
      // if(item.tagNumber === state.curModuleSelected.tagNumber){
      commit('delModuleSelectedItem', { ...item, targetIndex })
      // }
    }
  },
  // 删除小说推广创建推广页的内容
  resetAllModule({ state, commit }) {
    commit('resetAllModule')
  },
  setBookList({ commit }, list) {
    commit('setBookList', list)
  },
  setFeedBackFlag({ commit }, data) {
    commit('setFeedBackFlag', data)
  }
}
