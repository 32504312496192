<template>
  <div class="custom-schedule">
    <div class="custom-time-area">
      <p class="sumTime">
        一共:<span>{{ totalPutTime }}</span
        >小时
      </p>
      <div class="time-block">
        <div class="time-header">
          <div class="time-info">时间</div>
          <div class="time-range-block">
            <div class="time-range-block-list">
              <p class="time-range">0:00-12:00</p>
              <p class="time-range">12:00-24:00</p>
            </div>
            <ul class="time-hours" id="timeHours">
              <li class="hour-num" v-for="n in DAY_HOURS" :key="n">
                {{ n - 1 }}
              </li>
            </ul>
          </div>
        </div>
        <div class="time-body">
          <ul>
            <li class="day-item">周一</li>
            <li class="day-item">周二</li>
            <li class="day-item">周三</li>
            <li class="day-item">周四</li>
            <li class="day-item">周五</li>
            <li class="day-item">周六</li>
            <li class="day-item">周日</li>
          </ul>
          <ul
            class="time-item-block"
            ref="timeBlock"
            @mousedown.prevent="handleTimeBodyMouseDown"
            @mouseover="handleTimeBodyMouseOver"
          >
            <li
              class="hour-item active-hour"
              v-for="n in WEEK_DAYS * TIME_INTERVAL"
              :ref="`hourItem`"
              :col="
                Math.ceil(n % TIME_INTERVAL) === 0
                  ? 47
                  : Math.ceil(n % TIME_INTERVAL) - 1
              "
              :row="Math.ceil(n / TIME_INTERVAL) - 1"
              :key="n"
              :title="
                handleTitle(
                  Math.ceil(n % TIME_INTERVAL) === 0
                    ? 47
                    : Math.ceil(n % TIME_INTERVAL) - 1,
                  Math.ceil(n / TIME_INTERVAL) - 1
                )
              "
              :class="{
                'cell-hover':
                  cellItem.col === Math.ceil(n % TIME_INTERVAL) - 1 &&
                  cellItem.row === Math.ceil(n / TIME_INTERVAL) - 1,
              }"
              @click="
                cellClick(
                  Math.ceil(n % TIME_INTERVAL) === 0
                    ? 47
                    : Math.ceil(n % TIME_INTERVAL) - 1,
                  Math.ceil(n / TIME_INTERVAL) - 1,
                  n - 1
                )
              "
            ></li>
          </ul>
        </div>
      </div>
      <!-- 这里将click事件改为了 mouseup事件 不然document.mouseup事件会先触发，暂时没有想到更好的解决办法 -->
      <div class="daily-block">
        <p class="daily-title">每天</p>
        <ul
          class="daily-items"
          @mousedown.prevent="handleDailyMouseDown"
          @mouseover="handleDailyMouseOver"
          @mouseup="handleDailyMouseUp"
          @mouseleave.prevent="handleDailyMouseLeave"
        >
          <li
            v-for="n in TIME_INTERVAL"
            :key="n"
            :ref="`dailyItem`"
            :col="n - 1"
            :title="`每天${timePeriod[n - 1]}`"
            class="daily-item selected-daily-item"
          ></li>
          <!-- @mouseup.stop="dailyCellClick($event, n - 1)" -->
        </ul>
      </div>
    </div>
    <div class="choose-schedule-container">
      <div class="operation">
        <div
          v-if="
            scheduleTimeList.some(
              (item) => Array.isArray(item) && item.length !== 0
            )
          "
        >
          已选择时间段
        </div>
        <div v-else>可拖动鼠标选择时间段</div>
        <span
          @click="clearTimeSchedule"
          v-if="
            scheduleTimeList.some(
              (item) => Array.isArray(item) && item.length !== 0
            )
          "
          >清空</span
        >
      </div>
      <div
        class="schedule-detail"
        v-if="
          scheduleTimeList.some(
            (item) => Array.isArray(item) && item.length !== 0
          )
        "
      >
        <div v-for="n in WEEK_DAYS" :key="n" class="flex-row-start-start">
          <template v-if="scheduleTimeList[n - 1].length > 0">
            <div style="margin-top: 10px" class="margin-right-ten">
              星期{{ n | transformNum }}
            </div>
            <p style="max-width: 80%" class="flex-row-start-start-wrap">
              <span
                @mouseup="clearPerTimePeriod($event, item, n - 1)"
                class="per-timePeriod"
                v-for="(item, index) in scheduleTimeList[n - 1]"
                :key="index"
              >
                {{ item.timePeriod }}
              </span>
            </p>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { timePeriod } from '@/views/novel-put-management/scripts/extraData'
const DAY_HOURS = 24 // 一天24小时
const WEEK_DAYS = 7 // 一周七天
const TIME_INTERVAL = 48 // 半个小时一个间隔
export default {
  props: {
    inputData: Array,
    targetDomClassName: {
      type: String,
      default: '.plan-dialog-container'
    }
  },
  data () {
    return {
      totalPutTime: 0,
      DAY_HOURS,
      WEEK_DAYS,
      TIME_INTERVAL,
      timePeriod,
      isMouseOverWork: false, // *mouseOverWork事件是否生效
      directionInfo: {
        direction: '',
        col: null,
        row: null,
        colDiff: 0,
        rowDiff: 0
      },
      scheduleTimeList: [], // * 所选择的时间段(用于显示)
      isDailyClick: false,
      isDailyMouseDown: false, // *是否按下了"每日"栏位
      map: new Map(),
      dailyMap: new Map(),
      dailyCellArr: [], // *列数据
      weekDayCellArr: [], // * 行数据
      cellItem: {
        col: null,
        row: null
      },
      // * 鼠标按住的起始Dom(按下所在的dom)
      startDom: null,
      // * 鼠标放开时鼠标所在的dom
      endDom: null,
      startDailyDom: null, // * 起始daily节点
      perTimePeriod: null,
      returnData: null
    }
  },
  filters: {
    transformNum (value) {
      const arr = ['一', '二', '三', '四', '五', '六', '日']
      return arr[value - 1]
    }
  },
  mounted () {
    this.initData()
    this.initEvent()
    this.$on('hook:beforeDestroy', () => {
      document.onmouseup = null
    })
  },
  created () {},
  methods: {
    initData () {
      // * 模拟回显数据
      // const imitateData = Array.from(
      //   { length: this.WEEK_DAYS * this.TIME_INTERVAL },
      //   () => (Math.random() * 1 > 0.1 ? 1 : 0)
      // );
      let imitateData = new Array(48 * 7).fill(0)
      if (this.inputData && this.inputData.length) {
        imitateData = this.inputData.map(Number)
      }
      this.scheduleTimeList = Array.from({ length: this.WEEK_DAYS }, () => [])
      // ! 初始化dailyCellArr
      this.dailyCellArr = Array.from({ length: this.TIME_INTERVAL }, () => [])

      const domList = this.$refs.hourItem

      const dailyItem = this.$refs.dailyItem

      for (let i = 0; i < this.TIME_INTERVAL; i++) {
        const arr = []
        let flag = false
        for (let j = 0; j < this.WEEK_DAYS; j++) {
          const dom = domList[i + this.TIME_INTERVAL * j]
          if (imitateData[i + this.TIME_INTERVAL * j] === 0) {
            dom.classList.remove('active-hour')
            flag = true
          }
          if (flag) {
            // eslint-disable-next-line no-unused-expressions
            dailyItem[i].classList.contains('selected-daily-item')
              ? dailyItem[i].classList.remove('selected-daily-item')
              : ''
          }
          arr.push(dom)
        }
        this.$set(this.dailyCellArr, i, [...arr])
      }
      // ! 初始化weekDayCellArr
      for (let i = 0; i < this.WEEK_DAYS; i++) {
        const weekDaysArr = []
        for (let j = 0; j < this.TIME_INTERVAL; j++) {
          const dom = domList[i * this.TIME_INTERVAL + j]
          if (imitateData[i * this.TIME_INTERVAL + j] === 0) {
            dom.classList.remove('active-hour')
          }
          weekDaysArr.push({
            dom,
            col: +dom.getAttribute('col'),
            row: +dom.getAttribute('row')
          })
        }
        // weekDaysArr.push()
        this.$set(this.weekDayCellArr, i, [...weekDaysArr])
      }
      this.getTotalPutHour()
      // ! 合并时间段
      this.handleMergeTimePeriod()
    },
    getTotalPutHour () {
      this.totalPutTime = this.$refs.hourItem.reduce((acc, b, c) => {
        if (b.classList.contains('active-hour')) acc += 0.5
        return acc
      }, 0)
    },
    initEvent () {
      // document.querySelector(".plan-dialog-container").onmouseup = (e) => {
      document.querySelector(this.targetDomClassName).onmouseup = (e) => {
        this.isMouseOverWork = false
        // * 渲染 hour-item
        this.$nextTick(() => {
          if (!this.isDailyClick) {
            this.renderTimeBlock()
            this.checkDailyItemStatus()
          }
          this.getTotalPutHour()
          this.isDailyClick = false
          // * 移除周一到周日单元格高亮的类名和样式
          this.removeClassName()
          // * 移除每日单元格样式
          this.removeDailyClassName()
          // * 改变dailyitem的状态
          // ! 合并时间段
          this.handleMergeTimePeriod()
          // ! 对时间段进行排序
          this.handleSortTimePeriod()
          // 清空timeblock
          this.startDom = null
          this.endDom = null
          this.perTimePeriod = null
          this.isDailyMouseDown = null
          this.startDailyDom = null
          this.map.clear()
          this.directionInfo = {
            direction: '',
            col: null,
            row: null,
            colDiff: 0,
            rowDiff: 0
          }
          const docLi = document.querySelectorAll('.time-item-block .hour-item')
          this.returnData = ''
          docLi.forEach((item) => {
            if (item.className.includes('active')) {
              this.returnData += 1
            } else {
              this.returnData += 0
            }
          })
          this.$emit('returnData', this.returnData)
          this.$emit('getTimeList', this.scheduleTimeList)
        })
      }
    },
    cellClick (col, row, targetIndex) {},
    handleTimeBodyMouseDown (e) {
      if (e.button !== 0) return
      // !需要
      this.startDom = e.target
      this.handleMap(e.target)
      this.handleItemClassName(e.target, 'add')
      this.isMouseOverWork = true
    },
    handleTimeBodyMouseOver (e) {
      if (!this.isMouseOverWork) return
      this.endDom = e.target
      const col = e.target.getAttribute('col')
      const row = e.target.getAttribute('row')
      const startCol = +this.startDom.getAttribute('col')
      const startRow = +this.startDom.getAttribute('row')
      // *判断移动方向
      this.judgeDirection(col, row)
      // * 获取需要渲染的dom合集
      this.getIsMapDomList(
        col,
        row,
        startCol,
        startRow,
        this.directionInfo.direction
      )
    },
    judgeDirection (col, row) {
      const startCol = +this.startDom.getAttribute('col')
      const startRow = +this.startDom.getAttribute('row')

      const curCol = +col
      const curRow = +row

      // *!分为4种情况    以startCol,startRow为原点建立一个理想的坐标系 ， 右下方的空间为x轴和y轴的正方向
      this.directionInfo.col = curCol
      this.directionInfo.row = curRow
      this.directionInfo.colDiff = curCol - startCol
      this.directionInfo.rowDiff = curRow - startRow
      if (this.directionInfo.colDiff >= 0 && this.directionInfo.rowDiff <= 0) {
        this.directionInfo.direction = 'top-right'
      }

      if (this.directionInfo.colDiff >= 0 && this.directionInfo.rowDiff > 0) {
        this.directionInfo.direction = 'bottom-right'
      }

      if (this.directionInfo.colDiff <= 0 && this.directionInfo.rowDiff > 0) {
        this.directionInfo.direction = 'bottom-left'
      }
      if (this.directionInfo.colDiff < 0 && this.directionInfo.rowDiff <= 0) {
        this.directionInfo.direction = 'top-left'
      }
    },
    getIsMapDomList (
      col,
      row,
      startCol,
      startRow,
      direction,
      isDailyMouseOver = false
    ) {
      // const startCol = +this.startDom.getAttribute('col')
      // const startRow = +this.startDom.getAttribute('row')
      const domList = [...document.querySelectorAll('.hour-item')]
      for (let i = 0; i < domList.length; i++) {
        const dom = domList[i]
        const curCol = +dom.getAttribute('col')
        const curRow = +dom.getAttribute('row')

        // * 右下
        if (direction === 'bottom-right') {
          if (
            startCol <= curCol &&
            curCol <= +col &&
            startRow <= curRow &&
            curRow <= +row
          ) {
            this.addOperation(dom)
          } else {
            this.delOperation(dom)
          }
        }
        // * 右上
        if (direction === 'top-right') {
          if (
            startCol <= curCol &&
            curCol <= +col &&
            +row <= curRow &&
            curRow <= startRow
          ) {
            this.addOperation(dom, isDailyMouseOver)
          } else {
            this.delOperation(dom, isDailyMouseOver)
          }
        }
        // *  左上
        if (direction === 'top-left') {
          if (
            +row <= curRow &&
            curRow <= startRow &&
            +col <= curCol &&
            curCol <= startCol
          ) {
            this.addOperation(dom)
          } else {
            this.delOperation(dom)
          }
        }
        // * 左下
        if (direction === 'bottom-left') {
          if (
            +col <= curCol &&
            curCol <= startCol &&
            startRow <= curRow &&
            curRow <= +row
          ) {
            this.addOperation(dom, isDailyMouseOver)
          } else {
            this.delOperation(dom, isDailyMouseOver)
          }
        }
      }
    },
    // *操作map isRetract 是否回缩操作(鼠标首先按住面积增大，然后鼠标反方向移动面积缩小)
    handleMap (target, type = true, isRetract = false) {
      if (this.map.has(target)) {
        if (!type) {
          this.map.delete(target)
        }
        return
      }
      if (!isRetract) {
        this.map.set(target, {
          row: target.getAttribute('row'),
          col: target.getAttribute('col')
        })
      }
    },
    handleItemClassName (dom, type = 'add') {
      if (type === 'add') dom.classList.add('is-map')
      else dom.classList.remove('is-map')
    },
    removeClassName () {
      for (const [key] of this.map) {
        key.classList.remove('is-map')
      }
      // !清除可能因为dailyitem的hover效果产生的被动高亮的效果
      [...this.$refs.hourItem].forEach((item) => {
        item.classList.remove('is-passivity-highlight')
      })
    },

    renderTimeBlock () {
      if (!this.startDom && !this.startDailyDom) return
      let flag = false
      if (this.startDom) {
        flag = this.startDom.classList.contains('active-hour')
      }
      if (this.startDailyDom) {
        flag = this.startDailyDom.classList.contains('selected-daily-item')
      }
      for (const [key] of this.map) {
        flag
          ? key.classList.remove('active-hour')
          : key.classList.add('active-hour')
      }
    },
    addOperation (dom) {
      this.handleMap(dom)
      this.handleItemClassName(dom, 'add')
    },
    delOperation (dom) {
      this.handleMap(dom, false, true)
      this.handleItemClassName(dom, 'remove')
    },
    checkDailyItemStatus () {
      const dailyItemList = [...document.querySelectorAll('.daily-item')]
      if (this.perTimePeriod) {
        for (
          let i = this.perTimePeriod.startColIndex;
          i <= this.perTimePeriod.endColIndex;
          i++
        ) {
          if (!dailyItemList[i].classList.contains('selected-daily-item')) {
            continue
          }
          dailyItemList[i].classList.remove('selected-daily-item')
        }
      } else {
        if (!this.startDom && !this.startDailyDom) return
        for (const [key] of this.map) {
          const col = +key.getAttribute('col')
          const colIndex = col
          // !如果目标列所有dom都包含了active-hour这个class 则表示该列全选中
          const flag = this.dailyCellArr[colIndex].every((dom) =>
            dom.classList.contains('active-hour')
          )
          flag
            ? dailyItemList[colIndex].classList.add('selected-daily-item')
            : dailyItemList[colIndex].classList.remove('selected-daily-item')
        }
      }
    },
    clearTimeSchedule () {
      this.$confirm('确定要清除时间段吗?', '提示', {
        confirmButtonClass: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.scheduleTimeList = Array.from(
            { length: this.WEEK_DAYS },
            () => []
          )
          const domList = [...this.$refs.hourItem]
          const dailyItem = [...this.$refs.dailyItem]
          for (let i = 0; i < domList.length; i++) {
            const dom = domList[i]
            const flag = dom.classList.contains('active-hour')
            if (!flag) continue
            dom.classList.remove('active-hour')
          }
          for (let j = 0; j < dailyItem.length; j++) {
            const dom = dailyItem[j]
            const flag = dom.classList.contains('selected-daily-item')
            if (!flag) continue
            dom.classList.remove('selected-daily-item')
          }
          this.getTotalPutHour()
        })
        .catch(() => {})
    },
    // todo=>合并时间段
    // ! 合并时间段 *important
    handleMergeTimePeriod () {
      const resultArr = []
      this.weekDayCellArr.forEach((weekDayList, weekIndex) => {
        const arr = []
        weekDayList.forEach((item, index) => {
          if (item.dom.classList.contains('active-hour')) {
            arr.push({
              dom: item.dom,
              col: item.col,
              row: item.row,
              startColIndex: item.col,
              endColIndex: item.col,
              colIndex: this.timePeriod[item.col] // *列的下标
            })
          }
        })
        resultArr.push([...arr])
      })

      const firstLevelArr = []
      for (let i = 0; i < resultArr.length; i++) {
        const secondLevelArr = []
        const arr = resultArr[i]
        for (let m = 0; m < arr.length; m++) {
          let mCol = arr[m].col
          const domArr = [arr[m].dom]
          const startIndex = arr[m].startColIndex
          let endIndex = arr[m].endColIndex
          for (let n = m + 1; n < arr.length; n++) {
            const nCol = arr[n].col
            // ! 如果nCol-1 === mCol 则表明时间段是连续的

            if (nCol - 1 === mCol) {
              endIndex = nCol
              domArr.push(arr[n].dom)
              mCol = arr[n].col
            } else {
              m = n - 1
              break
            }
            // !
            if (n === arr.length - 1) {
              m = n
            }
          }
          secondLevelArr.push({
            domArr,
            startColIndex: startIndex,
            endColIndex: endIndex
          })
        }
        firstLevelArr.push([...secondLevelArr])
      }
      firstLevelArr.forEach((key, index) => {
        const timePeriodArr = []
        key.forEach((item, index2) => {
          const { startColIndex, endColIndex, domArr } = item
          let timePeriod = ''
          if (endColIndex === startColIndex) {
            timePeriod = this.timePeriod[endColIndex]
          }
          if (endColIndex > startColIndex) {
            const valOne = this.timePeriod[startColIndex]
            const valTwo = this.timePeriod[endColIndex]
            timePeriod = `${valOne.replace(/\s+/g, '').split('-')[0]} - ${
              valTwo.replace(/\s+/g, '').split('-')[1]
            }`
          }
          timePeriodArr.push({
            domArr,
            timePeriod,
            startColIndex,
            endColIndex
          })
        })
        this.$set(this.scheduleTimeList, index, [...timePeriodArr])
      })
    },
    clearPerTimePeriod (event, val, index) {
      if (event.button !== 0) return
      this.perTimePeriod = {
        val,
        startColIndex: val.startColIndex,
        endColIndex: val.endColIndex
      };
      [...val.domArr].forEach((item) => {
        item.classList.remove('active-hour')
      })
    },
    // ! 从小到大对时间段进行排序
    handleSortTimePeriod () {},
    handleDailyMouseDown (e) {
      this.startDailyDom = e.target
      this.map.clear()
      this.isDailyMouseDown = true
    },

    handleDailyMouseOver (e) {
      // !被动高亮hourItem元素
      if (!this.isMouseOverWork) {
        const col = +e.target.getAttribute('col')
        const hourItem = [...this.$refs.hourItem]
        const targetIndexList = Array.from(
          { length: this.WEEK_DAYS },
          (item, index) => {
            return index * this.TIME_INTERVAL + col
          }
        )
        for (let i = 0; i < this.TIME_INTERVAL * this.WEEK_DAYS; i++) {
          const dom = hourItem[i]
          if (targetIndexList.includes(i)) {
            dom.classList.add('is-passivity-highlight')
          } else {
            dom.classList.remove('is-passivity-highlight')
          }
        }
      }

      if (!this.isDailyMouseDown) return

      // const targetDom = e.target
      const curCol = +e.target.getAttribute('col')
      const startCol = +this.startDailyDom.getAttribute('col')
      // * 按住鼠标右移
      let direction = ''
      if (curCol >= startCol) {
        direction = 'bottom-right'
        this.getIsMapDomList(curCol, 7, startCol, 0, direction, true)
      } else {
        direction = 'bottom-left'
        // * 按住鼠标左移
        this.getIsMapDomList(curCol, 7, startCol, 0, direction, true)
      }
      this.renderDailyMap(curCol, startCol, direction)
    },
    handleDailyMouseUp (e) {
      this.dailyCellClick(e, +e.target.getAttribute('col'), false)
    },
    handleDailyMouseLeave () {
      [...this.$refs.hourItem].forEach((item) => {
        item.classList.remove('is-passivity-highlight')
      })
    },
    dailyCellClick ($event, index, flag = true) {
      if ($event.button !== 0) return
      this.isDailyClick = flag
      const targetDom = [...document.querySelectorAll('.daily-item')][index]
      const isSelected = targetDom.classList.contains('selected-daily-item')
      // *method1
      // for (let i = 0; i < this.WEEK_DAYS; i++) {
      //   const dom = domList[i * this.TIME_INTERVAL + index]
      //   isSelected
      //     ? dom.classList.remove('active-hour')
      //     : dom.classList.add('active-hour')
      // }
      // * method2
      const arrList = this.dailyCellArr[index]
      arrList.forEach((dom) => {
        isSelected
          ? dom.classList.remove('active-hour')
          : dom.classList.add('active-hour')
      })

      isSelected
        ? targetDom.classList.remove('selected-daily-item')
        : targetDom.classList.add('selected-daily-item')
    },
    renderDailyMap (curCol, startCol, direction) {
      const max = Math.max(curCol, startCol)
      const min = Math.min(curCol, startCol)
      const domList = this.$refs.dailyItem
      for (let i = 0; i < domList.length; i++) {
        const dom = domList[i]
        if (i < min || i > max) {
          dom.classList.remove('is-highlight-blue')
        } else {
          dom.classList.add('is-highlight-blue')
        }
      }
    },
    removeDailyClassName () {
      [...this.$refs.dailyItem].forEach((item) => {
        item.classList.remove('is-highlight-blue')
      })
    },
    handleTitle (col, row) {
      switch (row) {
        case 0:
          return `周一 ${this.timePeriod[col]}`
        case 1:
          return `周二 ${this.timePeriod[col]}`
        case 2:
          return `周三 ${this.timePeriod[col]}`
        case 3:
          return `周四 ${this.timePeriod[col]}`
        case 4:
          return `周五 ${this.timePeriod[col]}`
        case 5:
          return `周六 ${this.timePeriod[col]}`
        case 6:
          return `周日 ${this.timePeriod[col]}`
      }
    }
  },
  components: {}
}
</script>
<style lang="scss" scoped>
.custom-schedule {
  width: 600px;
  .custom-time-area {
    .sumTime {
      text-align: right;
      color: #a3a3a3;
      font-size: 14px;
      > span {
        font-weight: bold;
      }
    }
    .time-block {
      width: 100%;
      margin-top: 10px;
      .time-header {
        width: 100%;
        display: flex;
        margin-bottom: -1px;
        .time-info {
          margin: 0 -1px -1px 0;
          width: 46px;
          height: 48px;
          font-size: 12px;
          line-height: 48px;
          background: #fff;
          border: 1px solid #ebebeb;
          border-top-left-radius: 4px;
          color: #8c8c8c;
          letter-spacing: 0;
          text-align: center;
        }
        .time-range-block {
          flex: 1;
          .time-range-block-list {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            > p {
              margin: 0 -1px -1px 0;
              height: 26px;
              font-size: 12px;
              line-height: 26px;
              background: #fff;
              border: 1px solid #ebebeb;
              color: #8c8c8c;
              letter-spacing: 0;
              text-align: center;
              &:nth-of-type(1) {
                border-right-width: 3px;
              }
              &:nth-last-of-type(1) {
                border-left-width: 3px;
              }
            }
          }
          .time-hours {
            display: grid;
            grid-template-columns: repeat(24, 1fr);
            .hour-num {
              margin: 0 -1px -1px 0;
              height: 22px;
              font-size: 12px;
              line-height: 22px;
              letter-spacing: 0;
              color: #8c8c8c;
              text-align: center;
              background: #fff;
              border: 1px solid #ebebeb;
            }
          }
        }
      }
      .time-body {
        display: flex;
        width: 100%;
        > ul:nth-of-type(1) {
          .day-item {
            margin: 0 -1px -1px 0;
            width: 46px;
            height: 24px;
            font-size: 12px;
            color: #8c8c8c;
            letter-spacing: 0;
            text-align: center;
            line-height: 24px;
            background: #fff;
            border: 1px solid #ebebeb;
          }
        }
        .time-item-block {
          flex: 1;
          display: grid;
          grid-template-columns: repeat(48, 1fr);
          .hour-item {
            margin: 0 -1px -1px 0;
            height: 24px;
            background: #fff;
            border: 1px solid #ebebeb;
            cursor: pointer;
            &:hover {
              border-color: #e9e9e9;

              background-color: #ebefee;
            }
          }
          .is-passivity-highlight {
            border-color: #e9e9e9 !important;
            background-color: #ebefee !important;
          }
          .active-hour {
            background: #ddf3dd;
            border: 1px solid #07c160;
            z-index: 2;
          }
          .is-map {
            // background: #fde0e2 !important;
            background: #f5a3a7 !important;
            border: 1px solid #fd5d5a !important;
            z-index: 2;
          }
        }
      }
    }
    .daily-block {
      margin-top: 20px;
      display: -ms-flexbox;
      display: flex;
      .daily-title {
        width: 46px;
        height: 32px;
        font-size: 12px;
        font-weight: 700;
        color: #6b6b6b;
        letter-spacing: 0;
        text-align: center;
        line-height: 32px;
        background: #fff;
        border: 1px solid #ebebeb;
        border-radius: 4px 0 0 4px;
        margin-right: -1px;
      }
      .daily-items {
        flex: 1;
        display: grid;
        grid-template-columns: repeat(48, 1fr);
        .daily-item {
          height: 32px;
          background: #fff;
          border: 1px solid #ebebeb;
          margin-right: -1px;
          cursor: pointer;
          &:hover {
            border-color: #e9e9e9;

            background-color: #ebefee;
          }
        }
        .selected-daily-item {
          background: #ddf3dd;
          border: 1px solid #07c160;
          z-index: 2;
        }
        .is-highlight-blue {
          background-color: #fcd9dc !important;
          border-color: #fa8e8c !important;
        }
      }
    }
  }
  .choose-schedule-container {
    font-size: 12px;
    margin: 10px 0;
    border-radius: 4px;
    border: 1px solid #ebebeb;
    .operation {
      position: relative;
      margin: 10px 0;
      div {
        margin: 0 auto;
        text-align: center;
      }
      > span {
        position: absolute;
        top: 0;
        right: 0;
        padding-right: 20px;
        color: #338aff;
        font-weight: bold;
        cursor: pointer;
      }
    }
    .schedule-detail {
      > div {
        margin-bottom: 10px;
        padding-left: 10px;
        color: #6b6b6b;
        .per-timePeriod {
          padding: 1px 5px;
          margin: 0px 5px 5px 0;
          border: 1px solid #ccc;
          border-radius: 4px;
          line-height: 30px;
          transition: all 0.1s;
          &:hover {
            border-color: #fd5d5a;
            color: #fd5d5a;
            cursor: pointer;
            background-color: #fde0e2;
          }
        }
      }
    }
  }
}
</style>
