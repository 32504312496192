import request from './config'
import { formatTime } from '../assets/js/utils'

// 获取今日广告数据
export function getAdvertList(params) {
  return request.get('/adInfo', { params })
}

// 根据类型查询字段
export function getFields(params) {
  return request.get('/fields/dataType', { params })
}

// 获取投放广告数据
export function getadData(params) {
  return request.get('/adGroup/stats', { params })
}

// 获取投放计划数据
export function getCampaignData(params) {
  return request.get('/adCampaign/stats', { params })
}

// 获取每日用户消耗
export function getUserConsume(params) {
  return request.get('/everyday/users/cost', { params })
}

// 获取每日公众号消耗
export function getChannelConsume(params) {
  return request.get('/everyday/channel/cost', { params })
}

// 获取书籍日消耗线性图
export function getBooksCostGraph(params) {
  return request.get('/everyday/books/cost/graph', { params })
}

// 获取每日书籍消耗
export function getBooksCost(params) {
  return request.get('/everyday/books/cost', { params })
}

// 获取平台统计
export function getPlatformStatistics(params) {
  return request.get('/platform', { params })
}

// 获取平台统计书籍的预估回本数据
export function getPreReturnRate(params) {
  return request.get('/preReturnRate', { params })
}

// 投放日充值线性图
export function getPutDateRechargeGraph(params) {
  return request.get('/putDate/recharge/graph', { params })
}

// 日充值线性图
export function getEdRechargeGraph(params) {
  return request.get('/everyday/recharge/graph', { params })
}

// 数据导出
export function exportFile(url, data, name, method = 'get') {
  return new Promise((resolve, reject) => {
    if (method === 'get') {
      request({
        url: url,
        params: data,
        method,
        responseType: 'blob'
      }).then(res => {
        if (!res) return
        const url = window.URL.createObjectURL(new Blob([res]))
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = url
        link.setAttribute('download', name + ` (${formatTime(Date.now(), 'yyyy-MM-dd')}).xls`)
        document.body.appendChild(link)
        link.click()
        resolve()
      }).catch(() => {
        console.log('err')
      })
    }
  })
}
// 获取投放计划数据
export function getPutPlanData(params) {
  return request.get('/adCampaign/dataStats', { params })
}

// 获取连续三天未达标的状态new

export function getThreeDayNoReach(param) {
  return request.get('/warning/threeDayNoReach', { param })
}

// 获取当前未达标的数据new

export function getTodayNoReach(param) {
  return request.get('/warning/todayNoReach', { param })
}

// 获取数据告警当前出价排行new

export function getTodayPutAdInfoRanking(param) {
  return request.get('/ranking/todayPutAdInfo', { param })
}

// 获取订单明细

export function getOrderDetail(params) {
  return request.get('/order/all', { params })
}

// 获取时刻新增充值
export function getTimeRecharge(params) {
  return request.get('/timeNewRecharge', { params })
}

// 小时消耗充值
export function getHoursCost(params) {
  return request.get('/channel/hoursCost', { params })
}

// 订单小时线性图
export function getHourStatistics(params) {
  return request.get('/order/hourStatistics', { params })
}

// 区间用户消耗
export function getIntervalUser(params) {
  return request.get('/interval/users/cost', { params })
}

// 区间公众号消耗
export function getIntervalChannel(params) {
  return request.get('/interval/channel/cost', { params })
}

// 区间书籍消耗
export function getIntervalBook(params) {
  return request.get('/interval/books/cost', { params })
}

// 获取区间书籍投放日期
export function getIntervalBookDate(params) {
  return request.get('/book/putDate', { params })
}

// 运营考核
export function getOperationAssess(params) {
  return request.get('/operationAssess', { params })
}

// 运营统计
export function getOperationStatics(params) {
  return request.get('/operation', { params })
}

// 运营数据
export function getOperationEveryday(params) {
  return request.get('/operation/everyday', { params })
}

// 运营每日公众号数据
export function getOperationChannel(params) {
  return request.get('/operation/channel', { params })
}

// 运营每日公众号数据线性图
export function getOperationChannelGraph(params) {
  return request.get('/operation/channel/graph', { params })
}

// 获取用户复充
export function getUserRecharge(params) {
  return request.get('/userKeep', { params })
}
// 查询复充率线形图
export function getUserRechargeGraph(params) {
  return request.get('/repeat/rechargeNum/graph', { params })
}
// 获取缓存列表
export function getCacheList(params) {
  return request.get('/query/progress', { params })
}

// 缓存查询
export function getCacheData(params) {
  return request.get('/query/longData', { params })
}

// 新增数据统计缓存
export function cacheData(params) {
  return request.get('/insert/longData', { params })
}
// 排行统计-投手实时排行
export function getPutCrowdRank(params) {
  return request.get('/ranking/todayPutUser', { params })
}

// 排行统计-收益排行
export function getProfitRankingByDate(params) {
  return request.get('/profitRankingByDate', { params })
}

// 排行统计-用户排行榜
export function getUserEverydayRanking(params) {
  return request.get('/userEverydayRanking', { params })
}

// 财务统计

// 财务日情况表
export function getFinanceEverydayData(params) {
  return request.get('/finance/everydayData', { params })
}

// 财务书籍统计
export function getFinanceBooks(params) {
  return request.get('/finance/books', { params })
}

// 薪资管理
export function getFinanceSalary(params) {
  return request.get('/salary', { params })
}
// 新薪资管理
export function getFinanceSalaryNew(params) {
  return request.get('/userYear/salary', { params })
}

// 设置利率新
export function setSalaryRateNew(id, params) {
  return request.put(`/profit/userYear/salary/${id}`, params)
}

// 设置利率
export function setSalaryRate(id, params) {
  return request.put(`/profit/salary/${id}`, params)
}

// 利润统计
export function getFinanceProfit(params) {
  return request.get('/profit', { params })
}

// 累计统计

// 获取累计统计
export function getMultiStatistics(params) {
  return request.get('/date/multi', { params })
}

// 获取月度统计
export function getMultiMonth(params) {
  return request.get('/month', { params })
}

// 获取月度统计详情
export function getMultiMonthDetail(params) {
  return request.get('/month/detail', { params })
}

// 获取小说成本
export function getMultiCostRecharge(params) {
  return request.get('/cost/recharge', { params })
}

// 获取小说成本线性图
export function getMultiCostRechargeGraph(params) {
  return request.get('/cost/recharge/graph', { params })
}

// 书籍价值分析
export function getBookValueAnalysis(params) {
  return request.get('/book/valueAnalysis', { params })
}

/**
*投放区间相关 end by devT
**/

//投放区间汇总
export function getPutIntervalLabel(params) {
  return request.get('/putInterval/label', { params })
}

//投放区间表格图表
export function getPutIntervalExcel(params) {
  return request.get('/putInterval/excel', { params })
}

//运营区间汇总数据
export function getOperateIntervalLabel(params) {
  return request.get('/operateInterval/label', { params })
}

//运营区间汇总数据
export function getOperateIntervalExcel(params) {
  return request.get('/operateInterval/excel', { params })
}

// 实际运营考核回本
export function getOperateAssess(params) {
  return request.get('/operateAssess', { params })
}

// 预测运营考核回本
export function getPreOperateAssess(params) {
  return request.get('/preOperateAssess', { params })
}

// 关键字统计明细
export function getWechatKeyDetail(params) {
  return request.get('/wechatKey', { params })
}
// 关键字统计
export function getWechatKeyCount(params) {
  return request.get('/wechatKey/count', { params })
}

// 获取年度统计数据

export function getYearlyData(params) {
  return request.get('/yearDetail', { params })
}

// 获取年度统计数据明细

export function getWeekDetail(params) {
  return request.get('/weekDetail', { params })
}
