<template>
  <div class="user-recharge padding-20 box-shadow-light-grey">
    <div class="filter-box">
      <!-- <h4 class="filter-title">今日广告</h4> -->
      <div class="row">
        <exclude-query :params="params" />
        <el-date-picker
          v-model="params.selectTime"
          style="width: 220px"
          type="daterange"
          align="right"
          unlink-panels
          :clearable="false"
          range-separator="-"
          value-format="yyyy-MM-dd"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
        <select-platform-channel :params="params" data-type="obj" />
        <select-dept-user
          :params="params"
          @userChange="(name) => (this.userName = name)"
          @deptChange="(dept) => (this.deptName = dept)"
        />
        <!-- 新增标签类型选项 -->
        <el-select
          v-model="params.labelType"
          clearable
          placeholder="标签类型"
          style="width: 120px"
        >
          <el-option
            v-for="(item, index) in labelListShow"
            :key="index"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <!-- 新增书籍选项 -->
        <el-select
          v-model="params.booksId"
          filterable
          remote
          clearable
          style="width: 150px"
          reserve-keyword
          placeholder="书籍名称"
          :remote-method="getBook"
        >
          <el-option
            v-for="item in bookList"
            :key="item.id"
            :label="item.bookName"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <el-select
          v-model="params.isTest"
          clearable
          placeholder="类型"
          style="width: 100px"
        >
          <el-option label="普通号" :value="0"></el-option>
          <el-option label="测书号" :value="1"></el-option>
        </el-select>
        <el-select
          v-model="params.dayType"
          clearable
          placeholder="统计类型"
          style="width: 100px"
        >
          <el-option label="自然日统计" :value="0"></el-option>
          <el-option label="24小时统计" :value="1"></el-option>
        </el-select>
        <el-button
          type="primary"
          @click="handlePageChange(1)"
          style="margin-left: 5px"
          :loading="loading"
        >
          搜索
        </el-button>
        <el-button
          type="info"
          @click="handleReset"
          style="margin-left: 5px"
          :loading="loading"
        >
          重置
        </el-button>
      </div>
    </div>
    <div class="main-container">
      <div class="tags" v-if="graphData">
        <el-tag type="success"> 新增粉丝:{{ graphData.newUser }} </el-tag>
        <el-tag type="warning">
          未充值人数:{{ graphData.noRechargePeople }}
        </el-tag>
        <el-tag type="danger"> 充值率:{{ graphData.rechargeRate }}% </el-tag>
      </div>
      <div
        id="user-recharge-graph"
        v-loading="loadingGraph"
        style="width: 100%; height: 450px; z-index: 1"
      ></div>
      <el-table
        header-row-class-name="table-header"
        ref="userRechargeTable"
        class="user-recharge-table"
        :data="dataList"
        style="width: 100%"
        height="650px"
        v-loading="loading"
        empty-text="暂无数据"
        :row-class-name="tableRowClassName"
      >
        <el-table-column label="日期" min-width="120" fixed="left">
          <template slot-scope="scope">
            <div style="font-size: 12px">
              {{ scope.row.lists[0].putDate }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          class-name="extra-column"
          v-for="(head, index3) in extraHeader"
          :key="index3 + 'extra'"
          :label="head"
          min-width="140px"
        >
          <template slot-scope="scope">
            <div
              v-if="
                scope.row.lists[index3] && scope.row.lists[index3].rechargeDate
              "
            >
              <el-tooltip
                :content="`充值日期:${scope.row.lists[index3].rechargeDate}`"
                placement="top-start"
              >
                <div
                  style="cursor: pointer; line-height: 25px; font-size: 12px"
                >
                  <!-- <p class="recharge-date">
                  {{ scope.row.lists[index3].rechargeDate }}
                </p> -->
                  <p class="remark-num">
                    充值人数:{{ scope.row.lists[index3].payNum }}
                  </p>
                  <p class="green-num">
                    充值金额:{{ scope.row.lists[index3].afterTaxAmount }}
                  </p>
                  <p class="grey-num">
                    充值笔数:{{ scope.row.lists[index3].orderCnt }}
                  </p>
                </div>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        style="margin-top: 10px; text-align: right"
        background
        @current-change="handlePageChange"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import echarts from "echarts";
import {
  formatThousandsDots,
  formatTime,
  getRecentlyMonth,
} from "@/assets/js/utils";
import ExcludeQuery from "@/components/excludeQuery";
import SelectDeptUser from "@/components/SelectDeptUser";
import SelectPlatformChannel from "@/components/SelectPlatformChannel";
import {
  yesterdayOptions,
  labelList,
  platformList,
  pickerOptions,
} from "@/assets/js/options";
import {
  getFields,
  getUserRecharge,
  getUserRechargeGraph,
} from "@/api/statics";
import { getBookList } from "@/api/account";
import { mapActions } from "vuex";
export default {
  name: "userRecharge",
  props: {
    lastParams: {
      type: Object,
    },
  },
  data() {
    return {
      params: {},
      bookList: [],
      fieldsData: [],
      originFeild: [],
      selectFields: null,
      selectAllFields: false,
      dataList: [],
      totalData: [],
      loading: false,
      loadingGraph: false,
      sortName: null,
      sortDesc: null,
      showRecharge: false,
      yesterdayOptions,
      labelList,
      pickerOptions,
      sendField: [],
      showField: [],
      tmpShowField: [],
      showCommonDrawer: false,
      showTemplate: null,
      nextParams: {},
      showCacheList: false,
      cachePage: 1,
      cacheDataTotal: 0,
      hasPage: false,
      platformList,
      multiChart: null,
      startTime: null,
      endTime: null,
      page: 1,
      pageSize: 30,
      extraHeader: [],
      rechargeGraph: null,
      graphData: null,
      total: 0,
    };
  },
  watch: {
    lastParams: function () {
      this.getData("drawer");
    },
  },
  computed: {
    labelListShow() {
      return this.labelList.filter((item) => {
        return item.show;
      });
    },
    sortableType() {
      return function (value) {
        if (value.autoFields === 0) {
          return false;
        } else if (value.autoFields === 2 || value.autoFields === 4) {
          return true;
        } else if (value.autoFields === 1 || value.autoFields === 3) {
          return "custom";
        }
      };
    },
    filAlign() {
      return function (value) {
        switch (value.fontAlign) {
          case 0:
            return "left";
          case 1:
            return "center";
          case 2:
            return "right";
          default:
            return "center";
        }
      };
    },
    filFields: function () {
      // var _that = this
      const flag = !!this.params.currentOfficial;
      const tmpFilFields = this.showField.filter(function (item) {
        if (item.autoFields !== 1) {
          if (item.fieldCode === "bookName") {
            return flag;
          } else {
            return true;
          }
        }
        // return item.autoFields !== 1;
      });
      return [];
    },
  },
  filters: {
    sortField: function (value, item) {
      if (item.autoFields === 4 || item.autoFields === 5) {
        if (value !== undefined) {
          return formatThousandsDots(value.toString());
        }
      } else {
        return value;
      }
    },
    formatNum: function (value) {
      if (!value) return 0;
      return ("" + value).replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, "$1,");
    },
    filChildrenFieds() {
      return function (value) {
        return value.filter((item) => {
          return item.autoFields === 0;
        });
      };
    },
  },
  methods: {
    // 获取书籍列表by id
    getBookById(booksIds) {
      getBookList({
        page: 1,
        pageSize: 50,
        booksIds,
      }).then((res) => {
        this.bookList = res.list;
      });
    },
    // 定位到指定行
    tableScrollMove(refName, index = 0) {
      if (!refName || !this.$refs[refName]) return; // 不存在表格的ref vm 则返回
      const vmEl = this.$refs[refName].$el;
      if (!vmEl) return;
      // 计算滚动条的位置
      const target = vmEl.querySelectorAll(".el-table__body tr")[index];
      if (!target) return;
      const targetTop = vmEl
        .querySelectorAll(".el-table__body tr")
        [index].getBoundingClientRect().top;
      const containerTop = vmEl
        .querySelector(".el-table__body")
        .getBoundingClientRect().top;
      const scrollParent = vmEl.querySelector(".el-table__body-wrapper");
      scrollParent.scrollTop = targetTop - containerTop;
    },
    getBook(bookName) {
      getBookList({
        page: 1,
        pageSize: 50,
        bookName,
      }).then((res) => {
        this.bookList = res.list;
      });
    },
    getData(flag) {
      const today = getRecentlyMonth(true);
      if (!this.params.selectTime) {
        this.$set(this.params, "selectTime", today);
      }
      let tmpFields = null;
      if (this.sendField.length > 0) {
        tmpFields = this.handleFields(this.sendField);
      } else {
        this.showField = this.originFeild;
      }
      let tmpform = {};
      if (flag === "drawer") {
        if (this.lastParams.booksId) {
          this.getBookById(this.lastParams.booksId);
        }
        tmpform = {
          beginDate: this.lastParams.selectTimes[0],
          endDate: this.lastParams.selectTimes[1],
          page: this.page,
          pageSize: this.pageSize,
          originPlatform: this.lastParams.originPlatform,
          accountChannelId: this.lastParams.currentOfficial
            ? this.lastParams.currentOfficial.id
            : null,
          adminUserId: this.lastParams.adminUserId,
          deptId: this.lastParams.deptId,
          booksId: this.lastParams.booksId,
          labelType: this.lastParams.labelType,
          isTest: this.lastParams.isTest,
          dayType: this.lastParams.dayType,
          ruledOutBooksIds:
            this.params.ruledOutBooksIds && this.params.ruledOutBooksIds.length
              ? this.params.ruledOutBooksIds.map((item) => item.id).join()
              : null,
          ruledOutAccountChannelIds:
            this.params.ruledOutAccountChannelIds &&
            this.params.ruledOutAccountChannelIds.length
              ? this.params.ruledOutAccountChannelIds.join()
              : null,
        };
      } else if (flag === "created") {
        tmpform = {
          beginDate: this.params.selectTime[0],
          endDate: this.params.selectTime[1],
          page: this.page,
          pageSize: this.pageSize,
          originPlatform: this.params.originPlatform,
          accountChannelId: this.params.currentOfficial
            ? this.params.currentOfficial.id
            : null,
          adminUserId: this.params.adminUserId,
          deptId: this.params.deptId,
          booksId: this.params.booksId,
          labelType: this.params.labelType,
          isTest: this.params.isTest,
          dayType: this.params.dayType,
          ruledOutAccountChannelIds:
            this.params.ruledOutAccountChannelIds &&
            this.params.ruledOutAccountChannelIds.length
              ? this.params.ruledOutAccountChannelIds
              : null,
          ruledOutBooksIds:
            this.params.ruledOutBooksIds && this.params.ruledOutBooksIds.length
              ? this.params.ruledOutBooksIds
              : null,
        };
      } else {
        tmpform = {
          beginDate: this.params.selectTime[0],
          endDate: this.params.selectTime[1],
          page: this.page,
          pageSize: this.pageSize,
          originPlatform: this.params.originPlatform,
          accountChannelId: this.params.currentOfficial
            ? this.params.currentOfficial.id
            : null,
          adminUserId: this.params.adminUserId,
          deptId: this.params.deptId,
          booksId: this.params.booksId,
          labelType: this.params.labelType,
          isTest: this.params.isTest,
          dayType: this.params.dayType,
          ruledOutBooksIds:
            this.params.ruledOutBooksIds && this.params.ruledOutBooksIds.length
              ? this.params.ruledOutBooksIds.map((item) => item.id).join()
              : null,
          ruledOutAccountChannelIds:
            this.params.ruledOutAccountChannelIds &&
            this.params.ruledOutAccountChannelIds.length
              ? this.params.ruledOutAccountChannelIds.join()
              : null,
        };
      }

      // }
      this.loading = true;
      this.loadingGraph = true;
      getUserRecharge(tmpform)
        .then((res) => {
          this.total = res.total;
          if (tmpform.showFieldCodes) {
            this.extraHeader = tmpform.showFieldCodes.includes("returnRateList")
              ? res.headers
              : [];
          } else {
            this.extraHeader = res.headers;
          }
          this.totalData = res.showSumFields ? res.showSumFields : [];
          this.dataList = res.lists;
          if (flag !== "drawer") {
            this.addRouterHistory({
              path: this.$route.path,
              params: {
                ...tmpform,
                selectTime: this.params.selectTime,
                cacheShowField: this.showField,
                cacheSendField: this.sendField,
              },
            });
          }
        })
        .finally(() => {
          this.loading = false;
          this.$nextTick(() => {
            this.$refs.userRechargeTable.doLayout();
          });
        });

      getUserRechargeGraph(tmpform)
        .then((res) => {
          this.graphData = res;
          // ;
          this.setGraph(res);
        })
        .finally(() => {
          this.loadingGraph = false;
        });
    },
    setGraph(val) {
      const { rechargePeople, rechargeNum } = val;
      this.rechargeGraph = echarts.init(
        document.querySelector("#user-recharge-graph")
      );
      window.onresize = this.rechargeGraph.resize;
      this.rechargeGraph.setOption({
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          formatter: (params) => {
            const item = params[0];
            return "充值" + item.name + "次：" + item.data + "人";
          },
        },
        grid: {
          left: "3%",
          right: "10%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          name: "充值人数",
          type: "value",
          boundaryGap: [0, 0.01],
        },
        yAxis: {
          name: "充值次数",
          type: "category",
          data: rechargeNum,
        },
        series: [
          {
            type: "bar",
            data: rechargePeople,
          },
        ],
      });
    },
    handleGetFeilds(flag = "created") {
      getFields({ dataType: "repeatRechargeNumGraph" }).then((res) => {
        this.originFeild = res;
        const tmpdata = [];
        for (let i = 0; i < res.length; i++) {
          tmpdata.push({
            fieldName: res[i].fieldName,
            fieldCode: res[i].fieldCode,
          });
        }
        this.fieldsData = tmpdata;
        // !若显示字段为空则显示全部
        if (this.showField.length === 0) {
          this.showField = res;
        }
        this.selectAllFields = true;
        this.getData(flag);
      });
    },
    handleFieldChange(send, show) {
      this.sendField = send;
      this.tmpShowField = show;
      this.$nextTick(() => {
        this.$refs.userRechargeTable.doLayout();
      });
    },
    // 表格颜色添加
    tableRowClassName({ row, rowIndex }) {
      const queryDate = this.lastParams ? this.lastParams.clickDate : null;
      const isSelected = row.putDate === queryDate;
      if (isSelected) {
        this.tableScrollMove("userRechargeTable", rowIndex);
        return "table-lastSected";
      }
      return "";
    },
    handleSortChange({ column, prop, order }) {},
    handlePageChange(num) {
      this.page = num;
      this.getData();
    },
    handleReset() {
      this.params.selectTime = getRecentlyMonth(true);
      this.params.originPlatform = null;
      this.params.currentOfficial = null;
      this.params.adminUserId = null;
      this.params.deptId = null;
      this.params.isTest = null;
      this.params.dayType = 0;
      this.params.ruledOutAccountChannelIds = [];
      this.getData();
    },
    ...mapActions({
      addRouterHistory: "addRouterHistory",
    }),
  },
  created() {
    this.getBook();
    if (this.lastParams) {
      this.params = this.lastParams;
      this.handleGetFeilds("drawer");
      return false;
    }
    this.$set(this.params, "dayType", 0);
    const today = formatTime(new Date(), "yyyy-MM-dd");
    this.$set(this.params, "queryDate", today);

    const tmpIndex = this.$store.state.routerHistory.findIndex(
      (item) => item.path === this.$route.path
    );

    if (tmpIndex !== -1) {
      this.params = this.$store.state.routerHistory[tmpIndex].params;
      this.showField =
        this.$store.state.routerHistory[tmpIndex].params.cacheShowField;
      this.sendField =
        this.$store.state.routerHistory[tmpIndex].params.cacheSendField;
      // if (this.params.ruledOutAccountChannelIds) {
      //   this.params.ruledOutAccountChannelIds = this.params.ruledOutAccountChannelIds.split(
      //     ","
      //   );
      // }
    }
    this.handleGetFeilds();
  },
  beforeDestroy() {
    if (this.rechargeGraph) {
      echarts.dispose(this.rechargeGraph);
    }
  },
  components: {
    // CommonDrawer,
    ExcludeQuery,
    SelectDeptUser,
    SelectPlatformChannel,
  },
};
</script>
<style lang="scss" scoped>
.el-tag {
  margin-right: 10px;
}
/deep/ .el-table {
  .is-leaf {
    border-bottom: 0px;
  }
}
.user-recharge {
  background: #fff;
  .main-container {
    height: 750px;
    overflow: auto;
  }
  .recharge-date {
    color: #ffb85e;
    font-weight: bold;
  }
}

// 抽屉选中行
/deep/ .table-lastSected,
/deep/.table-lastSected td {
  background-color: #81ffe5 !important;
}
</style>
