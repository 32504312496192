<template>
  <el-table-column
    v-bind="$attrs"
    v-on="$listeners"
    :fixed="column.fixed"
    :min-width="column.width"
    :width="column.colWidth"
    :sortable="column.sortable"
    :align="column.align"
  >
    <template slot="header" slot-scope="scope">
      <!--传递scope -->
      <dy-render
        v-bind="$attrs"
        v-if="column.renderHeader"
        :scope="scope"
        :render="column.renderHeader"
      >
      </dy-render>
      <span v-else>{{ column.label }}</span>
    </template>
    <template slot-scope="scope">
      <dy-render
        v-bind="$attrs"
        :scope="scope"
        :column="column"
        v-on="$listeners"
      >
        <template
          v-if="column.action === 'operation'"
          v-slot:operation="{ row }"
        >
          <slot name="operation" :row="row"></slot>
        </template>
        <template
          v-if="column.action === 'dyoperation'"
          v-slot:dyoperation="{ row }"
        >
          <slot name="dyoperation" :row="row"></slot>
        </template>
        <!-- 向上传递slot -->
        <template v-if="column.slot" v-slot:[column.prop]="{ row }">
          <slot :name="column.prop" :row="row"></slot>
        </template>
      </dy-render>
    </template>
    <!-- 渲染多级表头 -->
    <template v-if="column.children">
      <dy-column
        v-bind="$attrs"
        v-for="(child, index) in column.children"
        :key="index"
        :column="child"
      ></dy-column>
    </template>
  </el-table-column>
</template>

<script>
import DyRender from "./dy-render.vue";
export default {
  name: "DyColumn",
  props: {
    column: Object,
    headerAlign: String,
    align: String,
  },
  data() {
    return {};
  },
  mounted() {},
  watch: {
    column: {
      handler() {
        this.setColumn();
      },
      immediate: true,
    },
  },
  methods: {
    setColumn() {
      if (!this.column.render) {
        // 将render属性定义为一个函数
        // this.column.render = (h, scope) => {
        //   return <span>{scope.row[scope.column.property]}</span>
        // }
      }
    },
  },
  components: {
    DyRender,
  },
};
</script>
<style lang='scss' scoped>
</style>
