export default {
  userInfo: (state) => state.userInfo,
  isCollapsed: (state) => state.isCollapsed,
  getGlobalYearSchedule:(state) => state.globalYearSchedule,
  officialList: (state) => state.officialList,
  userList: (state) => state.userList,
  operateUserList: (state) => {
    return state.userList.filter(item => {
      return item.isPut === 0
    })
  },
  putUserList: (state) => {
    return state.userList.filter(item => {
      return item.isPut === 1
    })
  },
  routerHistory: (state) => state.routerHistory,
  currentRouteParams: (state) => (path) => {
    const menu = state.routerHistory.find(item => item.path === path)
    return menu ? menu.params : {}
  }
}
