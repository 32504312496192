<template>
  <div class="ad-put-project padding-20 border-radius-6 box-shadow-light-grey">
    <div class="flex-between advantage-filter padding-bottom-20">
      <div class="row">
        <exclude-query :params="params" style="margin-right: 5px" />
        <el-date-picker
          v-model="params.selectTime"
          type="daterange"
          align="left"
          unlink-panels
          style="width: 240px; margin-right: 5px"
          :clearable="false"
          range-separator="-"
          value-format="yyyy-MM-dd"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
        <select-platform-channel :params="params" style="margin-right: 5px" />
        <el-select
          v-model="params.bookId"
          filterable
          remote
          clearable
          style="width: 150px; margin-right: 5px"
          reserve-keyword
          placeholder="书籍名称"
          :remote-method="getBook"
        >
          <el-option
            v-for="item in bookList"
            :key="item.id"
            :label="item.bookName"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <select-dept-user :params="params" />
          <el-select
          v-model="params.ideaUserId"
          placeholder="创意人员"
          style="width: 100px; margin-left: 5px"
          clearable
          filterable
        >
                    <el-option
            v-for="item in userList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
                      </el-option
          >
                  </el-select
        >
        <el-select
          v-model="params.isTest"
          clearable
          placeholder="类型"
          style="width: 100px; margin-left: 5px"
        >
          <el-option label="普通号" :value="0"></el-option>
          <el-option label="测书号" :value="1"></el-option>
        </el-select>
        <!-- <el-select v-model="params.ideaUserId"  placeholder="创意人员" style='width:100px;margin-left: 5px' clearable filterable >
          <el-option v-for="item in userList"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select> -->
        <el-input
          v-model.number="params.cost"
          type="number"
          @keyup.native="prevent($event)"
          style="width: 120px; margin-left: 5px"
          placeholder="最小花费"
          clearable
        ></el-input>

        <el-button
          type="primary"
          @click="handlePageChange(1)"
          style="margin-left: 15px"
          >搜索</el-button
        >
        <el-button type="info" @click="handleReset">重置</el-button>
      </div>
      <div class="row">
        <el-input
          placeholder="计划ID或名称"
          clearable
          v-model="search"
          class="input-with-select"
          @keydown.enter.native="handlePageChange(1)"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="handlePageChange(1)"
          ></el-button>
        </el-input>
        <el-radio-group v-model="dataType" @change="handlePageChange(1)">
          <el-radio-button :label="1">分日查看</el-radio-button>
          <el-radio-button :label="2">分时查看</el-radio-button>
        </el-radio-group>
      </div>
    </div>
    <div class="padding-bottom-20">
      <div class="custom-card">
        <div class="data-label">总花费：</div>
        <div class="statistic-num-text">
          <span class="custom-statistic-num">{{ statistic.cost || 0 }}</span>
        </div>
      </div>
      <div class="custom-card">
        <div class="data-label">曝光量：</div>
        <div class="statistic-num-text">
          <span class="custom-statistic-num">{{
            statistic.viewCount || 0
          }}</span>
        </div>
      </div>
      <div class="custom-card">
        <div class="data-label">点击量：</div>
        <div class="statistic-num-text">
          <span class="custom-statistic-num">{{
            statistic.validClickCount || 0
          }}</span>
        </div>
      </div>
      <div class="custom-card">
        <div class="data-label">点击率：</div>
        <div class="statistic-num-text">
          <span class="custom-statistic-num">{{ statistic.ctrStr || 0 }}</span>
        </div>
      </div>
      <div class="custom-card">
        <div class="data-label">转化目标量：</div>
        <div class="statistic-num-text">
          <span class="custom-statistic-num">{{
            statistic.conversionsCount || 0
          }}</span>
        </div>
      </div>
      <div class="custom-card">
        <div class="data-label">转化目标成本：</div>
        <div class="statistic-num-text">
          <span class="custom-statistic-num">{{
            statistic.conversionsCost || 0
          }}</span>
        </div>
      </div>
    </div>
    <div style="background: #fff">
      <div>
        <el-table
          v-loading="loading"
          :data="dataList"
          height="57vh"
          header-row-class-name="header-class-name"
          @sort-change="handleSortChange"
          style="width: 100%"
        >
          <el-table-column prop="date" label="时间" fixed="left" width="120">
          </el-table-column>
          <el-table-column
            prop="channelName"
            label="公众号"
            show-overflow-tooltip
            width="100"
          >
          </el-table-column>
          <el-table-column prop="putName" label="投手" fixed="left" width="100">
          </el-table-column>
          <el-table-column
            prop="campaignName"
            label="投放计划名称"
            show-overflow-tooltip
            width="150"
          >
          </el-table-column>
          <el-table-column
            prop="campaignId"
            label="计划ID"
            fixed="left"
            width="120"
          >
          </el-table-column>
          <el-table-column
            prop="cost"
            sortable="custom"
            label="花费(元)"
            width="100"
          >
          </el-table-column>
          <el-table-column
            sortable="custom"
            prop="viewCount"
            label="曝光量"
            width="100"
          >
          </el-table-column>
          <el-table-column
            prop="validClickCount"
            sortable="custom"
            label="点击量"
            width="100"
          >
          </el-table-column>
          <el-table-column
            prop="ctr"
            sortable="custom"
            label="点击率"
            width="100"
          >
            <template slot-scope="scope">
              {{ scope.row.ctrStr }}
            </template>
          </el-table-column>
          <el-table-column
            prop="conversionsCount"
            sortable="custom"
            label="目标转化量"
            min-width="150"
          >
          </el-table-column>
          <el-table-column
            prop="conversionsCost"
            sortable="custom"
            label="目标转化成本(元)"
            width="180"
          >
          </el-table-column>
          <el-table-column
            prop="conversionsRate"
            sortable="custom"
            label="目标转化率"
            min-width="150"
          >
            <template slot-scope="scope">
              {{ scope.row.conversionsRateStr }}
            </template>
          </el-table-column>
          <el-table-column
            prop="orderRoi"
            sortable="custom"
            label="下单ROI"
            min-width="120"
          >
          </el-table-column>
          <el-table-column
            prop="orderAmount"
            sortable="custom"
            label="下单金额"
            min-width="120"
          >
          </el-table-column>
          <el-table-column
            prop="orderUnitPrice"
            sortable="custom"
            label="下单客单价"
            min-width="120"
          >
          </el-table-column>
          <el-table-column
            prop="thousandDisplayPrice"
            sortable="custom"
            label="千次曝光成本"
            min-width="150"
          >
          </el-table-column>
          <el-table-column
            prop="dailyBudget"
            sortable="custom"
            label="日预算"
            min-width="120"
          >
          </el-table-column>
        </el-table>
        <el-pagination
          style="margin: 10px 0; text-align: right"
          background
          @current-change="handlePageChange"
          :current-page="page"
          :page-size="20"
          layout="total, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { platformList, pickerOptions } from "@/assets/js/options";
import { getBookList } from "@/api/account";
import { getPutPlanData } from "@/api/statics";
import ExcludeQuery from "./../components/excludeQuery";
import SelectDeptUser from "@/components/SelectDeptUser";

import SelectPlatformChannel from "./../components/SelectPlatformChannel";

export default {
  name: "index",
  props: {
    customParams: Object,
    lastParams: Object,
  },
  data() {
    return {
      loading: false,
      dataType: 1,
      search: null,
      page: 1,
      total: 0,
      bookList: [],
      dataList: [],
      statistic: {},
      sortName: null,
      sortDesc: null,
      platformList,
      pickerOptions,
      isShowDeptUser: false,
      selected: "",
      params: {},
    };
  },
  computed: {
    channelList() {
      const { officialList } = this;
      const { originPlatform } = this.params;
      return typeof originPlatform === "number"
        ? officialList.filter((item) => item.platform === originPlatform)
        : officialList;
    },
    ...mapGetters(["officialList", "userList"]),
  },
  created() {
    if (this.lastParams) {
      this.params = JSON.parse(JSON.stringify(this.lastParams.putData));
    } else {
      this.params = JSON.parse(JSON.stringify(this.customParams.putData));
    }
  },
  mounted() {
    this.getData();
    this.getBook(null);
  },
  methods: {
    prevent(e) {
      var keynum = window.event ? e.keyCode : e.which; //获取键盘码
      if (keynum == 189 || keynum == 190 || keynum == 109 || keynum == 110) {
        this.$message.warning("禁止输入小数以及负数");
        e.target.value = "";
      }
    },
    handleUserClick(item) {
      this.$set(this.params, "adminUserId", item.adminUserId);
      this.handlePageChange(1);
    },
    handleSortChange({ prop, order }) {
      this.sortName = prop;
      this.sortDesc = order === "descending";
      this.handlePageChange(1);
    },
    init() {
      this.handlePageChange(1);
    },
    handlePageChange(page) {
      this.page = page;
      this.getData();
    },
    getData() {
      this.loading = true;
      let beginDate, endDate;
      if (this.params.selectTime) {
        beginDate = this.params.selectTime[0];
        endDate = this.params.selectTime[1];
      } else {
        beginDate = new Date()
          .toLocaleString()
          .split("/")
          .map((item) => item.padStart(2, "0"))
          .join("-");
        endDate = beginDate;
      }

      const duration =
        new Date(endDate + " 00:00:00").getTime() -
        new Date(beginDate + " 23:59:59").getTime();
      if (duration > 30 * 24 * 3600 * 1000) {
        this.$message.error("筛选时间不能超过一个月");
        return false;
      }
      getPutPlanData({
        beginDate,
        endDate,
        dataType: this.dataType,
        search: this.search,
        page: this.page,
        pageSize: 20,
        ideaUserId: this.params.ideaUserId,
        originPlatform: this.params.originPlatform, // *
        accountChannelId: this.params.accountChannelId, // *
        booksId: this.params.bookId, // *
        adminUserId: this.params.adminUserId,
        sortName: this.sortName,
        sortDesc: this.sortDesc,
        ideaUserId: this.params.ideaUserId,
        deptId: this.params.deptId, // *
        isTest: this.params.isTest,
        cost: this.params.cost,
        ruledOutBooksIds:
          this.params.ruledOutBooksIds && this.params.ruledOutBooksIds.length
            ? this.params.ruledOutBooksIds.map((item) => item.id).join()
            : null,
        ruledOutAccountChannelIds:
          this.params.ruledOutAccountChannelIds &&
          this.params.ruledOutAccountChannelIds.length
            ? this.params.ruledOutAccountChannelIds.join()
            : null,
      })
        .then((res) => {
          if (!("total" in res)) {
            this.dataList = [];
            return;
          }
          const { list, total } = res;
          this.statistic = total;
          this.dataList = [
            {
              date: "汇总",
              campaignName: "--",
              channelName: "--",
              putName: "--",
              ...total,
            },
          ].concat(list.list);
          this.total = list.total;
        })
        .finally(() => (this.loading = false));
    },
    handleReset() {
      this.params.originPlatform = null;
      this.params.accountChannelId = null;
      this.params.bookId = null;
      this.params.adminUserId = null;
      this.params.deptId = null;
      this.params.isTest = null;
      this.params.cost = null;
      this.params.ruledOutBooksIds = [];
      this.params.ruledOutAccountChannelIds = [];
      this.sortName = null;
      this.sortDesc = null;
      this.search = null;
      this.dataType = 1;
      this.handlePageChange(1);
    },
    getBook(bookName) {
      getBookList({
        page: 1,
        pageSize: 50,
        bookName,
      }).then((res) => {
        this.bookList = res.list;
      });
    },
    chooseDeptOrUser() {
      this.isShowDeptUser = true;
    },
    confirm(selected) {
      this.selected = selected.name;
      if (selected.type === 1) {
        this.$set(this.params, "adminUserId", selected.id);
      } else if (selected.type === 0) {
        this.$set(this.params, "deptId", selected.id);
      }
    },
    handleClear() {
      this.$set(this.params, "adminUserId", null);
      this.$set(this.params, "deptId", null);
    },
  },
  components: {
    ExcludeQuery,
    SelectDeptUser,
    SelectPlatformChannel,
  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-table {
  .is-leaf {
    border-bottom: 0px;
    height: 56px !important;
  }
}

.ad-put-project {
  background: #fff;
}
.input-with-select {
  width: 170px;
  margin-right: 15px;
}
.advantage-filter {
  background: #fff;
}
.data-label {
  color: #475669 !important;
  font-weight: 400 !important;
}
.statistics-card {
  margin-bottom: 0;
}
</style>
