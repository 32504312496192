<template>
  <div class="ad-consume padding-20 box-shadow-light-grey">
    <!-- 条件搜索 -->
    <div class="filter-box">
      <!-- <h4 class="filter-title">今日广告</h4> -->
      <div class="row">
        <exclude-query :params="params" />
        <el-date-picker
          v-model="params.selectTime"
          style="width: 220px"
          type="daterange"
          align="right"
          unlink-panels
          :clearable="false"
          range-separator="-"
          value-format="yyyy-MM-dd"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="yesterdayOptions"
        >
        </el-date-picker>
        <el-select
          v-model="params.labelTypes"
          clearable
          collapse-tags
          multiple
          placeholder="标签类型"
          style="width: 170px"
        >
          <el-option
            v-for="(item, index) in labelListShow"
            :key="index"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <select-platform-channel :params="params" data-type="obj" />
        <el-select
          v-model="params.booksId"
          filterable
          remote
          clearable
          style="width: 150px"
          reserve-keyword
          placeholder="书籍名称"
          :remote-method="getBook"
        >
          <el-option
            v-for="item in bookList"
            :key="item.id"
            :label="item.bookName"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <select-dept-user
          :params="params"
          @userChange="(name) => (this.userName = name)"
          @deptChange="(dept) => (this.deptName = dept)"
        />
        <el-select
          v-model="params.isTest"
          clearable
          placeholder="类型"
          style="width: 100px"
        >
          <el-option label="普通号" :value="0"></el-option>
          <el-option label="测书号" :value="1"></el-option>
        </el-select>
        <el-input
          placeholder="回本率"
          v-model="params.returnRate"
          type="number"
          style="width: 170px"
          clearable
        >
          <el-select
            v-model="params.comperingType"
            slot="prepend"
            style="width: 75px"
            clearable
            placeholder="比较"
          >
            <el-option label="无" :value="0"></el-option>
            <el-option label="<=" :value="1"></el-option>
            <el-option label=">=" :value="2"></el-option>
          </el-select>
        </el-input>
        <!-- 新增排除vip -->
        <el-select
          v-model="params.ruledOutVip"
          placeholder="排除vip"
          style="width: 100px"
        >
          <el-option label="否" :value="0"></el-option>
          <el-option label="是" :value="1"></el-option>
        </el-select>
        <!-- 新增自然日统计 -->
        <el-select
          v-model="params.dayType"
          clearable
          placeholder="统计类型"
          style="width: 100px"
        >
          <el-option label="自然日统计" :value="0"></el-option>
          <el-option label="24小时统计" :value="1"></el-option>
        </el-select>
        <select-field
          :fieldsData="fieldsData"
          :originFeild="originFeild"
          :EchoField="sendField"
          @fieldChange="handleFieldChange"
        />
        <el-button
          type="primary"
          @click="handlePageChange(1)"
          style="margin-left: 5px"
          :loading="loading"
        >
          搜索
        </el-button>
        <el-button
          type="info"
          @click="handleReset"
          :loading="loading"
          style="margin-left: 5px"
        >
          重置
        </el-button>
        <el-button
          type="primary"
          @click="handleExportData"
          style="margin-left: 5px"
        >
          导出
        </el-button>
        <el-button @click="showCacheList = true" type="primary"
          >缓存列表</el-button
        >
      </div>
    </div>
    <div class="data-box" style="height: 720px; overflow-y: auto">
      <el-row :gutter="10" style="margin: 5px">
        <el-col :span="12">
          <!-- 投放日充值线性图 -->
          <div
            v-loading="loadingPutGraph"
            id="putDateGraph"
            :style="'width: 100%;height: 300px;z-index:1;'"
          ></div>
        </el-col>
        <el-col :span="12">
          <!-- 日充值线性图 -->
          <div
            v-loading="loadingEdGraph"
            id="edDateGraph"
            :style="'width: 100%;height: 300px;z-index:1;'"
          ></div>
        </el-col>
      </el-row>

      <!-- 汇总数据 -->
      <div class="aggregate-data">
        <div
          class="statistics-card"
          v-for="(item, index) in totalData"
          :key="index"
        >
          <div class="data-label">{{ item.label }}</div>
          <div class="statistic-num-text">
            <span class="statistic-num">{{ item.value || 0 }}</span>
          </div>
        </div>
        <div class="payback-reference-table">
          <el-button type="primary" @click="handleShowOperateAssess"
            >回本试算工具</el-button
          >
        </div>
        <div class="enlarge-button">
          <span @click="handleOpenTable" style="margin-right: 30px">
            <el-tag type="success" size="mini">
              {{ isOpenTable ? "收起" : "展开" }}
            </el-tag>
          </span>
          <i
            style="margin-right: 30px"
            class="iconfont icon-quanping"
            @click="handleToggleTable"
          ></i>
        </div>
        <div></div>
      </div>
      <!-- 表格数据 -->
      <fullscreen ref="largeTable" :teleport="true" @change="fullscreenChange">
        <el-table
          header-row-class-name="table-header"
          ref="staticTable"
          id="staticTable"
          class="consume-table platform-statistic"
          :data="dataList"
          style="width: 100%"
          :height="isFullTable ? '1000px' : '630px'"
          v-loading="loading"
          empty-text="暂无数据"
          :row-class-name="tableRowClassName"
        >
          <el-table-column
            prop="operation"
            width="60"
            label="固定"
            fixed="left"
          >
            <template slot-scope="scope">
              <span style="cursor: pointer" @click="handleFlowRow(scope)">
                <i class="iconfont icon-tuding"></i>
              </span>
            </template>
          </el-table-column>
          <el-table-column
            v-for="(item, index) in filFields"
            :key="index"
            :sortable="sortableType(item)"
            :align="filAlign(item)"
            @sort-change="handleSortChange"
            :prop="item.fieldCode"
            :fixed="fixedList.includes(item.fieldCode)"
            :label="item.fieldName"
            :min-width="item.width + 'px' || '90px'"
          >
            <template slot-scope="scope">
              <div v-if="item.hasChild">
                <div
                  v-for="(item2, index2) in filChildrenFieds(item.children)"
                  :key="index2"
                  class="left-cell"
                >
                  <template v-if="item2.fieldCode === 'returnCycle'">
                    <template v-if="scope.row[item2.fieldCode] !== -1">
                      <span
                        :class="item2.fieldName === '周期' ? 'drawer-text' : ''"
                        >{{
                          item2.fieldName ? item2.fieldName + ":" : ""
                        }}</span
                      >
                      <template v-if="scope.row[item2.fieldCode] > 90">
                        <span class="test1" :style="'color:rgb(255, 69, 0)'">{{
                          scope.row[item2.fieldCode] > 90
                            ? "90+"
                            : scope.row[item2.fieldCode]
                        }}</span>
                      </template>
                      <template v-else>
                        <span class="test2" :style="'color:rgb(35, 169, 35)'">{{
                          scope.row[item2.fieldCode] > 90
                            ? "90+"
                            : scope.row[item2.fieldCode]
                        }}</span>
                      </template>
                    </template>
                  </template>
                  <template
                    v-else-if="item2.fieldCode === 'remainderReturnDay'"
                  >
                    <div v-if="scope.row['returnCycle'] <= 90">
                      <span>{{
                        item2.fieldName ? item2.fieldName + ":" : ""
                      }}</span>
                      <span :style="'color:' + item.fontColor">{{
                        scope.row[item2.fieldCode]
                      }}</span>
                    </div>
                  </template>
                  <template v-else>
                    <span>{{
                      item2.fieldName ? item2.fieldName + ":" : ""
                    }}</span>
                    <span :style="'color:' + item2.fontColor">{{
                      scope.row[item2.fieldCode]
                    }}</span>
                  </template>
                </div>
              </div>
              <span
                :style="'color:' + item.fontColor"
                v-else
                :class="item.fieldCode === 'date' ? 'drawer-text' : ''"
                >{{ scope.row[item.fieldCode] | sortField(item) }}</span
              >
            </template>
          </el-table-column>
          <template>
            <el-table-column
              class-name="extra-column"
              v-for="(head, index3) in extraHeader"
              :key="index3 + 'extra'"
              :label="head"
              prop="head"
              width="130px"
            >
              <template slot-scope="scope">
                <div
                  v-if="
                    scope.row.returnRateList && scope.row.returnRateList[index3]
                  "
                >
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="`充值日期：${scope.row.returnRateList[index3].rechargeDate}`"
                    placement="top-start"
                  >
                    <div
                      class="extra-cell"
                      style="cursor: pointer; line-height: 20px"
                    >
                      <p>
                        <span></span>
                        <span
                          ><i class="remark-num">{{
                            scope.row.returnRateList[index3].newRecharge
                          }}</i></span
                        >
                        <!-- <span style="font-weight: bold">{{
                          scope.row.returnRateList[index3].newRecharge
                        }}</span> -->
                      </p>
                      <p class="green-num">
                        <span>年:</span>
                        <span>{{
                          scope.row.returnRateList[index3].vipAmount
                        }}</span>
                      </p>
                      <p class="green-num">
                        <span>回:</span>
                        <span
                          >{{
                            scope.row.returnRateList[index3].returnRate
                          }}%</span
                        >
                      </p>
                      <p>
                        <span>增:</span>
                        <span
                          >{{
                            scope.row.returnRateList[index3].addReturnRate
                          }}%</span
                        >
                      </p>
                      <!-- 点击展开更多 -->
                      <span
                        class="originExra"
                        v-if="scope.row.returnRateList[index3].isOpenExtra"
                      >
                        <p>
                          <span>支:</span>
                          <span
                            >{{
                              scope.row.returnRateList[index3].payRate
                            }}%</span
                          >
                        </p>

                        <p>
                          <span>支(增):</span>
                          <span
                            >{{
                              scope.row.returnRateList[index3].payAddRate
                            }}%</span
                          >
                        </p>
                        <p>
                          <span>系数:</span>
                          <span>{{
                            scope.row.returnRateList[index3].multipleRate
                          }}</span>
                        </p>
                        <p>
                          <span>复充率:</span>
                          <span
                            >{{
                              scope.row.returnRateList[index3].keepRate
                            }}%</span
                          >
                        </p>
                        <p>
                          <span>均复次:</span>
                          <span>{{
                            scope.row.returnRateList[index3].avgKeepNum
                          }}</span>
                        </p>
                      </span>
                    </div>
                  </el-tooltip>
                </div>
              </template>
            </el-table-column>
          </template>
        </el-table>
        <div class="table-bottom">
          <el-pagination
            v-if="hasPage"
            style="margin-top: 10px; text-align: right"
            background
            @current-change="handleCachePageChange"
            :current-page="cachePage"
            :page-size="31"
            layout="total, prev, pager, next, jumper"
            :total="cacheDataTotal"
          >
          </el-pagination>
          <div v-else style="height: 32px"></div>
          <span
            v-if="isFullTable"
            @click="handleOpenTable"
            class="bottom-trigger"
          >
            <el-tag type="success" size="mini">
              {{ isOpenTable ? "收起" : "展开" }}
            </el-tag>
          </span>
        </div>
      </fullscreen>
    </div>

    <!-- 公共抽屉 -->
    <CommonDrawer
      :showDrawer="showCommonDrawer"
      size="90%"
      @close="showCommonDrawer = false"
      :template="showTemplate"
      :nextParams="nextParams"
    />
    <!-- 预估回本 -->
    <el-drawer
      title="回本率预测"
      :visible.sync="dialogVisible"
      size="80%"
      append-to-body
      :before-close="handleClose"
    >
      <el-table
        v-if="dataList2 && dataList2.length > 0"
        :data="dataList2"
        border
        :row-class-name="tableRowClassName"
        :span-method="handleSpanMethod"
        :cell-style="cellStyle"
        :header-cell-style="{ background: '#F5F7FA', color: '#606266' }"
        max-height="650"
        style="width: 100%"
      >
        <el-table-column label="投放时间" align="center" width="150">
          <template>
            {{ rechargeDate }}
          </template>
        </el-table-column>
        <el-table-column label="区分" align="center" width="80">
          <template slot-scope="scoped">
            <span>{{ getResultPre(scoped, 0) }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column label="回本天数" align="center" width="150">
          <template slot-scope="scoped">
            <span>{{ getResultPre(scoped, 1) }}</span>
          </template>
        </el-table-column> -->
        <el-table-column
          class="header-column"
          v-for="(item, index) in headerList"
          :key="index"
          align="center"
          :label="item"
          width="85"
        >
          <template slot-scope="scoped">
            <span>{{ getResultPre(scoped, index + 1) }}</span>
          </template>
        </el-table-column>
      </el-table>
      <!-- 书籍流向 -->
      <el-row :gutter="2">
        <el-col :span="18">
          <div id="booksFlowDirection"></div>
        </el-col>
        <el-col :span="6">
          <!-- 指标卡 -->
          <div style="margin-top: 20px">
            <div
              class="statistics-card"
              v-for="(item, index) in totalData"
              :key="index"
            >
              <div class="data-label">{{ item.name }}</div>
              <div class="statistic-num-text">
                <span class="statistic-num">{{ item.value || 0 }}</span>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </el-drawer>
    <!-- 缓存列表 -->
    <cache-list
      :type="1"
      :show="showCacheList"
      @close="showCacheList = false"
      @select="handleSearch"
    />
    <!-- 回本试算 -->
    <el-drawer
      title="回本试算"
      :visible.sync="showOperateAssess"
      size="90%"
      append-to-body
    >
      <div class="Operate-box">
        <div class="row">
          <!-- <div class="pre-assess-box">

          </div> -->
          <div class="assess-box">
            <el-tag style="margin-right: 10px">预测项：</el-tag>
            <!-- 平均付费次数 -->
            <el-popover placement="bottom" width="300" trigger="click">
              <el-form label-width="80px">
                <el-form-item label="D1回本率">
                  <el-input
                    placeholder="D1回本率"
                    type="number"
                    v-model="operateAssessParams.firstRate"
                    style="width: 120px"
                  >
                  </el-input>
                  <span>(%)</span>
                </el-form-item>
                <el-form-item label="预计回本">
                  <el-input
                    placeholder="预计回本"
                    type="number"
                    v-model="operateAssessParams.returnDay"
                    style="width: 120px"
                  >
                  </el-input>
                  <span>(天)</span>
                </el-form-item>
              </el-form>
              <el-input
                slot="reference"
                placeholder="预计回本回本率"
                :value="showTextPreAssess"
                style="width: 200px; margin-right: 10px"
              />
            </el-popover>
            <el-tag style="margin: 0 10px 0 100px">实际项：</el-tag>
            <el-date-picker
              v-model="operateAssessParams.selectTime"
              style="width: 260px"
              type="daterange"
              align="right"
              unlink-panels
              :clearable="false"
              range-separator="-"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions"
            >
            </el-date-picker>
            <!-- 单独公众号 -->
            <el-select
              v-model="operateAssessParams.accountChannelId"
              filterable
              style="width: 180px"
              reserve-keyword
              clearable
              placeholder="选择公众号"
            >
              <el-option
                v-for="(item, index) in officialList"
                :label="item.channelName"
                :value="item.id"
                :key="index"
              >
              </el-option>
            </el-select>
            <!-- 书籍 -->
            <el-select
              v-model="operateAssessParams.booksId"
              filterable
              remote
              clearable
              style="width: 150px"
              reserve-keyword
              placeholder="书籍名称"
              :remote-method="getBook"
              clearable
            >
              <el-option
                v-for="item in bookList"
                :key="item.id"
                :label="item.bookName"
                :value="item.id"
              >
              </el-option>
            </el-select>
            <!-- 单独用户 -->
            <el-select
              style="width: 150px"
              v-model="operateAssessParams.adminUserId"
              filterable
              clearable
              placeholder="投手"
            >
              <el-option
                v-for="item in putUserList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <el-button
            type="primary"
            @click="handleAssessPageChange(1)"
            style="margin-left: 10px"
          >
            搜索
          </el-button>
          <el-button type="info" @click="handleAssessReset"> 重置 </el-button>
        </div>

        <el-row :gutter="10" style="margin-top: 10px" v-loading="assessLoading">
          <el-col :span="8">
            <el-table
              :data="assessTable"
              style="width: 100%"
              height="calc(100vh - 130px)"
            >
              <el-table-column prop="day" label="天数" width="120">
              </el-table-column>
              <el-table-column header-align="center" label="预测">
                <el-table-column
                  prop="cumReturnRate"
                  label="累计回本率"
                  min-width="100"
                >
                  <template slot-scope="scope">
                    {{
                      scope.row.hasOwnProperty("cumReturnRate")
                        ? scope.row.cumReturnRate + "%"
                        : ""
                    }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="addReturnRate"
                  label="增加回本率"
                  min-width="100"
                >
                  <template slot-scope="scope">
                    {{
                      scope.row.hasOwnProperty("addReturnRate")
                        ? scope.row.addReturnRate + "%"
                        : ""
                    }}
                  </template>
                </el-table-column>
              </el-table-column>
              <el-table-column header-align="center" label="实际">
                <el-table-column
                  prop="grand"
                  label="累计回本率"
                  min-width="100"
                >
                </el-table-column>
                <el-table-column prop="add" label="增加回本率" min-width="100">
                </el-table-column>
              </el-table-column>
            </el-table>
          </el-col>
          <el-col :span="16">
            <div id="preAssess"></div>
          </el-col>
        </el-row>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import {
  formatThousandsDots,
  formatTime,
  getRecentlyMonth,
} from "@/assets/js/utils";
import ExcludeQuery from "@/components/excludeQuery";
import SelectDeptUser from "@/components/SelectDeptUser";
import SelectField from "@/components/SelectField";
import SelectPlatformChannel from "@/components/SelectPlatformChannel";
import CacheList from "../components/cacheList";
import { getBookList } from "@/api/account";
import {
  getPlatformStatistics,
  getFields,
  exportFile,
  getPutDateRechargeGraph,
  getEdRechargeGraph,
  getCacheList,
  cacheData,
  getCacheData,
  getOperateAssess,
  getPreOperateAssess,
} from "@/api/statics";
import {
  yesterdayOptions,
  labelList,
  platformList,
  pickerOptions,
} from "@/assets/js/options";
import { mapGetters, mapActions } from "vuex";
import CommonDrawer from "@/components/commonDrawer.vue";
import echarts from "echarts";
// import ecStat from "echarts-stat";
export default {
  name: "platformStatics",
  props: {
    lastParams: {
      type: Object,
    },
  },
  components: {
    CommonDrawer,
    ExcludeQuery,
    SelectDeptUser,
    SelectPlatformChannel,
    SelectField,
    CacheList,
  },
  computed: {
    labelListShow() {
      return this.labelList.filter((item) => {
        return item.show;
      });
    },
    showTextPreAssess() {
      const { returnDay, firstRate } = this.operateAssessParams;
      if (
        (firstRate === 0 || firstRate > 0) &&
        (returnDay === 0 || returnDay > 0)
      ) {
        return `D1回本:${firstRate}~预计回本:${returnDay}`;
      } else if (firstRate === 0 || firstRate > 0) {
        return `D1回本:${firstRate}`;
      } else if (returnDay === 0 || returnDay > 0) {
        return `预计回本:${returnDay}`;
      }
      return "";
    },
    // 排序处理
    sortableType() {
      return function (value) {
        if (value.autoFields === 0) {
          return false;
        } else if (value.autoFields === 2 || value.autoFields === 4) {
          return true;
        } else if (value.autoFields === 1 || value.autoFields === 3) {
          return "custom";
        }
      };
    },
    filFields: function () {
      // var _that = this
      const flag = !!this.params.currentOfficial;
      const tmpFilFields = this.showField.filter(function (item) {
        if (item.autoFields !== 1) {
          if (
            item.fieldCode === "bookName" ||
            item.fieldCode === "labelTypeStr"
          ) {
            // 选择公众号筛选条件后展示书籍、标签
            return flag;
          } else {
            return true;
          }
        }
        // return item.autoFields !== 1;
      });
      return tmpFilFields;
    },
    filChildrenFieds() {
      return function (value) {
        // var _that = this
        return value.filter(function (item) {
          // 过滤首页展示字段
          return item.autoFields === 0;
        });
      };
    },
    // 文字对齐处理
    filAlign() {
      return function (value) {
        switch (value.fontAlign) {
          case 0:
            return "left";
          case 1:
            return "center";
          case 2:
            return "right";
          default:
            return "center";
        }
      };
    },
    // 缓存参数处理
    queryParams() {
      const {
        selectTime,
        originPlatform,
        labelTypes,
        currentOfficial,
        deptId,
        adminUserId,
        booksId,
        ruledOutBooksIds,
        ruledOutAccountChannelIds,
        isTest,
        comperingType,
        returnRate,
        ruledOutVip,
        dayType,
      } = this.params;
      const params = [
        {
          label: "开始时间",
          value: selectTime[0],
          key: "beginDate",
          text: selectTime[0],
        },
        {
          label: "结束时间",
          value: selectTime[1],
          key: "endDate",
          text: selectTime[1],
        },
      ];
      if (typeof originPlatform === "number") {
        params.push({
          label: "平台",
          text: this.platformList[originPlatform],
          key: "originPlatform",
          value: originPlatform,
        });
      }
      if (labelTypes.length) {
        let tempText = "";
        labelTypes.forEach((item) => {
          tempText += this.labelList[item].label + " ";
        });
        params.push({
          label: "标签类型",
          text: tempText,
          key: "labelTypes",
          value: labelTypes.join(","),
        });
      }
      if (currentOfficial) {
        params.push({
          label: "公众号",
          text: currentOfficial.channelName,
          key: "accountChannelId",
          value: currentOfficial.id,
        });
      }
      if (deptId) {
        params.push({
          label: "部门",
          text: this.deptName,
          key: "deptId",
          value: deptId,
        });
      }
      if (adminUserId) {
        params.push({
          label: "用户",
          text: this.userName,
          key: "adminUserId",
          value: adminUserId,
        });
      }
      if (booksId) {
        const book = this.bookList.find((item) => item.id === booksId);
        params.push({
          label: "书籍",
          text: book ? book.bookName : null,
          key: "booksId",
          value: booksId,
        });
      }
      if (typeof isTest === "number") {
        params.push({
          label: "类型",
          text: isTest ? "测书号" : "普通号",
          key: "isTest",
          value: isTest,
        });
      }
      if (ruledOutBooksIds && ruledOutBooksIds.length !== 0) {
        params.push({
          label: "排除书籍",
          text: ruledOutBooksIds.length
            ? ruledOutBooksIds.map((item) => item.bookName).join()
            : null,
          key: "ruledOutBooksIds",
          value: ruledOutBooksIds.length
            ? ruledOutBooksIds.map((item) => item.id).join()
            : null,
        });
      }
      if (ruledOutAccountChannelIds && ruledOutAccountChannelIds.length !== 0) {
        params.push({
          label: "排除公众号",
          text: ruledOutAccountChannelIds.length
            ? ruledOutAccountChannelIds
                .map((id) => {
                  const channel = this.officialList.find(
                    (item) => item.id === id
                  );
                  return channel ? channel.channelName : "";
                })
                .join()
            : null,
          key: "ruledOutAccountChannelIds",
          value: ruledOutAccountChannelIds.length
            ? ruledOutAccountChannelIds.join()
            : null,
        });
      }
      if (comperingType && returnRate) {
        params.push({
          label: "规则",
          text: comperingType === 1 ? "小于等于" : "大于等于",
          key: "comperingType",
          value: comperingType,
        });
        params.push({
          label: "回本率",
          text: returnRate + "%",
          key: "returnRate",
          value: returnRate,
        });
      }
      if (typeof dayType === "number") {
        params.push({
          label: "统计类型",
          text: dayType ? "24小时统计" : "自然日统计",
          key: "dayType",
          value: dayType,
        });
      }
      if (typeof ruledOutVip === "number") {
        params.push({
          label: "排除vip",
          text: ruledOutVip ? "是" : "否",
          key: "ruledOutVip",
          value: ruledOutVip,
        });
      }
      return params;
    },

    ...mapGetters(["officialList", "putUserList"]),
  },
  filters: {
    sortField: function (value, item) {
      if (item.autoFields === 4 || item.autoFields === 5) {
        return formatThousandsDots(value.toString());
      } else {
        return value;
      }
    },
  },
  watch: {
    lastParams: function () {
      this.getData("drawer");
    },
    dataListFlow: function () {
      // 更新浮动行表格
      this.handleUpdataFlowRow();
    },
  },
  data() {
    return {
      params: {},
      bookList: null,
      fieldsData: [],
      originFeild: [],
      selectFields: null,
      selectAllFields: false,
      statistic: {},
      dataList: [],
      totalData: [],
      loading: false,
      loadingPutGraph: false,
      loadingEdGraph: false,
      sortName: null,
      sortDesc: null,
      todayAdData: {},
      showRecharge: false,
      yesterdayOptions,
      labelList,
      sendField: [],
      showField: [],
      tmpShowField: [],
      showCommonDrawer: false,
      showTemplate: null,
      nextParams: {},
      extraHeader: [],
      dialogVisible: false,
      dataList2: [],
      dataListFlow: [],
      showCacheList: false,
      putDateGraph: null,
      edDateGraph: null,
      cachePage: 1,
      platformList,
      userName: null,
      deptName: null,
      hasPage: false,
      showKey: null,
      cacheDataTotal: 0,
      // 新增echart
      booksFlowDirection: null,
      totalData: null,
      // 回本参考表
      showOperateAssess: false,
      operateAssessData: [],
      operateAssessNativeData: [],
      preOperateAssessData: [],
      assessTable: [],
      operateAssessParams: {},
      pickerOptions,
      preAssessGraph: null,
      preAssessGraphData: [],
      assessLoading: false,
      // 表格改编相关
      isFullTable: false,
      isOpenTable: false,
      isOpenSigleRow: false,
      fixedList: ["date", "cashCostAmount", "totalRowRecharge"],
    };
  },
  methods: {
    handleOpenTable() {
      this.isOpenTable = !this.isOpenTable;
      this.dataList.forEach((item1) => {
        item1.returnRateList.forEach((item2) => {
          this.$set(item2, "isOpenExtra", this.isOpenTable);
        });
      });
    },
    handleToggleTable() {
      this.$refs.largeTable.toggle();
    },
    fullscreenChange() {
      this.isFullTable = !this.isFullTable;
      this.handleUpdataFlowRow();
    },
    // 固定行操作
    handleFlowRow(scope) {
      let that = this;
      let tr = document
        .getElementById("staticTable")
        .querySelectorAll("tbody .el-table__row")
        [scope.$index].cloneNode(true);
      let tmpextraTr = tr.querySelectorAll(".extra-column");
      let extraData = scope.row.returnRateList;
      tmpextraTr.forEach((item, index) => {
        if (item.querySelector("p")) {
          let tmpCell = item.querySelector(".item");
          let tmpP1 = document.createElement("p");
          // tmpP1.innerText = "支：" + extraData[index].payRate + "%";
          tmpP1.innerHTML =
            "<span>支:</span><span>" + extraData[index].payRate + "%</span>";
          let tmpP2 = document.createElement("p");
          tmpP2.innerHTML =
            "<span>支(增):</span><span>" +
            extraData[index].payAddRate +
            "%</span>";
          let tmpP3 = document.createElement("p");
          tmpP3.innerHTML =
            "<span>系数:</span><span>" +
            extraData[index].multipleRate +
            "</span>";
          let tmpP4 = document.createElement("p");
          tmpP4.innerHTML =
            "<span>复充率:</span><span>" +
            extraData[index].keepRate +
            "%</span>";
          let tmpP5 = document.createElement("p");
          tmpP5.innerHTML =
            "<span>均复次:</span><span>" +
            extraData[index].avgKeepNum +
            "</span>";
          let tmpSpan = document.createElement("span");
          tmpSpan.setAttribute("class", "extraHideData");
          tmpSpan.appendChild(tmpP1);
          tmpSpan.appendChild(tmpP2);
          tmpSpan.appendChild(tmpP3);
          tmpSpan.appendChild(tmpP4);
          tmpSpan.appendChild(tmpP5);
          tmpCell.appendChild(tmpSpan);
          // extraTr.push(item);
        }
      });
      let trFlex = document
        .getElementById("staticTable")
        .querySelectorAll(".el-table__fixed tbody .el-table__row")
        [scope.$index].cloneNode(true);
      let tmpextraTrFlex = trFlex.querySelectorAll(".is-hidden");
      // 删除原数据展开数据
      if (tr.querySelectorAll(".originExra").length) {
        tr.querySelectorAll(".originExra").forEach((item) => {
          item.remove();
        });
        trFlex.querySelectorAll(".originExra").forEach((item) => {
          item.remove();
        });
      }

      tmpextraTrFlex.forEach((item, index) => {
        if (item.querySelector("p")) {
          let tmpCell = item.querySelector(".item");
          let tmpP1 = document.createElement("p");
          tmpP1.innerText = "支：" + extraData[index].payRate + "%";
          let tmpP2 = document.createElement("p");
          tmpP2.innerText = "支(增)" + extraData[index].payAddRate + "%";
          let tmpP3 = document.createElement("p");
          tmpP3.innerText = "系数：" + extraData[index].multipleRate;
          let tmpP4 = document.createElement("p");
          tmpP4.innerText = "复充率：" + extraData[index].keepRate + "%";
          let tmpP5 = document.createElement("p");
          tmpP5.innerText = "均复次：" + extraData[index].avgKeepNum;
          let tmpSpan = document.createElement("span");
          tmpSpan.setAttribute("class", "extraHideData");
          tmpSpan.appendChild(tmpP1);
          tmpSpan.appendChild(tmpP2);
          tmpSpan.appendChild(tmpP3);
          tmpSpan.appendChild(tmpP4);
          tmpSpan.appendChild(tmpP5);
          tmpCell.appendChild(tmpSpan);
        }

        // 右侧cell点击展开收缩
        tr.onclick = function () {
          let allOpenDataBoxTr = this.querySelectorAll(".extraOpenData");
          let allHideDataBoxTr = this.querySelectorAll(".extraHideData");
          let allOpenDataBoxTrFlex = trFlex.querySelectorAll(".extraOpenData");
          let allHideDataBoxTrFlex = trFlex.querySelectorAll(".extraHideData");
          if (allOpenDataBoxTr && allOpenDataBoxTr.length) {
            allOpenDataBoxTr.forEach((item) => {
              item.setAttribute("class", "extraHideData");
            });
            allOpenDataBoxTrFlex.forEach((item) => {
              item.setAttribute("class", "extraHideData");
            });
          } else if (allHideDataBoxTr && allHideDataBoxTr.length) {
            allHideDataBoxTr.forEach((item) => {
              item.setAttribute("class", "extraOpenData");
            });
            allHideDataBoxTrFlex.forEach((item) => {
              item.setAttribute("class", "extraOpenData");
            });
          }
        };
        // 左侧cell点击抽屉展开
        trFlex.onclick = function (e) {
          let rowId = this.getAttribute("rowId");
          let tmpIndex = that.dataListFlow.findIndex((item) => {
            return item.index == rowId;
          });
        };
      });
      // 修改复制trflex参数
      trFlex.querySelector("td span").innerHTML =
        '<i class="iconfont icon-tuding"></i>';
      trFlex
        .querySelector("td span .icon-tuding")
        .setAttribute("rowId", scope.$index);
      trFlex.setAttribute("rowId", scope.$index);
      trFlex.querySelector("td span").style.color = "#FE4F0E";
      // 删除操作
      trFlex.querySelector("td span").onclick = function (e) {
        let deleteId = e.target.getAttribute("rowId");
        let tmpIndex = that.dataListFlow.findIndex((item) => {
          return item.index == deleteId;
        });
        if (tmpIndex != -1) {
          that.dataListFlow.splice(tmpIndex, 1);
        }
      };
      this.dataListFlow.push({
        index: scope.$index,
        detail: scope.row,
        tr,
        trFlex,
      });
    },
    // 更新浮动列表
    handleUpdataFlowRow() {
      let tableFlex = document
        .getElementById("staticTable")
        .querySelector(".el-table__fixed tbody");
      let table = document
        .getElementById("staticTable")
        .querySelector(".el-table__body-wrapper tbody");
      let divFlex = document.createElement("div");
      divFlex.setAttribute("class", "flow-div-flex");
      let div = document.createElement("div");
      div.setAttribute("class", "flow-div");
      if (document.querySelector(".flow-div-flex")) {
        tableFlex.removeChild(document.querySelector(".flow-div-flex"));
        table.removeChild(document.querySelector(".flow-div"));
      }
      this.dataListFlow.forEach((item) => {
        div.appendChild(item.tr);
        divFlex.appendChild(item.trFlex);
      });
      tableFlex.appendChild(divFlex);
      table.appendChild(div);
      // 滚动更新divtop值
      let testtbody = document.querySelector(".el-table__fixed-body-wrapper");
      let scrollTop = testtbody.scrollTop;
      document.querySelector(".flow-div-flex").style.top = scrollTop + "px";
      document.querySelector(".flow-div").style.top = scrollTop + "px";
      testtbody.onscroll = function () {
        scrollTop = this.scrollTop;
        document.querySelector(".flow-div-flex").style.top = scrollTop + "px";
        document.querySelector(".flow-div").style.top = scrollTop + "px";
      };
    },
    // 回本参考表处理
    handleAssessPageChange() {
      if (
        this.operateAssessParams.firstRate === "" ||
        this.operateAssessParams.returnDay === ""
      ) {
        return this.$message.error("请输入D1回本率和预计回本");
      }
      this.assessLoading = true;
      let hasPreFlag =
        this.operateAssessParams.booksId ||
        this.operateAssessParams.adminUserId ||
        this.operateAssessParams.accountChannelId;
      // 获取预测
      getPreOperateAssess({
        firstRate: this.operateAssessParams.firstRate,
        returnDay: this.operateAssessParams.returnDay,
      })
        .then((preRes) => {
          this.preAssessGraphData = preRes.graph;
          this.preOperateAssessData = preRes.data;
          // 如果没筛选shi
          if (hasPreFlag) {
            getOperateAssess({
              beginDate: this.operateAssessParams.selectTime[0],
              endDate: this.operateAssessParams.selectTime[1],
              booksId: this.operateAssessParams.booksId,
              adminUserId: this.operateAssessParams.adminUserId,
              accountChannelId: this.operateAssessParams.accountChannelId,
            })
              .then((res) => {
                this.operateAssessData = res;
                this.setOptionPreAssessGraph(
                  this.preAssessGraphData,
                  this.operateAssessData
                );
                // 获取预测
                if (
                  this.operateAssessData.length >=
                  this.preOperateAssessData.length
                ) {
                  this.operateAssessData.forEach((item) => {
                    this.preOperateAssessData.forEach((item2) => {
                      if (item2.day === item.day) {
                        item.addReturnRate = item2.addReturnRate;
                        item.cumReturnRate = item2.cumReturnRate;
                        return;
                      }
                    });
                  });
                  this.assessTable = this.operateAssessData;
                  this.assessLoading = false;
                } else {
                  this.preOperateAssessData.forEach((item) => {
                    this.operateAssessData.forEach((item2) => {
                      if (item2.day === item.day) {
                        item.add = item2.add;
                        item.grand = item2.grand;
                        return;
                      }
                    });
                  });
                  this.assessTable = this.preOperateAssessData;
                  this.assessLoading = false;
                }
              })
              .catch(() => {
                this.assessLoading = false;
              });
          } else {
            this.setOptionPreAssessGraph(this.preAssessGraphData);
            this.assessTable = this.preOperateAssessData;
            this.assessLoading = false;
          }
        })
        .catch(() => {
          this.assessLoading = false;
        });
    },
    handleAssessReset() {
      this.operateAssessParams = {
        selectTime: [
          formatTime(
            new Date().getTime() - 3600 * 1000 * 24 * 30,
            "yyyy-MM-dd"
          ),
          formatTime(new Date(), "yyyy-MM-dd"),
        ],
        firstRate: 25,
        returnDay: 60,
      };
      this.handleAssessPageChange();
    },
    // 缓存处理操作
    // 缓存页面切换
    handleCachePageChange(page) {
      this.cachePage = page;
      this.handleDateClick(this.showKey);
    },
    handleDateClick(key) {
      if (key) {
        this.showKey = key;
        this.loading = true;
        this.loadingPutGraph = true;
        this.loadingEdGraph = true;
        // 回显参数
        if (key) {
          this.handleReset(false);
          const query = JSON.parse(key);
          this.$set(this.params, "selectTime", [
            query[0].value,
            query[1].value,
          ]);
          const temp = query.slice(2);
          temp.forEach((item) => {
            if (item.key === "booksId") {
              const { bookList } = this;
              const temp = bookList.find((book) => book.id === item.value);
              if (!temp) {
                getBookList({ id: item.value }).then((res) => {
                  this.bookList = bookList.concat(res.list);
                });
              }
              this.$set(this.params, "booksId", item.value);
            } else if (item.key === "ruledOutBooksIds") {
              const bookIds = item.value.split(",");
              const bookNames = item.text.split(",");
              this.$set(
                this.params,
                "ruledOutBooksIds",
                bookIds.map((book, index) => {
                  return {
                    id: Number(book),
                    bookName: bookNames[index],
                  };
                })
              );
            } else if (item.key === "ruledOutAccountChannelIds") {
              this.$set(
                this.params,
                "ruledOutAccountChannelIds",
                item.value.split(",").map((id) => {
                  return Number(id);
                })
              );
            } else if (item.key === "accountChannelId") {
              this.$set(this.params, "currentOfficial", {
                id: item.value,
                channelName: item.text,
              });
            } else if (item.key === "labelTypes") {
              let tmparr = item.value.split(",").map(Number);
              this.$set(this.params, "labelTypes", tmparr);
            } else {
              this.$set(this.params, item.key, item.value);
            }
          });
        }

        // 投放日充值线形图数据获取
        getPutDateRechargeGraph({ ...this.formatQuery() })
          .then((res) => {
            this.setOptionPutDateRechargeGraph(res.x, res.y);
          })
          .finally(() => (this.loadingPutGraph = false));
        // 日充值线形图数据获取
        getEdRechargeGraph({ ...this.formatQuery() })
          .then((res) => {
            this.setOptionEverydayRechargeGraph(res.x, res.y);
          })
          .finally(() => (this.loadingEdGraph = false));
        getCacheData({
          param: key,
          dataType: 1,
          page: this.cachePage,
        })
          .then((res) => {
            this.hasPage = true;
            this.extraHeader = res.platformData.headers;
            this.totalData = res.showSumFields;
            this.cacheDataTotal = res.platformData.lists.total;
            // 如果用户选择过字段则表格展示用户选择字段
            if (this.tmpShowField.length > 0) {
              this.showField = this.tmpShowField;
            }
            this.dataList = res.platformData.lists.list;
            if (!this.lastParams) {
              this.addRouterHistory({
                path: this.$route.path,
                params: {
                  ...this.formatQuery(),
                  selectTime: this.params.selectTime,
                  cacheShowField: this.showField,
                  cacheSendField: this.sendField,
                },
              });
            }
          })
          .finally(() => (this.loading = false));
      }
    },
    handleDataSave() {
      const tmpParam = this.formatQuery();
      cacheData({
        ...tmpParam,
        param: JSON.stringify(this.queryParams),
        dataType: 1,
      }).then(() => {
        this.$message.success("操作成功，数据缓存中");
        if (!this.lastParams) {
          this.addRouterHistory({
            path: this.$route.path,
            params: {
              ...tmpParam,
              selectTime: this.params.selectTime,
              cacheShowField: this.showField,
              cacheSendField: this.sendField,
            },
          });
        }
      });
    },
    handleSearch(key) {
      this.cachePage = 1;
      this.handleDateClick(key);
    },

    // 初始化线形图
    initGraph() {
      this.putDateGraph = echarts.init(document.getElementById("putDateGraph"));
      this.edDateGraph = echarts.init(document.getElementById("edDateGraph"));
      window.onresize = this.putDateGraph.resize;
      window.onresize = this.edDateGraph.resize;
    },
    // 定位到指定行
    tableScrollMove(refName, index = 0) {
      if (this.isOpenSigleRow) {
        return;
      }
      if (!refName || !this.$refs[refName]) return; // 不存在表格的ref vm 则返回
      const vmEl = this.$refs[refName].$el;
      if (!vmEl) return;
      // 计算滚动条的位置
      const target = vmEl.querySelectorAll(".el-table__body tr")[index];
      if (!target) return;
      const targetTop = vmEl
        .querySelectorAll(".el-table__body tr")
        [index].getBoundingClientRect().top;
      const containerTop = vmEl
        .querySelector(".el-table__body")
        .getBoundingClientRect().top;
      const scrollParent = vmEl.querySelector(".el-table__body-wrapper");
      scrollParent.scrollTop = targetTop - containerTop;
    },
    ...mapActions({
      addRouterHistory: "addRouterHistory",
    }),
    // 组件字段变化
    // 获取书籍列表
    getBook(bookName) {
      getBookList({
        page: 1,
        pageSize: 50,
        bookName,
      }).then((res) => {
        this.bookList = res.list;
      });
    },
    // 获取书籍列表by id
    getBookById(booksIds) {
      getBookList({
        page: 1,
        pageSize: 50,
        booksIds,
      }).then((res) => {
        this.bookList = res.list;
      });
    },
    // 排序处理
    handleSortChange({ column, prop, order }) {
      if (column.sortable !== "custom") {
        return;
      }
      this.sortName = prop;
      this.sortDesc = order === "descending";
      this.params.sortName = this.sortName;
      this.params.sortDesc = this.sortDesc;
      this.handlePageChange(1);
    },
    // 预处理请求参数
    formatQuery() {
      let tmpFields = null;
      if (this.sendField.length > 0) {
        tmpFields = this.handleFields(this.sendField);
      } else {
        this.showField = this.originFeild;
      }
      const params = {
        beginDate: this.params.selectTime[0],
        endDate: this.params.selectTime[1],
        labelTypes: this.params.labelTypes
          ? this.params.labelTypes.join(",")
          : "",
        originPlatform: this.params.originPlatform,
        accountChannelId: this.params.currentOfficial
          ? this.params.currentOfficial.id
          : null,
        booksId: this.params.booksId,
        adminUserId: this.params.adminUserId,
        ruledOutVip: this.params.ruledOutVip,
        sortName: this.sortName,
        sortDesc: this.sortDesc,
        deptId: this.params.deptId,
        isTest: this.params.isTest,
        dayType: this.params.dayType,
        ruledOutBooksIds:
          this.params.ruledOutBooksIds && this.params.ruledOutBooksIds.length
            ? this.params.ruledOutBooksIds
            : null,
        ruledOutAccountChannelIds:
          this.params.ruledOutAccountChannelIds &&
          this.params.ruledOutAccountChannelIds.length
            ? this.params.ruledOutAccountChannelIds
            : null,
        showFieldCodes: tmpFields,
      };
      if (this.params.comperingType !== 0 && this.params.returnRate) {
        params.comperingType = this.params.comperingType;
        params.returnRate = this.params.returnRate;
      }
      return params;
    },
    // 重置操作
    handleReset(flag = true) {
      this.params.selectTime = getRecentlyMonth(true);
      this.params.labelTypes = [];
      this.params.comperingType = null;
      this.params.returnRate = null;
      this.params.originPlatform = null;
      this.params.currentOfficial = null;
      this.params.booksId = null;
      this.params.adminUserId = null;
      this.params.deptId = null;
      this.params.isTest = null;
      this.params.dayType = 0;
      this.params.ruledOutVip = 0;
      this.params.ruledOutBooksIds = [];
      this.params.ruledOutAccountChannelIds = [];
      this.sortName = null;
      this.sortDesc = null;
      this.sendField = [];
      this.showField = this.originFeild;
      this.tmpShowField = [];
      if (flag) {
        this.handlePageChange(1);
      }
    },
    // 分页操作
    handlePageChange(page) {
      this.page = page;
      this.getData();
    },
    // 选择字段变化
    handleFieldChange(send, show) {
      // 请求格式field
      this.sendField = send;
      // 展示格式field
      this.tmpShowField = show;
      this.$nextTick(() => {
        this.$refs.staticTable.doLayout();
      });
    },
    // 请求前处理字段
    handleFields(item) {
      if (item.length === 0) {
        return null;
      }
      let tmpSend = [];
      const _that = this;
      tmpSend = item.reduce(function (a, b) {
        return a.concat(b);
      });
      // 判断勾选项 推入其下所有子级
      for (let i = 0; i < tmpSend.length; i++) {
        const flagIndex = _that.originFeild.findIndex((item2) => {
          return item2.fieldCode === tmpSend[i];
        });
        if (flagIndex !== -1 && "children" in _that.originFeild[flagIndex]) {
          _that.originFeild[flagIndex].children.forEach((item3) => {
            tmpSend.push(item3.fieldCode);
          });
        }
      }
      // 去重
      tmpSend = Array.from(new Set(tmpSend));
      return tmpSend.join(",");
    },
    // 广告数据获取
    getData(flag) {
      const tody = getRecentlyMonth(true);
      if (!this.params.selectTime) {
        this.$set(this.params, "selectTime", tody);
      }
      // 处理后的请求字段 如果选择过字段则使用选择字段sendfield 否则传null
      let tmpFields = null;
      if (this.sendField.length > 0) {
        tmpFields = this.handleFields(this.sendField);
      } else {
        this.showField = this.originFeild;
      }
      let tmpform = {};
      // 如果是创建过程 参数默认取缓存参数 否则使用筛选参数
      if (flag === "created") {
        tmpform = {
          beginDate: this.params.selectTime[0],
          endDate: this.params.selectTime[1],
          labelTypes: this.params.labelTypes
            ? this.params.labelTypes.join(",")
            : "",
          comperingType: this.params.comperingType,
          returnRate: this.params.returnRate,
          originPlatform: this.params.originPlatform,
          accountChannelId: this.params.currentOfficial
            ? this.params.currentOfficial.id
            : null,
          booksId: this.params.booksId,
          adminUserId: this.params.adminUserId,
          ruledOutVip: this.params.ruledOutVip,
          sortName: this.sortName,
          sortDesc: this.sortDesc,
          deptId: this.params.deptId,
          isTest: this.params.isTest,
          ruledOutBooksIds:
            this.params.ruledOutBooksIds && this.params.ruledOutBooksIds.length
              ? this.params.ruledOutBooksIds.map((item) => item.id).join()
              : null,
          ruledOutAccountChannelIds:
            this.params.ruledOutAccountChannelIds &&
            this.params.ruledOutAccountChannelIds.length
              ? this.params.ruledOutAccountChannelIds.join()
              : null,
          showFieldCodes: tmpFields,
          dayType: this.params.dayType,
        };
      } else if (flag === "drawer") {
        const selectChannel = this.officialList.find(
          (channel) => channel.id === this.lastParams.accountChannelId
        );
        tmpform = {
          accountChannelId: this.lastParams.accountChannelId,
          beginDate: this.lastParams.selectTime[0],
          endDate: this.lastParams.selectTime[1],
          ...this.lastParams,
          ruledOutBooksIds:
            this.lastParams.ruledOutBooksIds &&
            this.lastParams.ruledOutBooksIds.length
              ? this.lastParams.ruledOutBooksIds.map((item) => item.id).join()
              : null,
          ruledOutAccountChannelIds:
            this.lastParams.ruledOutAccountChannelIds &&
            this.lastParams.ruledOutAccountChannelIds.length
              ? this.lastParams.ruledOutAccountChannelIds.join()
              : null,
          labelTypes:
            this.lastParams.labelTypes && this.lastParams.labelTypes.length
              ? this.lastParams.labelTypes.join(",")
              : "",
          dayType: this.params.dayType,
        };
        this.params = {
          ...this.lastParams,
          currentOfficial: selectChannel,
          dayType: this.params.dayType,
          ruledOutVip: this.params.ruledOutVip,
        };
      } else {
        tmpform = {
          beginDate: this.params.selectTime[0],
          endDate: this.params.selectTime[1],
          labelTypes: this.params.labelTypes
            ? this.params.labelTypes.join(",")
            : "",
          comperingType: this.params.comperingType,
          returnRate: this.params.returnRate,
          ruledOutVip: this.params.ruledOutVip,
          originPlatform: this.params.originPlatform,
          accountChannelId: this.params.currentOfficial
            ? this.params.currentOfficial.id
            : null,
          booksId: this.params.booksId,
          adminUserId: this.params.adminUserId,
          sortName: this.sortName,
          sortDesc: this.sortDesc,
          deptId: this.params.deptId,
          isTest: this.params.isTest,
          ruledOutBooksIds:
            this.params.ruledOutBooksIds && this.params.ruledOutBooksIds.length
              ? this.params.ruledOutBooksIds.map((item) => item.id).join()
              : null,
          ruledOutAccountChannelIds:
            this.params.ruledOutAccountChannelIds &&
            this.params.ruledOutAccountChannelIds.length
              ? this.params.ruledOutAccountChannelIds.join()
              : null,
          showFieldCodes: tmpFields,
          dayType: this.params.dayType,
        };
      }

      // 缓存判断处理----------------------》
      const duration =
        new Date(this.params.selectTime[1] + " 00:00:00").getTime() -
        new Date(this.params.selectTime[0] + " 23:59:59").getTime();
      // }
      // return;
      // 如果所选日期超过31天 则缓存列表
      if (duration > 1000 * 3600 * 24 * 30) {
        getCacheList({ dataType: 1 }).then((res) => {
          // 判断是否和缓存数据的参数相同  所有参数相同current为列表中参数相同项
          const current = res.find((item) => {
            // 拿到每条缓存参数 tmparr
            const tempArr = JSON.parse(item.key);
            // 长度不同直接返回false节省性能

            if (tempArr.length !== this.queryParams.length) {
              return false;
            }
            // 如果长度相同 遍历每条参数的详细参数
            for (const item of tempArr) {
              const params = this.queryParams.find(
                (query) => query.key === item.key
              );
              // 若果参数名相同 对比值 值不同直接返回false
              if (params) {
                if (item.value !== params.value) {
                  return false;
                }
              } else {
                return false;
              }
            }

            // 所有参数相同返回true
            return true;
          });
          if (current) {
            // 如果参数相同且查询开始日期距离今日相差三个月以上则提示去缓存区获取
            let timeSubTody =
              new Date().getTime() -
              new Date(this.params.selectTime[0] + " 00:00:00").getTime();
            if (timeSubTody > 1000 * 3600 * 24 * 30 * 3) {
              return this.$message.warning(
                "已有缓存数据，时间跨度过大请在缓存数据中查看"
              );
            }
            if (current.progress !== 100) {
              this.$message.warning("当前数据缓存中，请稍后再试");
            } else {
              this.handleDateClick(current.key);
            }
          } else {
            this.$confirm("查询日期超过一个月，是否需要缓存查询?", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                this.handleDataSave();
              })
              .catch(() => {});
          }
        });
      } else {
        this.loading = true;
        this.loadingPutGraph = true;
        this.loadingEdGraph = true;
        this.hasPage = false;
        // 平台统计表格数据获取
        getPlatformStatistics(tmpform)
          .then((res) => {
            // 是否选择了动态列表
            if (tmpform.showFieldCodes) {
              this.extraHeader = tmpform.showFieldCodes.includes(
                "returnRateList"
              )
                ? res.headers
                : [];
            } else {
              this.extraHeader = res.headers;
            }
            this.totalData = res.showSumFields;
            // 如果用户选择过字段则表格展示用户选择字段
            if (this.tmpShowField.length > 0) {
              this.showField = this.tmpShowField;
            }
            this.dataList = res.lists;
            // this.dataList = res.lists.slice(0, 8);
            // 如果不是抽屉 缓存参数
            if (flag !== "drawer") {
              this.addRouterHistory({
                path: this.$route.path,
                params: {
                  ...tmpform,
                  ruledOutAccountChannelIds:
                    this.params.ruledOutAccountChannelIds,
                  ruledOutBooksIds: this.params.ruledOutBooksIds,
                  cacheShowField: this.showField,
                  cacheSendField: this.sendField,
                  selectTime: this.params.selectTime,
                  cacheShowField: this.showField,
                  cacheSendField: this.sendField,
                },
              });
            }
          })
          .finally(() => (this.loading = false));
        // 投放日充值线形图数据获取
        getPutDateRechargeGraph(tmpform)
          .then((res) => {
            this.setOptionPutDateRechargeGraph(res.x, res.y);
          })
          .finally(() => (this.loadingPutGraph = false));
        // 日充值线形图数据获取
        getEdRechargeGraph(tmpform)
          .then((res) => {
            this.setOptionEverydayRechargeGraph(res.x, res.y);
          })
          .finally(() => (this.loadingEdGraph = false));
      }
      // 缓存判断处理----------------------》
      // 平台统计表格数据获取
    },
    // 获取字段
    handleGetFeilds(flag = "created") {
      getFields({ dataType: "platformStatistics" }).then((res) => {
        this.originFeild = res;
        const tmpdata = [];
        for (let i = 0; i < res.length; i++) {
          tmpdata.push({
            fieldName: res[i].fieldName,
            fieldCode: res[i].fieldCode,
          });
        }
        this.fieldsData = tmpdata;
        // 若显示字段为空则显示全部
        if (this.showField.length === 0) {
          this.showField = res;
        }
        this.selectAllFields = true;
        this.getData(flag);
        // 判断字段所选长度是否为0 是则全选 否则不全选
      });
    },
    // 表格颜色添加
    tableRowClassName({ row, rowIndex }) {
      const queryDate = this.lastParams ? this.lastParams.queryDate : null;
      const isSelected = row.date === queryDate;
      if (isSelected) {
        this.tableScrollMove("staticTable", rowIndex);
      }
      if (row.isReturn) {
        const tmpclass = isSelected
          ? "table-confirm table-lastSected"
          : "table-confirm";
        return tmpclass;
      } else if (!row.isReturn && isSelected) {
        return "table-lastSected";
      }
      // if (row.isReturn) {
      //   return "table-confirm";
      // }
      // return "";
    },
    // 书籍流向echarts

    handleClose() {
      this.dialogVisible = false;
    },
    handleSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        if (rowIndex % 2 === 0) {
          return {
            rowspan: 2,
            colspan: 1,
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0,
          };
        }
      }
    },
    getResultPre(scoped, index) {
      let res = null;
      if (scoped.row.data) {
        res = scoped.row.data[index].preReturnRate;
      }
      if (scoped.row.returnRateList) {
        res = scoped.row.returnRateList[index].returnRate;
      }
      return res ? (index === 0 ? `${res}` : `${res}%`) : "0%";
    },
    cellStyle({ row, column, rowIndex, columnIndex }) {
      if (columnIndex !== 0) {
        if (rowIndex % 2 !== 0) {
          return "color: #FF3300;font-size:12px;padding:10px;background:#FFFF66";
        }
      }
      return "font-size:12px;padding:10px;";
    },
    // 数据导出
    handleExportData() {
      const tmpParam = {};
      for (const key in this.params) {
        if (key !== "cacheSendField" && key !== "cacheShowField") {
          if (key === "selectTime") {
            tmpParam["beginDate"] = this.params.selectTime[0];
            tmpParam["endDate"] = this.params.selectTime[1];
          } else {
            tmpParam[key] = this.params[key];
          }
        }
      }
      exportFile("/statistics/export/investmentReturn", tmpParam, "每日数据")
        .then(() => {
          this.$message.success("导出成功");
        })
        .finally(() => (this.exportLoading = false));
    },
    handleShowOperateAssess() {
      this.showOperateAssess = true;
      this.operateAssessParams = {
        selectTime: [
          formatTime(
            new Date().getTime() - 3600 * 1000 * 24 * 30,
            "yyyy-MM-dd"
          ),
          formatTime(new Date(), "yyyy-MM-dd"),
        ],
        firstRate: 25,
        returnDay: 60,
      };
      this.$nextTick(() => {
        this.preAssess = echarts.init(document.getElementById("preAssess"));
        window.onresize = this.preAssess.resize;
        this.handleAssessPageChange();
      });
      // window.open(
      //   "https://h5.file.yayawx.cn/novel_put/WechatIMG97.png",
      //   "_blank"
      // );
    },
    // 预测运营回本图
    setOptionPreAssessGraph(data, nativeData) {
      let xData = null;
      let totalData = null;
      // let addData = null;
      let yData = null;

      let nativeAddData = [];
      let nativePreData = [];
      let nativeX = [];
      let nativeYTotal = [];
      let nativeYAdd = [];
      let addData = null;
      let regressionTotal = null;
      let regressionAdd = null;

      data.forEach((item) => {
        if (item.label === "天数") {
          xData = item.value;
          xData = xData.map((item2) => {
            return item2.split("D")[1];
          });
        }
        if (item.label === "累计回本率") {
          totalData = item;
        }
        if (item.label === "增加回本率") {
          addData = item;
        }
      });
      let totalY = xData.map((item, index) => {
        return [item, parseFloat(totalData.value[index])];
      });
      let addY = xData.map((item, index) => {
        return [item, parseFloat(addData.value[index])];
      });
      // regressionTotal = ecStat.regression("logarithmic", totalY);
      // regressionAdd = ecStat.regression("logarithmic", addY);
      if (nativeData) {
        nativeData.forEach((item) => {
          nativeX.push(item.day.split("D")[1]);
          nativeYTotal.push(item.grand.split("%")[0]);
          nativeYAdd.push(item.add.split("%")[0]);
          nativeAddData.push([item.day.split("D")[1], item.add.split("%")[0]]);
          nativePreData.push([
            item.day.split("D")[1],
            item.grand.split("%")[0],
          ]);
        });
      }
      yData = [
        {
          name: "实际累计回本率",
          type: "line",
          // showSymbol: false,
          // lineStyle: {
          //   type: "line",
          //   color: "#5B9BD5",
          // },
          data: nativeYTotal,
          // data: nativeAddData,
          smooth: true,
        },
        {
          name: "实际增加回本率",
          type: "line",
          data: nativeYAdd,
          // data: nativePreData,
          smooth: true,
        },
        {
          name: "预测累计回本率",
          type: "line",
          showSymbol: false,
          lineStyle: {
            type: "line",
            // color: "#ED7F35",
          },
          // data: regressionTotal ? regressionTotal.points : [],
          data: totalY,
        },
        {
          name: "预测增加回本率",
          type: "line",
          showSymbol: false,
          lineStyle: {
            type: "line",
            // color: " #5B9BD5",
          },
          // data: regressionAdd ? regressionAdd.points : [],
          data: addY,
        },
      ];
      this.preAssess.setOption(
        {
          color: ["#5B9BD5", "#ED7F35"],
          tooltip: {
            trigger: "axis",
            formatter: (params) => {
              // params为悬浮框上的全部数据
              var data = "D" + params[0].name + "<br/>";
              params.forEach((item) => {
                if (item.value instanceof Array) {
                  data +=
                    item.seriesName + "：" + item.value[1] + "%" + "<br/>";
                } else {
                  data += item.seriesName + "：" + item.value + "%" + "<br/>";
                }
              });
              return data;
              // params[0].seriesName +
              // "：" +
              // params[0].value[1].toFixed(2) +
              // "%"
            },
          },
          legend: {
            data: [
              {
                name: "实际累计回本率",
              },
              {
                name: "实际增加回本率",
              },
              {
                name: "预测累计回本率",
                icon: "circle",
              },
              {
                name: "预测增加回本率",
                icon: "circle",
              },
            ],
          },
          xAxis: {
            type: "category",
            boundaryGap: false,
            data: nativeX.length > 0 ? nativeX : null,
          },
          yAxis: {
            type: "value",
          },
          series: yData,
        },
        true
      );
    },
    // 投放日充值线性图
    setOptionPutDateRechargeGraph(x, y) {
      this.putDateGraph.setOption(
        {
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "cross",
              label: {
                backgroundColor: "#6a7985",
              },
            },
          },
          grid: {
            left: "3%",
            right: "3%",
            bottom: "10%",
            containLabel: true,
          },
          xAxis: {
            type: "category",
            data: x,
            // data: x.reverse()
          },
          yAxis: {
            type: "value",
            name: "投放日充值线形图",
          },
          series: [
            {
              data: y,
              // data: y.reverse(),
              type: "line",
              smooth: true,
            },
          ],
        },
        true
      );
    },
    // 日充值线性图
    setOptionEverydayRechargeGraph(x, y) {
      this.edDateGraph.setOption(
        {
          tooltip: {
            trigger: "axis",
          },
          grid: {
            left: "5%",
            right: "5%",
            bottom: "10%",
            containLabel: true,
          },
          xAxis: {
            type: "category",
            data: x,
            boundaryGap: false,
          },
          yAxis: {
            type: "value",
            name: "日充值线形图",
          },
          series: [
            {
              data: y,
              type: "line",
              smooth: true,
            },
          ],
        },
        true
      );
    },
    // 书籍流向
    setOptionbooksFlowDirectionGraph(data) {
      this.booksFlowDirection.setOption(
        {
          color: [
            "#6273E0",
            "#3F80FF",
            "#AB74E9",
            "#22C7B1",
            "#FF9163",
            "#FFB85E",
            "#00BF8A",
            "#FB817F",
          ],
          tooltip: {
            trigger: "item",
            triggerOn: "mousemove",
          },
          title: {
            subtext: "书籍流向",
            subtextStyle: {
              fontSize: 16,
              fontWeight: "bold",
              color: "#969696",
            },
          },
          series: {
            type: "sankey",
            layout: "none",
            label: {
              textStyle: {
                //数值样式
                color: "#969696",
                fontSize: 12,
              },
            },
            emphasis: {
              focus: "adjacency",
            },
            data: data.data,
            links: data.links,
          },
        },
        true
      );
    },
  },
  created() {
    this.$set(this.params, "dayType", 0);
    this.$set(this.params, "ruledOutVip", 0);
    if (this.lastParams) {
      this.lastParams.booksId
        ? this.getBookById(this.lastParams.booksId)
        : this.getBook();
      this.handleGetFeilds("drawer");
      return false;
    }
    this.getBook();
    const tody = formatTime(new Date(), "yyyy-MM-dd");
    this.$set(this.params, "queryDate", tody);
    const todySelectTime = getRecentlyMonth(true);
    this.$set(this.params, "selectTime", todySelectTime);
    // this.params.queryDate = formatTime(new Date(), "yyyy-MM-dd");
    // 缓存获取
    const tmpIndex = this.$store.state.routerHistory.findIndex(
      (item) => item.path === this.$route.path
    );
    // 若存在缓存
    if (tmpIndex !== -1) {
      this.params = this.$store.state.routerHistory[tmpIndex].params;
      // 获取缓存展示字段
      this.showField =
        this.$store.state.routerHistory[tmpIndex].params.cacheShowField;
      // 获取缓存发送前格式字段
      this.sendField =
        this.$store.state.routerHistory[tmpIndex].params.cacheSendField;
    }
    this.handleGetFeilds();
    // this.getData()
  },
  beforeCreate() {
    this.$options.components.CommonDrawer =
      require("@/components/commonDrawer").default;
  },
  mounted() {
    this.initGraph();
  },
  updated() {
    // 处理表格fixed错位问题
    this.$refs.staticTable.doLayout();
  },
  // 销毁echart不销毁会存在内存溢出
  destroyed() {
    if (this.edDateGraph) {
      echarts.dispose(this.edDateGraph);
    }
    if (this.putDateGraph) {
      echarts.dispose(this.putDateGraph);
    }
  },
};
</script>

<style lang="scss" scoped>
.aggregate-data {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .enlarge-button {
    margin-left: auto;
    margin-right: 20px;
    cursor: pointer;
  }
}
.ad-consume {
  background-color: #fff;
  border-radius: 6px;
}
// 筛选条件框
.filter-box {
  border-radius: 6px;
  // border: 1px solid #eceff2;
  .filter-title {
    color: $main-text;
    margin-bottom: 20px;
    .iconfont {
      font-size: 12px !important;
    }
  }
}
.consume-table {
  .adId {
    text-decoration: underline;
    cursor: pointer;
  }
  .min-img {
    width: 70px;
    height: 70px;
  }
}
.platform-statistic .el-table .el-table__row td {
  padding: 0;
}
.platform-statistic .el-table--small td {
  padding: 5px 0 !important;
}
.platform-statistic .el-table__body th,
.platform-statistic .el-table__body td {
  padding: 0 !important;
}
.platform-statistic /deep/ tr td {
  font-size: 12px;
}
.platform-statistic /deep/ td {
  padding: 0px !important;
  // margin: -12px 0;
}
.platform-statistic /deep/ .el-table {
  position: relative;
}
// .left-cell {
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
// }
.fullscreen {
  background-color: #fff !important;
  z-index: 2000;
}
// 抽屉选中
/deep/ .table-lastSected,
/deep/.table-lastSected td {
  background-color: #81ffe5 !important;
}
#booksFlowDirection {
  // width: 70%;
  height: 700px;
}
// 预测相关
.Operate-box {
  padding: 0 20px;
  .assess-box > div {
    margin-right: 10px;
  }
  #preAssess {
    min-height: 480px;
  }
  // 表头
  /deep/ .el-table--border th,
  /deep/.el-table .has-gutter tr th {
    border-bottom: 1px solid rgb(235, 238, 245) !important;
    border-right: 1px solid rgb(235, 238, 245) !important;
  }
}
// 浮动相关
/deep/ .flow-div-flex {
  // color: #fff !important;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99999;
  width: 100%;
  box-shadow: 0px 0px 6px 4px #ccc;
  td {
    border-color: #ccc !important;
  }
}
/deep/ .flow-div {
  color: #fff !important;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  box-shadow: 0px 0px 6px 4px #ccc;
  td {
    border-color: #000 !important;
    border-bottom: 1px solid #ccc !important;
  }
}
/deep/ .flow-div-flex .el-table__row {
  background-color: #ccc !important;
  // td {
  //   background-color: #c9bdbd !important;
  // }
  td .cell {
    width: 90px !important;
  }
  td:first-child .cell {
    width: 60px !important;
  }
  td:nth-child(2) .cell {
    width: 60px !important;
  }
  .is-hidden .cell {
    width: 130px !important;
  }
  .extraHideData {
    display: none;
  }
  .extraOpenData {
    display: inline-block;
  }
}
// /deep/ .el-table__body-wrapper tbody > .hover-row {
//   color: #fff !important;
//   td {
//     background-color: #2d2d2d !important;
//   }
// }
/deep/ .flow-div .el-table__row {
  td {
    background-color: #2d2d2d !important;
  }
  .is-hidden:first-child .cell {
    width: 60px !important;
  }
  .is-hidden:nth-child(2) .cell {
    width: 60px !important;
  }
  .is-hidden .cell {
    width: 90px !important;
  }
  td .cell {
    width: 130px !important;
  }
  .extraHideData {
    display: none;
  }
  .extraOpenData {
    display: inline-block;
    width: 100%;
    p {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}
.extra-cell p {
  // display: flex;
  // align-items: center;
  // justify-content: space-between;
}
.table-bottom {
  position: relative;
  .bottom-trigger {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 9999;
    cursor: pointer;
  }
}
</style>
