
// 文章类型
export const articleList = [
  {
    label: '教育',
    value: 'ENTERTAINMENT',
    checked: false
  },
  {
    label: '社会',
    value: 'SOCIETY',
    checked: false
  },
  {
    label: '汽车',
    checked: false,
    value: 'CARS'
  },
  {
    label: '国际',
    checked: false,
    value: 'INTERNATIONAL'
  },
  {
    label: '历史',
    checked: false,
    value: 'HISTORY'
  },
  {
    label: '体育',
    checked: false,
    value: 'SPORTS'
  },
  {
    label: '健康',
    checked: false,
    value: 'HEALTH'
  },
  {
    label: '军事',
    checked: false,
    value: 'MILITARY'
  },
  {
    label: '情感',
    checked: false,
    value: 'EMOTION'
  },
  {
    label: '时尚',
    checked: false,
    value: 'FASHION'
  },
  {
    label: '育儿',
    checked: false,
    value: 'PARENTING'
  },
  {
    label: '财经',
    checked: false,
    value: 'FINANCE'
  },
  {
    label: '搞笑',
    checked: false,
    value: 'AMUSEMENT'
  },
  {
    label: '数码',
    checked: false,
    value: 'DIGITAL'
  },
  {
    label: '探索',
    checked: false,
    value: 'EXPLORE'
  },
  {
    label: '旅游',
    checked: false,
    value: 'TRAVEL'
  },
  {
    label: '星座',
    checked: false,
    value: 'CONSTELLATION'
  },
  {
    label: '故事',
    checked: false,
    value: 'STORIES'
  },
  {
    label: '科技',
    checked: false,
    value: 'TECHNOLOGY'
  },
  {
    label: '美食',
    checked: false,
    value: 'GOURMET'
  },
  {
    label: '文化',
    checked: false,
    value: 'CULTURE'
  },
  {
    label: '家居',
    checked: false,
    value: 'HOME'
  },
  {
    label: '电影',
    checked: false,
    value: 'MOVIE'
  },
  {
    label: '宠物',
    checked: false,
    value: 'PETS'
  },
  {
    label: '游戏',
    checked: false,
    value: 'GAMES'
  },
  {
    label: '瘦身',
    checked: false,
    value: 'WEIGHT_LOSING'
  },
  {
    label: '奇葩',
    checked: false,
    value: 'FREAK'
  },
  {
    label: '教育',
    checked: false,
    value: 'EDUCATION'
  },
  {
    label: '房产',
    checked: false,
    value: 'ESTATE'
  },
  {
    label: '科学',
    checked: false,
    value: 'SCIENCE'
  },
  {
    label: '摄影',
    checked: false,
    value: 'PHOTOGRAPHY'
  },
  {
    label: '养生',
    checked: false,
    value: 'REGIMEN'
  },
  {
    label: '美文',
    checked: false,
    value: 'ESSAY'
  },
  {
    label: '收藏',
    checked: false,
    value: 'COLLECTION'
  },
  {
    label: '动漫',
    checked: false,
    value: 'ANIMATION'
  },
  {
    label: '漫画',
    checked: false,
    value: 'COMICS'
  },
  {
    label: '小窍门',
    checked: false,
    value: 'TIPS'
  },
  {
    label: '设计',
    checked: false,
    value: 'DESIGN'
  },
  {
    label: '本地',
    checked: false,
    value: 'LOCAL'
  },
  {
    label: '法律',
    checked: false,
    value: 'LAWS'
  },
  {
    label: '政务',
    checked: false,
    value: 'GOVERNMENT'
  },
  {
    label: '商业',
    checked: false,
    value: 'BUSINESS'
  },
  {
    label: '职场',
    checked: false,
    value: 'WORKPLACE'
  },
  {
    label: '辟谣',
    checked: false,
    value: 'RUMOR_CRACKER'
  },
  {
    label: '毕业生',
    checked: false,
    value: 'GRADUATES'
  }
]
// 手机品牌

export const deviceBrand = [
  {
    label: '荣耀',
    checked: false,
    value: 'HONOR'
  },
  {
    label: '苹果',
    checked: false,
    value: 'APPLE'
  },
  {
    label: '华为',
    checked: false,
    value: 'HUAWEI'
  },
  {
    label: '小米',
    checked: false,
    checked: false,
    value: 'XIAOMI'
  },
  {
    label: '三星',
    checked: false,
    value: 'SAMSUNG'
  },
  {
    label: 'OPPO',
    checked: false,
    value: 'OPPO'
  },
  {
    label: 'VIVO',
    checked: false,
    value: 'VIVO'
  },
  {
    label: '魅族',
    checked: false,
    value: 'MEIZU'
  },
  {
    label: '金立',
    checked: false,
    value: 'GIONEE'
  },
  {
    label: '酷派',
    checked: false,
    value: 'COOLPAD'
  },
  {
    label: '联想',
    checked: false,
    value: 'LENOVO'
  },
  {
    label: '乐视',
    checked: false,
    value: 'LETV'
  },
  {
    label: '中兴',
    checked: false,
    value: 'ZTE'
  },
  {
    label: '中国移动',
    checked: false,
    value: 'CHINAMOBILE'
  },
  {
    label: 'HTC',
    checked: false,
    value: 'HTC'
  },
  {
    label: '小辣椒',
    checked: false,
    value: 'PEPPER'
  },
  {
    label: '努比亚',
    checked: false,
    value: 'NUBIA'
  },
  {
    label: '海信',
    checked: false,
    value: 'HISENSE'
  },
  {
    label: '奇酷',
    checked: false,
    value: 'QIKU'
  },
  {
    label: 'TCL',
    checked: false,
    value: 'TCL'
  },
  {
    label: '索尼',
    checked: false,
    value: 'SONY'
  },
  {
    label: '锤子手机',
    checked: false,
    value: 'SMARTISAN'
  },
  {
    label: '360手机',
    checked: false,
    value: '360'
  },
  {
    label: '一加手机',
    checked: false,
    value: 'ONEPLUS'
  },
  {
    label: 'LG',
    checked: false,
    value: 'LG'
  },
  {
    label: '摩托罗拉',
    checked: false,
    value: 'MOTO'
  },
  {
    label: '诺基亚',
    checked: false,
    value: 'NOKIA'
  },
  {
    label: '谷歌',
    checked: false,
    value: 'GOOGLE'
  }

]

// 职业
export const career = [
  {
    label: '大学生',
    checked: false,
    value: 'COLLEGE_STUDENT'
  },
  {
    label: '教师',
    checked: false,
    value: 'TEACHER'
  },
  {
    label: 'IT',
    checked: false,
    value: 'IT'
  },
  {
    label: '公务员',
    checked: false,
    value: 'CIVIL_SERVANTS'
  },
  {
    label: '金融',
    checked: false,
    value: 'FINANCIAL'
  },
  {
    label: '医务人员',
    checked: false,
    value: 'MEDICAL_STAFF'
  }
]
