<template>
  <div class="ad-consume padding-20 box-shadow-light-grey">
    <!-- 条件搜索 -->
    <div class="filter-box">
      <!-- <h4 class="filter-title">今日广告</h4> -->
      <div class="row">
        <exclude-query :params="params" />
        <el-date-picker
          :clearable="false"
          v-model="params.queryDate"
          type="date"
          style="width: 150px"
          value-format="yyyy-MM-dd"
          :picker-options="{
            disabledDate(time) {
              return time > Date.now();
            },
          }"
          placeholder="选择日期1"
        >
        </el-date-picker>
        <select-platform-channel :params="params" />
        <el-select
          v-model="params.booksId"
          filterable
          remote
          clearable
          style="width: 150px"
          reserve-keyword
          placeholder="书籍名称"
          :remote-method="getBook"
        >
          <el-option
            v-for="item in bookList"
            :key="item.id"
            :label="item.bookName"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <select-dept-user :params="params" />
        <el-select
          v-model="params.isTest"
          clearable
          placeholder="类型"
          style="width: 100px"
        >
          <el-option label="普通号" :value="0"></el-option>
          <el-option label="测书号" :value="1"></el-option>
        </el-select>
        <!-- 字段选择 -->
        <select-field
          :fieldsData="fieldsData"
          :originFeild="originFeild"
          :EchoField="sendField"
          @fieldChange="handleFieldChange"
        />
        <el-button
          type="primary"
          @click="handlePageChange(1)"
          :loading="loading"
          style="margin-left: 10px"
        >
          搜索
        </el-button>
        <el-button type="info" @click="handleReset"> 重置 </el-button>
        <el-button
          type="primary"
          @click="handleExportData"
          style="margin-left: 5px"
        >
          导出
        </el-button>
      </div>
    </div>
    <div
      class="data-box"
      style="height: 690px; overflow: auto"
      v-loading="loading"
    >
      <!-- 汇总数据 -->
      <div>
        <div
          class="statistics-card"
          v-for="(item, index) in totalData"
          :key="index"
        >
          <div class="data-label">{{ item.label }}</div>
          <div class="statistic-num-text">
            <span class="statistic-num">{{ item.value || 0 }}</span>
          </div>
        </div>
      </div>
      <!-- 表格数据 -->
      <el-table
        header-row-class-name="table-header"
        ref="userConsumeTable"
        class="consume-table"
        :data="staticDataList"
        style="width: 100%"
        height="650px"
        v-loading="loading"
        empty-text="暂无数据"
        @sort-change="handleSortChange"
        @cell-click="handleCellClick"
      >
        <el-table-column
          v-for="(item, index) in showField"
          :key="index"
          :prop="item.fieldCode"
          :label="item.fieldName"
          :sortable="sortableType(item)"
          :align="filAlign(item)"
          :min-width="item.width + 'px' || '100px'"
        >
          <template slot-scope="scope">
            <div v-if="item.hasChild">
              <div v-for="(item2, index2) in item.children" :key="index2">
                {{ item2.fieldName }}:
                <span :style="'color:' + item.fontColor">{{
                  scope.row[item2.fieldCode]
                }}</span>
              </div>
            </div>
            <span
              :style="'color:' + item.fontColor"
              :datatest="item.fieldCode"
              :class="
                item.fieldCode === 'afterTaxTotalRecharge' ||
                item.fieldCode === 'currentRecharge' ||
                item.fieldCode === 'compareRecharge'
                  ? 'drawer-text'
                  : ''
              "
              v-else
              >{{ scope.row[item.fieldCode] | sortField(item) }}</span
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        style="margin-top: 10px; text-align: right"
        background
        @current-change="handleStaticPage"
        :current-page="staticPage"
        :page-size="staticPageSize"
        layout="total, prev, pager, next, jumper"
        :total="staticPageTotal"
      >
      </el-pagination>
    </div>

    <!-- 公共抽屉 -->
    <common-drawer
      :showDrawer="showCommonDrawer"
      size="90%"
      @close="showCommonDrawer = false"
      :template="showTemplate"
      :nextParams="nextParams"
    />
  </div>
</template>

<script>
import {
  platformList,
  yesterdayOptions,
  labelList,
  pickerOptions,
} from "@/assets/js/options";
import { formatThousandsDots, formatTime } from "@/assets/js/utils";
import SelectField from "@/components/SelectField";
import SelectPlatformChannel from "@/components/SelectPlatformChannel";
import { getBookList } from "@/api/account";
import { getOperationEveryday, getFields, exportFile } from "@/api/statics";

import { mapActions, mapGetters } from "vuex";
import ExcludeQuery from "@/components/excludeQuery";
import SelectDeptUser from "@/components/SelectDeptUser";
export default {
  name: "intervalChannel",
  props: {
    lastParams: Object,
  },
  components: {
    ExcludeQuery,
    SelectDeptUser,
    SelectPlatformChannel,
    SelectField,
  },
  computed: {
    ...mapGetters(["officialList"]),
    // 排序处理
    sortableType() {
      return function (value) {
        if (value.autoFields === 0) {
          return false;
        } else if (value.autoFields === 2 || value.autoFields === 4) {
          return true;
        } else if (
          value.autoFields === 1 ||
          value.autoFields === 3 ||
          value.autoFields === 5
        ) {
          return "custom";
        }
      };
    },
    // 文字对齐处理
    filAlign() {
      return function (value) {
        switch (value.fontAlign) {
          case 0:
            return "left";
          case 1:
            return "center";
          case 2:
            return "right";
          default:
            return "center";
        }
      };
    },
  },
  watch: {
    lastParams: function () {
      this.getData("drawer");
    },
  },
  filters: {
    sortField: function (value, item) {
      if (item.autoFields === 4 || item.autoFields === 5) {
        // 报错处理
        if (value !== undefined) {
          return formatThousandsDots(value.toString());
        }
        // return formatThousandsDots(value.toString());
      } else {
        return value;
      }
    },
  },
  data() {
    return {
      params: {},
      bookList: null,
      fieldsData: [],
      originFeild: [],
      selectFields: null,
      selectAllFields: false,
      dataList: [],
      totalData: [],
      loading: false,
      sortName: null,
      sortDesc: null,
      showRecharge: false,
      yesterdayOptions,
      labelList,
      sendField: [],
      showField: [],
      tmpShowField: [],
      showCommonDrawer: false,
      showTemplate: null,
      nextParams: {},
      pickerOptions,
      platformList,
      staticDataList: [],
      staticPage: 1,
      staticPageTotal: 0,
      staticPageSize: 15,
    };
  },
  methods: {
    // 数据导出
    handleExportData() {
      const tmpParam = {};
      for (const key in this.params) {
        if (key !== "cacheSendField" && key !== "cacheShowField") {
          tmpParam[key] = this.params[key];
        }
      }
      exportFile("/statistics/export/putData", tmpParam, "运营数据")
        .then(() => {
          this.$message.success("导出成功");
        })
        .finally(() => (this.exportLoading = false));
    },
    // 静态分页
    handleStaticPage(page) {
      this.staticPage = page;
      // this.staticDataList.splice(0, this.staticDataList.length);
      this.staticDataList = this.dataList.slice(
        (this.staticPage - 1) * this.staticPageSize,
        this.staticPage * this.staticPageSize
      );
    },
    // 表格点击事件
    handleCellClick(row, column) {
      // 进入每日公众号数据
      if (column.label === "整体收益") {
        const begin = formatTime(
          new Date(row.selectDate).getTime() - 3600 * 1000 * 24 * 15,
          "yyyy-MM-dd"
        );
        let tmpEnd = new Date(
          new Date(row.selectDate).getTime() + 3600 * 1000 * 24 * 15
        );
        if (tmpEnd.getTime() >= new Date().getTime()) {
          tmpEnd = new Date();
        }
        const end = formatTime(tmpEnd, "yyyy-MM-dd");
        this.nextParams = {
          beginDate: begin,
          endDate: end,
          selectTime: [begin, end],
          queryDate: this.params.queryDate,
          accountChannelId: row.accountChannelId,
        };
        this.showTemplate = "channelData";
        this.showCommonDrawer = true;
      } else if (column.label === "当日充值") {
        const begin = formatTime(
          new Date(row.selectDate).getTime(),
          "yyyy-MM-dd 00:00:00"
        );
        const end = formatTime(
          new Date(row.selectDate).getTime(),
          "yyyy-MM-dd 23:59:59"
        );
        this.nextParams = {
          beginDate: begin,
          endDate: end,
          accountChannelId: row.accountChannelId,
          selectTime: [begin, end],
        };
        this.showTemplate = "orderDetail";
        this.showCommonDrawer = true;
      } else if (column.label === "相比昨日") {
        const queryDate = formatTime(
          new Date(row.selectDate).getTime(),
          "yyyy-MM-dd 23:59:59"
        );
        this.nextParams = {
          queryDate: queryDate,
          accountChannelId: row.accountChannelId,
        };
        this.showTemplate = "consumeCharging";
        this.showCommonDrawer = true;
      }
    },
    ...mapActions({
      addRouterHistory: "addRouterHistory",
    }),
    // 组件字段变化
    // handleFieldChange (value) {
    //   if (value.length === 0) {
    //     this.showField = this.originFeild
    //   } else {
    //     this.showField = value
    //   }
    //   this.$nextTick(() => {
    //     this.$refs.userConsumeTable.doLayout()
    //   })
    // },
    // 获取书籍列表
    getBook(bookName) {
      getBookList({
        page: 1,
        pageSize: 50,
        bookName,
      }).then((res) => {
        this.bookList = res.list;
      });
    },
    // 获取书籍列表by id
    getBookById(booksIds) {
      getBookList({
        page: 1,
        pageSize: 50,
        booksIds,
      }).then((res) => {
        this.bookList = res.list;
      });
    },
    // 排序处理
    handleSortChange({ column, prop, order }) {
      if (column.sortable !== "custom") {
        return;
      }
      this.sortName = prop;
      this.sortDesc = order === "descending";
      this.params.sortName = this.sortName;
      this.params.sortDesc = this.sortDesc;
      this.handlePageChange(1);
    },
    // 重置操作
    handleReset() {
      this.params.queryDate = formatTime(new Date(), "yyyy-MM-dd");
      this.params.originPlatform = null;
      this.params.adminUserId = null;
      this.params.deptId = null;
      this.params.accountChannelId = null;
      this.params.booksId = null;
      this.params.ruledOutBooksIds = null;
      this.params.ruledOutAccountChannelIds = null;
      this.params.isTest = null;
      this.sendField = [];
      this.sortName = null;
      this.sortDesc = null;
      this.showField = this.originFeild;
      this.tmpShowField = [];
      this.handlePageChange(1);
    },
    // 分页操作
    handlePageChange(page) {
      this.page = page;
      this.getData();
    },
    // 选择字段变化
    handleFieldChange(send, show) {
      // if (value.length === 0) {
      //   this.showField = this.originFeild;
      // } else {
      //   this.showField = value;
      // }
      // 请求格式field
      this.sendField = send;
      // 展示格式field
      this.tmpShowField = show;
      this.$nextTick(() => {
        this.$refs.userConsumeTable.doLayout();
      });
    },
    // 请求前处理字段
    handleFields(item) {
      if (item.length === 0) {
        return null;
      }
      let tmpSend = [];
      const _that = this;
      tmpSend = item.reduce(function (a, b) {
        return a.concat(b);
      });
      // 判断勾选项 推入其下所有子级
      for (let i = 0; i < tmpSend.length; i++) {
        const flagIndex = _that.originFeild.findIndex((item2) => {
          return item2.fieldCode === tmpSend[i];
        });
        if (flagIndex !== -1 && "children" in _that.originFeild[flagIndex]) {
          _that.originFeild[flagIndex].children.forEach((item3) => {
            tmpSend.push(item3.fieldCode);
          });
        }
      }
      // 去重
      tmpSend = Array.from(new Set(tmpSend));
      return tmpSend.join(",");
    },
    // 广告数据获取
    getData(flag) {
      const tody = formatTime(new Date(), "yyyy-MM-dd");
      if (!this.params.queryDate) {
        this.$set(this.params, "queryDate", tody);
      }
      this.loading = true;
      let tmpFields = null;
      if (this.sendField.length > 0) {
        tmpFields = this.handleFields(this.sendField);
      } else {
        this.showField = this.originFeild;
      }
      let tmpform = {};
      // 如果是创建过程 参数默认取缓存参数 否则使用筛选参数
      if (flag === "created") {
        tmpform = {
          queryDate: this.params.queryDate,
          originPlatform: this.params.originPlatform,
          adminUserId: this.params.adminUserId,
          deptId: this.params.deptId,
          accountChannelId: this.params.accountChannelId,
          booksId: this.params.booksId,
          ruledOutBooksIds:
            this.params.ruledOutBooksIds && this.params.ruledOutBooksIds.length
              ? this.params.ruledOutBooksIds.map((item) => item.id).join()
              : null,
          ruledOutAccountChannelIds:
            this.params.ruledOutAccountChannelIds &&
            this.params.ruledOutAccountChannelIds.length
              ? this.params.ruledOutAccountChannelIds.join()
              : null,
          isTest: this.params.isTest,
          sortName: this.sortName,
          sortDesc: this.sortDesc,
          showFieldCodes: tmpFields,
        };
      } else if (flag === "drawer") {
        if (this.lastParams.booksId) {
          this.getBookById(this.lastParams.booksId);
        }
        tmpform = {
          ...this.lastParams,
        };

        this.params = { ...this.lastParams };
      } else {
        tmpform = {
          queryDate: this.params.queryDate,
          originPlatform: this.params.originPlatform,
          adminUserId: this.params.adminUserId,
          deptId: this.params.deptId,
          accountChannelId: this.params.accountChannelId,
          booksId: this.params.booksId,
          ruledOutBooksIds:
            this.params.ruledOutBooksIds && this.params.ruledOutBooksIds.length
              ? this.params.ruledOutBooksIds.map((item) => item.id).join()
              : null,
          ruledOutAccountChannelIds:
            this.params.ruledOutAccountChannelIds &&
            this.params.ruledOutAccountChannelIds.length
              ? this.params.ruledOutAccountChannelIds.join()
              : null,
          isTest: this.params.isTest,
          sortName: this.sortName,
          sortDesc: this.sortDesc,
          showFieldCodes: tmpFields,
        };
      }
      getOperationEveryday(tmpform)
        .then((res) => {
          this.totalData = res.showSumFields;
          // 如果用户选择过字段则表格展示用户选择字段
          if (this.tmpShowField.length > 0) {
            this.showField = this.tmpShowField;
          }
          this.dataList = res.lists;
          this.staticDataList = this.dataList.slice(0, this.staticPageSize);
          this.staticPage = 1;
          this.staticPageTotal = this.dataList.length;

          // 如果不是抽屉 缓存参数
          if (flag !== "drawer") {
            this.addRouterHistory({
              path: this.$route.path,
              params: {
                ...tmpform,
                ruledOutAccountChannelIds:
                  this.params.ruledOutAccountChannelIds,
                ruledOutBooksIds: this.params.ruledOutBooksIds,
                cacheShowField: this.showField,
                cacheSendField: this.sendField,
              },
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    // 获取字段
    handleGetFeilds(flag = "created") {
      getFields({ dataType: "operationEverydayData" }).then((res) => {
        this.originFeild = res;
        const tmpdata = [];
        for (let i = 0; i < res.length; i++) {
          tmpdata.push({
            fieldName: res[i].fieldName,
            fieldCode: res[i].fieldCode,
          });
        }
        this.fieldsData = tmpdata;
        if (this.showField.length === 0) {
          this.showField = res;
        }
        this.selectAllFields = true;
        this.getData(flag);
        // 判断字段所选长度是否为0 是则全选 否则不全选
      });
    },
    // 处理字段
  },
  created() {
    if (this.lastParams) {
      this.handleGetFeilds("drawer");
      return false;
    }
    this.getBook();
    // 缓存获取
    const tmpIndex = this.$store.state.routerHistory.findIndex(
      (item) => item.path === this.$route.path
    );
    // 若存在缓存
    if (tmpIndex !== -1) {
      this.params = this.$store.state.routerHistory[tmpIndex].params;
      // 获取缓存展示字段
      this.showField =
        this.$store.state.routerHistory[tmpIndex].params.cacheShowField;
      // 获取缓存发送前格式字段
      this.sendField =
        this.$store.state.routerHistory[tmpIndex].params.cacheSendField;
    }
    // this.params.queryDate = formatTime(new Date(), "yyyy-MM-dd");
    this.handleGetFeilds();
    // this.getData()
  },
};
</script>

<style lang="scss" scoped>
.ad-consume {
  background-color: #fff;
  border-radius: 6px;
}
// 筛选条件框
.filter-box {
  border-radius: 6px;
  // border: 1px solid #eceff2;
  .filter-title {
    color: $main-text;
    margin-bottom: 20px;
    .iconfont {
      font-size: 12px !important;
    }
  }
}
.consume-table {
  .adId {
    text-decoration: underline;
    cursor: pointer;
  }
  .min-img {
    width: 70px;
    height: 70px;
  }
}
</style>
