<template>
  <div class="ad-consume padding-20 box-shadow-light-grey">
    <!-- 条件搜索 -->
    <div class="filter-box">
      <!-- <h4 class="filter-title">今日广告</h4> -->
      <div class="row">
        <el-date-picker
          v-model="params.selectTime"
          style="width: 260px"
          type="daterange"
          align="right"
          unlink-panels
          :clearable="false"
          range-separator="-"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
        <!-- 单独公众号 -->
        <el-select
          v-model="params.accountChannelId"
          filterable
          style="width: 180px"
          reserve-keyword
          placeholder="选择公众号"
        >
          <el-option
            v-for="(item, index) in officialList"
            :label="item.channelName"
            :value="item.id"
            :key="index"
          >
          </el-option>
        </el-select>
        <select-field
          :fieldsData="fieldsData"
          :originFeild="originFeild"
          :EchoField="sendField"
          @fieldChange="handleFieldChange"
        />
        <el-button
          type="primary"
          @click="handlePageChange(1)"
          :loading="loading"
          style="margin-left: 10px"
        >
          搜索
        </el-button>
        <el-button type="info" @click="handleReset"> 重置 </el-button>
      </div>
    </div>
    <div
      class="data-box"
      :style="
        lastParams
          ? 'height: 770px; overflow: auto'
          : 'height: 690px; overflow: auto'
      "
      v-loading="loading"
    >
      <!-- 订单小时线形图 -->
      <div
        id="channelDataGraph"
        :style="'width: 100%;height: 300px;z-index:1;'"
      ></div>
      <!-- 表格数据 -->
      <el-table
        header-row-class-name="table-header"
        ref="userConsumeTable"
        class="consume-table"
        :data="dataList"
        style="width: 100%"
        height="690px"
        v-loading="loading"
        empty-text="暂无数据"
        :row-class-name="tableRowClassName"
        @sort-change="handleSortChange"
        @cell-click="handleCellClick"
      >
        <el-table-column
          v-for="(item, index) in showField"
          :key="index"
          :prop="item.fieldCode"
          :label="item.fieldName"
          :sortable="sortableType(item)"
          :align="filAlign(item)"
          :min-width="item.width + 'px' || '100px'"
        >
          <template slot-scope="scope">
            <div v-if="item.hasChild">
              <div v-for="(item2, index2) in item.children" :key="index2">
                {{ item2.fieldName }}:
                <span :style="'color:' + item.fontColor">{{
                  scope.row[item2.fieldCode]
                }}</span>
              </div>
            </div>
            <span
              :style="'color:' + item.fontColor"
              :datatest="item.fieldCode"
              :class="
                item.fieldCode === 'selectDate' ||
                item.fieldCode === 'currentRecharge' ||
                item.fieldCode === 'compareRecharge'
                  ? 'drawer-text'
                  : ''
              "
              v-else
              >{{ scope.row[item.fieldCode] | sortField(item) }}</span
            >
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 公共抽屉 -->
    <common-drawer
      :showDrawer="showCommonDrawer"
      size="90%"
      @close="showCommonDrawer = false"
      :template="showTemplate"
      :nextParams="nextParams"
    />
  </div>
</template>

<script>
import {
  platformList,
  yesterdayOptions,
  labelList,
  pickerOptions,
} from "@/assets/js/options";
import {
  formatThousandsDots,
  getRecentlyMonth,
  getRecentlyWeek,
  formatTime,
} from "@/assets/js/utils";
import echarts from "echarts";
import SelectField from "@/components/SelectField";
// import SelectPlatformChannel from '@/components/SelectPlatformChannel'
import { getBookList } from "@/api/account";
import { getOperationChannel, getFields } from "@/api/statics";

import { mapActions, mapGetters } from "vuex";
export default {
  name: "intervalChannel",
  props: {
    lastParams: Object,
  },
  components: {
    // ExcludeQuery,
    // SelectDeptUser,
    SelectField,
  },
  computed: {
    ...mapGetters(["officialList"]),
    // 排序处理
    sortableType() {
      return function (value) {
        if (value.autoFields === 0) {
          return false;
        } else if (value.autoFields === 2 || value.autoFields === 4) {
          return true;
        } else if (value.autoFields === 1 || value.autoFields === 3) {
          return "custom";
        }
      };
    },
    // 文字对齐处理
    filAlign() {
      return function (value) {
        switch (value.fontAlign) {
          case 0:
            return "left";
          case 1:
            return "center";
          case 2:
            return "right";
          default:
            return "center";
        }
      };
    },
  },
  watch: {
    lastParams: function () {
      this.getData("drawer");
    },
  },
  filters: {
    sortField: function (value, item) {
      if (item.autoFields === 4 || item.autoFields === 5) {
        // 报错处理
        if (value !== undefined) {
          return formatThousandsDots(value.toString());
        }
        // return formatThousandsDots(value.toString());
      } else {
        return value;
      }
    },
  },
  data() {
    return {
      params: {},
      bookList: null,
      fieldsData: [],
      originFeild: [],
      selectFields: null,
      selectAllFields: false,
      dataList: [],
      totalData: [],
      loading: false,
      sortName: null,
      sortDesc: null,
      showRecharge: false,
      yesterdayOptions,
      labelList,
      sendField: [],
      showField: [],
      tmpShowField: [],
      showCommonDrawer: false,
      showTemplate: null,
      nextParams: {},
      pickerOptions,
      platformList,
      channelDataChart: null,
    };
  },
  methods: {
    // 定位到指定行
    tableScrollMove(refName, index = 0) {
      if (!refName || !this.$refs[refName]) return; // 不存在表格的ref vm 则返回
      const vmEl = this.$refs[refName].$el;
      if (!vmEl) return;
      // 计算滚动条的位置
      const target = vmEl.querySelectorAll(".el-table__body tr")[index];
      if (!target) return;
      const targetTop = vmEl
        .querySelectorAll(".el-table__body tr")
        [index].getBoundingClientRect().top;
      const containerTop = vmEl
        .querySelector(".el-table__body")
        .getBoundingClientRect().top;
      const scrollParent = vmEl.querySelector(".el-table__body-wrapper");
      scrollParent.scrollTop = targetTop - containerTop;
    },
    // 表格颜色添加
    tableRowClassName({ row, rowIndex }) {
      const queryDate = this.lastParams ? this.lastParams.queryDate : null;
      const isSelected = row.selectDate === queryDate;
      if (isSelected) {
        this.tableScrollMove("userConsumeTable", rowIndex);
        return "table-lastSected";
      }
      return "";
      // if (row.isReturn) {
      //   const tmpclass = isSelected
      //     ? "table-confirm table-lastSected"
      //     : "table-confirm";
      //   return tmpclass;
      // } else if (!row.isReturn && isSelected) {
      //   return "table-lastSected";
      // }
    },
    // 表格点击事件
    handleCellClick(row, column) {
      // 进入平台统计
      if (column.label === "日期") {
        const begin = formatTime(
          new Date(row.selectDate).getTime() - 3600 * 1000 * 24 * 15,
          "yyyy-MM-dd"
        );
        let tmpEnd = new Date(
          new Date(row.selectDate).getTime() + 3600 * 1000 * 24 * 15
        );
        if (tmpEnd.getTime() >= new Date().getTime()) {
          tmpEnd = new Date();
        }
        const end = formatTime(tmpEnd, "yyyy-MM-dd");
        this.nextParams = {
          // beginDate: begin,
          // endDate: end,
          queryDate: formatTime(new Date(row.selectDate), "MM/dd"),
          selectTime: [begin, end],
          accountChannelId: row.accountChannelId,
        };
        this.showTemplate = "platformStatics";
        this.showCommonDrawer = true;
      } else if (column.label === "当日充值") {
        const begin = formatTime(
          new Date(row.selectDate).getTime(),
          "yyyy-MM-dd 00:00:00"
        );
        const end = formatTime(
          new Date(row.selectDate).getTime(),
          "yyyy-MM-dd 23:59:59"
        );
        this.nextParams = {
          beginDate: begin,
          endDate: end,
          accountChannelId: this.params.accountChannelId,
          selectTime: [begin, end],
        };
        this.showTemplate = "orderDetail";
        this.showCommonDrawer = true;
      } else if (column.label === "相比昨日") {
        const queryDate = formatTime(
          new Date(row.selectDate).getTime(),
          "yyyy-MM-dd 23:59:59"
        );
        this.nextParams = {
          queryDate: queryDate,
          accountChannelId: this.params.accountChannelId,
        };
        this.showTemplate = "consumeCharging";
        this.showCommonDrawer = true;
      }
    },
    // 运营公众号数据线性图
    setOptionoPerationChannelData(x, y) {
      this.channelDataChart.setOption(
        {
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "cross",
              label: {
                backgroundColor: "#6a7985",
              },
            },
          },
          grid: {
            left: "3%",
            right: "3%",
            bottom: "10%",
            containLabel: true,
          },
          xAxis: {
            type: "category",
            data: x.reverse(),
          },
          yAxis: {
            type: "value",
            name: "当日充值",
          },
          series: [
            {
              data: y.reverse(),
              type: "line",
              smooth: true,
            },
          ],
        },
        true
      );
    },
    // 初始化线形图
    initGraph() {
      this.channelDataChart = echarts.init(
        document.getElementById("channelDataGraph")
      );

      window.onresize = this.channelDataChart.resize;
    },
    ...mapActions({
      addRouterHistory: "addRouterHistory",
    }),
    // 组件字段变化
    // handleFieldChange (value) {
    //   if (value.length === 0) {
    //     this.showField = this.originFeild
    //   } else {
    //     this.showField = value
    //   }
    //   this.$nextTick(() => {
    //     this.$refs.userConsumeTable.doLayout()
    //   })
    // },
    // 获取书籍列表
    getBook(bookName) {
      getBookList({
        page: 1,
        pageSize: 50,
        bookName,
      }).then((res) => {
        this.bookList = res.list;
      });
    },
    // 获取书籍列表by id
    getBookById(booksIds) {
      getBookList({
        page: 1,
        pageSize: 50,
        booksIds,
      }).then((res) => {
        this.bookList = res.list;
      });
    },
    // 排序处理
    handleSortChange({ column, prop, order }) {
      if (column.sortable !== "custom") {
        return;
      }
      this.sortName = prop;
      this.sortDesc = order === "descending";
      this.params.sortName = this.sortName;
      this.params.sortDesc = this.sortDesc;
      this.handlePageChange(1);
    },
    // 重置操作
    handleReset() {
      this.params.accountChannelId = 1;
      this.params.selectTime = getRecentlyWeek();
      this.params.accountChannelId = null;
      this.sendField = [];
      this.showField = this.originFeild;
      this.tmpShowField = [];
      this.handlePageChange(1);
    },
    // 分页操作
    handlePageChange(page) {
      this.page = page;
      this.getData();
    },
    // 选择字段变化
    handleFieldChange(send, show) {
      // if (value.length === 0) {
      //   this.showField = this.originFeild;
      // } else {
      //   this.showField = value;
      // }
      // 请求格式field
      this.sendField = send;
      // 展示格式field
      this.tmpShowField = show;
      this.$nextTick(() => {
        this.$refs.userConsumeTable.doLayout();
      });
    },
    // 请求前处理字段
    handleFields(item) {
      if (item.length === 0) {
        return null;
      }
      let tmpSend = [];
      const _that = this;
      tmpSend = item.reduce(function (a, b) {
        return a.concat(b);
      });
      // 判断勾选项 推入其下所有子级
      for (let i = 0; i < tmpSend.length; i++) {
        const flagIndex = _that.originFeild.findIndex((item2) => {
          return item2.fieldCode === tmpSend[i];
        });
        if (flagIndex !== -1 && "children" in _that.originFeild[flagIndex]) {
          _that.originFeild[flagIndex].children.forEach((item3) => {
            tmpSend.push(item3.fieldCode);
          });
        }
      }
      // 去重
      tmpSend = Array.from(new Set(tmpSend));
      return tmpSend.join(",");
    },
    // 广告数据获取
    getData(flag) {
      const tody = getRecentlyWeek();
      if (!this.params.selectTime) {
        this.$set(this.params, "selectTime", tody);
      }
      if (!this.params.accountChannelId) {
        this.$set(this.params, "accountChannelId", 1);
      }
      this.loading = true;
      let tmpFields = null;
      if (this.sendField.length > 0) {
        tmpFields = this.handleFields(this.sendField);
      } else {
        this.showField = this.originFeild;
      }
      let tmpform = {};
      // 如果是创建过程 参数默认取缓存参数 否则使用筛选参数
      if (flag === "created") {
        tmpform = {
          beginDate: this.params.selectTime[0],
          endDate: this.params.selectTime[1],
          accountChannelId: this.params.accountChannelId,
          showFieldCodes: tmpFields,
        };
      } else if (flag === "drawer") {
        if (this.lastParams.booksId) {
          this.getBookById(this.lastParams.booksId);
        }
        tmpform = {
          ...this.lastParams,
        };

        this.params = { ...this.lastParams };
      } else {
        tmpform = {
          beginDate: this.params.selectTime[0],
          endDate: this.params.selectTime[1],
          accountChannelId: this.params.accountChannelId,
          showFieldCodes: tmpFields,
        };
      }
      getOperationChannel(tmpform)
        .then((res) => {
          // 如果用户选择过字段则表格展示用户选择字段
          if (this.tmpShowField.length > 0) {
            this.showField = this.tmpShowField;
          }
          this.dataList = res.list;
          this.setOptionoPerationChannelData(res.x, res.y);
          // 如果不是抽屉 缓存参数
          if (flag !== "drawer") {
            this.addRouterHistory({
              path: this.$route.path,
              params: {
                ...tmpform,
                cacheShowField: this.showField,
                cacheSendField: this.sendField,
              },
            });
          }
        })
        .finally(() => (this.loading = false));
      // 线形图数据获取
      // getOperationChannelGraph(tmpform)
      //   .then((res) => {
      //     this.setOptionoPerationChannelData(res.x, res.y);
      //   })
      //   .finally(() => {
      //     this.loadingGraph = false;
      //   });
    },
    // 获取字段
    handleGetFeilds(flag = "created") {
      getFields({ dataType: "operationChannelData" }).then((res) => {
        this.originFeild = res;
        const tmpdata = [];
        for (let i = 0; i < res.length; i++) {
          tmpdata.push({
            fieldName: res[i].fieldName,
            fieldCode: res[i].fieldCode,
          });
        }
        this.fieldsData = tmpdata;
        if (this.showField.length === 0) {
          this.showField = res;
        }
        this.selectAllFields = true;
        this.getData(flag);
        // 判断字段所选长度是否为0 是则全选 否则不全选
      });
    },
    // 处理字段
  },
  created() {
    if (this.lastParams) {
      this.handleGetFeilds("drawer");
      return false;
    }
    this.getBook();
    // 缓存获取
    const tmpIndex = this.$store.state.routerHistory.findIndex(
      (item) => item.path === this.$route.path
    );
    // 若存在缓存
    if (tmpIndex !== -1) {
      this.params = this.$store.state.routerHistory[tmpIndex].params;
      // 获取缓存展示字段
      this.showField =
        this.$store.state.routerHistory[tmpIndex].params.cacheShowField;
      // 获取缓存发送前格式字段
      this.sendField =
        this.$store.state.routerHistory[tmpIndex].params.cacheSendField;
    }
    // this.params.queryDate = formatTime(new Date(), "yyyy-MM-dd");
    this.handleGetFeilds();
    // this.getData()
  },
  mounted() {
    this.initGraph();
  },
  // 销毁echart不销毁会存在内存溢出
  destroyed() {
    if (this.channelDataChart) {
      echarts.dispose(this.channelDataChart);
    }
  },
};
</script>

<style lang="scss" scoped>
.ad-consume {
  background-color: #fff;
  border-radius: 6px;
}
// 筛选条件框
.filter-box {
  border-radius: 6px;
  // border: 1px solid #eceff2;
  .filter-title {
    color: $main-text;
    margin-bottom: 20px;
    .iconfont {
      font-size: 12px !important;
    }
  }
}
.consume-table {
  .adId {
    text-decoration: underline;
    cursor: pointer;
  }
  .min-img {
    width: 70px;
    height: 70px;
  }
}

// 抽屉选中
/deep/ .table-lastSected,
/deep/.table-lastSected td {
  background-color: #81ffe5 !important;
}
</style>
