<template>
  <div class="ad-consume padding-20 box-shadow-light-grey">
    <!-- 条件搜索 -->
    <div class="filter-box">
      <!-- <h4 class="filter-title">今日广告</h4> -->
      <div class="row">
        <el-date-picker
          v-model="params.queryDate"
          style="width: 260px"
          type="month"
          align="right"
          unlink-panels
          :clearable="false"
          range-separator="-"
          format="yyyy-MM"
          value-format="yyyy-MM"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
        <el-select
          v-model="params.accountChannelId"
          filterable
          style="width: 180px"
          reserve-keyword
          clearable
          placeholder="选择公众号"
        >
          <el-option
            v-for="(item, index) in officialList"
            :label="item.channelName"
            :value="item.id"
            :key="index"
          >
          </el-option>
        </el-select>
        <!-- 字段选择 -->
        <select-field
          :fieldsData="fieldsData"
          :originFeild="originFeild"
          :EchoField="sendField"
          @fieldChange="handleFieldChange"
        />
        <el-button
          type="primary"
          @click="handlePageChange(1)"
          style="margin-left: 10px"
          :loading="loading"
        >
          搜索
        </el-button>
        <el-button type="info" @click="handleReset" :loading="loading">
          重置
        </el-button>
      </div>
    </div>
    <!-- 表格数据 -->
    <el-table
      header-row-class-name="table-header"
      ref="userConsumeTable"
      class="consume-table"
      :data="dataList"
      style="width: 100%"
      :height="lastParams ? '710px' : '640px'"
      v-loading="loading"
      empty-text="暂无数据"
      @sort-change="handleSortChange"
      @cell-click="handleCellClick"
    >
      <el-table-column
        v-for="(item, index) in showField"
        :key="index"
        :prop="item.fieldCode"
        :label="item.fieldName"
        :sortable="sortableType(item)"
        :align="filAlign(item)"
        :min-width="item.width + 'px' || '100px'"
      >
        <template slot-scope="scope">
          <div v-if="item.hasChild">
            <div v-for="(item2, index2) in item.children" :key="index2">
              {{ item2.fieldName }}:
              <span :style="'color:' + item.fontColor">{{
                scope.row[item2.fieldCode]
              }}</span>
            </div>
          </div>
          <span
            :style="'color:' + item.fontColor"
            :class="item.fieldCode === 'channelName' ? 'drawer-text' : ''"
            v-else
            >{{ scope.row[item.fieldCode] | sortField(item) }}</span
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 普通分页 -->
    <el-pagination
      style="margin: 10px 0; text-align: right"
      background
      @current-change="handlePageChange"
      :current-page="page"
      :page-size="pageSize"
      layout="total, prev, pager, next, jumper"
      :total="totalPage"
    >
    </el-pagination>
    <!-- 公共抽屉 -->
    <CommonDrawer
      :showDrawer="showCommonDrawer"
      size="90%"
      @close="showCommonDrawer = false"
      :template="showTemplate"
      :nextParams="nextParams"
    />
  </div>
</template>

<script>
import {
  platformList,
  yesterdayOptions,
  labelList,
  pickerOptions,
} from "@/assets/js/options";
import { formatThousandsDots, formatTime } from "@/assets/js/utils";
import SelectField from "@/components/SelectField";
import { getMultiMonth, getFields } from "@/api/statics";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "month",
  props: {
    lastParams: Object,
  },
  components: {
    SelectField,
  },
  computed: {
    ...mapGetters(["officialList"]),
    // 排序处理
    sortableType() {
      return function (value) {
        if (value.autoFields === 0) {
          return false;
        } else if (value.autoFields === 2 || value.autoFields === 4) {
          return true;
        } else if (
          value.autoFields === 1 ||
          value.autoFields === 3 ||
          value.autoFields === 5
        ) {
          return "custom";
        }
      };
    },
    // 文字对齐处理
    filAlign() {
      return function (value) {
        switch (value.fontAlign) {
          case 0:
            return "left";
          case 1:
            return "center";
          case 2:
            return "right";
          default:
            return "center";
        }
      };
    },
  },
  watch: {
    lastParams: function () {
      this.getData("drawer");
    },
  },
  filters: {
    sortField: function (value, item) {
      if (item.autoFields === 4 || item.autoFields === 5) {
        // 报错处理
        if (value !== undefined) {
          return formatThousandsDots(value.toString());
        }
        // return formatThousandsDots(value.toString());
      } else {
        return value;
      }
    },
  },
  data() {
    return {
      params: {},
      bookList: null,
      fieldsData: [],
      originFeild: [],
      selectFields: null,
      selectAllFields: false,
      dataList: [],
      totalData: [],
      loading: false,
      sortName: null,
      sortDesc: null,
      showRecharge: false,
      yesterdayOptions,
      labelList,
      sendField: [],
      showField: [],
      tmpShowField: [],
      showCommonDrawer: false,
      showTemplate: null,
      nextParams: {},
      pickerOptions,
      platformList,
      queryDate: "",
      page: 1,
      totalPage: 0,
      pageSize: 0,
    };
  },
  methods: {
    // 表格点击事件
    handleCellClick(row, column) {
      // 月度详情
      if (column.label === "公众号名称") {
        this.nextParams = {
          // beginDate: begin,
          // endDate: end,
          queryDate: this.params.queryDate,
          accountChannelId: row.accountChannelId,
        };
        this.showTemplate = "monthDetail";
        this.showCommonDrawer = true;
      }
    },
    ...mapActions({
      addRouterHistory: "addRouterHistory",
    }),
    // 排序处理
    handleSortChange({ column, prop, order }) {
      if (column.sortable !== "custom") {
        return;
      }
      this.sortName = prop;
      this.sortDesc = order === "descending";
      this.params.sortName = this.sortName;
      this.params.sortDesc = this.sortDesc;
      this.handlePageChange(1);
    },
    // 重置操作
    handleReset() {
      this.params.queryDate = formatTime(new Date(), "yyyy-MM");
      this.params.accountChannelId = null;
      this.page = 1;
      this.sendField = [];
      this.showField = this.originFeild;
      this.tmpShowField = [];
      this.handlePageChange(1);
    },
    // 分页操作
    handlePageChange(page) {
      this.page = page;
      this.getData();
    },
    // 选择字段变化
    handleFieldChange(send, show) {
      // if (value.length === 0) {
      //   this.showField = this.originFeild;
      // } else {
      //   this.showField = value;
      // }
      // 请求格式field
      this.sendField = send;
      // 展示格式field
      this.tmpShowField = show;
    },
    // 请求前处理字段
    handleFields(item) {
      if (item.length === 0) {
        return null;
      }
      let tmpSend = [];
      const _that = this;
      tmpSend = item.reduce(function (a, b) {
        return a.concat(b);
      });
      // 判断勾选项 推入其下所有子级
      for (let i = 0; i < tmpSend.length; i++) {
        const flagIndex = _that.originFeild.findIndex((item2) => {
          return item2.fieldCode === tmpSend[i];
        });
        if (flagIndex !== -1 && "children" in _that.originFeild[flagIndex]) {
          _that.originFeild[flagIndex].children.forEach((item3) => {
            tmpSend.push(item3.fieldCode);
          });
        }
      }
      // 去重
      tmpSend = Array.from(new Set(tmpSend));
      return tmpSend.join(",");
    },
    // 广告数据获取
    getData(flag) {
      const tody = formatTime(new Date(), "yyyy-MM");
      if (!this.params.queryDate) {
        this.$set(this.params, "queryDate", tody);
      }
      this.queryDate = this.params.queryDate;
      this.loading = true;
      let tmpFields = null;
      if (this.sendField.length > 0) {
        tmpFields = this.handleFields(this.sendField);
      } else {
        this.showField = this.originFeild;
      }
      let tmpform = {};
      // 如果是创建过程 参数默认取缓存参数 否则使用筛选参数
      if (flag === "created") {
        tmpform = {
          page: this.page,
          queryDate: this.params.queryDate,
          accountChannelId: this.params.accountChannelId,
          showFieldCodes: tmpFields,
        };
      } else if (flag === "drawer") {
        tmpform = {
          ...this.lastParams,
          page: this.page,
        };

        this.params = { ...this.lastParams };
      } else {
        tmpform = {
          page: this.page,
          queryDate: this.params.queryDate,
          accountChannelId: this.params.accountChannelId,
          showFieldCodes: tmpFields,
        };
      }
      getMultiMonth(tmpform)
        .then((res) => {
          this.totalData = res.showSumFields;
          // 如果用户选择过字段则表格展示用户选择字段
          if (this.tmpShowField.length > 0) {
            this.showField = this.tmpShowField;
          }
          this.dataList = res;
          this.dataList = res.list;
          this.totalPage = res.total;
          this.pageSize = res.pageSize;
          // 如果不是抽屉 缓存参数
          if (flag !== "drawer") {
            this.addRouterHistory({
              path: this.$route.path,
              params: {
                ...tmpform,
                cacheShowField: this.showField,
                cacheSendField: this.sendField,
              },
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    // 获取字段
    handleGetFeilds(flag = "created") {
      getFields({ dataType: "monthStatistics" }).then((res) => {
        this.originFeild = res;
        const tmpdata = [];
        for (let i = 0; i < res.length; i++) {
          tmpdata.push({
            fieldName: res[i].fieldName,
            fieldCode: res[i].fieldCode,
          });
        }
        this.fieldsData = tmpdata;
        if (this.showField.length === 0) {
          this.showField = res;
        }
        this.selectAllFields = true;
        this.getData(flag);
        // 判断字段所选长度是否为0 是则全选 否则不全选
      });
    },
    // 处理字段
  },
  created() {
    if (this.lastParams) {
      this.handleGetFeilds("drawer");
      return false;
    }
    // 缓存获取
    const tmpIndex = this.$store.state.routerHistory.findIndex(
      (item) => item.path === this.$route.path
    );
    // 若存在缓存
    if (tmpIndex !== -1) {
      this.params = this.$store.state.routerHistory[tmpIndex].params;
      // 获取缓存展示字段
      this.showField =
        this.$store.state.routerHistory[tmpIndex].params.cacheShowField;
      // 获取缓存发送前格式字段
      this.sendField =
        this.$store.state.routerHistory[tmpIndex].params.cacheSendField;
    }
    // this.params.queryDate = formatTime(new Date(), "yyyy-MM-dd");
    this.handleGetFeilds();
    // this.getData()
  },
  beforeCreate() {
    this.$options.components.CommonDrawer =
      require("@/components/commonDrawer").default;
  },
};
</script>

<style lang="scss" scoped>
.ad-consume {
  background-color: #fff;
  border-radius: 6px;
}
// 筛选条件框
.filter-box {
  border-radius: 6px;
  // border: 1px solid #eceff2;
  .filter-title {
    color: $main-text;
    margin-bottom: 20px;
    .iconfont {
      font-size: 12px !important;
    }
  }
}
/deep/.el-calendar__header {
  display: none;
}
/deep/.el-calendar__body {
  height: 690px;
  overflow: auto;
  padding: 10px;
}
// /deep/ .el-calendar-table {
//   width: 900px !important;
// }
/deep/ .el-calendar-table .el-calendar-day {
  height: 100px;
}
.detail-page /deep/.el-calendar__body {
  width: 100% !important;
}
</style>
