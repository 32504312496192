import request from './config'
import * as qiniu from 'qiniu-js'
import { formatTime } from '../assets/js/utils'
import store from '@/store'
export function uploadFile({ file, classify = 'novel_put' }) {
  const type = file.type.split('/')[1]
  const Time = formatTime(Date.now(), 'yyyyMMdd')
  const key = Time + (Math.random() * (99999 - 10000 + 1) + 10000).toFixed()
  return new Promise((resolve, reject) => {
    request.get('/token').then(res => {
      qiniu.upload(file, `${classify}/${key}.${type}`, res, {
        fname: '',
        params: {},
        mimeType: ''
      },
        { useCdnDomain: true, region: qiniu.region.z2 })
        .subscribe({
          error(err) {
            reject(err.message)
          },
          next({ total }) {
            store.commit('setUploadAudioPercent', Number(total.percent.toFixed(2)))
          },
          complete(res) {
            resolve(`https://file.gaohaiwl.com/${res.key}`)
          }
        })
    }).catch(err => {
      reject(err.message)
    })
  })
}

export function customFileGetToken() {
  return new Promise((resolve) => {
    request.get("/token").then((res) => {
      resolve(res)
    });

  });
}

// base64格式图片上传七牛云
export function customFile(uptoken, imgbase64) {
  return new Promise((resolve) => {
    let token = uptoken;
    let url = "http://up-z2.qiniup.com/putb64/-1";
    var xhr = new XMLHttpRequest();
    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4) {
        resolve(`https://file.gaohaiwl.com/${JSON.parse(xhr.responseText).key}`)
      }
    };
    xhr.open("POST", url, true);
    xhr.setRequestHeader("Content-Type", "application/octet-stream");
    xhr.setRequestHeader("Authorization", `UpToken ${token}`);
    xhr.send(imgbase64);

  });
}

/* *
 * 将以base64的图片url数据转换为Blob
 * @param urlData
 *            用url方式表示的base64图片数据
  */
export function convertBase64UrlToBlob(urlData) {

  var bytes = window.atob(urlData);         // 去掉url的头，并转换为byte

  // 处理异常,将ascii码小于0的转换为大于0
  var ab = new ArrayBuffer(bytes.length);
  var ia = new Uint8Array(ab);
  for (var i = 0; i < bytes.length; i++) {
    ia[i] = bytes.charCodeAt(i);
  }

  return new Blob([ab], { type: 'image/png' });
}

export function uploadFilebyToken(file, token, classify = 'novel_put') {
  const type = file.type.split('/')[1]
  const Time = formatTime(Date.now(), 'yyyyMMdd')
  const key = Time + (Math.random() * (99999 - 10000 + 1) + 10000).toFixed()
  return new Promise((resolve, reject) => {
    qiniu.upload(file, `${classify}/${key}.${type}`, token, {
      fname: '',
      params: {},
      mimeType: ['image/png', 'image/jpeg', 'audio/mpeg', 'audio/ogg', 'audio/x-m4a'] || null
    },
      { useCdnDomain: true, region: qiniu.region.z2 })
      .subscribe({
        error(err) {
          reject(err.message)
        },
        next({ total }) {
          store.commit('setUploadAudioPercent', total.percent)
        },
        complete(token) {
          resolve(`https://file.gaohaiwl.com/${token.key}`)
        }
      })
  })
}
