<template>
  <el-drawer size="600px" append-to-body :visible.sync="show" :before-close="handleClose">
    <div slot="title">
      <span style="margin-right:20px;cursor: pointer; padding-bottom:10px;"
            :class="{'is-active':accountType === 'novel'}" @click="accountType = 'novel'">书城账号列表</span>
      <span @click="accountType = 'ocean'" style="cursor: pointer;padding-bottom:10px"
            :class="{'is-active':accountType === 'ocean'}">巨量引擎账号</span>
    </div>
    <div class="body">
      <template v-if="accountType === 'novel'">
        <div class="row">
          <el-button @click="getList" style="margin-right: 10px">刷新数据</el-button>
          <el-select
            clearable
            placeholder="平台"
            v-model="platform"
            style="width: 120px; margin-right: 10px"
            :disabled="canDisabled"
            @change="handlePageChange(1)"
          >
            <el-option
              v-for="(item, index) in platformList"
              :label="item"
              :value="index"
              :key="index"
            ></el-option>
          </el-select>
          <el-input
            placeholder="公众号"
            v-model="channelName"
            class="input-with-select"
            :disabled="canDisabled"
            clearable
            @keydown.native.enter="handlePageChange(1)"
          >
            <el-button slot="append" icon="el-icon-search" @click="handlePageChange(1)"></el-button>
          </el-input>
        </div>
        <el-table
          v-loading="loading"
          :data="accountList"
          height="68vh"
          style="width: 100%; margin-top: 20px"
        >
          <el-table-column prop="channelName" min-width="80" label="公众号"></el-table-column>
          <el-table-column min-width="80" prop="platform" label="平台">
            <template slot-scope="scope">
              <span>{{ platformList[scope.row.platform] }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="100px">
            <template slot-scope="scope">
              <el-button
                type="primary"
                class="novel-login button-small"
                :datatype="scope.row.platform"
                :dataId="scope.row.id"
              >进入系统
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          style="margin: 20px 0; text-align: right"
          background
          @current-change="handlePageChange"
          :current-page="page"
          :page-size="15"
          layout="total, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </template>
      <template v-else-if="accountType === 'ocean'">
        <div class="ocean-account-list">
          <dy-table :dataList="getFilterList()" :loading='oceanLoading' :rowList="rowList" height="75vh">
            <template #filter>
              <el-button @click="refreshData">刷新数据</el-button>
              <c-input v-model="search" placeholder="请输入账号名或备注!" style='width:300px;margin-left:10px'></c-input>
            </template>
            <template #operation="{ row }">
              <el-button class="button-small" type="success" @click="JumpTargetSystem(row)">巨量引擎</el-button>
              <el-button class="button-small" type="warning" @click="JumpFanQieSystem(row)">番茄小说</el-button>
            </template>
          </dy-table>
        </div>
      </template>
    </div>
  </el-drawer>
</template>

<script>
import {getLoginAccount, getCurrentUserBindOeAccountList} from '../api/account'
import {platformList} from '../assets/js/options'
import {mapGetters} from 'vuex'
import {isInstallChromeExtension} from '@/assets/js/utils'

export default {
  name: 'cacheList',
  props: {
    show: Boolean,
    type: Number,
    defaultPlatformId: {
      default: null
    },
    defaultChannelName: {
      type: String,
      default: () => ''
    },
    canDisabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      accountList: [],
      loading: false,
      search: '',
      page: 1,
      total: 0,
      oceanLoading: false,
      rowList: [{
        label: 'ID',
        prop: 'id',
        colWidth: '100px'
      }, {
        label: '账户名',
        prop: 'userName'
      }, {
        label: '备注',
        prop: 'remark'
      }, {
        label: '操作',
        action: 'operation',
        colWidth: '200'
      }],
      platformList,
      platform: null,
      userName: null,
      channelName: null,
      accountType: 'novel',
      oceanAccountList: [],
      extendId: process.env.VUE_APP_BASE_extendId
    }
  },
  watch: {
    show(newVal) {
      if (newVal) {
        if (this.defaultPlatformId !== undefined || this.defaultPlatformId !== null) {
          this.platform = this.defaultPlatformId
        }
        if (this.defaultChannelName) {
          this.channelName = this.defaultChannelName
        }
        this.handlePageChange(1)
      }
    },
    accountType: {
      handler(newV) {
        if (newV === 'ocean') {
          this.getOceanAccountList()
        }
      }
    }
  },
  computed: {
    ...mapGetters(['userInfo'])
  },
  methods: {
    handlePageChange(page) {
      this.page = page
      this.getList()
    },
    getList() {
      this.loading = true
      getLoginAccount({
        page: this.page,
        pageSize: 15,
        platform: this.platform,
        userName: this.userName,
        channelName: this.channelName
      })
        .then((res) => {
          this.accountList = res.list
          this.total = res.total
        })
        .finally(() => {
          this.loading = false
        })
    },
    getOceanAccountList() {
      this.oceanLoading = true
      getCurrentUserBindOeAccountList().then(res => {
        this.oceanAccountList = res
      }).finally(() => {
        this.oceanLoading = false
      })
    },
    handleClose() {
      this.platform = null
      this.channelName = null
      this.$emit('close')
    },
    refreshData() {
      this.getOceanAccountList()
    },
    getFilterList() {
      return this.search ? this.oceanAccountList.filter(item => (item.userName.includes(this.search)) || (item.remark ? item.remark.includes(this.search) : false)) : this.oceanAccountList
    },
    JumpTargetSystem(row) {
      if (!isInstallChromeExtension()) return
      chrome.runtime.sendMessage(this.extendId, {
        novelapi: 'clearCookie',
        url: 'https://business.oceanengine.com/'
      }, (data) => {
        try {
          data = JSON.parse(data)
          if (data.code === 200) {
            // ! 将该账号的数据传递到插件的localStorage进行存储
            chrome.runtime.sendMessage(this.extendId, {
              OceanType: 'LoginAccountInfo',
              type: 'set',
              value: row
            }, () => {
              window.open('https://business.oceanengine.com/site/login', '_blank')
            })
          } else {
            this.$message.error('清除cookie失败!')
          }
        } catch (e) {
          console.log(e)
          this.$message.error('清除cookie失败!')
        }
      })
    },
    JumpFanQieSystem(row) {
      if (!isInstallChromeExtension()) return
      chrome.runtime.sendMessage(this.extendId, {
        novelapi: 'clearCookie',
        url: 'https://www.changdunovel.com'
      }, (data) => {
        try {
          data = JSON.parse(data)
          if (data.code === 200) {
            // ! 将该账号的数据传递到插件的localStorage进行存储
            chrome.runtime.sendMessage(this.extendId, {
              OceanType: 'LoginAccountInfo',
              type: 'set',
              value: row
            }, () => {
              window.open('https://www.changdunovel.com/sale/login', '_blank')
            })
          } else {
            this.$message.error('清除cookie失败!')
          }
        } catch (e) {
          console.log(e)
          this.$message.error('清除cookie失败!')
        }
      })
    }

  }
}
</script>

<style scoped>
.body {
  padding: 20px;
}

.input-with-select {
  width: 200px;
  margin-right: 10px;
}

.is-active {
  border-bottom: 2px solid #00bf8a;
}
</style>
