<template>
  <!-- 二次封装el-table 减少了诸如el-table-column类似代码的编写 -->
  <div class="dynamic-table">
    <div class="filter-list">
      <slot name="filter"></slot>
    </div>
    <el-table
      :ref="refName"
      v-loading="loading"
      style="width: 100%"
      :data="dataList"
      :height="height"
      :border="border"
      v-on="$listeners"
      v-bind="$attrs"
      :span-method="objectSpanMethod"
      @selection-change="handleSelectionChange"
      @select="handleSelect"
      @row-click="handleRowClick"
    >
      <el-table-column
        v-if="isUseCheckbox"
        type="selection"
        width="55"
      ></el-table-column>
      <dy-column
        v-bind="$attrs"
        v-on="$listeners"
        v-for="(item, index) in rowList"
        :key="index"
        :column="item"
      >
        <template v-slot:operation="{ row }">
          <slot name="operation" :row="row"></slot>
        </template>
        <template v-slot:dyoperation="{ row }">
          <slot name="dyoperation" :row="row"></slot>
        </template>
        <template v-slot:[item.prop]="{ row }" v-if="item.slot">
          <slot :name="item.prop" :row="row"></slot>
        </template>
      </dy-column>
    </el-table>
    <el-pagination
      style="margin-top: 10px; text-align: right"
      background
      layout="total, prev, pager, next, jumper"
      @current-change="handlePageChange"
      :current-page="page"
      :page-size="pageSize"
      :total="total"
      v-if="showPagination"
    >
    </el-pagination>
  </div>
</template>

<script>
import DyColumn from './dynamic-column.vue'
import _ from 'lodash'
export default {
  name: 'DyTable',
  props: {
    loading: {
      type: Boolean,
      default: false
    }, // 是否显示加载动画
    refName: String,
    dataList: {
      type: Array,
      default: () => {
        []
      }
    },
    total: Number,
    page: Number,
    pageSize: Number,
    height: String,
    border: Boolean,
    objectSpanMethod: {
      type: Function
    },
    rowList: {
      type: Array,
      default: () => {
        []
      }
    },
    isUseCheckbox: Boolean,
    showPagination: {
      type: Boolean,
      default: false
    },
    refName: {
      type: String,
      default: 'dyTable'
    },
  },
  data () {
    return {}
  },
  watch: {},
  methods: {
    handleSelect (val) {
      this.$emit('selected', val)
    },
    handleSelectionChange (val) {
      this.$emit('selectionChange', val)
    },
    arraySpanMethod () {},
    handlePageChange (page) {
      this.$emit('pageChange', page)
    },
    handleConfirm (cb, row) {},
    handleDelete (cb, row) {},
    handleRowClick (row, column) {
      this.$emit('handleRowClick', row, column)
    },
  },
  components: {
    DyColumn
  }
}
</script>
<style lang='scss' scoped>
.filter-list {
  margin-bottom: 20px;
}
</style>
