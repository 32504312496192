import Vue from 'vue'
import CButton from '@/components/customButton.vue'
import CInput from '@/components/custom-input.vue'
import TInput from '@/components/tinytInput.vue'
import DyTable from '@/components/dynamic-table/dynamic-table.vue'
import CImage from '@/components/custom/custom-image.vue'
import CommonDrawer from "@/components/commonDrawer";

let globalComponent = [CButton, CInput, DyTable, CImage, TInput, CommonDrawer]

globalComponent.forEach(component => {
    Vue.component(component.name, component)
})