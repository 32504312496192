export default {
  AD_STATUS_DELIVERY_OK: "投放中",
  AD_STATUS_DISABLE: "计划暂停",
  AD_STATUS_AUDIT: "新建审核中",
  AD_STATUS_REAUDIT: "修改审核中",
  AD_STATUS_DONE: "已完成（投放达到结束时间)",
  AD_STATUS_CREATE: "计划新建",
  AD_STATUS_AUDIT_DENY: "审核不通过",
  AD_STATUS_BALANCE_EXCEED: "账户余额不足",
  AD_STATUS_BUDGET_EXCEED: "超出预算",
  AD_STATUS_NOT_START: "未到达投放时间",
  AD_STATUS_NO_SCHEDULE: "不在投放时段",
  AD_STATUS_CAMPAIGN_DISABLE: "已被广告组暂停",
  AD_STATUS_CAMPAIGN_EXCEED: "广告组超出预算",
  AD_STATUS_DELETE: "已删除",
  AD_STATUS_ALL: "所有包含已删除",
  AD_STATUS_NOT_DELETE: "所有不包含已删除（状态过滤默认值）",
  AD_STATUS_ADVERTISER_BUDGET_EXCEED: "超出广告主日预算",
  AD_STATUS_LIVE_CANNOT_LANUCH: "关联直播间不可投放, 该枚举值仅用作广告计划投放状态展示，不可在状态过滤时使用",
  AD_STATUS_CAMPAIGN_PRE_OFFLINE_BUDGET: "广告组接近预算, 该枚举值仅用作广告计划投放状态展示，不可在状态过滤时使用",
  AD_STATUS_PRE_OFFLINE_BUDGET: "广告接近预算, 该枚举值仅用作广告计划投放状态展示，不可在状态过滤时使用",
  AD_STATUS_PRE_ONLINE: "预上线, 该枚举值仅用作广告计划投放状态展示，不可在状态过滤时使用",
  AD_STATUS_DSP_AD_DISABLE: "已被广告计划暂停, 该枚举值仅用作广告计划投放状态展示，不可在状态过滤时使用",
  AD_STATUS_AUDIT_STATUS_ERROR: "异常，请联系审核人员, 该枚举值仅用作广告计划投放状态展示，不可在状态过滤时使用",
  AD_STATUS_DRAFT: "草稿, 该枚举值仅用作广告计划投放状态展示，不可在状态过滤时使用",
  AD_STATUS_ADVERTISER_PRE_OFFLINE_BUDGET: "账户接近预算, 该枚举值仅用作广告计划投放状态展示，不可在状态过滤时使用",
  AD_STATUS_CANNOT_EDIT: "不可编辑, 该枚举值仅用作广告计划投放状态展示，不可在状态过滤时使用",
  AD_STATUS_EXTERNAL_URL_DISABLE: "落地页暂不可用, 该枚举值仅用作广告计划投放状态展示，不可在状态过滤时使用",
  AD_STATUS_LIVE_ROOM_OFF: "关联直播间未开播, 该枚举值仅用作广告计划投放状态展示，不可在状态过滤时使用",
  AD_STATUS_AWEME_ACCOUNT_PUNISHED: "关联抖音账号封禁不可投放, 该枚举值仅用作广告计划投放状态展示，不可在状态过滤时使用",
  AD_STATUS_AWEME_ACCOUNT_DISABLED: "关联抖音号不可投, 该枚举值仅用作广告计划投放状态展示，不可在状态过滤时使用",
  AD_STATUS_PRODUCT_OFFLINE: "关联商品不可投, 该枚举值仅用作广告计划投放状态展示，不可在状态过滤时使用",
}