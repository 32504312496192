<template>
  <div class="ad-consume padding-20 box-shadow-light-grey">
    <!-- 条件搜索 -->
    <div class="filter-box">
      <!-- <h4 class="filter-title">今日广告</h4> -->
      <div class="row">
        <exclude-query :params="params" />
        <el-date-picker
          v-model="params.selectTime"
          style="width: 330px"
          type="daterange"
          align="right"
          unlink-panels
          :clearable="false"
          range-separator="-"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="yesterdayOptions"
        >
        </el-date-picker>
        <el-date-picker
          v-model="params.queryRechargeDate"
          type="date"
          style="width: 150px"
          value-format="yyyy-MM-dd"
          :picker-options="yesterdayOptions"
          placeholder="充值截至日期"
        >
        </el-date-picker>
        <el-select
          v-model="params.labelTypes"
          clearable
          collapse-tags
          multiple
          placeholder="标签类型"
          style="width: 170px"
        >
          <el-option
            v-for="(item, index) in labelListShow"
            :key="index"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <select-platform-channel :params="params" data-type="obj" />
        <el-select
          v-model="params.booksId"
          filterable
          remote
          clearable
          style="width: 150px"
          reserve-keyword
          placeholder="书籍名称"
          :remote-method="getBook"
        >
          <el-option
            v-for="item in bookList"
            :key="item.id"
            :label="item.bookName"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <select-dept-user
          :params="params"
          @userChange="(name) => (this.userName = name)"
          @deptChange="(dept) => (this.deptName = dept)"
        />
        <el-select
          v-model="params.isTest"
          clearable
          placeholder="类型"
          style="width: 100px"
        >
          <el-option label="普通号" :value="0"></el-option>
          <el-option label="测书号" :value="1"></el-option>
        </el-select>
        <el-input
          placeholder="回本率"
          v-model="params.returnRate"
          type="number"
          style="width: 170px"
          clearable
        >
          <el-select
            v-model="params.comperingType"
            slot="prepend"
            style="width: 75px"
            clearable
            placeholder="比较"
          >
            <el-option label="无" :value="0"></el-option>
            <el-option label="<=" :value="1"></el-option>
            <el-option label=">=" :value="2"></el-option>
          </el-select>
        </el-input>
        <!-- 字段选择 -->
        <!-- <el-cascader
          :options="fieldsData"
          placeholder="选择展示字段"
          :props="{
            multiple: true,
            label: 'fieldName',
            value: 'fieldCode',
            checkStrictly: true,
          }"
          v-model="selectFields"
          collapse-tags
          clearable
          @change="handleChangeFields"
        >
        </el-cascader>
        <el-checkbox
          style="margin-left: 10px"
          v-model="selectAllFields"
          @change="handleCheckAllFields"
          >全选</el-checkbox
        > -->
        <select-field
          :fieldsData="fieldsData"
          :originFeild="originFeild"
          :EchoField="sendField"
          @fieldChange="handleFieldChange"
        />
        <el-button
          type="primary"
          @click="handlePageChange(1)"
          style="margin-left: 10px"
          :loading="loading"
        >
          搜索
        </el-button>
        <el-button type="info" @click="handleReset" :loading="loading">
          重置
        </el-button>
        <el-button @click="showCacheList = true" type="primary"
          >缓存列表</el-button
        >
      </div>
    </div>
    <!-- 汇总数据 -->
    <div>
      <div
        class="statistics-card"
        v-for="(item, index) in totalData"
        :key="index"
      >
        <div class="data-label">{{ item.label }}</div>
        <div class="statistic-num-text">
          <span class="statistic-num">{{ item.value || 0 }}</span>
        </div>
      </div>
    </div>
    <!-- 表格数据 -->
    <el-table
      header-row-class-name="table-header"
      ref="userConsumeTable"
      class="consume-table"
      :data="dataList"
      style="width: 100%"
      :height="lastParams ? '640px' : '570px'"
      v-loading="loading"
      empty-text="暂无数据"
      @sort-change="handleSortChange"
      @cell-click="handleCellClick"
    >
      <el-table-column
        v-for="(item, index) in showField"
        :key="index"
        :prop="item.fieldCode"
        :label="item.fieldName"
        :sortable="sortableType(item)"
        :align="filAlign(item)"
        :min-width="item.width + 'px' || '100px'"
      >
        <template slot-scope="scope">
          <div v-if="item.hasChild">
            <div v-for="(item2, index2) in item.children" :key="index2">
              {{ item2.fieldName }}:
              <span :style="'color:' + item.fontColor">{{
                scope.row[item2.fieldCode]
              }}</span>
            </div>
          </div>
          <span
            :class="item.fieldCode === 'channelName' ? 'drawer-text' : ''"
            :style="'color:' + item.fontColor"
            v-else
            >{{ scope.row[item.fieldCode] | sortField(item) }}</span
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 公共抽屉 -->
    <common-drawer
      :showDrawer="showCommonDrawer"
      size="90%"
      @close="showCommonDrawer = false"
      :template="showTemplate"
      :nextParams="nextParams"
    />
    <!-- 缓存列表 -->
    <cache-list
      :type="2"
      :show="showCacheList"
      @close="showCacheList = false"
      @select="handleSearch"
    />
  </div>
</template>

<script>
import { formatThousandsDots, getRecentlyWeek } from "@/assets/js/utils";
import ExcludeQuery from "@/components/excludeQuery";
import SelectDeptUser from "@/components/SelectDeptUser";
import SelectField from "@/components/SelectField";
import CacheList from "../components/cacheList";
import SelectPlatformChannel from "@/components/SelectPlatformChannel";
import { getBookList } from "@/api/account";
import {
  getIntervalChannel,
  getFields,
  getCacheList,
  cacheData,
  getCacheData,
} from "@/api/statics";
import {
  yesterdayOptions,
  labelList,
  pickerOptions,
  platformList,
} from "@/assets/js/options";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "intervalChannel",
  props: {
    lastParams: Object,
  },
  components: {
    ExcludeQuery,
    SelectDeptUser,
    SelectPlatformChannel,
    SelectField,
    CacheList,
  },
  computed: {
    labelListShow() {
      return this.labelList.filter((item) => {
        return item.show;
      });
    },
    // 排序处理
    sortableType() {
      return function (value) {
        if (value.autoFields === 0) {
          return false;
        } else if (value.autoFields === 2 || value.autoFields === 4) {
          return true;
        } else if (value.autoFields === 1 || value.autoFields === 3) {
          return "custom";
        }
      };
    },
    // 文字对齐处理
    filAlign() {
      return function (value) {
        switch (value.fontAlign) {
          case 0:
            return "left";
          case 1:
            return "center";
          case 2:
            return "right";
          default:
            return "center";
        }
      };
    },
    // 缓存参数处理
    queryParams() {
      const {
        selectTime,
        queryRechargeDate,
        originPlatform,
        labelTypes,
        currentOfficial,
        deptId,
        adminUserId,
        booksId,
        ruledOutBooksIds,
        ruledOutAccountChannelIds,
        isTest,
        comperingType,
        returnRate,
        ruledOutVip,
        dayType,
      } = this.params;
      const params = [
        {
          label: "开始时间",
          value: selectTime[0],
          key: "beginDate",
          text: selectTime[0],
        },
        {
          label: "结束时间",
          value: selectTime[1],
          key: "endDate",
          text: selectTime[1],
        },
      ];
      if (typeof originPlatform === "number") {
        params.push({
          label: "平台",
          text: this.platformList[originPlatform],
          key: "originPlatform",
          value: originPlatform,
        });
      }
      if (labelTypes.length) {
        let tempText = "";
        labelTypes.forEach((item) => {
          tempText += this.labelList[item].label + " ";
        });
        params.push({
          label: "标签类型",
          // text: this.labelList[labelType].label,
          text: tempText,
          key: "labelTypes",
          value: labelTypes.join(","),
        });
      }
      if (currentOfficial) {
        params.push({
          label: "公众号",
          text: currentOfficial.channelName,
          key: "accountChannelId",
          value: currentOfficial.id,
        });
      }
      if (deptId) {
        params.push({
          label: "部门",
          text: this.deptName,
          key: "deptId",
          value: deptId,
        });
      }
      if (adminUserId) {
        params.push({
          label: "用户",
          text: this.userName,
          key: "adminUserId",
          value: adminUserId,
        });
      }
      if (booksId) {
        const book = this.bookList.find((item) => item.id === booksId);
        params.push({
          label: "书籍",
          text: book ? book.bookName : null,
          key: "booksId",
          value: booksId,
        });
      }
      if (typeof isTest === "number") {
        params.push({
          label: "类型",
          text: isTest ? "测书号" : "普通号",
          key: "isTest",
          value: isTest,
        });
      }
      if (ruledOutBooksIds && ruledOutBooksIds.length !== 0) {
        params.push({
          label: "排除书籍",
          text: ruledOutBooksIds.length
            ? ruledOutBooksIds.map((item) => item.bookName).join()
            : null,
          key: "ruledOutBooksIds",
          value: ruledOutBooksIds.length
            ? ruledOutBooksIds.map((item) => item.id).join()
            : null,
        });
      }
      if (ruledOutAccountChannelIds && ruledOutAccountChannelIds.length !== 0) {
        params.push({
          label: "排除公众号",
          text: ruledOutAccountChannelIds.length
            ? ruledOutAccountChannelIds
                .map((id) => {
                  const channel = this.officialList.find(
                    (item) => item.id === id
                  );
                  return channel ? channel.channelName : "";
                })
                .join()
            : null,
          key: "ruledOutAccountChannelIds",
          value: ruledOutAccountChannelIds.length
            ? ruledOutAccountChannelIds.join()
            : null,
        });
      }
      if (comperingType && returnRate) {
        params.push({
          label: "规则",
          text: comperingType === 1 ? "小于等于" : "大于等于",
          key: "comperingType",
          value: comperingType,
        });
        params.push({
          label: "回本率",
          text: returnRate + "%",
          key: "returnRate",
          value: returnRate,
        });
      }
      if (typeof dayType === "number") {
        params.push({
          label: "统计类型",
          text: dayType ? "24小时统计" : "自然日统计",
          key: "dayType",
          value: dayType,
        });
      }
      if (typeof ruledOutVip === "number") {
        params.push({
          label: "排除vip",
          text: ruledOutVip ? "是" : "否",
          key: "ruledOutVip",
          value: ruledOutVip,
        });
      }
      if (queryRechargeDate) {
        params.push({
          label: "充值截止日期",
          text: queryRechargeDate,
          key: "queryRechargeDate",
          value: queryRechargeDate,
        });
      }
      return params;
    },
    ...mapGetters(["officialList"]),
  },
  watch: {
    lastParams: function () {
      this.getData("drawer");
    },
  },
  filters: {
    sortField: function (value, item) {
      if (item.autoFields === 4 || item.autoFields === 5) {
        // 报错处理
        if (value !== undefined) {
          return formatThousandsDots(value.toString());
        }
        // return formatThousandsDots(value.toString());
      } else {
        return value;
      }
    },
  },
  data() {
    return {
      params: {},
      bookList: null,
      fieldsData: [],
      originFeild: [],
      selectFields: null,
      selectAllFields: false,
      dataList: [],
      totalData: [],
      loading: false,
      sortName: null,
      sortDesc: null,
      showRecharge: false,
      yesterdayOptions,
      labelList,
      sendField: [],
      showField: [],
      tmpShowField: [],
      showCommonDrawer: false,
      showTemplate: null,
      nextParams: {},
      pickerOptions,
      showCacheList: false,
      cachePage: 1,
      cacheDataTotal: 0,
      hasPage: false,
      platformList,
    };
  },
  methods: {
    // 表格点击事件
    handleCellClick(row, column) {
      if (column.label !== "公众号") {
        return false;
      }
      this.showTemplate = "platformStatics";
      this.showCommonDrawer = true;
      this.nextParams = {
        selectTime: this.params.selectTime,
        beginDate: this.params.selectTime[0],
        endDate: this.params.selectTime[1],
        ...this.params,
        showFieldCodes: null,
        cacheSendField: null,
        cacheShowField: null,
        accountChannelId: row.accountChannelId,
      };
    },
    // 缓存相关操作
    handleSearch(key) {
      this.cachePage = 1;
      this.handleDateClick(key);
    },
    // 存储缓存
    handleDataSave() {
      const tmpParam = this.formatQuery();
      cacheData({
        ...tmpParam,
        param: JSON.stringify(this.queryParams),
        dataType: 2,
      }).then(() => {
        this.$message.success("操作成功，数据缓存中");
        if (!this.lastParams) {
          this.addRouterHistory({
            path: this.$route.path,
            params: {
              ...tmpParam,
              currentOfficial: this.params.currentOfficial,
              selectTime: this.params.selectTime,
              cacheShowField: this.showField,
              cacheSendField: this.sendField,
            },
          });
        }
      });
    },
    // 查看缓存
    handleDateClick(key) {
      if (key) {
        this.showKey = key;
        this.loading = true;
        // 回显参数
        if (key) {
          this.handleReset(false);
          const query = JSON.parse(key);
          this.$set(this.params, "selectTime", [
            query[0].value,
            query[1].value,
          ]);
          const temp = query.slice(2);
          temp.forEach((item) => {
            if (item.key === "booksId") {
              const { bookList } = this;
              const temp = bookList.find((book) => book.id === item.value);
              if (!temp) {
                getBookList({ id: item.value }).then((res) => {
                  this.bookList = bookList.concat(res.list);
                });
              }
              this.$set(this.params, "booksId", item.value);
            } else if (item.key === "ruledOutBooksIds") {
              const bookIds = item.value.split(",");
              const bookNames = item.text.split(",");
              this.$set(
                this.params,
                "ruledOutBooksIds",
                bookIds.map((book, index) => {
                  return {
                    id: Number(book),
                    bookName: bookNames[index],
                  };
                })
              );
            } else if (item.key === "ruledOutAccountChannelIds") {
              this.$set(
                this.params,
                "ruledOutAccountChannelIds",
                item.value.split(",").map((id) => {
                  return Number(id);
                })
              );
            } else if (item.key === "accountChannelId") {
              this.$set(this.params, "currentOfficial", {
                id: item.value,
                channelName: item.text,
              });
            } else if (item.key === "labelTypes") {
              let tmparr = item.value.split(",").map(Number);
              this.$set(this.params, "labelTypes", tmparr);
            } else {
              this.$set(this.params, item.key, item.value);
            }
          });
        }
        getCacheData({
          param: key,
          dataType: 2,
          page: this.cachePage,
        })
          .then((res) => {
            this.hasPage = true;
            // 如果用户选择过字段则表格展示用户选择字段
            if (this.tmpShowField.length > 0) {
              this.showField = this.tmpShowField;
            }
            this.dataList = res.channelList;
            this.totalData = res.showSumFields;
            if (!this.lastParams) {
              this.addRouterHistory({
                path: this.$route.path,
                params: {
                  ...this.formatQuery(),
                  selectTime: this.params.selectTime,
                  cacheShowField: this.showField,
                  cacheSendField: this.sendField,
                },
              });
            }
          })
          .finally(() => (this.loading = false));
      }
    },
    // 预处理请求参数
    formatQuery() {
      let tmpFields = null;
      if (this.sendField.length > 0) {
        tmpFields = this.handleFields(this.sendField);
      } else {
        this.showField = this.originFeild;
      }
      const params = {
        beginDate: this.params.selectTime[0],
        endDate: this.params.selectTime[1],
        queryRechargeDate: this.params.queryRechargeDate,
        labelTypes: this.params.labelTypes
          ? this.params.labelTypes.join(",")
          : "",
        originPlatform: this.params.originPlatform,
        accountChannelId: this.params.currentOfficial
          ? this.params.currentOfficial.id
          : null,
        booksId: this.params.booksId,
        adminUserId: this.params.adminUserId,
        sortName: this.sortName,
        sortDesc: this.sortDesc,
        deptId: this.params.deptId,
        isTest: this.params.isTest,
        ruledOutBooksIds:
          this.params.ruledOutBooksIds && this.params.ruledOutBooksIds.length
            ? this.params.ruledOutBooksIds
            : null,
        ruledOutAccountChannelIds:
          this.params.ruledOutAccountChannelIds &&
          this.params.ruledOutAccountChannelIds.length
            ? this.params.ruledOutAccountChannelIds
            : null,
        showFieldCodes: tmpFields,
      };
      if (this.params.comperingType !== 0 && this.params.returnRate) {
        params.comperingType = this.params.comperingType;
        params.returnRate = this.params.returnRate;
      }
      return params;
    },
    ...mapActions({
      addRouterHistory: "addRouterHistory",
    }),
    // 组件字段变化
    // handleFieldChange (value) {
    //   if (value.length === 0) {
    //     this.showField = this.originFeild
    //   } else {
    //     this.showField = value
    //   }
    //   this.$nextTick(() => {
    //     this.$refs.userConsumeTable.doLayout()
    //   })
    // },
    // 获取书籍列表
    getBook(bookName) {
      getBookList({
        page: 1,
        pageSize: 50,
        bookName,
      }).then((res) => {
        this.bookList = res.list;
      });
    },
    // 获取书籍列表by id
    getBookById(booksIds) {
      getBookList({
        page: 1,
        pageSize: 50,
        booksIds,
      }).then((res) => {
        this.bookList = res.list;
      });
    },
    // 排序处理
    handleSortChange({ column, prop, order }) {
      if (column.sortable !== "custom") {
        return;
      }
      this.sortName = prop;
      this.sortDesc = order === "descending";
      this.params.sortName = this.sortName;
      this.params.sortDesc = this.sortDesc;
      this.handlePageChange(1);
    },
    // 重置操作
    handleReset(flag = true) {
      this.params.queryRechargeDate = null;
      this.params.selectTime = getRecentlyWeek(true);
      this.params.labelTypes = [];
      this.params.comperingType = null;
      this.params.returnRate = null;
      this.params.originPlatform = null;
      this.params.accountChannelId = null;
      this.params.booksId = null;
      this.params.adminUserId = null;
      this.params.deptId = null;
      this.params.isTest = null;
      this.params.ruledOutBooksIds = [];
      this.params.ruledOutAccountChannelIds = [];
      this.sortName = null;
      this.sortDesc = null;
      this.sendField = [];
      this.showField = this.originFeild;
      this.tmpShowField = [];
      if (flag) {
        this.handlePageChange(1);
      }
    },
    // 分页操作
    handlePageChange(page) {
      this.page = page;
      this.getData();
    },
    // 选择字段变化
    handleFieldChange(send, show) {
      // if (value.length === 0) {
      //   this.showField = this.originFeild;
      // } else {
      //   this.showField = value;
      // }
      // 请求格式field
      this.sendField = send;
      // 展示格式field
      this.tmpShowField = show;
      this.$nextTick(() => {
        this.$refs.userConsumeTable.doLayout();
      });
    },
    // 请求前处理字段
    handleFields(item) {
      if (item.length === 0) {
        return null;
      }
      let tmpSend = [];
      const _that = this;
      tmpSend = item.reduce(function (a, b) {
        return a.concat(b);
      });
      // 判断勾选项 推入其下所有子级
      for (let i = 0; i < tmpSend.length; i++) {
        const flagIndex = _that.originFeild.findIndex((item2) => {
          return item2.fieldCode === tmpSend[i];
        });
        if (flagIndex !== -1 && "children" in _that.originFeild[flagIndex]) {
          _that.originFeild[flagIndex].children.forEach((item3) => {
            tmpSend.push(item3.fieldCode);
          });
        }
      }
      // 去重
      tmpSend = Array.from(new Set(tmpSend));
      return tmpSend.join(",");
    },
    // 广告数据获取
    getData(flag) {
      const tody = getRecentlyWeek(true);
      if (!this.params.selectTime) {
        this.$set(this.params, "selectTime", tody);
      }
      let tmpFields = null;
      if (this.sendField.length > 0) {
        tmpFields = this.handleFields(this.sendField);
      } else {
        this.showField = this.originFeild;
      }
      let tmpform = {};
      // 如果是创建过程 参数默认取缓存参数 否则使用筛选参数
      if (flag === "created") {
        tmpform = {
          queryRechargeDate: this.params.queryRechargeDate,
          beginDate: this.params.selectTime[0],
          endDate: this.params.selectTime[1],
          labelTypes: this.params.labelTypes
            ? this.params.labelTypes.join(",")
            : "",
          comperingType: this.params.comperingType,
          returnRate: this.params.returnRate,
          originPlatform: this.params.originPlatform,
          accountChannelId: this.params.currentOfficial
            ? this.params.currentOfficial.id
            : null,
          currentOfficial: this.params.currentOfficial,
          booksId: this.params.booksId,
          adminUserId: this.params.adminUserId,
          sortName: this.sortName,
          sortDesc: this.sortDesc,
          deptId: this.params.deptId,
          isTest: this.params.isTest,
          ruledOutBooksIds:
            this.params.ruledOutBooksIds && this.params.ruledOutBooksIds.length
              ? this.params.ruledOutBooksIds.map((item) => item.id).join()
              : null,
          ruledOutAccountChannelIds:
            this.params.ruledOutAccountChannelIds &&
            this.params.ruledOutAccountChannelIds.length
              ? this.params.ruledOutAccountChannelIds.join()
              : null,
          showFieldCodes: tmpFields,
        };
      } else if (flag === "drawer") {
        this.params = {
          ...this.lastParams,
          accountChannelId: this.lastParams.currentOfficial
            ? this.lastParams.currentOfficial.id
            : null,
        };
        if (this.lastParams.booksId) {
          this.getBookById(this.lastParams.booksId);
        }
        tmpform = {
          ...this.lastParams,
          accountChannelId: this.lastParams.currentOfficial
            ? this.lastParams.currentOfficial.id
            : null,
          labelTypes: this.lastParams.labelTypes
            ? this.lastParams.labelTypes.join(",")
            : "",
        };
      } else {
        tmpform = {
          queryRechargeDate: this.params.queryRechargeDate,
          beginDate: this.params.selectTime[0],
          endDate: this.params.selectTime[1],
          labelTypes: this.params.labelTypes
            ? this.params.labelTypes.join(",")
            : "",
          comperingType: this.params.comperingType,
          returnRate: this.params.returnRate,
          originPlatform: this.params.originPlatform,
          accountChannelId: this.params.currentOfficial
            ? this.params.currentOfficial.id
            : null,
          currentOfficial: this.params.currentOfficial,
          booksId: this.params.booksId,
          adminUserId: this.params.adminUserId,
          sortName: this.sortName,
          sortDesc: this.sortDesc,
          deptId: this.params.deptId,
          isTest: this.params.isTest,
          ruledOutBooksIds:
            this.params.ruledOutBooksIds && this.params.ruledOutBooksIds.length
              ? this.params.ruledOutBooksIds.map((item) => item.id).join()
              : null,
          ruledOutAccountChannelIds:
            this.params.ruledOutAccountChannelIds &&
            this.params.ruledOutAccountChannelIds.length
              ? this.params.ruledOutAccountChannelIds.join()
              : null,
          showFieldCodes: tmpFields,
        };
      }
      // 缓存判断处理----------------------》
      const duration =
        new Date(this.params.selectTime[1] + " 00:00:00").getTime() -
        new Date(this.params.selectTime[0] + " 23:59:59").getTime();
      // 如果所选日期超过31天 则缓存列表
      if (duration > 1000 * 3600 * 24 * 30) {
        getCacheList({ dataType: 2 }).then((res) => {
          // 判断是否和缓存数据的参数相同  所有参数相同current为列表中参数相同项
          const current = res.find((item) => {
            // 拿到每条缓存参数 tmparr
            const tempArr = JSON.parse(item.key);
            // 长度不同直接返回false节省性能

            if (tempArr.length !== this.queryParams.length) {
              return false;
            }
            // 如果长度相同 遍历每条参数的详细参数
            for (const item of tempArr) {
              const params = this.queryParams.find(
                (query) => query.key === item.key
              );
              // 若果参数名相同 对比值 值不同直接返回false
              if (params) {
                if (item.value !== params.value) {
                  return false;
                }
              } else {
                return false;
              }
            }
            // 所有参数相同返回true
            return true;
          });
          if (current) {
            if (current.progress !== 100) {
              this.$message.warning("当前数据缓存中，请稍后再试");
            } else {
              this.handleDateClick(current.key);
            }
          } else {
            this.$confirm("查询日期超过一个月，是否需要缓存查询?", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                this.handleDataSave();
              })
              .catch(() => {});
          }
        });
      } else {
        this.loading = true;
        this.hasPage = false;
        getIntervalChannel(tmpform)
          .then((res) => {
            // 如果用户选择过字段则表格展示用户选择字段
            if (this.tmpShowField.length > 0) {
              this.showField = this.tmpShowField;
            }
            this.totalData = res.showSumFields;
            this.dataList = res.channelList;
            // 如果不是抽屉 缓存参数
            if (flag !== "drawer") {
              this.addRouterHistory({
                path: this.$route.path,
                params: {
                  ...tmpform,
                  ruledOutAccountChannelIds:
                    this.params.ruledOutAccountChannelIds,
                  ruledOutBooksIds: this.params.ruledOutBooksIds,
                  selectTime: this.params.selectTime,
                  cacheShowField: this.showField,
                  cacheSendField: this.sendField,
                },
              });
            }
          })
          .finally(() => (this.loading = false));
      }
      // 缓存判断处理----------------------》
    },
    // 获取字段
    handleGetFeilds(flag = "created") {
      getFields({ dataType: "intervalChannelCost" }).then((res) => {
        this.originFeild = res;
        const tmpdata = [];
        for (let i = 0; i < res.length; i++) {
          tmpdata.push({
            fieldName: res[i].fieldName,
            fieldCode: res[i].fieldCode,
          });
        }
        this.fieldsData = tmpdata;
        if (this.showField.length === 0) {
          this.showField = res;
        }
        this.selectAllFields = true;
        this.getData(flag);
        // 判断字段所选长度是否为0 是则全选 否则不全选
      });
    },
    // 处理字段
  },
  created() {
    if (this.lastParams) {
      this.handleGetFeilds("drawer");
      return false;
    }
    this.getBook();
    // 缓存获取
    const tmpIndex = this.$store.state.routerHistory.findIndex(
      (item) => item.path === this.$route.path
    );
    // 若存在缓存
    if (tmpIndex !== -1) {
      this.params = this.$store.state.routerHistory[tmpIndex].params;
      // 获取缓存展示字段
      this.showField =
        this.$store.state.routerHistory[tmpIndex].params.cacheShowField;
      // 获取缓存发送前格式字段
      this.sendField =
        this.$store.state.routerHistory[tmpIndex].params.cacheSendField;
    }
    // this.params.queryDate = formatTime(new Date(), "yyyy-MM-dd");
    this.handleGetFeilds();
    // this.getData()
  },
};
</script>

<style lang="scss" scoped>
.ad-consume {
  background-color: #fff;
  border-radius: 6px;
}
// 筛选条件框
.filter-box {
  border-radius: 6px;
  // border: 1px solid #eceff2;
  .filter-title {
    color: $main-text;
    margin-bottom: 20px;
    .iconfont {
      font-size: 12px !important;
    }
  }
}
.consume-table {
  .adId {
    text-decoration: underline;
    cursor: pointer;
  }
  .min-img {
    width: 70px;
    height: 70px;
  }
}
</style>
