<template>
  <div>
    <!--叶子级菜单-->
    <template v-if="!item.children || !item.children.length">
      <el-menu-item
        :class="$route.path.includes(item.path) ? 'is-active' : 'not-active'"
        :key="item.path"
        :index="item.path"
      >
        <i class="iconfont" :class="item.icon"></i>
        <span class="father-span">{{ item.title }}</span>
      </el-menu-item>
    </template>
    <!-- :class="$route.meta.firstMenu == item.path ? 'is-active' : 'not-active'" -->
    <!--父级菜单-->
    <el-submenu
      :class="$route.path.includes(item.path) ? 'is-active' : 'not-active'"
      v-else
      :index="item.path"
      style="text-align: left"
    >
      <template slot="title">
        <i class="iconfont" :class="item.icon"></i>
        <span class="father-span">{{ item.title }}</span>
      </template>
      <el-menu
        router
        class="el-menu-vertical-demo-chi"
        :collapse="true"
        :default-active="$route.path"
        unique-opened
      >
        <navigation-item
          v-for="child in item.children"
          :key="child.path"
          :item="child"
        />
      </el-menu>
    </el-submenu>
  </div>
</template>
 
<script>
export default {
  name: "navigationItem",
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getFontColor(val) {
      if ("color" in val) {
        return {
          color: `${val.color}`,
        };
      }
    },
  },
};
</script>
<style lang='scss' scoped>
.el-menu-vertical-demo-chi {
  width: 100% !important;
}
.iconfont {
  font-size: 16px;
  font-weight: bold;
  margin-left: 22px;
  margin-right: 9px;
  color: #1f2d3d !important;
}
/deep/.el-submenu.is-active {
  background-color: #eceff2;
  color: #00bf8a !important;
  font-weight: bold;
  border-right: 3px solid #00bf8a;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  .father-span,
  .iconfont {
    color: #00bf8a !important;
  }
}
/deep/.el-menu--popup .el-menu-item.not-active {
  color: #1f2d3d !important;
  .father-span,
  .iconfont {
    color: #1f2d3d !important;
  }
}
</style>
