import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Element from 'element-ui'
import './plugins/element.js'
import './assets/style/index.scss'
import fullscreen from 'vue-fullscreen'
import dialogDrag from './assets/js/directive.js'
import CTable from './components/customTable.vue'
import './assets/js/globalComponent'
import './assets/js/globalFunction'
import DatePicker from './components/year-range/index'

Vue.component('CTable', CTable)
Vue.component(DatePicker.name,DatePicker)
Vue.config.productionTip = false
Vue.use(fullscreen)
Vue.use(Element, { size: 'small' })

Vue.use(dialogDrag)

// 全局守卫
router.beforeEach((to, from, next) => {
  store.commit('clearDrawerCount')
  next()
},
  // 手动刷新页面清空drawerCount
  window.onbeforeunload = function () {
    store.commit('clearDrawerCount')
  }
)
export const app = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
