// mutations
import Vue from 'vue'

export default {
  setGlobalYear(state, data) {
    state.globalYear = data
  },
  setGlobalYearSchedule(state, data) {
    state.stateYears = data
  },
  setUserInfo(state, userInfo) {
    state.userInfo = userInfo
  },
  setCollapsed(state, collapse) {
    state.isCollapsed = collapse
  },
  setOfficialList(state, list) {
    state.officialList = list
  },
  setUserList(state, list) {
    state.userList = list
  },
  setBookList(state, list) {
    state.bookListFirstPage = list
  },
  // 添加路由记录 存在则替换 不存在则保存
  addRouterHistory(state, route) {
    const routerIndex = state.routerHistory.findIndex(item => item.path === route.path)
    if (routerIndex === -1) {
      state.routerHistory.push({ ...route })
    } else {
      state.routerHistory.splice(routerIndex, 1, {
        path: route.path,
        title: route.title,
        params:
          JSON.stringify(route.params) === '{}'
            ? state.routerHistory[routerIndex].params
            : route.params
      })
    }
  },
  delRouterHistory(state, {
    index,
    active,
    router
  }) {
    state.routerHistory.splice(index, 1)
    if (active) {
      router.replace(state.routerHistory[index - 1].path)
    }
  },
  resetHistory(state, path) {
    const currentRoute = state.routerHistory.find(item => item.path === path)
    state.routerHistory =
      path !== '/' && currentRoute
        ? [
          {
            path: '/',
            title: '首页'
          },
          { ...currentRoute }
        ]
        : [
          {
            path: '/',
            title: '首页'
          }
        ]
  },
  clearHistory(state) {
    state.routerHistory = []
  },
  // 设置看板数据
  setBoardList(state, data) {
    state.boardList = data
  },
  // 设置所有看板的汇总
  setBoardTotalData(state, data) {
    state.boardTotalData[data.datatype] = data.value
  },
  clearBoardTotalData(state) {
    state.boardTotalData = {}
  },
  // 设置搜索书籍的历史记录
  setHistoryBookList(state, {
    index,
    bookList
  }) {
    Vue.set(state.historyBookList2, index, bookList)
  },
  setHistoryBookCache(state, data) {
    state.historyBookCache[data.name] = data.value
  },
  setModuleDragStart(state, flag) {
    state.moduleDragStart = flag
  },
  setModuleClickStatus(state, flag) {
    state.moduleClickStatus = flag
  },
  setModuleNumber(state, operation = 'add') {
    // state.moduleNumber = operation === 'add'? +=
    operation === 'add' ? (state.moduleNumber += 1) : (state.moduleNumber -= 1)
  },
  // 设定具体的moduleNumber
  setTargetModuleNumber(state, value) {
    state.moduleNumber = value
  },
  // 设置推广页编辑页面的样式
  setEditAreaStyle(state, obj) {
    state.editAreaStyle = obj
  },
  // 保存当前组件的数据
  setCurModuleSelected(state, item) {
    state.curModuleSelected = item
  },
  // 增加单个组件
  setModuleSelectedList(state, item) {
    state.moduleSelectedList.push(item)
  },
  // 设置组件的值 (用于拖拽调整顺序)
  changeModuleSelectedOrder(state, list) {
    state.moduleSelectedList = list
  },
  setIsClickModuleItemFlag(state, flag) {
    state.isClickModuleItemFlag = flag
  },
  // 设置分享标题和分享描述
  setShare(state, {
    shareTitle,
    shareDescription
  }) {
    state.shareTitle = shareTitle
    state.shareDescription = shareDescription
  },
  setPageId(state, id) {
    state.pageId = id
  },
  setIsPageUpdate(state, flag) {
    state.isPageUpdate = flag
  },
  // 设定公众号名称
  setChannelName(state, name) {
    state.channelName = name
  },
  // 改变单个组件的值
  changeModuleSelectedList(state, item, reSet = false) {
    const targetIndex = state.moduleSelectedList.findIndex(
      module => module.tagNumber === item.tagNumber
    )
    if (!reSet) {
      if (targetIndex > -1) {
        state.moduleSelectedList.splice(targetIndex, 1, item)
      }
    } else {
      state.moduleSelectedList.splice(targetIndex, 1, null)
    }
  },
  // 删除单个组件
  delModuleSelectedItem(state, {
    targetIndex,
    ...item
  }) {
    state.moduleSelectedList.splice(targetIndex, 1)
  },
  // 设置isUploadImage
  setIsUploadImage(state, flag) {
    state.isUploadImage = flag
  },
  // 设置推广页名称
  setCanvasName(state, name) {
    state.canvasName = name
  },
  // 删除所有编辑组件(用于重做按钮 和 跳转未保存的操作)
  resetAllModule(state) {
    state.moduleSelectedList = []
    state.curModuleSelected = {}
    state.tagNumber = 1
    state.moduleNumber = 0
    state.canvasName = ''
    state.shareTitle = ''
    state.shareDescription = ''
    state.isPageUpdate = false
    state.pageId = null
  },
  // 设置tagNumber
  setTagNumber(state, value = 0) {
    if (value) {
      state.tagNumber = value
    }
    state.tagNumber = state.tagNumber + 1
  },
  // 设置回首页时跳转至上一次选择面板
  setLastBoardId(state, data) {
    state.LastBoardId = data
  },
  // 设置缓存板块统一处理提示框
  setCacheIndexPop(state, data) {
    state.CacheIndexPop = data
  },
  // 设置缓存板块统一处理提示框 是否是第一次触发
  setIsFirstCachePop(state, data) {
    state.isFirstCachePop = data
  },

  setMarginInfo(state, info) {
    state.marginInfo = info
  },
  setPromotedText(state, val) {
    state.promotedText = val
  },
  setCrowdInfoList(state, val) {
    state.crowdInfoList = val
  },
  delCrowdInfoList(state, index, flag = false) {
    if (flag) {
      state.crowdInfoList = []
      return
    }
    state.crowdInfoList.splice(index, 1)
  },
  setIsNovelPutEdit(state, flag) {
    state.isNovelPutEdit = flag
  },
  // 设置广告文案信息
  setAdPartList(state, val) {
    state.adPartList = val
  },
  // 设置定向人群
  setDirectionalCrowdList(state, val) {
    state.directionalCrowdList = val
  },

  // 设置顶图
  setTopImages(state, val) {
    state.topImgs = val
  },

  setIsProjectSaved(state, flag) {
    state.isProjectSaved = flag
  },

  // 设置服务商登录信息
  setProviderInfo(state, obj) {
    state.providerInfo = obj
  },
  // 设置第一步的数据
  setFirstStepData(
    state,
    {
      posType,
      beginTime,
      endTime,
      timeset,
      bidActionType,
      bidStrategy,
      bidObjective,
      pageIds,
      crowdInfoList,
      adPartList,
      directionalCrowdList,
      planTopImgs,
      directs,
      planGroup,
      education,
      os, // 操作系统
      devicePrice, // 手机价格区间
      deviceBrandModel, // 手机品牌
      telcom, // 运营商
      connection, // 联网方式
      inActionList, // 再营销
      notInActionList, // 排除营销
      editorList,
      timesetType,
      selectedList

    }
  ) {
    if (editorList) state.firstStepData.editorList = editorList
    if (posType === 0 || posType) {
      state.firstStepData.posType = posType
    }
    if (beginTime) {
      state.firstStepData.beginTime = beginTime
    }
    if (endTime === 0 || endTime) {
      state.firstStepData.endTime = endTime
    }
    // 如果选择的是全天投放 需要添加timeset === ''的判断
    if (timeset || timeset === '') {
      state.firstStepData.timeset = timeset
    }
    if (bidActionType) {
      state.firstStepData.bidActionType = bidActionType
    }
    if (bidStrategy) {
      state.firstStepData.bidStrategy = bidStrategy
    }
    if (pageIds) {
      state.firstStepData.pageIds = pageIds
    }
    if (crowdInfoList) {
      state.firstStepData.crowdInfoList = crowdInfoList
    }
    if (adPartList) {
      state.firstStepData.adPartList = adPartList
    }
    if (directionalCrowdList) {
      state.firstStepData.directionalCrowdList = directionalCrowdList
    }
    if (planTopImgs) {
      state.firstStepData.planTopImgs = planTopImgs
    }
    if (Array.isArray(directs) && directs.length > 0) {
      state.firstStepData.directs = directs
    }
    if (planGroup) {
      state.firstStepData.planGroup = planGroup
    }
    if (education) state.firstStepData.education = education
    if (os) state.firstStepData.os = os
    if (devicePrice) state.firstStepData.devicePrice = devicePrice
    if (deviceBrandModel) {
      state.firstStepData.deviceBrandModel = deviceBrandModel
    }
    if (telcom) state.firstStepData.telcom = telcom
    if (connection) state.firstStepData.connection = connection
    if (inActionList) state.firstStepData.inActionList = inActionList
    if (notInActionList) state.firstStepData.notInActionList = notInActionList
    if (timesetType) state.firstStepData.timesetType = timesetType
    if (selectedList) state.firstStepData.selectedList = selectedList
  },
  // 设置第二步数据
  setSecondStepData(
    state,
    {
      bid,
      dayBudget,
      bookId,
      cname,
      topImgStr,
      cNum,
      cAdNum,
      expandTargetingSetting,
      expandTargetingSwitch,
      autoAcquisitionBudget,
      autoAcquisitionSwitch,
      categoryId
    }
  ) {
    if (bid) state.secondStepData.bid = bid
    if (dayBudget) state.secondStepData.dayBudget = dayBudget
    if (bookId) state.secondStepData.bookId = bookId
    if (cname) state.secondStepData.cname = cname
    if (topImgStr) state.secondStepData.topImgStr = topImgStr
    if (cNum) state.secondStepData.cNum = cNum
    if (cAdNum) state.secondStepData.cAdNum = cAdNum
    if (expandTargetingSwitch) {
      state.secondStepData.expandTargetingSwitch = expandTargetingSwitch
    }
    if (expandTargetingSetting) {
      state.secondStepData.expandTargetingSetting = expandTargetingSetting
    }
    if (autoAcquisitionSwitch) state.secondStepData.autoAcquisitionSwitch = autoAcquisitionSwitch
    if (autoAcquisitionBudget) state.secondStepData.autoAcquisitionBudget = autoAcquisitionBudget
    if (categoryId) state.secondStepData.categoryId = categoryId
  },
  resetFirstStepData(state) {
    state.firstStepData = {
      //  投放类型
      posType: 999,
      editorList: null, // 文本域信息
      // 投放计划开始时间
      beginTime: '',
      // 投放计划结束时间
      endTime: '',
      // 投放时间段(默认全为1)
      timeset: '',
      // 出价方式
      bidActionType: 1, // 默认oCPM
      // 投放模式
      bidStrategy: 2, // 投放模式   1：均衡投放，2：优先跑量，3：优先成本   默认2
      // 优化目标
      bidObjective: 6, // 默认 关注
      // 选择的推广页
      pageIds: [],
      // 定向人群信息
      crowdInfoList: [],
      // 公众平台广告文案 内容(针对组合设置(第一步)-公众号的内容)
      adPartList: [],
      // 定向人群信息
      directionalCrowdList: [],
      // 顶图信息
      planTopImgs: [],
      // 外层广告设置配置信息
      planGroup: {
        // linkName: '了解公众号',
        // linkPageType: 6, // 默认公众号详情页
        // snsDynamicShowType: 0, // 数据类型
        // snsDynamicShowText: '', // 转化行为
        // desc: [] // 文案
      },
      // 定向人群信息
      directs: [{
        name: '', // 广告名称,
        minAge: '', // 最小年龄(默认14)
        maxAge: '',
        gender: '',
        area: '',
        areaType: ''
      }],
      // 学历
      education: '[]',
      // 操作系统
      os: '[]',
      // 手机价格
      devicePrice: '[]',
      // 手机品牌
      deviceBrandModel: '[]',
      // 运营商
      telcom: '[]',
      // 联网方式
      connection: '[]',
      // 再营销
      inActionList: '[]',
      // 排除营销
      notInActionList: '[101]',
      // 投放时段类型
      timesetType: 1,
      // 自定义时间段数组(用于回显)
      selectedList: []

    }
  },
  resetSecondStepData(state) {
    state.secondStepData = {
      cname: '', // 计划名称
      bookId: '', // 选中书籍的bookId
      bid: 0, // 出价必须>0
      dayBudget: 0, // 每日预算必须>0
      topImgStr: 0, // 计划当中的顶图数量
      cNum: 1, // 计划数量
      cAdNum: 1, // 每个计划当中的广告数量
      expandTargetingSetting: [], // 不可突破定向选择的内容
      expandTargetingSwitch: 'EXPAND_TARGETING_SWITCH_CLOSE', // 是否开启自动扩量
      autoAcquisitionBudget: 0,
      autoAcquisitionSwitch: 0, // 默认0 不开启
      categoryId: 0
    }
  },
  resetThirdStepData(state) {
    state.thirdStepData = {}
  },
  setEchoNovelPlanInfo(state, obj) {
    state.echoNovelPlanInfo = obj
  },
  setEchoNovelPlanProperty(state, {
    propertyName,
    propertyValue
  }) {
    state.echoNovelPlanInfo[`${propertyName}`] = propertyValue
  },
  setResourceData(state, obj) {
    state.resourceData = JSON.parse(JSON.stringify(obj))
  },
  setIsEditTopImages(state, flag) {
    state.isEditTopImages = flag
  },
  addDrawerCount(state) {
    state.drawerCount = state.drawerCount + 1
  },
  clearDrawerCount(state) {
    state.drawerCount = 0
  },
  delDrawerCount(state) {
    state.drawerCount = state.drawerCount - 1
  },
  // 设置看板下缓存板块缓存后 再次搜索相同条件不弹框
  // setSearchCacheParam (state, data) {
  //   state.searchCacheParam = data
  // }

  // 设置素材视频上传百分比

  setUploadPercent(state, percent) {
    state.uploadPercent = percent
  },
  setUploadAudioPercent(state, percent) {
    state.uploadAudioPercent = percent
  },
  setIsConfirmFailedInfo(state, flag) {
    state.isConfirmFailedInfo = flag
  },

  setWeChatSourceTemporaryData(state, data) {
    state.weChatSourceTemporaryData = data
  },
  // * 设置批量投放账号列表数据
  setAccountList(state, data) {
    state.accountList = JSON.parse(JSON.stringify(data))
  },
  setAuthNodeExpanded(state, data) {
    state.authNodeExpanded = data
  },
  setFeedBackFlag(state, data) {
    state.isFeedBack = data;
  }

}
