
<script>
//  简易二次封装的el-input(可以限制非数字的输入)
export default {
  name: 'CInput',
  props: {
    value: [String, Number],
    // * 是否只能输入数字
    onlyNum: Boolean,
    // * 是否只能输入整数(只在设置onlyNum才有效)
    isInter: Boolean,
    // *最小值
    min: {
      type: [Number, String]
    },

    type: String,
    // * 最大值
    max: {
      type: [Number, String]
    },
    maxlength: {
      type: [Number, String]
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {}
  },
  computed: {
    eventList () {
      return {
        ...this.$listeners,
        change: this.handleValueChange
      }
    }
  },
  watch: {},

  render () {
    return (
      <el-input
        value={this.value}
        type={this.type}
        on={{
          input: this.handleInput,
          change: this.handleValueChange
        }}
        disabled={this.disabled}
        clearable
        placeholder={this.$attrs.placeholder}
        maxlength={this.maxlength}
        show-word-limit
      >
        <template slot={'prefix'}>{this.$slots.prefix}</template>
        <template slot={'suffix'}>{this.$slots.suffix}</template>
        <template slot={'append'}>{this.$slots.append}</template>
      </el-input>
    )
  },
  methods: {
    handleInput (val) {
      if (this.type === 'password') return this.$emit('input', val)
      if (this.onlyNum) {
        if (this.isInter) {
          this.$emit('input', val.replace(/[^\d+]/gi, ''))
        } else {
          this.$emit('input', val.replace(/[^\d.]+/gi, ''))
        }
      } else {
        this.$emit('input', val)
      }
    },
    handleValueChange (val) {
      if (this.type === 'password') return
      let newVal = val
      if (this.onlyNum) {
        if (!this.isInter) {
          newVal = val.replace(/[^\d+\.]/gi, '')
          if (/\.+/gi.test(newVal)) {
            newVal = newVal.split(/\.+/g)

            this.$emit(
              'input',
              [...newVal[0], ['.'], ...newVal[1].slice(0, 2)].join('')
            )
          }
        }
        if (this.min !== undefined) {
          if (parseFloat(val) < parseFloat(this.min)) {
            this.$emit('input', `${this.min}`)
          }
        }
        if (this.max !== undefined) {
          if (parseFloat(val) > parseFloat(this.max)) {
            this.$emit('input', `${this.max}`)
          }
        }
      }
    }
  },
  components: {}
}
</script>
