<template>
  <div v-if="item.children && item.children.length > 0">
    <el-submenu :index="item.path">
      <template #title>
        {{ item.title }}
      </template>

      <template v-for="child in item.children">
        <sidebar-item
          v-if="child.children && child.children.length > 0"
          :item="child"
          :key="child.path"
        />
        <el-menu-item v-else :key="child.path" :index="child.path">
          {{ child.title }}
        </el-menu-item>
      </template>
    </el-submenu>
  </div>
  <div v-else>
    <el-menu-item :index="item.path">
      {{ item.title }}
    </el-menu-item>
  </div>
</template>

<script>
export default {
  name: "SidebarItem",
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>
