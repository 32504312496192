// state状态
const curYear = new Date().getFullYear()
export default {
  globalYear: '', // 全局数据（年）
  stateYears: [`${curYear}`, `${curYear}`],
  userInfo: {},
  isCollapsed: false,
  officialList: [], // 当前登录用户下的公众号列表
  userList: [], // 全部用户
  bookListFirstPage: [], // 全部书籍 第一页
  routerHistory: [], // { path, title, params  }
  boardList: [],
  // !存储用户在新增客服消息模块里面选择小说的历史书籍记录
  historyBookList: [[], [], [], []],
  historyBookList2: [[], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], []],
  historyBookCache: {
    zhangZhongYun: [],
    yueWen: [],
    yangGuang: [],
    youShuGe: [],
    wangYi: [],
    qiYue: [],
    huaSheng: [],
    haiDu: [],
    kanShuWang: []
  },
  isConfirmFailedInfo: false, // 是否确认了发送失败的信息

  canvasName: '', // 推广页名称
  shareTitle: '', // 推广页分享标题
  shareDescription: '', // 推广页分享描述
  isPageUpdate: false, // 是否是编辑推广页的操作
  pageId: null, // 推广页ID
  moduleNumber: 0, // 编辑组件的数量,
  editAreaStyle: { // 创建推广页编辑区域的样式(默认只有背景颜色)
    backgroundColor: '#FFFFFF',
    height: '667px'
  },
  moduleDragStart: false, // 编辑组件是否开始拖拽
  moduleClickStatus: false, // 编辑组件是否被点击
  moduleSelectedList: [], // 点击组件的集合（用组件提示代替）
  curModuleSelected: {}, // *鼠标当前选中的组件 用于保存样式的状态
  isUploadImage: false, // 是否是点击了上传图片的按钮(右边侧边栏点击素材设置)
  tagNumber: 1, // 编辑组件的标识(值唯一)
  marginInfo: { // 初始化 边距的设定值
    marginTopValue: 0,
    marginBottomValue: 0
  },
  // !在编辑区点击组件的时候用户判断是否点击的时候鼠标是否移动到了不同的组件
  isClickModuleItemFlag: false,
  promotedText: '', // 推广文案的文本域内容
  channelName: '广告主名称', // 推广页当前关联的公众号名称

  isNovelPutEdit: false, // 是否是推广计划的回显操作
  isProjectSaved: false, // 新增或者回显的投放计划是否已经保存(是否走到了最后一步)

  uploadPercent: 0, // 小说投放视频素材上传百分比
  uploadAudioPercent: 0, // 客服消息音频上传百分比

  // 存储服务商登录信息
  providerInfo: {

  },
  // 第一步数据
  firstStepData: {
    //  投放类型
    posType: '',
    editorList: null, // 文本域信息
    // 投放计划开始时间
    beginTime: '',
    // 投放计划结束时间
    endTime: '',
    // 投放时间段(默认全为1)
    timeset: '',
    // 出价方式
    bidActionType: 1, // 默认oCPM
    // 投放模式
    bidStrategy: 2, // 投放模式   1：均衡投放，2：优先跑量，3：优先成本   默认2
    // 优化目标
    bidObjective: 6, // 默认 关注
    // 选择的推广页
    pageIds: [],
    // 定向人群信息
    crowdInfoList: [],
    // 公众平台广告文案 内容(针对组合设置(第一步)-公众号的内容)
    adPartList: [],
    // 定向人群信息
    directionalCrowdList: [],
    // 顶图信息
    planTopImgs: [],
    // 外层广告设置配置信息
    planGroup: {
      // linkName: '了解公众号',
      // linkPageType: 6, // 默认公众号详情页
      // snsDynamicShowType: 0, // 数据类型
      // snsDynamicShowText: '', // 转化行为
      // desc: [] // 文案
    },
    // 定向人群信息
    directs: [{
      name: '', // 广告名称,
      minAge: '', // 最小年龄(默认14)
      maxAge: '',
      gender: '',
      area: '',
      areaType: ''
    }],
    // 学历
    education: '[]',
    // 操作系统
    os: '[]',
    // 手机价格
    devicePrice: '[]',
    // 手机品牌
    deviceBrandModel: '[]',
    // 运营商
    telcom: '[]',
    // 联网方式
    connection: '[]',
    // 再营销
    inActionList: '[]',
    // 排除营销
    notInActionList: '[101]',
    // 投放时段类型
    timesetType: 1,
    // 自定义时间段数组(用于回显)
    selectedList: []

  },

  // 第二步数据
  secondStepData: {
    cname: '', // 计划名称
    bookId: '', // 选中书籍的bookId
    bid: 0, // 出价必须>0
    dayBudget: 0, // 每日预算必须>0
    topImgStr: 0, // 计划当中的顶图数量
    cNum: 1, // 计划数量
    cAdNum: 1, // 每个计划当中的广告数量
    expandTargetingSetting: [], // 不可突破定向选择的内容
    expandTargetingSwitch: 'EXPAND_TARGETING_SWITCH_CLOSE', // 是否开启自动扩量
    autoAcquisitionBudget: 0,
    autoAcquisitionSwitch: 0, // 默认0 不开启
    categoryId: 0 // 书籍分类Id
  },

  // 第三步数据

  thirdStepData: {

  },

  // 投放计划回显数据
  echoNovelPlanInfo: {

  },
  // 回显投放计划设置用户是否对现有的顶图数据进行了修改
  isEditTopImages: false,

  // 单个投放素材信息
  resourceData: {},

  // 存储看板汇总信息
  boardTotalData: {},
  LastBoardId: '',
  // 看板下有缓存板块统一处理提示框
  CacheIndexPop: 0,
  // 看板下有缓存板块统一处理提示框 是否是第一次触发
  isFirstCachePop: 0,
  // 看板下缓存板块缓存后 再次搜索相同条件不弹框
  // searchCacheParam:0
  drawerCount: 0,

  // 保存素材库 - 新建素材临时数据
  weChatSourceTemporaryData: {

  },
  // * 批量投放账号所选列表
  accountList: [],
  // 权限上次展开状态记录
  authNodeExpanded: [1],
  // 客户消息复制消息缓存公众号
  messageServerChannel: {},



  // 用户是否填写反馈
  isFeedBack: true,
}


