import { uploadFile } from "@/api/common";
import { nanoid } from "nanoid";

export function formatTime(obj, format) {
  if (format) {
    let date;
    if (obj instanceof Date) {
      date = obj;
    } else {
      obj = obj.toString().length === 10 ? parseInt(obj) * 1000 : obj;
      date = new Date(obj);
    }
    const dayNames = [
      "星期一",
      "星期二",
      "星期三",
      "星期四",
      "星期五",
      "星期六",
      "星期日"
    ];

    const o = {
      "M+":
        date.getMonth() < 9 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1, // 月份
      "d+": date.getDate() < 10 ? "0" + date.getDate() : date.getDate(), // 日
      "h+": date.getHours(), // 小时
      "m+": date.getMinutes(), // 分
      "s+":
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds(), // 秒
      "q+": Math.floor((date.getMonth() + 3) / 3), // 季度
      "S+": date.getMilliseconds(), // 毫秒
      "D+": dayNames[date.getDay()] // 星期
    };

    if (/(y+)/.test(format))
      format = format.replace(
        RegExp.$1,
        `${date.getFullYear()}`.substr(4 - RegExp.$1.length)
      );
    for (const k in o) {
      if (new RegExp(`(${k})`).test(format)) {
        format = format.replace(
          RegExp.$1,
          RegExp.$1.length === 1 ? o[k] : `00${o[k]}`.substr(`${o[k]}`.length)
        );
      }
    }
    return format;
  } else {
    obj = obj.toString().length === 10 ? parseInt(obj) * 1000 : obj;
    const date = new Date(obj);
    var _year = date.getFullYear();
    var _month =
      date.getMonth() + 1 > 9
        ? date.getMonth() + 1
        : "0" + (date.getMonth() + 1);
    var _date = date.getDate() > 9 ? date.getDate() : "0" + date.getDate();
    var _hour = date.getHours() > 9 ? date.getHours() : "0" + date.getHours();
    var _minute =
      date.getMinutes() > 9 ? date.getMinutes() : "0" + date.getMinutes();
    var _second =
      date.getSeconds() > 9 ? date.getSeconds() : "0" + date.getSeconds();
    return (
      _year +
      "-" +
      _month +
      "-" +
      _date +
      " " +
      _hour +
      ":" +
      _minute +
      ":" +
      _second
    );
  }
}
export function deduplication(array) {
  const obj = {};
  const res = array.reduce((acc, b, c) => {
    if (!(`${b.id}` in obj) && "id" in b) {
      obj[`${b.id}`] = b;
      acc.push(b);
    }
    if (!(`${b.cbid}` in obj) && "cbid" in b) {
      obj[`${b.cbid}`] = b;
      acc.push(b);
    }
    if (!(`${b.book_id}` in obj) && "book_id" in b) {
      obj[`${b.book_id}`] = b;
      acc.push(b);
    }
    return acc;
  }, []);
  return res;
}

export function formatThousands(num) {
  const NumStr = Number(num).toFixed(2);
  const reg = /(\d)(?=(\d{3})+\.)/g;
  return NumStr.replace(reg, "$1,");
}

export function formatThousandsOne(num) {
  const NumStr = Number(num).toFixed(1);
  const reg = /(\d)(?=(\d{3})+\.)/g;
  return NumStr.replace(reg, "$1,");
}

export function formatThousandsnoz(num) {
  const NumStr = Number(num).toFixed(0);
  const reg = /(\d)(?=(\d{3})$)/g;
  return NumStr.replace(reg, "$1,");
}

export function formatThousandsDots(num) {
  const strNum = num.split(",").join("");
  const NumStr = Number(strNum).toFixed(2);
  const reg = /(\d)(?=(\d{3})+\.)/g;
  return NumStr.replace(reg, "$1,");
}

export function formatNum(num) {
  if ((num + "").indexOf(".") === -1) {
    const reg = /\d{1,3}(?=(\d{3})+$)/g;
    return (num + "").replace(reg, "$&,");
  } else {
    const reg = /(\d)(?=(\d{3})+\.)/g;
    return (num + "").replace(reg, "$1,");
  }
}

// descType Boolean类型，true为升序,false为降序，默认升序
export function sortBy(attr, descType) {
  if (descType === undefined) {
    descType = 1;
  } else {
    descType = descType ? 1 : -1;
  }

  return function(a, b) {
    a = a[attr];
    b = b[attr];
    if (a < b) {
      return descType * -1;
    }
    if (a > b) {
      return descType * 1;
    }
    return 0;
  };
}

// 获取当前月日期区间
export function getCurrentMonth() {
  const start = new Date();
  const end = new Date();
  start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
  return [formatTime(start, "yyyy-MM-dd"), formatTime(end, "yyyy-MM-dd")];
}

// 本月
export function getPresentMonth(flag) {
  var begin = "";
  var end = "";
  begin = formatTime(new Date(), "yyyy-MM-01");
  if (flag) {
    end = formatTime(new Date().getTime() - 3600 * 1000 * 24, "yyyy-MM-dd");
  } else {
    end = formatTime(new Date(), "yyyy-MM-dd");
  }

  return [begin, end];
}

// 获取上月日期区间
export function getPreMonth() {
  const tody = new Date();
  var year = tody.getFullYear(); //获取当前日期的年份
  var month = tody.getMonth(); //获取当前日期的月份
  var year2 = year;
  var month2 = parseInt(month);
  if (month2 == 0) {
    year2 = parseInt(year2) - 1;
    month2 = 12;
  }
  var days2 = new Date(year2, month2, 0);
  days2 = days2.getDate();
  if (month2 < 10) {
    month2 = "0" + month2;
  }
  var t2Start = year2 + "-" + month2 + "-" + "01";
  var t2End = year2 + "-" + month2 + "-" + days2;
  return [t2Start, t2End];
}

// 获取上月日期区间基于当前日
export function getPreMonthByCurrent(flag) {
  const tody = new Date();
  let todyDate = tody.getDate();
  if (todyDate < 10) {
    todyDate = "0" + todyDate;
  }
  var year = tody.getFullYear(); //获取当前日期的年份
  var month = tody.getMonth(); //获取当前日期的月份
  var year2 = year;
  if (flag) {
    var month2 = parseInt(month - 1);
    if (month2 == 0) {
      year2 = parseInt(year2) - 1;
      month2 = 12;
    }
    if (month2 == -1) {
      year2 = parseInt(year2) - 1;
      month2 = 11;
    }
  } else {
    var month2 = parseInt(month);
    if (month2 == 0) {
      year2 = parseInt(year2) - 1;
      month2 = 12;
    }
  }
  var days2 = new Date(year2, month2, 0);
  days2 = days2.getDate();
  if (month2 < 10) {
    month2 = "0" + month2;
  }
  var t2Start = year2 + "-" + month2 + "-" + "01";
  if (todyDate < days2) {
    if (flag) {
      var t2End = year2 + "-" + month2 + "-" + days2;
    } else {
      var t2End = year2 + "-" + month2 + "-" + todyDate;
    }
  } else {
    var t2End = year2 + "-" + month2 + "-" + days2;
  }

  return [t2Start, t2End];
}

// 获取上月日期区间基于当前日 上期
export function getPreMonthByCurrentLast(flag) {
  let current = getPreMonthByCurrent();
  const tody = new Date();
  const todyDate = current[1].slice(8);
  var year = tody.getFullYear(); //获取当前日期的年份
  var month = tody.getMonth(); //获取当前日期的月份
  var year2 = year;
  if (flag) {
    var month2 = parseInt(month - 1);
    if (month2 == 0) {
      year2 = parseInt(year2) - 1;
      month2 = 12;
    }
    if (month2 == -1) {
      year2 = parseInt(year2) - 1;
      month2 = 11;
    }
  } else {
    var month2 = parseInt(month);
    if (month2 == 0) {
      year2 = parseInt(year2) - 1;
      month2 = 12;
    }
  }
  var days2 = new Date(year2, month2, 0);
  days2 = days2.getDate();
  if (month2 < 10) {
    month2 = "0" + month2;
  }
  var t2Start = year2 + "-" + month2 + "-" + "01";
  if (todyDate < days2) {
    var t2End = year2 + "-" + month2 + "-" + todyDate;
  } else {
    var t2End = year2 + "-" + month2 + "-" + days2;
  }

  return [t2Start, t2End];
}

export function getCurrentDate() {
  return formatTime(Date.now() - 24 * 60 * 60 * 1000, "yyyy-MM-dd");
}

export function getCurrentDay() {
  const today = formatTime(Date.now(), "yyyy/MM/dd");
  return [
    formatTime(new Date(today + " 00:00:00")),
    formatTime(new Date(today + " 23:59:59"))
  ];
}

export function getFiveMonth() {
  const end = new Date();
  const start = new Date();
  start.setMonth(start.getMonth() - 5);
  return [formatTime(start, "yyyy-MM"), formatTime(end, "yyyy-MM")];
}

export function getYesterdayMonth() {
  const start = new Date();
  const end = new Date();
  start.setTime(start.getTime() - 3600 * 1000 * 24 * 31);
  return [
    formatTime(start, "yyyy-MM-dd"),
    formatTime(end - 1000 * 24 * 3600, "yyyy-MM-dd")
  ];
}

export function getAMonthDay(date) {
  const [year, month] = date.split("-");
  const now = new Date();
  const currentYear = now.getFullYear();
  const currentMonth = now.getMonth() + 1;
  if (
    currentYear > Number(year) ||
    (currentYear === Number(year) && currentMonth > parseInt(month))
  ) {
    return [
      `${year}-${month}-01`,
      `${year}-${month}-${getMonthDay(year, month)}`
    ];
  } else {
    return [`${year}-${month}-01`, `${year}-${month}-${now.getDate()}`];
  }
}

export function getYesterdayWeek() {
  const datetime = Date.now() - 1000 * 24 * 3600;
  return [
    formatTime(datetime - 1000 * 24 * 3600 * 7, "yyyy-MM-dd"),
    formatTime(datetime, "yyyy-MM-dd")
  ];
}

function getMonthDay(year, month) {
  let days = 0;
  switch (month) {
    case "01":
      days = 31;
      break;
    case "02":
      days = (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0 ? 29 : 28;
      break;
    case "03":
      days = 31;
      break;
    case "04":
      days = 30;
      break;
    case "05":
      days = 31;
      break;
    case "06":
      days = 30;
      break;
    case "07":
      days = 31;
      break;
    case "08":
      days = 31;
      break;
    case "09":
      days = 30;
      break;
    case "10":
      days = 31;
      break;
    case "11":
      days = 30;
      break;
    case "12":
      days = 31;
      break;
  }
  return days;
}

export function validActive(msgType, selectChannel, textStr, newsForm) {
  if (
    msgType === "text" &&
    selectChannel.platform === 0 &&
    (textStr.includes("{tpl:v2,level:40,limit:3}") ||
      textStr.includes("{platformActivityId:637568}"))
  ) {
    return true;
  }
  if (
    msgType === "news" &&
    selectChannel.platform === 0 &&
    (newsForm.url.includes("{tpl:v2,level:40,limit:3}") ||
      newsForm.url.includes("{platformActivityId:637568}"))
  ) {
    return true;
  }
  if (
    msgType === "text" &&
    selectChannel.platform === 1 &&
    textStr.includes("{tpl:1,level:5,limit:3}")
  ) {
    return true;
  }
  if (
    msgType === "news" &&
    selectChannel.platform === 1 &&
    newsForm.url.includes("{tpl:1,level:5,limit:3}")
  ) {
    return true;
  }
  if (
    msgType === "text" &&
    selectChannel.platform === 3 &&
    textStr.includes("{level:312471559637762251,limit:3}")
  ) {
    return true;
  }
  if (
    msgType === "news" &&
    selectChannel.platform === 3 &&
    newsForm.url.includes("{level:312471559637762251,limit:3}")
  ) {
    return true;
  }
  return false;
}

export function GetRequest(urlStr) {
  let url = null;
  if (typeof urlStr === "undefined") {
    url = decodeURI(location.search); // 获取url中"?"符后的字符串
  } else {
    url = "?" + urlStr.split("?")[1];
  }
  const theRequest = {};
  if (url.indexOf("?") !== -1) {
    const str = url.substr(1);
    const strs = str.split("&");
    for (let i = 0; i < strs.length; i++) {
      theRequest[strs[i].split("=")[0]] = decodeURI(strs[i].split("=")[1]);
    }
  }
  return theRequest;
}

// export function clearCookie (url) {
//   return new Promise((resolve, reject) => {
//     // figgdochegaejmdigdncnidhdpdeaaog  正式插件ID
//     // bmmbbiahjngpbbheehjpfngccelmgphn 开发环境插件ID
//     chrome.runtime.sendMessage('figgdochegaejmdigdncnidhdpdeaaog', {
//       novelapi: 'clearCookie',
//       url
//     }, function (response) {
//       const { code } = JSON.parse(response)
//       if (code === 200) {
//         resolve()
//       } else {
//         reject()
//       }
//     })
//   })
// }

// 最近一周
export function getRecentlyWeek(flag) {
  const begin = new Date();
  const end = new Date();
  if (flag) {
    begin.setTime(begin.getTime() - 3600 * 1000 * 24 * 8);
    end.setTime(end.getTime() - 3600 * 1000 * 24);
  } else {
    begin.setTime(begin.getTime() - 3600 * 1000 * 24 * 7);
  }
  return [formatTime(begin, "yyyy-MM-dd"), formatTime(end, "yyyy-MM-dd")];
}

// 本周
export function getCurrentWeek(flag) {
  var now = new Date();
  var nowYear = now.getFullYear(); // 当前年
  var nowDayOfWeek = now.getDay(); // 今天本周的第几天
  var nowDay;
  if (flag) {
    nowDay = now.getDate() - 1; // 当前日
  } else {
    nowDay = now.getDate(); // 当前日
  }
  var nowMonth = now.getMonth(); // 当前月

  var weekStart = new Date(nowYear, nowMonth, nowDay - nowDayOfWeek + 1);
  var weekEnd = new Date(nowYear, nowMonth, nowDay);
  var res = [
    formatTime(weekEnd, "yyyy-MM-dd"),
    formatTime(weekStart, "yyyy-MM-dd")
  ];

  return res;
}
// 上周
export function getLastWeek(flag) {
  var now = new Date(new Date().getTime() - 3600 * 1000 * 24 * 7);
  if (flag) {
    now = new Date(new Date().getTime() - 3600 * 1000 * 24 * 14);
  }
  var nowYear = now.getFullYear(); // 当前年
  var nowDayOfWeek = now.getDay(); // 今天本周的第几天
  var nowDay = now.getDate(); // 当前日
  var nowMonth = now.getMonth(); // 当前月

  var weekStart = new Date(nowYear, nowMonth, nowDay - nowDayOfWeek + 1);
  var weekEnd = new Date(nowYear, nowMonth, nowDay);
  var res = [
    formatTime(weekEnd, "yyyy-MM-dd"),
    formatTime(weekStart, "yyyy-MM-dd")
  ];

  return res;
}
// 上一整周
export function getLastAllWeek(flag) {
  var now = new Date(new Date().getTime() - 3600 * 1000 * 24 * 7);
  if (flag) {
    now = new Date(new Date().getTime() - 3600 * 1000 * 24 * 14);
  }
  var nowYear = now.getFullYear(); // 当前年
  var nowDayOfWeek = now.getDay(); // 今天本周的第几天
  var nowDay = now.getDate(); // 当前日
  var nowMonth = now.getMonth(); // 当前月

  var weekStart = new Date(nowYear, nowMonth, nowDay - nowDayOfWeek + 1);
  var weekEnd = new Date(nowYear, nowMonth, nowDay - nowDayOfWeek + 7);
  var res = [
    formatTime(weekEnd, "yyyy-MM-dd"),
    formatTime(weekStart, "yyyy-MM-dd")
  ];

  return res;
}

// 最近一月
export function getRecentlyMonth(flag) {
  const begin = new Date();
  const end = new Date();
  begin.setTime(begin.getTime() - 3600 * 1000 * 24 * 30);
  if (flag) {
    end.setTime(end.getTime() - 3600 * 1000 * 24);
  }
  // return {
  //   begin: formatTime(begin, 'yyyy-MM-dd'),
  //   end: formatTime(end, 'yyyy-MM-dd')
  // }
  return [formatTime(begin, "yyyy-MM-dd"), formatTime(end, "yyyy-MM-dd")];
}
// 最近一月 时分秒
export function getTodyTime(flag) {
  const begin = new Date();
  const end = new Date();
  // return {
  //   begin: formatTime(begin, 'yyyy-MM-dd'),
  //   end: formatTime(end, 'yyyy-MM-dd')
  // }
  return [
    formatTime(begin, "yyyy-MM-dd 00:00:00"),
    formatTime(end, "yyyy-MM-dd 23:59:59")
  ];
}
export function clearCookie(url) {
  return new Promise((resolve, reject) => {
    // let testId = "figgdochegaejmdigdncnidhdpdeaaog";
    // let testId ='gdpdodfafaiacgmnmchoikalikiemakp'
    // let testId = 'gdpdodfafaiacgmnmchoikalikiemakp'
    let testId = process.env.VUE_APP_BASE_extendId;
    chrome.runtime.sendMessage(
      testId,
      {
        novelapi: "clearCookie",
        url
      },
      function(response) {
        console.log(response, "response");
        const { code } = JSON.parse(response);
        if (code === 200) {
          resolve();
        } else {
          // reject('error')
        }
      }
    );
  });
}
export function flatDeep(arr) {
  return arr.reduce(
    (acc, val) => acc.concat(Array.isArray(val) ? flatDeep(val) : val),
    []
  );
}

// 获取指定两个日期的天数
export function getDays(time1, time2) {
  let smallTime = Math.min(+new Date(time1), +new Date(time2));
  let largeTime = Math.max(+new Date(time1), +new Date(time2));
  let days = (largeTime - smallTime) / (1000 * 60 * 60 * 24);
  return Math.floor(days);
}

export function _changeTimeBySecond(second) {
  var hourTime = 0;
  var minuteTime = 0;
  var secondTime = 0;
  if (second > 60) {
    //如果秒数大于60
    minuteTime = Math.floor(second / 60);
    secondTime = Math.floor(second % 60);
    if (minuteTime >= 60) {
      //如果分钟大于60
      hourTime = Math.floor(minuteTime / 60);
      minuteTime = Math.floor(minuteTime % 60);
    } else {
      hourTime = 0;
    }
  } else {
    hourTime = 0;
    minuteTime = 0;
    if (second == 60) {
      //如果秒数等于60
      minuteTime = 1;
      secondTime = 0;
    } else {
      secondTime = second;
    }
  }
  var timeResult =
    _addZero(hourTime) +
    ":" +
    _addZero(minuteTime) +
    ":" +
    _addZero(secondTime);
  return timeResult;
}

function _addZero(time) {
  let str = time;
  if (time < 10) {
    str = "0" + Math.floor(time);
  }
  return str;
}

export function deepFlatten(arr) {
  return arr.reduce((acc, b, c) => {
    if (Array.isArray(b)) {
      return acc.concat(...deepFlatten(b));
    } else {
      acc.push(b);
      return acc;
    }
  }, []);
}

export const chunk = (arr, size) => {
  return arr.reduce(
    (acc, b, c) => {
      if (c < acc.length) {
        acc[c] = [...arr.slice(c * size, c * size + size)];
      }
      return acc;
    },
    Array.from(
      {
        length: Math.ceil(arr.length / size)
      },
      () => []
    )
  );
};

// ! 计算字符长度
export const calculateCharacter = textValue => {
  // 汉字字符长度为1，其余2个字符为1个长度
  const ChineseLen =
    (textValue.match(/[\u4e00-\u9fa5]/g)
      ? textValue.match(/[\u4e00-\u9fa5]/g).length
      : null) || 0;
  // 中文标点符号需要算一个字符
  const isContainsChanesePunctuation = textValue.match(
    /[\u3002|\uff1f|\uff01|\uff0c|\u3001|\uff1b|\uff1a|\u201c|\u201d|\u2018|\u2019|\uff08|\uff09|\u300a|\u300b|\u3008|\u3009|\u3010|\u3011|\u300e|\u300f|\u300c|\u300d|\ufe43|\ufe44|\u3014|\u3015|\u2026|\u2014|\uff5e|\ufe4f|\uffe5]/g
  );
  const ChinesePunctuationLen =
    (isContainsChanesePunctuation
      ? isContainsChanesePunctuation.length
      : null) || 0;

  const WordLen = textValue.length - ChineseLen - ChinesePunctuationLen;
  const totalLength = WordLen + ChineseLen * 2 + ChinesePunctuationLen * 2;
  return totalLength;
};

export function deduplicationProperty(array, property) {
  const obj = {};
  const res = array.reduce((acc, b, c) => {
    if (!(`${b[property]}` in obj) && `${property}` in b) {
      obj[`${b[property]}`] = b;
      acc.push(b);
    }
    return acc;
  }, []);
  return res;
}

// ! 返回素材资源信息
export function getMaterialInfo(file,  variety= "image") {
  return new Promise(async resolve => {
    const type = file.type.split(/\//g);
    const name = file.name.replace(/\.\w+/g, "")
    if (variety === "image") {
      const fr = new FileReader();
      fr.readAsDataURL(file);
      fr.onload = e => {
        const frUrl = e.currentTarget.result;
        const image = new Image();
        image.src = frUrl;
        image.onload = async () => {
          try {
            const { width, height } = image;
            const url = await uploadFile({ file });
            return resolve({
              widthAndHigh: `${width} * ${height}`,
              name: name,
              title: name,
              materialId:null,
              format: type[type.length - 1],
              url,
              id:nanoid(),
              sort:1,
              src: url,
              size: `${Math.floor(file.size / 1024)}KB`
            });
          } catch (e) {
            resolve(null);
          }
        };
      };
    } else if (variety === "video") {
      let videoSize
      if(file.size / Math.pow(1024,2) < 1){
        videoSize = `${Math.floor(file.size / 1024)}KB`
      }else {
        videoSize = `${Math.floor(file.size / Math.pow(1024,2))}MB`
      }
      const temVideoDom = document.createElement("video");
      temVideoDom.autoplay = "autoplay";
      const url = URL.createObjectURL(file);
      temVideoDom.src = url;
      document.body.append(temVideoDom);
      temVideoDom.style = "display:absolute;left:-9999px";
      temVideoDom.onplay = async(e) => {
        try {
          const {
            duration,
            videoWidth,
            videoHeight,
            offsetTop,
            offsetLeft
          } = temVideoDom;
          document.body.removeChild(temVideoDom)
          const url = await uploadFile({file})
          return resolve({
            title:name,
            name:name,
            widthAndHigh:`${videoWidth} * ${videoHeight}`,
            src:url,
            url,
            id:nanoid(),
            size:videoSize,
            format:type[type.length -1],
            materialId:null,
            videoTime:_changeTimeBySecond(Number(duration)),
            sort:1
          })
        } catch (error) {
          return  resolve(null)
        }
      }

    } else if (variety === "audio") {
    }
  });
}

// ! 图片资源判断
export function imageStatusCheck(
  file,
  targetSize,
  widthHeight,
  needCompress = true
) {
  return new Promise(async resolve => {
    let flag = true;
    let message = "";
    let { size } = file;
    let compressFile = null;
    if (size > +targetSize * 1024) {
      // *如果图片大小不符合要求，先做一次图片压缩。
      if (needCompress) {
        compressFile = await compressImage(file);
        if (compressFile.size > +targetSize * 1024) {
          flag = false;
          message = "所选图片太大，请重新上传!";
          return resolve({
            flag,
            message,
            file: compressFile || file
          });
        }
      } else {
        return resolve({
          flag: false,
          message: "所选图片太大，请重新上传!",
          file: compressFile || file
        });
      }
    }
    const fr = new FileReader();
    fr.readAsDataURL(file);
    fr.onload = event => {
      const image = new Image();
      image.src = event.currentTarget.result;
      image.onload = () => {
        const { width, height } = image;
        if (width !== +widthHeight.width || height !== +widthHeight.height) {
          flag = false;
          message = "该图片宽高不符合要求!";
        }
        resolve({
          flag,
          message,
          file: compressFile || file
        });
      };
    };
  });
}

// 图片压缩
export const compressImage = file => {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = event => {
      const img = new Image();
      img.src = reader.result;
      img.onload = () => {
        let { width, height } = img;
        const elem = document.createElement("canvas");
        elem.width = width;
        elem.height = height;
        const ctx = elem.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);
        document.body.append(elem);
        ctx.canvas.toBlob(
          blob => {
            const newFile = new File([blob], "123", {
              type: "image/jpeg",
              lastModified: Date.now()
            });
            resolve(newFile);
          },
          "image/jpeg",
          0.6
        );
        document.body.removeChild(elem);
      };
    };
  });
};

export const videoStatusCheck = (
  targetFile,
  targetSize,
  { minTime, maxTime },
  { width, height }
) => {
  return new Promise(async resolve => {
    let types = ["video/mp4", "video/avi", "video/quicktime"];
    let { type, size } = targetFile;
    if (!types.includes(type)) {
      resolve({
        file: null,
        message: "上传的视频格式不符合要求"
      });
      return;
    }
    if (size > targetSize * 1024 * 1024) {
      return resolve({ file: null, message: "上传的视频太大,请重新上传!" });
    }
    const temVideoDom = document.createElement("video");
    temVideoDom.autoplay = "autoplay";
    const url = URL.createObjectURL(targetFile);
    temVideoDom.src = url;
    document.body.append(temVideoDom);
    temVideoDom.style = "display:absolute;left:-9999px";
    temVideoDom.onplay = e => {
      const {
        duration,
        videoWidth,
        videoHeight,
        offsetTop,
        offsetLeft
      } = temVideoDom;
      if (minTime && maxTime) {
        if (duration < +minTime || duration > +maxTime) {
          return resolve({
            file: null,
            message: `上传的视频长度不符合要求，请上传${minTime}s - ${maxTime}s 之间的视频`
          });
        }
      }
      if (width && height) {
        if (+width !== videoWidth && +height !== videoHeight) {
          return resolve({ file: null, message: "请上传宽高符合比例的视频!" });
        }
      }
      document.body.removeChild(temVideoDom);
      resolve({ file: targetFile, duration,width:videoWidth,height:videoHeight });
    };
  });
};
// 获取对象类型
export const getElementType = val => {
  return Object.prototype.toString.call(val).match(/\w+/g)[1];
};

// ! 取不重复的指定个数的随机数

export const getRandomNumList = (start, end, size = 5) => {
  if (end <= start) return [];
  let simulateArr = Array.from(
    { length: end - start + 1 },
    (_, index) => start + index
  );
  let count = 0;
  let res = [];
  while (count < size) {
    let len = simulateArr.length;
    let randomIndex = Math.ceil(Math.random() * (len - 1));
    let target = simulateArr[randomIndex];
    res.push(target);
    simulateArr.splice(randomIndex, 1);
    count++;
  }
  return res.sort((a, b) => a - b);
};

// * 大小单位换算

export const sizeMatrixing = strSize => {
  if (!strSize) return 0;
  if (typeof strSize === "number") return strSize;
  let unit = strSize.match(/[a-zA-Z]/g);
  let num = strSize.match([/[^a-zA-Z]/g]).join(",");
  // 表示没有输入单位
  if (unit === null) {
    return +strSize;
  }
  let lowerCaseUnit = unit[0].toLowerCase();
  // kb
  if (lowerCaseUnit === "k") return +num * Math.pow(1024, 1);
  // mb
  if (lowerCaseUnit === "m") return +num * Math.pow(1024, 2);
  // gb
  if (lowerCaseUnit === "g") return +num * Math.pow(1024, 3);
  return null;
};

// 是否是空数组
export const isValidArray = val => Array.isArray(val) && val.length > 0;

// 空对象
export const isValidObject = val =>
  getElementType(val) === "Object" && Object.keys(val).length > 0;

// 判断当前浏览器是否支持插件环境

export const isInstallChromeExtension = () => typeof chrome && !!chrome.runtime;

// 一次删除多个数组下标

export const spliceMultiArrayIndex = (arr,indexList)=>{
 if(!Array.isArray(arr)) return arr
 for(const i of indexList){
   arr[i] = null

 }
 return arr.filter(item=>item !== null )
}





// 获取常见的上传文件素材的类型

export const getFileType = (file) =>{
  const map = {
    'FFD8FFE0': 'jpg',
    'FFD8FFFE':'jpeg',
    'FFD8FFE1':'jpg',
    'FFD8FFDB':'jpg',
    '89504E47': 'png',
    '47494638': 'gif',
    "52494646": 'webp',
    '00000018': 'mp4',
    '6D6F6F76': 'mov',
    '1A45DFA3' : 'mkv',
    '41564920':'avi',
    '464C5601':'flv',
    '424D':'bmp',
    '00000020':'m4a',
}

const reader = new FileReader();
reader.readAsArrayBuffer(file);
return new Promise((resolve, reject) => {
    reader.onload = (event) => {
      try {
          let array = new Uint8Array(event.target.result);
          array = array.slice(0, 4);
          let arr = [...array]
          let key = arr.map(item => item.toString(16)
              .toUpperCase()
              .padStart(2, '0'))
              .join('')
          resolve(map[key])

      } catch (e) {
          reject(e);
      }
    };
});
}

// ! 判断对象属性和值是否相等

//eq是第一层比较，主要进行简单的筛别
var toString = Object.prototype.toString;

function isFunction(obj) {
  return toString.call(obj) === "[object Function]";
}

// ! 求两个数的最大公约数
export const gcd = (a, b) => {
  if (+b === 0) return +a;
  let r = +a % +b;
  return gcd(+b, +r);
};

export const isFalsy = val => {
  return val !== val || val === undefined || val === null || val === '';
};

export const isEqual = (a, b, aStack, bStack) => {
  // === 结果为 true 的区别出 +0 和 -0
  if (a === b) return a !== 0 || 1 / a === 1 / b;

  // typeof null 的结果为 object ，这里做判断，是为了让有 null 的情况尽早退出函数
  if (a == null || b == null) return false;

  // 判断 NaN
  if (a !== a) return b !== b;

  // 判断参数 a 类型，如果是基本类型，在这里可以直接返回 false
  var type = typeof a;
  if (type !== "function" && type !== "object" && typeof b != "object")
    return false;

  // 更复杂的对象使用 deepEq 函数进行深度比较
  return deepEq(a, b, aStack, bStack);
};

function deepEq(a, b, aStack, bStack) {
  // a 和 b 的内部属性 [[class]] 相同时 返回 true
  var className = toString.call(a);
  if (className !== toString.call(b)) return false;

  switch (className) {
    case "[object RegExp]":
    case "[object String]":
      return "" + a === "" + b;
    case "[object Number]":
      if (+a !== +a) return +b !== +b;
      return +a === 0 ? 1 / +a === 1 / b : +a === +b;
    case "[object Date]":
    case "[object Boolean]":
      return +a === +b;
  }

  var areArrays = className === "[object Array]";
  // 不是数组
  if (!areArrays) {
    // 过滤掉两个函数的情况
    if (typeof a != "object" || typeof b != "object") return false;

    var aCtor = a.constructor,
      bCtor = b.constructor;
    // aCtor 和 bCtor 必须都存在并且都不是 Object 构造函数的情况下，aCtor 不等于 bCtor， 那这两个对象就真的不相等啦
    if (
      aCtor == bCtor &&
      !(
        isFunction(aCtor) &&
        aCtor instanceof aCtor &&
        isFunction(bCtor) &&
        bCtor instanceof bCtor
      ) &&
      "constructor" in a && "constructor" in b
    ) {
      return false;
    }
  }

  aStack = aStack || [];
  bStack = bStack || [];
  var length = aStack.length;

  // 检查是否有循环引用的部分
  while (length--) {
    if (aStack[length] === a) {
      return bStack[length] === b;
    }
  }

  aStack.push(a);
  bStack.push(b);

  // 数组判断
  if (areArrays) {
    length = a.length;
    if (length !== b.length) return false;

    while (length--) {
      if (!isEqual(a[length], b[length], aStack, bStack)) return false;
    }
  }
  // 对象判断
  else {
    var keys = Object.keys(a),
      key;
    length = keys.length;

    if (Object.keys(b).length !== length) return false;
    while (length--) {
      key = keys[length];
      if (!(b.hasOwnProperty(key) && isEqual(a[key], b[key], aStack, bStack)))
        return false;
    }
  }

  aStack.pop();
  bStack.pop();
  return true;
}
