<template>
  <el-popover placement="bottom" width="320" trigger="click">
    <el-cascader
      :options="fieldsData"
      placeholder="选择展示字段"
      :props="{
        multiple: true,
        label: 'fieldName',
        value: 'fieldCode',
        checkStrictly: true,
      }"
      v-model="selectFields"
      collapse-tags
      clearable
      @change="handleChangeFields"
    >
    </el-cascader>
    <el-checkbox
      style="margin-left: 10px"
      v-model="selectAllFields"
      @change="handleCheckAllFields"
      >全选</el-checkbox
    >
    <!-- <el-input
      slot="reference"
      placeholder="平台 / 公众号"
      clearable
      @clear="handleClear"
      style="width: 150px"
      :value="showText"
    /> -->
    <el-cascader
      class="cascader-out"
      slot="reference"
      disabled
      :options="fieldsData"
      placeholder="选择展示字段"
      :props="{
        multiple: true,
        label: 'fieldName',
        value: 'fieldCode',
        checkStrictly: true,
      }"
      v-model="selectFields"
      collapse-tags
      clearable
      @change="handleChangeFields"
    >
    </el-cascader>
  </el-popover>
</template>

<script>
export default {
  name: 'SelectPlatformChannel',
  props: {
    fieldsData: Array,
    originFeild: {
      default: () => [],
      type: Array
    },
    EchoField: Array
  },
  data () {
    return {
      selectFields: [],
      selectAllFields: false,
      showField: [],
      showText: '123'
    }
  },
  watch: {
    originFeild: function () {
      this.selectFields = this.EchoField
    },
    EchoField: function () {
      if (this.EchoField.length === 0) {
        this.selectFields = []
      }
    }
  },
  computed: {},
  methods: {
    handleClear () {},
    // 选择字段变化
    handleChangeFields () {
      if (this.selectFields.length !== this.originFeild.length) {
        this.selectAllFields = false
      } else {
        this.selectAllFields = true
      }
      this.showField = this.originFeild.filter((item) => {
        const flag = this.selectFields.find((n) => item.fieldCode === n[0])
        if (flag) {
          return item
        }
      })
      // console.log(this.selectFields, "selectFields");
      this.$emit('fieldChange', this.selectFields, this.showField)
      // this.$emit('fieldChange', this.showField)
    },
    // 字段全选
    handleCheckAllFields (value) {
      if (value) {
        this.selectFields = []
        for (let i = 0; i < this.originFeild.length; i++) {
          this.selectFields.push([this.originFeild[i].fieldCode])
        }
        this.selectFields = Array.from(new Set(this.selectFields))
      } else {
        this.selectFields = []
      }
      this.showField = this.originFeild.filter((item) => {
        const flag = this.selectFields.find((n) => item.fieldCode === n[0])
        if (flag) {
          return item
        }
      })
      this.$emit('fieldChange', this.selectFields, this.showField)
      // this.$emit('fieldChange', this.showField)
    }
  }
  // mounted() {
  //   this.selectFields = this.EchoField;
  //   console.log(this.selectFields, "in mounted");
  // },
}
</script>

<style scoped lang="scss">
.cascader-out{
  /deep/.el-input.is-disabled .el-input__inner{
    background-color: #fff;
    cursor: pointer;
  }
  /deep/ .el-input__suffix{
    display: none;
  }
}
</style>
