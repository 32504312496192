import request from './config'

// 获取所有团队
export function getAllTeam () {
  return request.get('/dept')
}

// 新增团队
export function addTeam (params) {
  return request.post('/dept', params)
}

// 修改团队
export function editTeam (id, params) {
  return request.put(`/dept/${id}`, params)
}

// 删除团队
export function deleteTeam (deptId) {
  return request.delete(`/dept/${deptId}`)
}

// 获取团队下用户
export function getTeamUserList (deptId, params) {
  return request.get(`/dept/member/${deptId}`, { params })
}

// 新增用户
export function addTeamUser (deptId, adminUserIds) {
  return request.post(`/dept/member/${deptId}`, { adminUserIds })
}

// 移除团队下用户
export function removeTeamUser (relationIds) {
  return request.delete(`/dept/member/${relationIds}`)
}

// 获取用户所在团队
export function getUserTeam (adminUserId) {
  return request.get(`/dept/${adminUserId}`)
}

// 转移用户
export function moveUser (adminUserId, deptId) {
  return request.put(`/dept/move/${adminUserId}?deptId=${deptId}`)
}
