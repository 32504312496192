<template>
  <div>
    <div class="slider-box" @mouseup="handleSliderMouseUp">
      <div class="slider-bar" @mouseleave="handleSliderMouseUp">
        <div class="slider-bar-container" style="width: 400px">
          <div
            class="slider-button"
            style="left: 0%"
            id="btOne"
            :data-index="buttonIndexOne"
            @mousedown.stop="handleSliderMouseDown"
          ></div>
          <div
            class="slider-button"
            style="left: 0%"
            id="btTwo"
            :data-index="buttonIndexTwo"
            @mousedown.stop="handleSliderMouseDown"
          ></div>
          <div
            class="slider-active"
            :style="'left:' + activeLeft + ';width:' + activeWidth + ';'"
          ></div>
          <div
            class="slider-stop"
            @mouseenter="handletest"
            :data-index="n"
            :class="m >= priceData[0] && m <= priceData[1] ? 'active' : ''"
            v-for="(m, n) in priceDots"
            :key="n"
            :style="'left:' + m / 110 + '%'"
          ></div>
          <div class="slider-mark">
            <div
              class="slider-mark-text"
              v-for="(m, n) in priceDots"
              :key="n"
              :style="'left:' + m / 110 + '%'"
            >
              {{
                m === 2000 || m === 5000 ? m + "元" : m === 10000 ? "1万元" : ""
              }}
            </div>
          </div>
        </div>
      </div>
      <div class="slider-result-box">{{ this.valueText }}</div>
    </div>
    <div class="quick-set">
      <div class="quick-set-name">快捷设置：</div>
      <ul class="quick-set-menu" @click="handleQuickSet">
        <li class="ad-color-text-link" data-type="4-8">2000-4000元</li>
        <li class="ad-color-text-link" data-type="8-10">4000-6000元</li>
        <li class="ad-color-text-link" data-type="10-12">6000-8000元</li>
        <li class="ad-color-text-link" data-type="12-15">8000-11000元</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    price: {
      type: Array,
      default: () => [0, 10],
    },
  },
  data() {
    return {
      priceDots: [
        0, 500, 1000, 1500, 2000, 2500, 3000, 3500, 4000, 5000, 6000, 7000,
        8000, 9000, 10000, 11000,
      ],
      startDom: null,
      endDom: null,
      startX: null,
      startLeft: null,
      buttonIndexOne: 0,
      buttonIndexTwo: 0,
      MouseOverFlag: false,
      activeLeft: 0,
      activeWidth: 0,
      valueText: "不限",
      priceData: [0, 0],
    };
  },
  computed: {},
  methods: {
    getPriceText() {
      let tmpArr = [];
      if (this.buttonIndexOne > this.buttonIndexTwo) {
        tmpArr = [this.buttonIndexTwo, this.buttonIndexOne];
      } else {
        tmpArr = [this.buttonIndexOne, this.buttonIndexTwo];
      }
      this.priceData = [this.priceDots[tmpArr[0]], this.priceDots[tmpArr[1]]];
      this.$emit("change", this.priceData);
      if (
        this.priceDots[tmpArr[1]] === this.priceDots[this.priceDots.length - 1]
      ) {
        if (tmpArr[0] === this.priceDots[0]) {
          return (this.valueText = "不限");
        }
        return (this.valueText = this.priceDots[tmpArr[0]] + "元以上");
      }
      if (tmpArr[0] === this.priceDots[0]) {
        return (this.valueText = this.priceDots[tmpArr[1]] + "元以内");
      }
      return (this.valueText =
        this.priceDots[tmpArr[0]] + "元-" + this.priceDots[tmpArr[1]] + "元");
    },
    handleSliderMouseDown(e) {
      if (e.button !== 0) {
        return;
      }
      this.startDom = e.target;
      this.startLeft = this.startDom.style.left.split("%")[0];
      this.startX = e.clientX;
      this.MouseOverFlag = true;
    },
    handletest(e) {
      if (!this.MouseOverFlag) {
        return;
      }
      let tmpIndex = parseInt(e.target.dataset.index);
      this.startDom.style.left = this.priceDots[tmpIndex] / 110 + "%";
      if (this.startDom.id === "btOne") {
        this.buttonIndexOne = tmpIndex;
      } else {
        this.buttonIndexTwo = tmpIndex;
      }
      this.handleChangeActiveWidth();
    },
    // 修改有效条长度
    handleChangeActiveWidth() {
      let tmpArr = [];
      if (this.buttonIndexOne > this.buttonIndexTwo) {
        tmpArr = [this.buttonIndexTwo, this.buttonIndexOne];
      } else {
        tmpArr = [this.buttonIndexOne, this.buttonIndexTwo];
      }
      let tmpWidth =
        (this.priceDots[tmpArr[1]] - this.priceDots[tmpArr[0]]) / 110;
      let tmpLeft = this.priceDots[tmpArr[0]] / 110;
      this.activeLeft = tmpLeft + "%";
      this.activeWidth = tmpWidth + "%";
      this.getPriceText();
    },
    handleSliderMouseUp() {
      this.MouseOverFlag = false;
    },
    handleQuickSet(e) {
      if (!e.target.dataset.type) {
        return;
      }
      let tmpArr = e.target.dataset.type.split("-");
      this.handleSetPrice(tmpArr);
    },
    handleSetPrice(tmpArr) {
      this.buttonIndexOne = parseInt(tmpArr[0]);
      this.buttonIndexTwo = parseInt(tmpArr[1]);
      this.startDom = document.getElementById("btOne");
      this.endDom = document.getElementById("btTwo");
      this.startDom.style.left =
        this.priceDots[this.buttonIndexOne] / 110 + "%";
      this.endDom.style.left = this.priceDots[this.buttonIndexTwo] / 110 + "%";
      let tmpWidth =
        (this.priceDots[tmpArr[1]] - this.priceDots[tmpArr[0]]) / 110;
      let tmpLeft = this.priceDots[tmpArr[0]] / 110;
      this.activeLeft = tmpLeft + "%";
      this.activeWidth = tmpWidth + "%";
      this.getPriceText();
    },
  },
  created() {
    this.priceData = this.price;
  },
  mounted() {
    let tmpIndexOne = this.priceDots.findIndex((item) => {
      return item == this.priceData[0];
    });
    let tmpIndexTwo = this.priceDots.findIndex((item) => {
      return item == this.priceData[1];
    });
    if (tmpIndexOne === -1 || tmpIndexTwo === -1) {
      this.handleSetPrice([0, 10]);
    } else {
      this.handleSetPrice([tmpIndexOne, tmpIndexTwo]);
    }
  },
};
</script>

<style lang='scss' scoped>
.slider-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  .slider-bar {
    width: 100%;
    position: relative;
    padding: 10px 0;
    .slider-bar-container {
      border-radius: 3px;
      height: 8px;
      background-color: #ebebeb;
      position: relative;
      .slider-button {
        width: 16px;
        height: 16px;
        background-color: #fff;
        border: 2px solid #00bf8a;
        position: absolute;
        border-radius: 100%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        cursor: pointer;
        z-index: 100;
      }
      .slider-active {
        height: 100%;
        border-radius: 3px;
        background-color: #00bf8a;
        position: absolute;
        top: 0;
      }
      .slider-stop {
        width: 12px;
        height: 12px;
        background-color: #fff;
        border: 3px solid #ebebeb;
        position: absolute;
        border-radius: 100%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        cursor: pointer;
        &.active {
          border: 3px solid #00bf8a;
        }
      }
      .slider-mark {
        position: absolute;
        top: 20px;
        left: 0;
        width: 100%;
        .slider-mark-text {
          position: absolute;
          top: 0;
          transform: translateX(-50%);
          color: #333;
          font-weight: 400;
          font-size: 12px;
        }
      }
    }
  }
  .slider-result-box {
    width: 160px;
    padding-left: 20px;
    font-size: 12px;
  }
}
.quick-set {
  margin-top: 12px;
  padding-top: 12px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  .quick-set-name {
    width: 80px;
    font-size: 14px;
  }
  .quick-set-menu {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .ad-color-text-link {
      color: #00bf8a;
      margin-left: 24px;
      font-size: 14px;
      cursor: pointer;
    }
  }
}
</style>