<template>
  <div>
    <el-drawer
      :title="showTitle"
      append-to-body
      :destroy-on-close="true"
      :visible.sync="showDrawer"
      :with-header="true"
      :size="drawerSize"
      :before-close="
        () => {
          $emit('close');
          showTemplate = null;
        }
      "
    >
      <template slot="title">
        <span>{{ showTitle }}</span>
        <div class="test">
          <el-button
            v-if="drawerSize !== '90%'"
            size="mini"
            @click="handleChangeSize"
          >
            {{ isFullScreen ? "缩小" : "放大" }}
          </el-button>
        </div>
      </template>
      <component :is="showTemplate" :lastParams="nextParams"> </component>
      <!-- <channel-consume
        v-if="showTemplate === 'channelConsume'"
        :lastParams="nextParams"
      /> -->
    </el-drawer>
  </div>
</template>

<script>
import channelConsume from "@/views/statistics/consume/channelConsume";
import adConsume from "@/views/statistics/consume/adConsume";
import bookConsume from "@/views/statistics/consume/bookConsume";
import userConsume from "@/views/statistics/consume/userConsume";
import platformStatics from "@/views/statistics/platformStatics";
import intervalChannel from "@/views/statistics/channelData/intervalChannel";
import intervalBook from "@/views/statistics/channelData/intervalBook";
import orderDetail from "@/views/statistics/order/orderDetail";
import consumeCharging from "@/views/statistics/order/consumeCharging";
import channelData from "@/views/statistics/operate/channelData";
import monthDetail from "@/views/statistics/multiStatistics/monthDetail";
import month from "@/views/statistics/multiStatistics/month";
import userRecharge from "@/views/statistics/userRecharge";
import operateData from "@/views/statistics/operate/operateData";
import customerMessage from "@/views/home/operateRealTime/customerMessage";
import putProject from "@/views/statistics/today/put.vue";
import intervalUser from "@/views/statistics/channelData/intervalUser.vue";
import { mapMutations } from "vuex";
export default {
  name: "CommonDrawer",
  props: {
    showDrawer: Boolean,
    size: String,
    template: {
      default: null,
      type: String,
    },
    nextParams: Object,
  },
  data() {
    return {
      showTemplate: null,
      drawerSize: null,
      isFullScreen: false,
      originSize: "",
      currentView: null,
    };
  },
  components: {
    channelConsume,
    adConsume,
    bookConsume,
    userConsume,
    platformStatics,
    intervalChannel,
    orderDetail,
    consumeCharging,
    channelData,
    monthDetail,
    month,
    userRecharge,
    operateData,
    customerMessage,
    putProject, // 新增“计划”跳转
    intervalBook,
    intervalUser,
  },
  watch: {
    showDrawer: function (newval, oldval) {
      if (newval) {
        this.isFullScreen = false;
        this.addDrawerCount();
        switch (this.$store.state.drawerCount) {
          case 1:
            this.drawerSize = "90%";
            this.originSize = "90%";
            break;
          case 2:
            this.drawerSize = "70%";
            this.originSize = "70%";
            break;
          default:
            this.drawerSize = "50%";
            this.originSize = "50%";
            break;
        }
        setTimeout(() => {
          this.showTemplate = this.template;
        }, 100);
      } else {
        this.delDrawerCount();
      }
    },
  },
  computed: {
    showTitle: function () {
      switch (this.showTemplate) {
        case "channelConsume":
          return "每日公众号消耗";
        case "adConsume":
          return "今日广告";
        case "bookConsume":
          return "每日书籍消耗";
        case "userConsume":
          return "每日用户消耗";
        case "platformStatics":
          return "平台统计";
        case "intervalChannel":
          return "区间公众号消耗";
        case "orderDetail":
          return "订单明细";
        case "consumeCharging":
          return "消耗充值";
        case "channelData":
          return "每日公众号数据";
        case "monthDetail":
          return "月度统计详情";
        case "month":
          return "月度统计";
        case "userRecharge":
          return "用户复充";
        case "operateData":
          return "运营数据";
        case "customerMessage":
          return "客户信息统计";
        case "putProject":
          return "计划详情";
        case "intervalBook":
          return "区间书籍消耗";
        case "intervalUser":
          return "区间用户消耗";
        default:
          return "标题";
      }
    },
  },
  methods: {
    ...mapMutations({
      addDrawerCount: "addDrawerCount",
      delDrawerCount: "delDrawerCount",
    }),
    handleChangeSize() {
      if (this.isFullScreen) {
        this.drawerSize = this.originSize;
        this.isFullScreen = !this.isFullScreen;
      } else {
        this.drawerSize = "90.01%";
        this.isFullScreen = true;
      }
    },
  },
  beforeCreate() {
    this.$options.components.channelConsume =
      require("@/views/statistics/consume/channelConsume").default;
    this.$options.components.platformStatics =
      require("@/views/statistics/platformStatics").default;
    this.$options.components.orderDetail =
      require("@/views/statistics/order/orderDetail").default;
    this.$options.components.consumeCharging =
      require("@/views/statistics/order/consumeCharging").default;
    this.$options.components.intervalChannel =
      require("@/views/statistics/channelData/intervalChannel").default;
    this.$options.components.channelData =
      require("@/views/statistics/operate/channelData").default;
    this.$options.components.monthDetail =
      require("@/views/statistics/multiStatistics/monthDetail").default;
    this.$options.components.month =
      require("@/views/statistics/multiStatistics/month").default;
    this.$options.components.userRecharge =
      require("@/views/statistics/userRecharge").default;
    this.$options.components.operateData =
      require("@/views/statistics/operate/operateData").default;
    this.$options.components.putProject =
      require("@/views/statistics/today/put.vue").default;
  },

  beforeDestroy() {
    this.showTemplate = null;
  },
};
</script>

<style lang="scss" scoped>
/deep/ #el-drawer__title {
  font-weight: bold;
}
.test {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 20px;
}
.el-button--mini {
  padding: 7px 15px !important;
  font-size: 12px !important;
  border-radius: 3px !important;
}
</style>
