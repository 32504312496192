<template>
  <div class="ad-consume padding-20 order-detail box-shadow-light-grey">
    <!-- 条件搜索 -->
    <div class="filter-box">
      <!-- <h4 class="filter-title">今日广告</h4> -->
      <div class="row">
        <el-date-picker
          v-model="params.selectTime"
          style="width: 330px"
          type="datetimerange"
          align="right"
          unlink-panels
          :clearable="false"
          range-separator="-"
          format="yyyy-MM-dd HH:mm:ss"
          value-format="yyyy-MM-dd HH:mm:ss"
          :default-time="['00:00:00', '23:59:59']"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
        <select-platform-channel :params="params" />
        <el-select
          v-model="params.orderType"
          clearable
          placeholder="订单状态"
          style="width: 100px"
        >
          <el-option label="已支付" :value="1"></el-option>
          <el-option label="已退款" :value="4"></el-option>
        </el-select>
        <!-- <el-select
          v-model="params.adminUserId"
          filterable
          remote
          clearable
          style="width: 150px"
          reserve-keyword
          placeholder="用户"
        >
          <el-option
            v-for="item in userList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select> -->
        <select-dept-user :params="params" />
        <el-input
          placeholder="订单id"
          v-model="params.orderId"
          type="number"
          style="width: 170px"
          clearable
        >
        </el-input>
        <el-input
          placeholder="来源小说"
          v-model="params.originNovel"
          type="string"
          style="width: 170px"
          clearable
        >
        </el-input>
        <select-field
          :fieldsData="fieldsData"
          :originFeild="originFeild"
          :EchoField="sendField"
          @fieldChange="handleFieldChange"
        />
        <el-button
          type="primary"
          @click="handlePageChange(1)"
          style="margin-left: 10px"
          :loading="loadingGraph || loading"
        >
          搜索
        </el-button>
        <el-button
          type="info"
          @click="handleReset"
          :loading="loadingGraph || loading"
        >
          重置
        </el-button>
      </div>
    </div>
    <div
      class="data-box"
      :style="
        lastParams
          ? 'height: 760px; overflow: auto'
          : 'height: 690px; overflow: auto'
      "
    >
      <!-- 汇总数据 -->
      <div>
        <div
          class="statistics-card"
          v-for="(item, index) in totalData"
          :key="index"
        >
          <div class="data-label">{{ item.label }}</div>
          <div class="statistic-num-text">
            <span class="statistic-num">{{ item.value || 0 }}</span>
          </div>
        </div>
      </div>
      <!-- 订单小时线形图 -->
      <div
        v-loading="loadingGraph"
        id="chargingGraph"
        :style="'width: 100%;height: 300px;z-index:1;'"
      ></div>
      <!-- 表格数据 -->
      <el-table
        header-row-class-name="table-header"
        ref="userConsumeTable"
        class="consume-table"
        :data="dataList"
        style="width: 100%"
        height="620px"
        v-loading="loading"
        empty-text="暂无数据"
        @sort-change="handleSortChange"
      >
        <el-table-column
          v-for="(item, index) in filFields"
          :key="index"
          :sortable="sortableType(item)"
          :align="filAlign(item)"
          :prop="item.fieldCode"
          :label="item.fieldName"
          :min-width="item.width + 'px' || '100px'"
        >
          <template slot-scope="scope">
            <div v-if="item.hasChild">
              <div
                v-for="(item2, index2) in filChildrenFieds(item.children)"
                :key="index2"
                class="children-fieds"
                :style="item2.fieldCode !== 'avatar' ? 'line-height: 19px' : ''"
              >
                <template v-if="item2.fieldCode === 'avatar'">
                  {{ scope.row[item2.fieldCode] ? item2.fieldName + ":" : "" }}
                  <img
                    v-if="scope.row[item2.fieldCode]"
                    class="order-avatar"
                    :src="scope.row[item2.fieldCode]"
                  />
                </template>
                <template v-else>
                  {{
                    scope.row[item2.fieldCode] && item2.fieldName !== ""
                      ? item2.fieldName + ":"
                      : ""
                  }}
                  <span :style="'color:' + item.fontColor">{{
                    scope.row[item2.fieldCode]
                  }}</span>
                </template>

                <!-- <span class="primary-num">{{ scope.row[item2.fieldCode] }}</span> -->
              </div>
            </div>
            <span v-else :style="'color:' + item.fontColor">{{
              scope.row[item.fieldCode] | sortField(item)
            }}</span>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        style="margin-top: 10px; text-align: right"
        background
        @current-change="handlePageChange"
        :current-page="page"
        :page-size="15"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
      <!-- 公共抽屉 -->
      <common-drawer
        :showDrawer="showCommonDrawer"
        size="90%"
        @close="showCommonDrawer = false"
        :template="showTemplate"
        :nextParams="nextParams"
      />
    </div>
  </div>
</template>

<script>
import {
  formatThousandsDots,
  formatThousands,
  sortBy,
  getTodyTime,
  isValidArray
} from "@/assets/js/utils";
import SelectDeptUser from "@/components/SelectDeptUser";
import echarts from "echarts";
import SelectField from "@/components/SelectField";
import SelectPlatformChannel from "@/components/SelectPlatformChannel";
import { getBookList } from "@/api/account";
import { getOrderDetail, getFields, getHourStatistics } from "@/api/statics";
import {
  yesterdayOptions,
  labelList,
  pickerOptions,
} from "@/assets/js/options";
import { mapActions, mapGetters } from "vuex";
import CommonDrawer from "@/components/commonDrawer.vue";
export default {
  name: "channelConsume",
  props: {
    lastParams: Object,
  },
  components: {
    CommonDrawer,
    SelectPlatformChannel,
    SelectField,
    SelectDeptUser,
  },
  computed: {
    ...mapGetters(["userList"]),
    filFields: function () {
      // var _that = this
      const tmpFilFields = this.showField.filter(function (item) {
        return item.autoFields !== 1;
      });
      return tmpFilFields;
    },
    // 排序处理
    sortableType() {
      return function (value) {
        if (value.autoFields === 0) {
          return false;
        } else if (value.autoFields === 2 || value.autoFields === 4) {
          return true;
        } else if (value.autoFields === 1 || value.autoFields === 3) {
          return "custom";
        }
      };
    },
    filChildrenFieds() {
      return function (value) {
        // var _that = this
        return value.filter(function (item) {
          // 过滤首页展示字段
          return item.autoFields === 0;
        });
      };
    },
    // 文字对齐处理
    filAlign() {
      return function (value) {
        switch (value.fontAlign) {
          case 0:
            return "left";
          case 1:
            return "center";
          case 2:
            return "right";
          default:
            return "center";
        }
      };
    },
  },
  filters: {
    sortField: function (value, item) {
      if (item.autoFields === 4 || item.autoFields === 5) {
        if (value !== undefined) {
          return formatThousandsDots(value.toString());
        }
      } else {
        return value;
      }
    },
  },
  watch: {
    lastParams: function () {
      this.getData("drawer");
    },
  },
  data() {
    return {
      params: {},
      bookList: null,
      fieldsData: [],
      originFeild: [],
      selectFields: null,
      selectAllFields: false,
      statistic: {},
      dataList: [],
      totalData: [],
      loading: false,
      loadingGraph: false,
      sortName: null,
      sortDesc: null,
      page: 1,
      total: 0,
      pageSize: 0,
      todayAdData: {},
      showRecharge: false,
      yesterdayOptions,
      labelList,
      sendField: [],
      showField: [],
      tmpShowField: [],
      showCommonDrawer: false,
      showTemplate: null,
      nextParams: {},
      pickerOptions,
      chargingChart: null,
    };
  },
  methods: {
    // 线形图数据处理
    // 订单小时线性图
    setOptionOrderHourGraph(data) {
      const { hours, dataList } = data;
      const seriesData = dataList.map((item) => {
        return {
          name: item.name,
          data: item.data,
          type: "line",
          smooth: true,
        };
      });
      this.chargingChart.setOption(
        {
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "cross",
              label: {
                backgroundColor: "#6a7985",
              },
            },
            formatter: (params) => {
              // params为悬浮框上的全部数据
              const newParams = [];
              const paramsData = params.sort(sortBy("value", false)); // 根据value值降序
              paramsData.forEach((p) => {
                // p.marker为对应数据线的颜色的圆点
                // p.seriesName为对应数据的数据名称
                // p.value为对应数据的值
                const cont =
                  p.marker +
                  " " +
                  p.seriesName +
                  " : " +
                  formatThousands(p.value) +
                  "元" +
                  "<br/>";
                newParams.push(cont);
              });
              return newParams.join(" "); // 这里是需要将数组转化成字符串显示，如果不转化，每个数据前面都会出现一个逗号（，），_.join为lodash的方法
            },
          },
          legend: {
            show: false,
            data: dataList.map((item) => item.name),
          },
          toolbox: {
            feature: {
              saveAsImage: {},
            },
          },
          dataZoom: [
            {
              type: "slider",
              start: 0,
              end: 100,
            },
            {
              type: "inside",
              start: 10,
              end: 100,
            },
          ],
          grid: {
            top: "15%",
            left: "3%",
            right: "3%",
            bottom: "10%",
            containLabel: true,
          },
          xAxis: [
            {
              type: "category",
              boundaryGap: false,
              data: hours,
              name: "时",
            },
          ],
          yAxis: [
            {
              type: "value",
              name: "税后金额",
            },
          ],
          series: seriesData,
        },
        true
      );
    },
    // 初始化线形图
    initGraph() {
      this.chargingChart = echarts.init(
        document.getElementById("chargingGraph")
      );

      window.onresize = this.chargingChart.resize;
    },
    // 表格点击事件
    ...mapActions({
      addRouterHistory: "addRouterHistory",
    }),
    // 组件字段变化
    // handleFieldChange (value) {
    //   if (value.length === 0) {
    //     this.showField = this.originFeild
    //   } else {
    //     this.showField = value
    //   }
    //   this.$nextTick(() => {
    //     this.$refs.channelConsumeTable.doLayout()
    //   })
    // },
    // 获取书籍列表
    getBook(bookName) {
      getBookList({
        page: 1,
        pageSize: 50,
        bookName,
      }).then((res) => {
        this.bookList = res.list;
      });
    },
    // 排序处理
    handleSortChange({ column, prop, order }) {
      if (column.sortable !== "custom") {
        return;
      }
      this.sortName = prop;
      this.sortDesc = order === "descending";
      this.params.sortName = this.sortName;
      this.params.sortDesc = this.sortDesc;
      this.handlePageChange(1);
    },
    // 重置操作
    handleReset() {
      this.params.selectTime = getTodyTime();
      this.page = 1;
      this.params.adminUserId = null;
      this.params.deptId = null;
      this.originPlatform = null;
      this.orderId = null;
      this.orderType = null;
      this.beginDate = null;
      this.endDate = null;
      this.accountChannelId = null;
      this.adminUserId = null;
      this.originNovel = null;
      this.sortName = null;
      this.sortDesc = null;
      this.sendField = [];
      this.showField = this.originFeild;
      this.tmpShowField = [];
      this.handlePageChange(1);
      // this.showField = this.originFeild;
    },
    // 分页操作
    handlePageChange(page) {
      this.page = page;
      this.getData();
    },
    // 选择字段变化
    handleFieldChange(send, show) {
      // if (value.length === 0) {
      //   this.showField = this.originFeild;
      // } else {
      //   this.showField = value;
      // }
      // 请求格式field
      this.sendField = send;
      // 展示格式field
      this.tmpShowField = show;
    },
    // 请求前处理字段
    handleFields(item) {
      if (item.length === 0) {
        return null;
      }
      let tmpSend = [];
      const _that = this;
      tmpSend = item.reduce(function (a, b) {
        return a.concat(b);
      });
      // 判断勾选项 推入其下所有子级
      for (let i = 0; i < tmpSend.length; i++) {
        const flagIndex = _that.originFeild.findIndex((item2) => {
          return item2.fieldCode === tmpSend[i];
        });
        if (flagIndex !== -1 && "children" in _that.originFeild[flagIndex]) {
          _that.originFeild[flagIndex].children.forEach((item3) => {
            tmpSend.push(item3.fieldCode);
          });
        }
      }
      // 去重
      tmpSend = Array.from(new Set(tmpSend));
      return tmpSend.join(",");
    },
    // 广告数据获取
    getData(flag) {
      // return false;
      const tody = getTodyTime();
      if (!this.params.selectTime) {
        this.$set(this.params, "selectTime", tody);
      }
      // this.params.queryDate ? '' : (this.params.queryDate = tody)
      this.loading = true;
      this.loadingGraph = true;
      // 处理后的请求字段 如果选择过字段则使用选择字段sendfield 否则传null
      let tmpFields = null;
      if (this.sendField.length > 0) {
        tmpFields = this.handleFields(this.sendField);
      } else {
        this.showField = this.originFeild;
      }
      let tmpform = {};
      // 如果是创建过程 参数默认取缓存参数 否则使用筛选参数
      if (flag === "created") {
        tmpform = {
          page: this.page,
          originPlatform: this.params.originPlatform,
          orderId: this.params.orderId,
          orderType: this.params.orderType,
          beginDate: this.params.selectTime[0],
          endDate: this.params.selectTime[1],
          accountChannelId: this.params.accountChannelId,
          adminUserId: this.params.adminUserId,
          deptId: this.params.deptId,
          originNovel: this.params.originNovel,
          sortName: this.sortName,
          sortDesc: this.sortDesc,
          showFieldCodes: tmpFields,
        };
      } else if (flag === "drawer") {
        tmpform = {
          ...this.lastParams,
        };

        this.params = { ...this.lastParams };
      } else {
        tmpform = {
          page: this.page,
          originPlatform: this.params.originPlatform,
          orderId: this.params.orderId,
          orderType: this.params.orderType,
          beginDate: this.params.selectTime[0],
          endDate: this.params.selectTime[1],
          accountChannelId: this.params.accountChannelId,
          adminUserId: this.params.adminUserId,
          deptId: this.params.deptId,
          originNovel: this.params.originNovel,
          sortName: this.sortName,
          sortDesc: this.sortDesc,
          showFieldCodes: tmpFields,
        };
      }
      // ! 添加全局年数据
      // tmpform.year = new Date(this.$store.state.globalYear).getFullYear()
       const {stateYears} = this.$store.state
      tmpform.beginYear  = isValidArray(stateYears) ? stateYears[0] || null : null
      tmpform.endYear = isValidArray(stateYears) ? stateYears[1] || null :null
      // 表格数据获取
      getOrderDetail(tmpform)
        .then((res) => {
          this.total = res.orderDetail.total;
          this.pageSize = res.orderDetail.pageSize;
          this.totalData = res.showSumFields;
          // 如果用户选择过字段则表格展示用户选择字段
          if (this.tmpShowField.length > 0) {
            this.showField = this.tmpShowField;
          }
          this.dataList = res.orderDetail.list;
          // 如果不是抽屉 缓存参数
          if (flag !== "drawer") {
            this.addRouterHistory({
              path: this.$route.path,
              params: {
                ...tmpform,
                cacheShowField: this.showField,
                cacheSendField: this.sendField,
                selectTime: this.params.selectTime,
              },
            });
          }
        })
        .finally(() => (this.loading = false));
      // 线形图数据获取
      getHourStatistics(tmpform)
        .then((res) => {
          this.setOptionOrderHourGraph(res);
        })
        .finally(() => {
          this.loadingGraph = false;
        });
    },
    // 获取字段
    handleGetFeilds(flag = "created") {
      getFields({ dataType: "orderDetail" }).then((res) => {
        this.originFeild = res;
        const tmpdata = [];
        for (let i = 0; i < res.length; i++) {
          tmpdata.push({
            fieldName: res[i].fieldName,
            fieldCode: res[i].fieldCode,
          });
        }
        this.fieldsData = tmpdata;
        // 若显示字段为空则显示全部
        if (this.showField.length === 0) {
          this.showField = res;
        }
        this.selectAllFields = true;
        this.getData(flag);
        // 判断字段所选长度是否为0 是则全选 否则不全选
      });
    },
    // 处理字段
  },
  created() {
    // this.$set(this.params, "selectTime", []);
    this.getBook();
    if (this.lastParams) {
      this.handleGetFeilds("drawer");
      return false;
    }
    // this.params.queryDate = formatTime(new Date(), "yyyy-MM-dd");
    // 缓存获取
    const tmpIndex = this.$store.state.routerHistory.findIndex(
      (item) => item.path === this.$route.path
    );
    // 若存在缓存
    if (tmpIndex !== -1) {
      this.params = this.$store.state.routerHistory[tmpIndex].params;
      // 获取缓存展示字段
      this.showField =
        this.$store.state.routerHistory[tmpIndex].params.cacheShowField;
      // 获取缓存发送前格式字段
      this.sendField =
        this.$store.state.routerHistory[tmpIndex].params.cacheSendField;
    }
    this.handleGetFeilds();
    // this.getData()
  },

  mounted() {
    this.initGraph();
  },
  beforeCreate() {
    this.$options.components.CommonDrawer =
      require("@/components/commonDrawer").default;
  },
  // 销毁echart不销毁会存在内存溢出
  destroyed() {
    if (this.chargingChart) {
      echarts.dispose(this.chargingChart);
    }
  },
};
</script>

<style lang="scss" scoped>
.ad-consume {
  background-color: #fff;
  border-radius: 6px;
}
// 筛选条件框
.filter-box {
  border-radius: 6px;
  // border: 1px solid #eceff2;
  .filter-title {
    color: $main-text;
    margin-bottom: 20px;
    .iconfont {
      font-size: 12px !important;
    }
  }
}
.order-avatar {
  width: 40px;
  height: 40px;
  vertical-align: middle;
  border-radius: 40px;
}
.order-detail /deep/ tr td {
  font-size: 12px !important;
}
</style>
