<template>
  <div
    class="charging-box padding-20 box-shadow-light-grey"
    :style="
      lastParams
        ? 'height: 850px; overflow: auto'
        : 'height: 780px; overflow: auto'
    "
  >
    <!-- 订单小时消耗充值表格 -->
    <div class="chargin-table">
      <!-- 条件搜索 -->
      <div class="filter-box">
        <!-- <h4 class="filter-title">今日广告</h4> -->
        <div class="row">
          <el-date-picker
            :clearable="false"
            v-model="paramsTable.queryDate"
            type="datetime"
            style="width: 200px"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
            :picker-options="{
              disabledDate(time) {
                return time > Date.now();
              },
            }"
            placeholder="选择日期"
          >
          </el-date-picker>
          <!-- <el-select
            v-model="paramsTable.labelType"
            style="width: 170px"
            clearable
            placeholder="请选择标签"
          >
            <el-option
              v-for="(item, index) in labelList"
              :key="index"
              :label="item"
              :value="index"
            >
            </el-option>
          </el-select> -->
          <select-platform-channel :params="paramsTable" />
          <select-dept-user :params="paramsTable" />
          <el-input
            placeholder="订单id1"
            v-model="paramsTable.orderId"
            type="number"
            style="width: 170px"
            clearable
          >
          </el-input>
          <el-input
            placeholder="来源小说"
            v-model="paramsTable.originNovel"
            type="string"
            style="width: 170px"
            clearable
          >
          </el-input>
          <select-field
            :fieldsData="fieldsDataTable"
            :originFeild="originFeildTable"
            :EchoField="sendFieldTable"
            @fieldChange="handleFieldChangeTable"
          />
          <el-button
            type="primary"
            @click="handlePageChangeTable(1)"
            style="margin-left: 10px"
            :loading="loadingTable"
          >
            搜索
          </el-button>
          <el-button
            type="info"
            @click="handleResetTable"
            :loading="loadingTable"
          >
            重置
          </el-button>
        </div>
      </div>
      <!-- 表格数据 -->
      <el-table
        header-row-class-name="table-header"
        ref="userConsumeTable"
        class="consume-table"
        :data="dataListTable"
        style="width: 100%"
        height="688px"
        v-loading="loadingTable"
        empty-text="暂无数据"
        :row-class-name="tableRowClassName"
        @sort-change="handleSortChange"
      >
        <el-table-column
          prop="title"
          align="left"
          label="标签"
          width="110"
          style="color: rgb(39, 39, 39)"
        >
        </el-table-column>
        <el-table-column prop="dates" align="center" label="缩略图" width="200">
          <template slot-scope="scope">
            {{ drawEcharts(scope.row, scope.$index) }}
            <div
              :id="`orderChargingTable` + scope.$index"
              class="tiger-trend-charts"
            ></div>
          </template>
        </el-table-column>
        <el-table-column
          align="right"
          v-for="(head, index3) in extraHeader"
          :key="index3 + 'extra'"
          :label="head"
          prop="head"
          min-width="140px"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.amounts" style="padding-right: 10px">
              {{ scope.row.amounts[index3] | handleThousandsDots }}
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!-- 公共抽屉 -->
      <common-drawer
        :showDrawer="showCommonDrawer"
        size="90%"
        @close="showCommonDrawer = false"
        :template="showTemplate"
        :nextParams="nextParams"
      />
    </div>
    <!-- 订单小时消耗充值线形图 -->
    <!-- <div class="chargin-graph">
      <div class="filter-box">
        <div class="row">
          <el-date-picker
            :clearable="false"
            v-model="paramsTable.queryDate"
            type="datetime"
            style="width: 200px"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
            :picker-options="{
              disabledDate(time) {
                return time > Date.now();
              },
            }"
            placeholder="选择日期"
          >
          </el-date-picker>
          <select-platform-channel :params="paramsTable" />
          <el-select
            v-model="paramsTable.adminUserId"
            filterable
            clearable
            style="width: 150px"
            reserve-keyword
            placeholder="用户"
          >
            <el-option
              v-for="item in userList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <el-input
            placeholder="订单id"
            v-model="paramsTable.orderId"
            type="number"
            style="width: 170px"
            clearable
          >
          </el-input>
          <el-input
            placeholder="来源小说"
            v-model="paramsTable.originNovel"
            type="string"
            style="width: 170px"
            clearable
          >
          </el-input>
          <el-button
            type="primary"
            @click="handlePageChangeTable(1)"
            style="margin-left: 10px"
          >
            搜索
          </el-button>
          <el-button type="danger" @click="handleResetTable"> 重置 </el-button>
        </div>
      </div>
      <div
        id="charginGraph"
        :style="'width: 100%;height: 300px;z-index:1;'"
      ></div>
    </div> -->
  </div>
</template>

<script>
import { formatThousandsDots, formatTime } from "@/assets/js/utils";
// import ExcludeQuery from '@/components/excludeQuery'
import SelectDeptUser from "@/components/SelectDeptUser";
import SelectField from "@/components/SelectField";
import SelectPlatformChannel from "@/components/SelectPlatformChannel";
import { getBookList } from "@/api/account";
import { getHoursCost, getFields } from "@/api/statics";
import { yesterdayOptions, labelList } from "@/assets/js/options";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { isValidArray } from "@/assets/js/utils";
import echarts from "echarts";
export default {
  name: "consumeCharging",
  props: {
    lastParams: Object,
  },
  components: {
    // ExcludeQuery,
    SelectDeptUser,
    SelectPlatformChannel,
    SelectField,
  },
  computed: {
    ...mapGetters(["userList"]),
    // 排序处理
    sortableType() {
      return function (value) {
        if (value.autoFields === 0) {
          return false;
        } else if (value.autoFields === 2 || value.autoFields === 4) {
          return true;
        } else if (value.autoFields === 1 || value.autoFields === 3) {
          return "custom";
        }
      };
    },
  },
  filters: {
    sortField: function (value, item) {
      if (item.autoFields === 4 || item.autoFields === 5) {
        // 报错处理
        if (value !== undefined) {
          return formatThousandsDots(value.toString());
        }
      } else {
        return value;
      }
    },
    handleThousandsDots: function (value) {
      return formatThousandsDots(value.toString());
    },
  },
  data() {
    return {
      // 表格相关数据
      paramsTable: {},
      bookList: null,
      fieldsDataTable: [],
      originFeildTable: [],
      selectFieldsTable: null,
      dataListTable: [],
      totalDataTable: [],
      loadingTable: false,
      sortName: null,
      sortDesc: null,
      yesterdayOptions,
      labelList,
      sendFieldTable: [],
      showFieldTable: [],
      tmpShowFieldTable: [],
      // 线形图相关数据
      loadingGraph: false,
      sendFieldGraph: [],
      showFieldGraph: [],
      tmpShowFieldGraph: [],
      extraHeader: [],
      // 抽屉相关数据
      showCommonDrawer: false,
      showTemplate: null,
      nextParams: {},
      chargingChart: null,
    };
  },
  watch: {
    lastParams: function () {
      this.getDataTable("drawer");
    },
  },
  methods: {
    tableRowClassName({ row }) {
      switch (row.title) {
        case "账户消耗":
          return "charging-row row-ac";
        case "新增充值":
          return "charging-row row-nc";
        case "老用户充值":
          return "charging-row row-oc";
        case "利润充值":
          return "charging-row row-pc";
        default:
          break;
      }
    },
    // 表格点击事件
    ...mapActions({
      addRouterHistory: "addRouterHistory",
    }),
    ...mapMutations({
      setGlobalYearSchedule: "setGlobalYearSchedule",
    }),
    // 获取书籍列表
    getBook(bookName) {
      getBookList({
        page: 1,
        pageSize: 50,
        bookName,
      }).then((res) => {
        this.bookList = res.list;
      });
    },
    // 排序处理
    handleSortChange({ column, prop, order }) {
      if (column.sortable !== "custom") {
        return;
      }
      this.sortName = prop;
      this.sortDesc = order === "descending";
      this.paramsTable.sortName = this.sortName;
      this.paramsTable.sortDesc = this.sortDesc;
      this.handlePageChange(1);
    },
    // 表格相关操作--------------------------------->
    //
    // 缩略图绘制
    drawEcharts(data, index) {
      // data.amounts.forEach((item) => {
      //   YData.push(formatThousandsDots(item.toString()));
      // });
      // 绘制趋势echarts
      const option = {
        color: ["#00bf8a", "ff7800"],
        tooltip: {
          padding: [3],
          trigger: "axis",
          // confine: true,
          // formatter: "{b}<br>{c}",
          formatter: (params) => {
            // params为悬浮框上的全部数据
            return (
              params[0].name +
              "<br>" +
              formatThousandsDots(params[0].value.toString())
            );
          },
          position: [20, -5],
        },
        xAxis: {
          type: "category",
          data: data.dates,
          show: false,
        },
        yAxis: {
          show: false,
          boundaryGap: false,
          type: "value",
        },
        series: [
          {
            data: data.amounts,
            type: "line",
          },
        ],
      };
      const chartId = `orderChargingTable${index}`;
      this.$nextTick(() => {
        const myChart = echarts.init(document.getElementById(chartId));
        myChart.setOption(option);
        myChart.resize();
      });
    },
    // 重置操作
    handleResetTable() {
      this.paramsTable.queryDate = formatTime(
        new Date(),
        "yyyy-MM-dd 23:59:59"
      );
      this.paramsTable.originPlatform = null;
      this.paramsTable.accountChannelId = null;
      this.paramsTable.adminUserId = null;
      this.paramsTable.deptId = null;
      this.paramsTable.originNovel = null;
      this.paramsTable.orderId = null;
      this.paramsTable.sendFieldTable = [];
      this.paramsTable.showFieldTable = this.originFeildTable;
      this.paramsTable.tmpShowFieldTable = [];
      // this.paramsTable.labelType = null;
      this.sendFieldTable = [];
      this.showFieldTable = this.originFeildTable;
      this.tmpShowFieldTable = [];
      this.handlePageChangeTable(1);
    },
    // 分页操作
    handlePageChangeTable(page) {
      this.page = page;
      this.getDataTable();
    },
    // 选择字段变化
    handleFieldChangeTable(send, show) {
      // 请求格式field
      this.sendFieldTable = send;
      // 展示格式field
      this.tmpShowFieldTable = show;
    },
    // 请求前处理字段
    handleFieldsTable(item) {
      if (item.length === 0) {
        return null;
      }
      let tmpSend = [];
      const _that = this;
      tmpSend = item.reduce(function (a, b) {
        return a.concat(b);
      });
      // 判断勾选项 推入其下所有子级
      for (let i = 0; i < tmpSend.length; i++) {
        const flagIndex = _that.originFeildTable.findIndex((item2) => {
          return item2.fieldCode === tmpSend[i];
        });
        if (
          flagIndex !== -1 &&
          "children" in _that.originFeildTable[flagIndex]
        ) {
          _that.originFeildTable[flagIndex].children.forEach((item3) => {
            tmpSend.push(item3.fieldCode);
          });
        }
      }
      // 去重
      tmpSend = Array.from(new Set(tmpSend));
      return tmpSend.join(",");
    },
    // 数据表格获取
    getDataTable(flag) {
      // this.params.queryDate ? '' : (this.params.queryDate = tody)
      this.loadingTable = true;
      let tmpFields = null;
      if (this.sendFieldTable.length > 0) {
        tmpFields = this.handleFieldsTable(this.sendFieldTable);
      } else {
        this.showFieldTable = this.originFeildTable;
      }
      let tmpform = {};
      // 如果是创建过程 参数默认取缓存参数 否则使用筛选参数
      if (flag === "drawer") {
        tmpform = {
          ...this.lastParams,
        };
        this.paramsTable = { ...this.lastParams };
      } else {
        tmpform = {
          queryDate: this.paramsTable.queryDate,
          originPlatform: this.paramsTable.originPlatform,
          accountChannelId: this.paramsTable.accountChannelId,
          adminUserId: this.paramsTable.adminUserId,
          deptId: this.paramsTable.deptId,
          originNovel: this.paramsTable.originNovel,
          orderId: this.paramsTable.orderId,
          // labelType: this.paramsTable.labelType,
          showFieldCodes: tmpFields,
        };
      }
      const { stateYears } = this.$store.state;
      tmpform.beginYear = isValidArray(stateYears)
        ? stateYears[0] || null
        : null;
      tmpform.endYear = isValidArray(stateYears) ? stateYears[1] || null : null;
      getHoursCost(tmpform)
        .then((res) => {
          // 如果用户选择过字段则表格展示用户选择字段
          // if (this.tmpShowFieldTable.length > 0) {
          //   this.showFieldTable = this.tmpShowFieldTable;
          // }
          this.extraHeader = res.dates;
          this.dataListTable = res.data;
          // 如果不是抽屉 缓存参数
          if (flag !== "drawer") {
            // 缓存参数
            this.addRouterHistory({
              path: this.$route.path + "Table",
              params: {
                ...tmpform,
                cacheShowField: this.showFieldTable,
                cacheSendField: this.sendFieldTable,
              },
            });
          }
        })
        .finally(() => (this.loadingTable = false));
    },
    // 获取字段
    handleGetFeildsTable(flag = "created") {
      getFields({ dataType: "channelHoursCost" }).then((res) => {
        this.originFeildTable = res;
        const tmpdata = [];
        for (let i = 0; i < res.length; i++) {
          tmpdata.push({
            fieldName: res[i].fieldName,
            fieldCode: res[i].fieldCode,
          });
        }
        this.fieldsDataTable = tmpdata;
        // 若显示字段为空则显示全部
        if (this.showFieldTable.length === 0) {
          this.showFieldTable = res;
        }
        this.getDataTable(flag);
        // 判断字段所选长度是否为0 是则全选 否则不全选
      });
    },
    // 处理字段
    // 线形图相关操作-------------------------->
    initGraph() {
      this.chargingChart = echarts.init(
        document.getElementById("chargingGraph")
      );

      window.onresize = this.chargingChart.resize;
    },
    // 订单小时线性图

    // 线形图数据获取
    getDataGraph() {
      // this.params.queryDate ? '' : (this.params.queryDate = tody)
      this.loadingGraph = true;
      let tmpFields = null;
      if (this.sendFieldGraph.length > 0) {
        tmpFields = this.handleFieldsGraph(this.sendFieldGraph);
      } else {
        this.showFieldGraph = this.originFeildGraph;
      }
      let tmpform = {};
      // 如果是创建过程 参数默认取缓存参数 否则使用筛选参数
      tmpform = {
        queryDate: this.paramsTable.queryDate,
        originPlatform: this.paramsTable.originPlatform,
        accountChannelId: this.paramsTable.accountChannelId,
        originNovel: this.paramsTable.originNovel,
        orderId: this.paramsTable.orderId,
        // labelType: this.paramsTable.labelType,
        showFieldCodes: tmpFields,
      };
      getHoursCost(tmpform)
        .then((res) => {
          // 如果用户选择过字段则表格展示用户选择字段
          if (this.tmpShowFieldTable.length > 0) {
            this.showFieldTable = this.tmpShowFieldTable;
          }
          this.dataListTable = res;
          // 缓存参数
          this.addRouterHistory({
            path: this.$route.path + "Table",
            params: {
              ...tmpform,
              cacheShowField: this.showFieldTable,
              cacheSendField: this.sendFieldTable,
            },
          });
        })
        .finally(() => (this.loadingTable = false));
    },
  },
  created() {
    if (this.lastParams) {
      this.handleGetFeildsTable("drawer");
      return false;
    }

    // todo => 全局
    const year = new Date().getFullYear();
    this.setGlobalYearSchedule([`${year}`, `${year}`]);

    const todyTable = formatTime(new Date(), "yyyy-MM-dd 23:59:59");
    this.$set(this.paramsTable, "queryDate", todyTable);
    // 缓存获取
    const tmpIndexTable = this.$store.state.routerHistory.findIndex(
      (item) => item.path === this.$route.path + "Table"
    );
    // 若存在缓存
    if (tmpIndexTable !== -1) {
      this.paramsTable = this.$store.state.routerHistory[tmpIndexTable].params;
      // 获取缓存展示字段
      this.showFieldTable =
        this.$store.state.routerHistory[tmpIndexTable].params.cacheShowField;
      // 获取缓存发送前格式字段
      this.sendFieldTable =
        this.$store.state.routerHistory[tmpIndexTable].params.cacheSendField;
    }
    // this.params.queryDate = formatTime(new Date(), "yyyy-MM-dd");
    this.handleGetFeildsTable();
    this.getDataTable();
  },
};
</script>

<style lang="scss" scoped>
.charging-box {
  background-color: #fff;
  border-radius: 6px;
}
// 筛选条件框
.filter-box {
  border-radius: 6px;
  // border: 1px solid #eceff2;
  .filter-title {
    color: $main-text;
    margin-bottom: 20px;
    .iconfont {
      font-size: 12px !important;
    }
  }
}
.tiger-trend-charts {
  height: 80px;
  min-width: 100px;
}
// 每行数据颜色不同 td:first-child
// /deep/ .charging-row td:first-child{
//  color: rgb(39, 39, 39) !important;
// }
/deep/ .row-ac {
  color: rgb(255, 120, 0) !important;
}
/deep/ .row-nc {
  color: rgb(35, 169, 35) !important;
}
/deep/ .row-oc {
  color: rgb(199, 21, 133) !important;
}
/deep/ .row-pc {
  color: rgb(4, 132, 4) !important;
}
// /deep/ .row-om{
//  color: rgb(4, 132, 4) !important;
// }
</style>
