<template>
  <div class="ad-consume padding-20 box-shadow-light-grey">
    <!-- 条件搜索 -->
    <div class="filter-box">
      <!-- <h4 class="filter-title">今日广告</h4> -->
      <div class="row">
        <exclude-query :params="params" />
        <select-platform-channel :params="params" />
        <el-select
          v-model="params.booksId"
          filterable
          remote
          clearable
          style="width: 150px"
          reserve-keyword
          placeholder="书籍名称"
          :remote-method="getBook"
        >
          <el-option
            v-for="item in bookList"
            :key="item.id"
            :label="item.bookName"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <select-dept-user :params="params" />
        <el-select
          v-model="params.isTest"
          clearable
          placeholder="类型"
          style="width: 100px"
        >
          <el-option label="普通号" :value="0"></el-option>
          <el-option label="测书号" :value="1"></el-option>
        </el-select>
        <el-select
          v-model="params.labelType"
          clearable
          placeholder="标签"
          style="width: 100px"
        >
          <el-option label="腾讯" :value="1"></el-option>
          <el-option label="百度" :value="2"></el-option>
          <el-option label="头条" :value="3"></el-option>
          <el-option label="GDT" :value="4"></el-option>
        </el-select>
        <!-- 创意人员 -->
        <el-select
          v-model="params.ideaUserId"
          filterable
          clearable
          placeholder="创意人员"
          style="width: 100px"
        >
          <el-option
            v-for="item in userList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <select-field
          :fieldsData="fieldsData"
          :originFeild="originFeild"
          :EchoField="sendField"
          @fieldChange="handleFieldChange"
        />
        <el-button
          type="primary"
          @click="handlePageChange(1)"
          :loading="loading"
        >
          搜索
        </el-button>
        <el-button type="info" @click="handleReset" :loading="loading">
          重置
        </el-button>
      </div>
    </div>
    <!-- 汇总数据 -->
    <div>
      <div
        class="statistics-card"
        v-for="(item, index) in totalData"
        :key="index"
      >
        <div class="data-label">{{ item.label }}</div>
        <div class="statistic-num-text">
          <span class="statistic-num">{{ item.value || 0 }}</span>
        </div>
      </div>
    </div>
    <!-- 表格数据 -->
    <el-table
      id="consumeTable"
      header-row-class-name="table-header"
      ref="adConsumeTable"
      class="consume-table"
      :data="dataList"
      style="width: 100%"
      :height="lastParams ? '640px' : '565px'"
      v-loading="loading"
      empty-text="暂无数据"
      @sort-change="handleSortChange"
    >
      <el-table-column
        v-for="(item, index) in showField"
        :key="index"
        :prop="item.fieldCode"
        :label="item.fieldName"
        :sortable="sortableType(item)"
        :align="filAlign(item)"
        :min-width="item.width + 'px' || '100px'"
      >
        <template slot-scope="scope">
          <div
            v-if="
              item.hasChild &&
              item.fieldCode !== 'images' &&
              item.fieldCode !== 'plan'
            "
          >
            <div v-for="(item2, index2) in item.children" :key="index2">
              {{ item2.fieldName }}:
              <span :style="'color:' + item.fontColor">{{
                scope.row[item2.fieldCode]
              }}</span>
            </div>
          </div>
          <div v-else-if="item.hasChild && item.fieldCode === 'images'">
            <el-popover
              v-if="scope.row.crtInfo"
              placement="right"
              width="330"
              trigger="hover"
            >
              <preview-advert
                :desc="scope.row.desc"
                :channel-name="scope.row.channelName"
                :crt-info="scope.row.crtInfo"
              />
              <img
                v-if="
                  JSON.parse(scope.row.crtInfo) &&
                  JSON.parse(scope.row.crtInfo).image
                "
                :src="JSON.parse(scope.row.crtInfo).image[0]"
                @click="handleCrtInfo(scope.row)"
                slot="reference"
                alt=""
                class="min-img"
              />
              <img
                v-else-if="
                  JSON.parse(scope.row.crtInfo) &&
                  JSON.parse(scope.row.crtInfo).video
                "
                src="@/assets/img/video.png"
                @click="handleCrtInfo(scope.row)"
                slot="reference"
                alt=""
                class="min-img"
                style="cursor: pointer"
              />
              <img
                v-else
                src="https://h5.file.yayawx.cn/novel_put/2020121750082.png"
                @click="handleCrtInfo(scope.row)"
                slot="reference"
                alt=""
                class="min-img"
                style="cursor: pointer"
              />
              <!-- <span slot="reference">{{
                JSON.parse(scope.row.crtInfo).image
              }}</span> -->
            </el-popover>
          </div>
          <div v-else-if="item.fieldCode === 'aname'">
            <p class="line-tow">
              {{ scope.row.aname }}
            </p>
            <p
              class="tip-text adId"
              :style="'color:' + item.fontColor"
              @click="handleadvertShow(scope.row, 1)"
            >
              {{ scope.row.aid }}
            </p>
            <span v-if="scope.row.labelType" class="label-type">
              <template v-if="scope.row.labelType === 1">
                <img src="@/assets/img/qq.png" alt="" />
              </template>
              <template v-else-if="scope.row.labelType === 2">
                <img src="@/assets/img/baidu.png" alt="" />
              </template>
              <template v-else-if="scope.row.labelType === 3">
                <img
                  @click="handleShowRecordDetail(scope.row)"
                  style="cursor: pointer"
                  src="@/assets/img/toutiao.png"
                  alt="123"
                />
              </template>
              <template v-else-if="scope.row.labelType === 4">
                <img src="@/assets/img/gdt.png" />
              </template>
            </span>
            <!-- <p
              @click="handleShowRecordDetail(scope.row)"
              v-if="scope.row.labelType === 3"
              class="tip-text adId"
            >
              记录详情
            </p> -->
          </div>
          <div v-else-if="item.fieldCode === 'plan'">
            <p class="line-tow">{{ scope.row.cname }}</p>
            <p
              class="tip-text adId"
              :style="'color:' + item.fontColor"
              @click="handleadvertShow(scope.row, 2)"
            >
              {{ scope.row.cid }}
            </p>
          </div>
          <span :style="'color:' + item.fontColor" v-else>{{
            scope.row[item.fieldCode] | sortField(item)
          }}</span>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="margin-top: 10px; text-align: right"
      background
      @current-change="handlePageChange"
      :current-page="page"
      :page-size="15"
      layout="total, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
    <ad-recharge
      :show="showRecharge"
      @close="showRecharge = false"
      :account-channel-id="todayAdData ? todayAdData.accountChannelId : null"
      :channel-name="todayAdData ? todayAdData.channelName : null"
      :todayAdData="todayAdData"
    />
    <CreateInfo
      :show="showCreateInfo"
      :baiduId="baiduId"
      :type="type"
      @close="showCreateInfo = false"
    />
    <!-- 记录详情 -->
    <el-drawer
      title="记录详情"
      :visible.sync="showRecordDetail"
      size="1700px"
      append-to-body
    >
      <recordDetail
        v-if="showRecordDetail"
        :detailId="recordDetailId"
        :detailType="'plan'"
        @close="showRecordDetail = false"
      />
    </el-drawer>
  </div>
</template>

<script>
import recordDetail from "../../small-plane/batch-put/recordDetail.vue";
import { formatThousandsDots } from "@/assets/js/utils";
import ExcludeQuery from "@/components/excludeQuery";
import SelectDeptUser from "@/components/SelectDeptUser";
import SelectField from "@/components/SelectField";
import SelectPlatformChannel from "@/components/SelectPlatformChannel";
import { getBookList } from "@/api/account";
import { getAdvertList, getFields } from "@/api/statics";
import PreviewAdvert from "./components/preview";
import AdRecharge from "./components/todyAdRecharge";
import CreateInfo from "@/components/todayAdCreateInfo";
import { mapActions, mapGetters } from "vuex";
let timer = null;
export default {
  name: "adConsume",
  props: {
    lastParams: Object,
  },
  components: {
    ExcludeQuery,
    SelectDeptUser,
    SelectPlatformChannel,
    PreviewAdvert,
    AdRecharge,
    SelectField,
    CreateInfo,
    recordDetail,
  },
  watch: {
    lastParams: function () {
      this.getData("drawer");
    },
  },
  computed: {
    ...mapGetters(["userList"]),
    filFields: function () {
      var _that = this;
      return this.fieldsData.filter(function (item) {
        if (item.fieldCode === "returnRateList") {
          _that.autoFieldsChildren = item.children;
        }
        // 处理创意预览多图

        // 是否是详情页 否过滤 是显示除autofields字段所有字段
        return item.autoFields !== 1;
      });
    },
    filChildrenFieds() {
      return function (value) {
        var _that = this;
        return value.filter(function (item) {
          // 过滤首页展示字段
          const flag = _that.showFieldCodes.findIndex((item2) => {
            return item2 === item.fieldCode;
          });
          if (_that.detail) {
            return item.autoFields === 0;
          } else {
            return item.autoFields === 0 && flag !== -1;
          }
        });
      };
    },
    // 排序处理
    sortableType() {
      return function (value) {
        if (value.autoFields === 0) {
          return false;
        } else if (value.autoFields === 2 || value.autoFields === 4) {
          return true;
        } else if (value.autoFields === 1 || value.autoFields === 3) {
          return "custom";
        }
      };
    },
    // 文字对齐处理
    filAlign() {
      return function (value) {
        switch (value.fontAlign) {
          case 0:
            return "left";
          case 1:
            return "center";
          case 2:
            return "right";
          default:
            return "center";
        }
      };
    },
  },
  filters: {
    sortField: function (value, item) {
      if (item.autoFields === 4 || item.autoFields === 5) {
        if (value !== undefined) {
          return formatThousandsDots(value.toString());
        }
      } else {
        return value;
      }
    },
  },
  data() {
    return {
      params: {},
      bookList: null,
      fieldsData: [],
      originFeild: [],
      selectFields: null,
      statistic: {},
      dataList: [],
      totalData: [],
      loading: false,
      page: 1,
      total: 0,
      sortName: null,
      sortDesc: null,
      todayAdData: {},
      showRecharge: false,
      sendField: [],
      showField: [],
      tmpShowField: [],
      // 今日广告相关
      sortName: null,
      showRecharge: false,
      todayAdData: {},
      params: {},
      showCreateInfo: false,
      baiduId: null,
      type: 2, // 默认百度
      // 记录详情
      showRecordDetail: false,
      recordDetailId: null,
    };
  },
  methods: {
    // 展示记录详情
    // 今日广告在这里
    handleShowRecordDetail(row) {
      this.recordDetailId = row.aid;
      // this.recordDetailId = 1707768384364788;
      this.showRecordDetail = true;
    },
    // 创意预览展示
    handleCrtInfo(data) {
      if (data.labelType === 2 || data.labelType === 3) {
        if (data.labelType === 2) this.type = 2;
        if (data.labelType === 3) this.type = 3;
        this.baiduId = data.aid;
        this.showCreateInfo = true;
      }
    },
    ...mapActions({
      addRouterHistory: "addRouterHistory",
    }),
    // 组件字段变化
    handleFieldChange(send, show) {
      // if (value.length === 0) {
      //   this.showField = this.originFeild;
      // } else {
      //   this.showField = value;
      // }
      // 请求格式field
      this.sendField = send;
      // 展示格式field
      this.tmpShowField = show;
      this.$nextTick(() => {
        this.$refs.adConsumeTable.doLayout();
      });
    },
    // 投放广告处理
    handleadvertShow(row, type) {
      this.todayAdData = row;
      this.todayAdData._type = type;
      this.showRecharge = true;
    },
    // 获取书籍列表
    getBook(bookName) {
      getBookList({
        page: 1,
        pageSize: 50,
        bookName,
      }).then((res) => {
        this.bookList = res.list;
      });
    },
    // 排序处理
    handleSortChange({ column, prop, order }) {
      if (column.sortable !== "custom") {
        return;
      }
      this.sortName = prop;
      this.sortDesc = order === "descending";
      this.params.sortName = this.sortName;
      this.params.sortDesc = this.sortDesc;
      this.handlePageChange(1);
    },
    // 重置操作
    handleReset() {
      this.params.originPlatform = null;
      this.params.accountChannelId = null;
      this.params.booksId = null;
      this.params.adminUserId = null;
      this.params.deptId = null;
      this.params.isTest = null;
      this.params.ruledOutBooksIds = [];
      this.params.ruledOutAccountChannelIds = [];
      this.params.labelType = null;
      this.sortName = null;
      this.sortDesc = null;
      this.sendField = [];
      this.showField = this.originFeild;
      this.tmpShowField = [];
      this.handlePageChange(1);
      // this.showField = this.originFeild;
    },
    // 分页操作
    handlePageChange(page) {
      this.page = page;
      this.getData();
    },

    // 请求前处理字段
    handleFields(item) {
      if (item.length === 0) {
        return null;
      }
      let tmpSend = [];
      const _that = this;
      tmpSend = item.reduce(function (a, b) {
        return a.concat(b);
      });
      // 判断勾选项 推入其下所有子级
      for (let i = 0; i < tmpSend.length; i++) {
        const flagIndex = _that.originFeild.findIndex((item2) => {
          return item2.fieldCode === tmpSend[i];
        });
        if (flagIndex !== -1 && "children" in _that.originFeild[flagIndex]) {
          _that.originFeild[flagIndex].children.forEach((item3) => {
            tmpSend.push(item3.fieldCode);
          });
        }
      }
      // 去重
      tmpSend = Array.from(new Set(tmpSend));
      return tmpSend.join(",");
    },
    // 广告数据获取
    getData(flag) {
      this.loading = true;
      // 处理后的请求字段 如果选择过字段则使用选择字段sendfield 否则传null
      let tmpFields = null;
      if (this.sendField.length > 0) {
        tmpFields = this.handleFields(this.sendField);
      } else {
        this.showField = this.originFeild;
      }
      let tmpform = {};
      // 如果是创建过程 参数默认取缓存参数 否则使用筛选参数
      if (flag === "created") {
        tmpform = {
          page: this.page,
          originPlatform: this.params.originPlatform,
          accountChannelId: this.params.accountChannelId,
          booksId: this.params.booksId,
          adminUserId: this.params.adminUserId,
          sortName: this.sortName,
          sortDesc: this.sortDesc,
          deptId: this.params.deptId,
          isTest: this.params.isTest,
          labelType: this.params.labelType,
          ideaUserId: this.params.ideaUserId,
          ruledOutBooksIds:
            this.params.ruledOutBooksIds && this.params.ruledOutBooksIds.length
              ? this.params.ruledOutBooksIds
              : null,
          ruledOutAccountChannelIds:
            this.params.ruledOutAccountChannelIds &&
            this.params.ruledOutAccountChannelIds.length
              ? this.params.ruledOutAccountChannelIds
              : null,
          showFieldCodes: tmpFields,
        };
      } else if (flag === "drawer") {
        tmpform = {
          ...this.lastParams,
        };

        this.params = { ...this.lastParams };
      } else {
        tmpform = {
          page: this.page,
          originPlatform: this.params.originPlatform,
          accountChannelId: this.params.accountChannelId,
          booksId: this.params.booksId,
          adminUserId: this.params.adminUserId,
          sortName: this.sortName,
          sortDesc: this.sortDesc,
          deptId: this.params.deptId,
          isTest: this.params.isTest,
          labelType: this.params.labelType,
          ideaUserId: this.params.ideaUserId,
          ruledOutBooksIds:
            this.params.ruledOutBooksIds && this.params.ruledOutBooksIds.length
              ? this.params.ruledOutBooksIds.map((item) => item.id).join()
              : null,
          ruledOutAccountChannelIds:
            this.params.ruledOutAccountChannelIds &&
            this.params.ruledOutAccountChannelIds.length
              ? this.params.ruledOutAccountChannelIds.join()
              : null,
          showFieldCodes: tmpFields,
        };
      }

      getAdvertList(tmpform)
        .then((res) => {
          // 如果用户选择过字段则表格展示用户选择字段
          if (this.tmpShowField.length > 0) {
            this.showField = this.tmpShowField;
          }
          const { list, showSumFields } = res;
          this.totalData = showSumFields;
          this.dataList = list.list;
          this.total = list.total;
          // 如果不是抽屉 缓存参数
          if (flag !== "drawer") {
            this.addRouterHistory({
              path: this.$route.path,
              params: {
                ...tmpform,
                cacheShowField: this.showField,
                cacheSendField: this.sendField,
              },
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    // 获取字段
    handleGetFeilds(flag = "created") {
      getFields({ dataType: "adGroup" }).then((res) => {
        this.originFeild = res;
        const tmpdata = [];
        // 处理掉子级
        for (let i = 0; i < res.length; i++) {
          tmpdata.push({
            fieldName: res[i].fieldName,
            fieldCode: res[i].fieldCode,
          });
        }
        this.fieldsData = tmpdata;
        // 若显示字段为空则显示全部
        if (this.showField.length === 0) {
          this.showField = res;
        }
        this.selectAllFields = true;
        this.getData(flag);
      });
    },
    // 处理字段
  },
  created() {
    if (this.lastParams) {
      this.handleGetFeilds("drawer");
      return false;
    }
    const tmpIndex = this.$store.state.routerHistory.findIndex(
      (item) => item.path === this.$route.path
    );
    // 若存在缓存
    if (tmpIndex !== -1) {
      this.params = this.$store.state.routerHistory[tmpIndex].params;
      // 获取缓存展示字段
      this.showField =
        this.$store.state.routerHistory[tmpIndex].params.cacheShowField;
      // 获取缓存发送前格式字段
      this.sendField =
        this.$store.state.routerHistory[tmpIndex].params.cacheSendField;
    }
    this.getBook();
    this.handleGetFeilds();
    clearInterval(timer);
    timer = setInterval(this.getData, 1000 * 5 * 60);
  },
  // mounted: function () {
  //   let tody = formatTime(new Date(), "yyyy-MM-dd");
  //   Watermark.set(this.$store.state.userInfo.name + " " + tody, "consumeTable");
  // },
  beforeDestroy() {
    clearInterval(timer);
    timer = null;
  },
};
</script>

<style lang="scss" scoped>
.ad-consume {
  background-color: #fff;
  border-radius: 6px;
}
// 筛选条件框
.filter-box {
  border-radius: 6px;
  // border: 1px solid #eceff2;
  .filter-title {
    color: $main-text;
    margin-bottom: 20px;
    .iconfont {
      font-size: 12px !important;
    }
  }
}
.consume-table {
  .adId {
    text-decoration: underline;
    cursor: pointer;
  }
  .min-img {
    width: 70px;
    height: 70px;
  }
}

// 广告名称徽标
.label-type {
  font-size: 12px;
  vertical-align: text-top;
  img {
    margin-left: 5px;
    width: 10px;
    height: 10px;
  }
}
.label-type-1 {
  color: rgb(0, 191, 138);
}
.label-type-2 {
  color: rgb(253, 93, 90);
}
</style>
