import request from './config'

/*
 *转化追踪相关==================================>
//==============================================>
 */

//  获取转化追踪列表
export function getConvertList(params) {
  return request.get('/convert', { params })
}

// 新增转化追踪
export function addConvert(params) {
  return request.post('/convert', params)
}
// 修改转化追踪
export function updateConvert(id, params) {
  return request.put(`/convert/${id}`, params)
}
// 删除转化追踪
export function deleteConvert(id) {
  return request.delete(`/convert/${id}`)
}
// 上传转化追踪
export function uploadConvert(id) {
  return request.put(`/convert/${id}`)
}

// 获取巨量引擎账号备注
export function getOeRemark(params) {
  return request.get('/oeRemark', { params })
}
// 新增巨量引擎账号备注
export function addOemark(params) {
  return request.post('/oeRemark', params)
}
// 修改巨量引擎账号备注
export function updateOemark(id, params) {
  return request.put(`/oeRemark/${id}`, params)
}
// 删除巨量引擎账号
export function deleteOemark(ids) {
  return request.delete(`/oeRemark/${ids}`)
}

// 批量投放相关==================================>
//= =============================================>

// 获取动态创意词包
export function getCreativeWord(params) {
  return request.get('/tools/creativeWord', { params })
}

// 获取行业列表
export function getIndustryList(params) {
  return request.get('/tools/industry', { params })
}

// 获取标题包
export function getDocumentPackage(params) {
  return request.get('/document/package', { params })
}
// 获取已上传转化跟踪
export function getConvertLine(params) {
  return request.get('/convert/line', { params })
}

// 查询已上传广告组
export function getuploadAd(params) {
  return request.get('/uploadAd', { params })
}



// 推广卡片相关==================================>
//==============================================>
//查询推广卡片
export function getPromotionCard(params) {
  return request.get('/promotionCard', { params })
}

// 新增推广卡片
export function addPromotionCard(params) {
  return request.post('/promotionCard', params)
}

// 修改推广卡片
export function updatePromotionCard(id, params) {
  return request.put(`/promotionCard/${id}`, params)
}

// 删除推广卡片
export function deletePromotionCard(id) {
  return request.delete(`/promotionCard/${id}`)
}

// 上传推广卡片
export function uploadPromotionCard(id) {
  return request.put(`/promotionCard/${id}`)
}




// 规则模板相关==================================>
//= =============================================>

// 查询规则模板
export function getRuleTemplate(params) {
  return request.get('/ruleTemplate', { params })
}

// 新增模板
export function addRuleTemplate(params) {
  return request.post(`/ruleTemplate`, params)
}

// 修改模板
export function updateRuleTemplate(id, params) {
  return request.put(`/ruleTemplate/${id}`, params)
}

// 删除模板
export function deleteRuleTemplate(id) {
  return request.delete(`/ruleTemplate/${id}`)
}

// 模板详情
export function detailRuleTemplate(id) {
  return request.get(`/ruleTemplate/${id}`)
}

// 上传广告计划
export function uploadAd(params) {
  return request.post(`/uploadAd`, params)
}

// * 转化追踪目标

export function conversionTrackingList(params) {
  return request.get('/convert/line', { params })
}

// * 定向包查询

export function getAudience(params) {
  return request.get('/audience', { params })
}

// * 删除定向包

export function deleteAudience(id) {
  return request.delete(`/audience/${id}`)
}

// * 修改定向包
export function editAudience(id, params) {
  return request.put(`/audience/${id}`, params)
}

// * 新增定向包

export function addAudience(params) {
  return request.post('/audience', params)
}

// * 获取地域信息

export function getRegion(params) {
  return request.get('/tools/region', { params })
}

// * 获取人群包

export function getCustomCrowd(params) {

  return request.get('/tools/customAudience', { params })
}

// 获取创意基本信息推广卡片
export function getCreativeComponent(params) {
  return request.get('/tools/creativeComponent', { params })
}

// 站点列表获取 橙子建站
export function getToolSite(params) {
  return request.get('/tools/site', { params })
}

// 获取第三方落地页站点列表
export function getToolThirdSite(params) {
  return request.get('/tools/thirdSite', { params })
}

// 获取程序化落地页站点列表
export function getProceduralSite(params) {
  return request.get('/tools/proceduralSite', { params })
}

// 查询已创建资产列表
export function getAppManagement(params) {
  return request.get('/tools/appManagement', { params })
}

// 查询已创建资产列表
export function assetsGet(params) {
  return request.get('/tools/assetsGet', { params })
}

// 获取建资优化目标
export function optimizedGoal(params) {
  return request.get('/tools/optimizedGoal', { params })
}

// 获取事件资产下的监测链接
export function actionTrackUrl(params) {
  return request.get('/tools/trackUrl', { params })
}

// 投放列表相关==================================>
//= =============================================>

// 查询投放记录
export function getUploadAdRecord(params) {
  return request.get('/uploadAd/record', { params })
}

// 批量删除记录
export function deleteUploadAdRecord(ids) {
  return request.delete(`/uploadAd/${ids}`)
}

// 查询投放记录明细
export function getUploadAdRecordDetail(params) {
  return request.get(`/uploadAd/record/detail`, { params })
}

// 更新广告组状态(adStatus = CAMPAIGN_STATUS_DELETE时不选择)
export function uploadAdGroupStatus(ids, type) {
  return request.put(`/uploadAd/adGroup/status/${ids}?optStatus=${type}`)
}

// 修改计划状态（adStatus=CAMPAIGN_STATUS_DELETE不选择）
export function uploadPlanGroupStatus(ids, type) {
  return request.put(`/uploadAd/plan/status/${ids}?optStatus=${type}`)
}

// 获取广告组详情
export function uploadAdGroupDetail(id) {
  return request.get(`/uploadAd/adGroup/${id}`)
}

// 修改广告组
export function updateUploadAdGroupDetail(id, params) {
  return request.put(`/uploadAd/adGroup/${id}`, params)
}

// 获取计划详情
export function getUploadAdPlan(id) {
  return request.get(`/uploadAd/plan/${id}`)
}

// 修改计划详情
export function updateUploadAdPlan(id, params) {
  return request.put(`/uploadAd/plan/${id}`, params)
}

// 获取创意详情
export function getUploadAdCreative(id) {
  return request.get(`/uploadAd/creative/${id}`)
}

// 联调

export function putConvertJoint(link) {
  return request.post('/convert/joint', { link })
}
