<template>
  <div class="exclude">
    <el-tag
      effect="plain"
      @click="handleDialogShow"
      style="cursor: pointer"
      size="default"
      :type="hasSelect ? 'success' : 'info'"
      :closable="hasSelect"
      @close="handleClear"
      >{{ hasSelect ? "排除项已选" : "选择排除项" }}</el-tag
    >
    <!-- <el-dialog
        center
        top="10vh"
        title="数据筛选排除选项"
        :visible.sync="dialogVisible"
        width="780px"
        :before-close="()=>this.dialogVisible = false"> -->
    <el-drawer
      append-to-body
      title="数据筛选排除选项"
      :visible.sync="dialogVisible"
      size="760px"
    >
      <el-tabs type="border-card">
        <el-tab-pane label="公众号">
          <el-transfer
            filterable
            :filter-method="filterMethod"
            :titles="['公众号列表', '排除项']"
            :props="{ key: 'id', label: 'channelName' }"
            filter-placeholder="公众号名称"
            v-model="originChannel"
            :data="officialList"
          >
            <div
              slot-scope="{ option }"
              class="flex-between"
              style="width: 200px"
            >
              <span>{{ option.channelName }}</span>
              <span class="desc"
                >{{ option.platformStr }}- {{ option.uname }}</span
              >
            </div>
          </el-transfer>
        </el-tab-pane>
        <el-tab-pane label="书籍">
          <div class="row" style="margin-top: 10px">
            <el-select
              size="default"
              v-model="selectBook"
              filterable
              remote
              clearable
              style="flex: 1"
              reserve-keyword
              multiple
              value-key="id"
              placeholder="书籍名称"
              :remote-method="getBook"
            >
              <el-option
                v-for="item in bookList"
                :key="item.id"
                :disabled="
                  originBook && originBook.some((book) => book.id === item.id)
                "
                :label="item.bookName"
                :value="item"
              >
              </el-option>
            </el-select>
            <el-button type="primary" size="default" @click="handleAddBook"
              >添加</el-button
            >
          </div>
          <div class="flex-between" style="margin-top: 14px">
            <span>书籍排除项：</span>
            <el-button type="text" @click="originBook = []"
              >清除所有选项</el-button
            >
          </div>
          <div class="select-book">
            <el-tag
              v-for="(item, index) in originBook"
              :key="item.id"
              closable
              @close="originBook.splice(index, 1)"
              >{{ item.bookName }}
            </el-tag>
          </div>
        </el-tab-pane>
      </el-tabs>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button
          type="danger"
          @click="
            originBook = [];
            originChannel = [];
          "
          >清除选项</el-button
        >
        <el-button type="primary" @click="handleSelect">确 定</el-button>
      </span>
    </el-drawer>
  </div>
</template>

<script>
import { getBookList } from '@/api/account'
import { mapGetters } from 'vuex'

export default {
  name: 'excludeQuery',
  props: {
    params: Object
  },
  computed: {
    hasSelect () {
      const { ruledOutAccountChannelIds, ruledOutBooksIds } = this.params
      return (
        (ruledOutAccountChannelIds && ruledOutAccountChannelIds.length !== 0) ||
        (ruledOutBooksIds && ruledOutBooksIds.length !== 0)
      )
    },
    ...mapGetters(['officialList'])
  },
  data () {
    return {
      moveBooks: [],
      bookList: [],
      selectBook: [],
      dialogVisible: false,
      filterMethod (query, item) {
        return item.channelName.indexOf(query) > -1
      },
      originChannel: [],
      originBook: []
    }
  },
  mounted () {
    this.getBook(null)
  },
  methods: {
    handleClear () {
      this.$set(this.params, 'ruledOutAccountChannelIds', [])
      this.$set(this.params, 'ruledOutBooksIds', [])
    },
    handleSelect () {
      this.dialogVisible = false
      this.$set(this.params, 'ruledOutAccountChannelIds', [
        ...this.originChannel
      ])
      this.$set(
        this.params,
        'ruledOutBooksIds',
        this.originBook.map((book) => {
          return {
            id: book.id,
            bookName: book.bookName
          }
        })
      )
    },
    handleDialogShow () {
      this.dialogVisible = true
      const { ruledOutBooksIds, ruledOutAccountChannelIds } = this.params
      this.originChannel = ruledOutAccountChannelIds
        ? [...ruledOutAccountChannelIds]
        : []
      this.originBook = ruledOutBooksIds
        ? ruledOutBooksIds.map((book) => {
          return {
            id: book.id,
            bookName: book.bookName
          }
        })
        : []
    },
    handleAddBook () {
      // if (this.params.ruledOutBooksIds) {
      //   this.params.ruledOutBooksIds.push(...this.selectBook);
      // } else {
      //   this.$set(this.params, 'ruledOutBooksIds', this.selectBook)
      // }
      this.originBook.push(...this.selectBook)
      this.selectBook = []
    },
    getBook (bookName) {
      getBookList({
        page: 1,
        pageSize: 50,
        bookName
      }).then((res) => {
        this.bookList = res.list
      })
    }
  }
}
</script>

<style lang='scss' scoped>
/deep/.el-transfer-panel__list.is-filterable {
  height: 500px;
}
/deep/.el-transfer-panel__body {
  height: 500px;
}
/deep/.el-transfer-panel {
  width: 240px;
}
/deep/ .el-drawer {
  padding: 20px;
  /deep/ .el-drawer__header {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
  }
}
.select-book {
  border: 1px solid #1c9399;
  padding: 0 15px;
  border-radius: 8px;
  height: 450px;
  overflow: auto;
  margin-top: 9px;
}

.select-book .el-tag {
  margin-right: 10px;
  margin-top: 10px;
}

.desc {
  flex: 1;
  padding-left: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
