var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-popover',{attrs:{"placement":"bottom","width":"320","trigger":"click"}},[_c('el-cascader',{attrs:{"options":_vm.fieldsData,"placeholder":"选择展示字段","props":{
      multiple: true,
      label: 'fieldName',
      value: 'fieldCode',
      checkStrictly: true,
    },"collapse-tags":"","clearable":""},on:{"change":_vm.handleChangeFields},model:{value:(_vm.selectFields),callback:function ($$v) {_vm.selectFields=$$v},expression:"selectFields"}}),_c('el-checkbox',{staticStyle:{"margin-left":"10px"},on:{"change":_vm.handleCheckAllFields},model:{value:(_vm.selectAllFields),callback:function ($$v) {_vm.selectAllFields=$$v},expression:"selectAllFields"}},[_vm._v("全选")]),_c('el-cascader',{staticClass:"cascader-out",attrs:{"slot":"reference","disabled":"","options":_vm.fieldsData,"placeholder":"选择展示字段","props":{
      multiple: true,
      label: 'fieldName',
      value: 'fieldCode',
      checkStrictly: true,
    },"collapse-tags":"","clearable":""},on:{"change":_vm.handleChangeFields},slot:"reference",model:{value:(_vm.selectFields),callback:function ($$v) {_vm.selectFields=$$v},expression:"selectFields"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }