<template>
  <el-drawer
    :title="`${channelName}`"
    size="800px"
    append-to-body
    :visible.sync="show"
    @open="handleInit"
    :before-close="() => $emit('close')"
  >
    <div class="recharge-cont">
      <div id="recharge"></div>
      <el-table :data="dataList" height="50vh" style="width: 100%">
        <el-table-column prop="time" label="时间" min-width="80">
        </el-table-column>
        <el-table-column
          prop="cashCostAmount"
          sortable
          label="账户消耗"
          min-width="80"
        >
        </el-table-column>
        <el-table-column
          prop="afterTaxAmount"
          sortable
          label="充值"
          min-width="80"
        >
        </el-table-column>
        <el-table-column prop="roi" sortable label="roi" min-width="80">
        </el-table-column>
        <el-table-column
          prop="subscribeNum"
          sortable
          label="关注人数"
          min-width="80"
        >
        </el-table-column>
        <el-table-column
          prop="rechargeNum"
          sortable
          label="充值人数"
          min-width="80"
        >
        </el-table-column>
        <el-table-column prop="payRate" sortable label="付费率" min-width="80">
          <template slot-scope="scope"> {{ scope.row.payRate }}% </template>
        </el-table-column>
        <el-table-column
          prop="payCost"
          sortable
          label="付费成本"
          min-width="80"
        >
        </el-table-column>
        <el-table-column prop="action" label="操作" width="80">
          <template slot-scope="scope">
            <el-button
              class="detail-button"
              type="text"
              :disabled="scope.row.time === activeTime"
              @click="handleTimeClick(scope.row)"
              >查看详情
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </el-drawer>
</template>

<script>
import { getTimeRecharge } from "@/api/statics";
import { formatTime } from "@/assets/js/utils";

const echarts = require("echarts/lib/echarts");
require("echarts/lib/chart/line");
require("echarts/lib/component/tooltip");
export default {
  name: "timeRecharge",
  props: {
    show: Boolean,
    accountChannelId: Number,
    channelName: String,
    queryDate: {
      type: String,
      default: formatTime(Date.now(), "yyyy-MM-dd"),
    },
  },
  data() {
    return {
      dataList: [],
      loading: false,
      myChart: null,
      activeTime: null,
    };
  },
  watch: {
    show(newVal) {
      if (newVal) {
        this.loading = true;
        getTimeRecharge({
          accountChannelId: this.accountChannelId,
          queryDate: this.queryDate,
        })
          .then((res) => {
            this.dataList = res;
            this.activeTime = res[0].time;
            this.setOption(res[0]);
          })
          .finally(() => (this.loading = false));
      }
    },
  },
  methods: {
    handleInit() {
      this.$nextTick(() => {
        if (!this.myChart) {
          this.myChart = echarts.init(document.getElementById("recharge"));
          window.onresize = this.myChart.resize;
        }
      });
    },
    handleTimeClick(data) {
      this.activeTime = data.time;
      this.setOption(data);
    },
    setOption(data) {
      const { timeList, payNumList } = data;
      this.myChart.setOption(
        {
          xAxis: {
            type: "category",
            data: timeList,
            boundaryGap: false,
          },
          yAxis: {
            type: "value",
            minInterval: 1,
          },
          tooltip: {
            trigger: "axis",
          },
          grid: {
            left: "5%",
            right: "5%",
            bottom: "10%",
            top: "5%",
            // containLabel: true
          },
          series: [
            {
              data: payNumList,
              type: "line",
              smooth: true,
            },
          ],
        },
        true
      );
    },
  },
};
</script>

<style scoped>
.recharge-cont {
  padding: 0 20px;
  width: 100%;
}

#recharge {
  width: 100%;
  height: 30vh;
  margin-bottom: 20px;
}
.detail-button {
  padding: 0 !important;
}
</style>
