import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "../layout/layout";
import { getCookie } from "../assets/js/cookie";
import store from '../store/index'

Vue.use(VueRouter);

// const vuex = JSON.parse(localStorage.getItem("vuex"));
// const homeRoleArr =
//   vuex &&
//   vuex.userInfo &&
//   vuex.userInfo.homeRole &&
//   vuex.userInfo.homeRole
//     .split(",")
//     .map(item => Number(item))
//     .sort((a, b) => a - b);
// function getNext(homeRoleArr, to, callback) {
//   if (homeRoleArr && homeRoleArr.some(val => to.meta.authority.includes(val))) {
//     callback();
//   }
//   if (!homeRoleArr || homeRoleArr.length === 0) {
//     callback();
//   }
// }

// 根据homeRoleId来重定向
// const redirectWithHomeRole = {
//   "-1": "/home/customer-analysis-board", // 针对homeRole为空的监控台默认显示暂未开放
//   0: "/home/operateRealTime",
//   1: "/home/put/putter-board",
//   2: "/home/operateRealTime",
//   3: "/home/put/put-decision-maker",
//   999: "/home/customer-analysis-board" // 登录之后没有homeRole属性的情况
// };
const routes = [
  {
    path: "/",
    component: Layout,
    redirect: "/statistics/consume/channel",
    // beforeEnter: (to, from, next) => {
    //   getCookie('token') ? next() : next('login')
    // },
    meta: { title: "首页", mainMenu: "/" },
    children: [
      {
        path: "novel-put-management",
        name: "NovelPutManagement",
        component: () => import("../views/novel-put-management/index"),
        redirect: "/novel-put-management/client",
        children: [
          {
            path: "deliveryMaterialManage",
            name: "DeliveryMaterialManage",
            component: () =>
              import(
                "../views/novel-put-management/deliveryMaterialManage/index.vue"
              ),
            redirect: "deliveryMaterialManage/page-manage",
            children: [
              {
                path: "client",
                name: "Client",
                component: () => import("../views/novel-put-management/client"),
                meta: {
                  title: "服务商",
                  mainMenu: "/novel-put-management",
                  submenu: "/novel-put-management/deliveryMaterialManage/client"
                }
              },
              {
                path: "page-manage",
                name: "PageManage",
                component: () =>
                  import(
                    "../views/novel-put-management/deliveryMaterialManage/page-manage.vue"
                  ),
                meta: {
                  title: "推广页",
                  mainMenu: "/novel-put-management",
                  submenu:
                    "/novel-put-management/deliveryMaterialManage/page-manage"
                }
              },
              {
                path: "material-manage",
                name: "MaterialManage",
                component: () =>
                  import(
                    "../views/novel-put-management/deliveryMaterialManage/material-manage.vue"
                  ),
                meta: {
                  title: "投放素材",
                  mainMenu: "/novel-put-management",
                  submenu:
                    "/novel-put-management/deliveryMaterialManage/material-manage"
                }
              }
            ],
            meta: {
              title: "投放素材管理",
              mainMenu: "/novel-put-management",
              submenu: "/novel-put-management/deliveryMaterialManage"
            }
          },
          {
            path: "materialLibrary",
            name: "MaterialLibrary",
            component: () =>
              import("../views/novel-put-management/material-library.vue")
          },
          {
            path: "addNovelPut",
            name: "AddNovelPut",
            component: () =>
              import("../views/novel-put-management/addNovelPlan/index.vue")
          },
          {
            path: "echoNovelPut",
            name: "EchoNovelPut",
            component: () =>
              import("../views/novel-put-management/echoNovelPlan/index.vue")
          },
          {
            path: "promotionPage",
            name: "PromotionPage",
            component: () =>
              import("../views/novel-put-management/promotionPage.vue")
          },
          {
            path: "completePage",
            name: "CompletePage",
            component: () =>
              import(
                "../views/novel-put-management/components/completePage.vue"
              )
          },
          {
            path: "novelProjectManager",
            name: "NovelProjectManager",
            component: () =>
              import("../views/novel-put-management/novel-project-manager.vue"),
            redirect: "/novelProjectManager/novel-today",
            children: [
              {
                path: "novel-today",
                name: "NovelToday",
                redirect: "novel-today/put-data",
                component: () => import("../views/statistics/today/index.vue"),
                children: [
                  {
                    path: "put-data",
                    name: "PutData",
                    component: () =>
                      import("../views/statistics/today/put.vue"),
                    meta: {
                      title: "投放计划",
                      mainMenu: "/novel-put-management",
                      submenu:
                        "/novel-put-management/novelProjectManager/novel-today/put-data"
                    }
                  },
                  {
                    path: "ad-group",
                    name: "AdGroup",
                    component: () =>
                      import("../views/statistics/today/ad-group.vue"),
                    meta: {
                      title: "今日广告",
                      mainMenu: "/novel-put-management",
                      submenu:
                        "/novel-put-management/novelProjectManager/novel-today/ad-group"
                    }
                  }
                ]
              }
            ],
            meta: {
              title: "投放管理",
              mainMenu: "/novel-put-management"
            }
          },
          // 新增投放派单
          {
            path: "putDispatch",
            name: "PutDispatch",
            component: () =>
              import("../views/novel-put-management/putDispatch/index.vue"),
            redirect: "putDispatch/puter",
            children: [
              {
                path: "puter",
                name: "Puter",
                component: () =>
                  import("../views/novel-put-management/putDispatch/puter.vue"),
                meta: {
                  title: "投手",
                  mainMenu: "/novel-put-management",
                  submenu: "/novel-put-management/putDispath/puter"
                }
              },
              {
                path: "designer",
                name: "Designer",
                component: () =>
                  import(
                    "../views/novel-put-management/putDispatch/designer.vue"
                  ),
                meta: {
                  title: "美工",
                  mainMenu: "/novel-put-management",
                  submenu: "/views/novel-put-management/putDispath/designer."
                }
              }
            ],
            meta: {
              title: "投放素材管理",
              mainMenu: "/novel-put-management",
              submenu: "/novel-put-management/deliveryMaterialManage"
            }
          },
          {
            path: "landing-page-library",
            name: "LandingPageLibrary",
            component: () =>
              import(
                "../views/novel-put-management/landing-page-library/index.vue"
              ),
            meta: {
              title: "落地页素材库",
              mainMenu: "/novel-put-management"
            }
          }
        ],
        meta: {
          title: "广告投放",
          mainMenu: "/novel-put-management"
        }
      },
      {
        path: "small-plane",
        name: "SmallPlane",
        component: () => import("../views/small-plane/index.vue"),
        redirect: "/small-plane/change-track",
        children: [
          {
            path: "toutiao",
            name: "touTiao",
            component: () => import("../views/small-plane/toutiao/index.vue"),
            meta: {
              title: "巨量引擎",
              mainMenu: "/small-plane",
              submenu: "/small-plane/change-track"
            },
            children: [
              {
                path: "change-track",
                name: "changeTrack",
                component: () =>
                  import("../views/small-plane/change-track/index.vue"),
                meta: {
                  title: "转化追踪",
                  mainMenu: "/small-plane",
                  submenu: "/small-plane/change-track"
                }
              },
              {
                path: "promotion-card",
                name: "promotionCard",
                component: () =>
                  import("../views/small-plane/promotion-card/index.vue"),
                meta: {
                  title: "推广卡片",
                  mainMenu: "/small-plane",
                  submenu: "/small-plane/promotion-card"
                }
              },
              {
                path: "batch-put",
                name: "BatchPut",
                component: () =>
                  import("../views/small-plane/batch-put/platform.vue"),
                meta: {
                  title: "广告计划",
                  mainMenu: "/small-plane",
                  submenu: "/small-plane/batch-out"
                }
              }
            ]
          },
          {
            path: "baidu",
            name: "BaiDu",
            redirect: "/small-plane/baidu/put-plan",
            component: () =>
              import("../views/small-plane/baidu-project/index.vue"),
            children: [
              {
                path: "put-plan",
                name: "PutPlan",
                component: () =>
                  import("../views/small-plane/baidu-project/add-template.vue"),
                meta: {
                  title: "投放计划",
                  mainMenu: "/small-plane",
                  secondLevelMenu: "/small-plane/baidu",
                  thirdLevelMenu: "/small-plane/baidu/put-plan"
                }
              },
              {
                path: "landing-page",
                name: "LandingPage",
                component: () =>
                  import("../views/small-plane/baidu-project/landing-page.vue"),
                meta: {
                  title: "落地页",
                  mainMenu: "/small-plane",
                  secondLevelMenu: "/small-plane/baidu",
                  thirdLevelMenu: "/small-plane/baidu/landing-page"
                }
              }
            ],
            meta: {
              title: "百度投放",
              mainMenu: "/small-plane",
              submenu: "/small-plane/baidu-project"
            }
          },
          {
            path: "Tencent-put",
            name: "TencentPut",
            redirect: "/small-plane/Tencent-put/ad-put-list",
            component: () =>
              import("../views/small-plane/Tencent-put/index.vue"),
            children: [
              {
                path: "ad-put",
                name: "AdPut",
                component: () =>
                  import("../views/small-plane/Tencent-put/ad-put.vue"),
                meta: {
                  title: "广告投放",
                  mainMenu: "/small-plane",
                  secondLevelMenu: "/small-plane/Tencent-put",
                  thirdLevelMenu: "/small-plane/Tencent-put/ad-put"
                }
              },
              {
                path: "ad-put-list",
                name: "AdPutList",
                component: () =>
                  import("../views/small-plane/Tencent-put/ad-put-list.vue"),
                meta: {
                  title: "投放列表",
                  mainMenu: "/small-plane",
                  secondLevelMenu: "/small-plane/Tencent-put",
                  thirdLevelMenu: "/small-plane/Tencent-put/ad-put-list"
                }
              }
            ],
            meta: {
              title: "腾讯投放",
              mainMenu: "/small-plane",
              submenu: "/small-plane/Tencent-put"
            }
          },
          {
            path: 'weixin-put',
            name: 'WeiXinPut',
            component: () => import('../views/small-plane/weixin-put/index.vue'),
            redirect: "/small-plane/weixin-put/plan",
            children: [{
              path: 'plan',
              name: 'Plan',
              component: () => import('./../views/small-plane/weixin-put/plan/plan.vue'),
              meta: {
                title: '推广计划',
                mainMenu: '/small-plane',
                secondLevelMenu: '/small-plane/plan'
              }
            }, {
              path: 'promotion',
              name: 'Promotion',
              component: () => import('./../views/small-plane/weixin-put/promotion/promotion.vue'),
              meta: {
                title: '推广页',
                mainMenu: '/small-plane',
                secondLevelMenu: "/small-plane/promotion"
              }
            }],
            meta: {
              title: '微信投放',
              mainMenu: '/small-plane',
              submenu: '/small-plane/weixin-put',
            }
          }
        ],
        meta: {
          title: "批量投放",
          mainMenu: "/small-plane"
        }
      },
      {
        path: "account-management",
        name: "AccountManagement",
        component: () => import("../views/account-management/index"),
        redirect: "/account-management/platform-account",
        children: [
          {
            path: "platform-account",
            name: "PlatformAccount",
            component: () => import("../views/account-management/account.vue"),
            meta: {
              title: "平台账号",
              mainMenu: "/account-management",
              submenu: "/account-management/platform-account"
            }
          },
          {
            path: "channel-list",
            name: "ChannelList",
            component: () => import("../views/account-management/channel-list/index.vue"),
            meta: {
              title: "公众号",
              mainMenu: "/account-management",
              submenu: "/account-management/channel-list"
            }
          },
          {
            path: "account-login",
            name: "AccountLogin",
            component: () =>
              import("../views/account-management/login/index.vue"),
            meta: {
              title: "书城记录",
              mainMenu: "/account-management",
              submenu: "/account-management/account-login"
            }
          },
          {
            path: "baidu-account",
            name: "BaiduAccount",
            component: () => import("../views/account-management/baiduAccount"),
            meta: {
              title: "百度账号",
              mainMenu: "/account-management",
              submenu: "/account-management/baidu-account"
            }
          },
          {
            path: "ocean-account",
            name: "OceanAccount",
            component: () =>
              import("../views/account-management/ocean-account/index.vue"),
            meta: {
              title: "巨量引擎",
              mainMenu: "/account-management",
              submenu: "/account-management/ocean-account"
            }
          },
          {
            path: 'ocean-list',
            name: 'OceanAccountList',
            component: () => import("../views/account-management/ocean-account-list/index.vue"),
            meta: {
              title: '巨量账号',
              mainMenu: '/account-management',
              submenu: '/account-management/ocean-list'
            }
          },
          {
            path: "ADQ",
            name: "ADQ",
            component: () => import("../views/account-management/adq.vue"),
            meta: {
              title: "ADQ",
              mainMenu: "/account-management",
              submenu: "/account-management/ADQ"
            }
          }
        ],
        meta: {
          title: "账号管理",
          mainMenu: "/account-management"
        }
      },
      // 数据统计相关路由------------------》
      {
        path: "/statistics",
        name: "Statistics",
        component: () => import("../views/statistics/index.vue"),
        children: [
          {
            path: "consume",
            name: "Consume",
            component: () => import("../views/statistics/consume/index.vue"),
            children: [
              {
                path: "user",
                name: "user",
                component: () =>
                  import("../views/statistics/consume/userConsume"),
                meta: {
                  title: "每日用户消耗",
                  mainMenu: "/consume",
                  submenu: "/consume/user"
                }
              },
              {
                path: "channel",
                name: "Channel",
                component: () =>
                  import("../views/statistics/consume/channelConsume"),
                meta: {
                  title: "每日公众号消耗",
                  mainMenu: "/consume",
                  submenu: "/consume/channel"
                }
              },
              {
                path: "book",
                name: "Book",
                component: () =>
                  import("../views/statistics/consume/bookConsume"),
                meta: {
                  title: "每日书籍消耗",
                  mainMenu: "/consume",
                  submenu: "/consume/book"
                }
              },
              {
                path: "ad",
                name: "Ad",
                component: () =>
                  import("../views/statistics/consume/adConsume"),
                meta: {
                  title: "今日广告",
                  mainMenu: "/consume",
                  submenu: "/consume/ad"
                }
              }
            ]
          },
          // 订单明细
          {
            path: "order",
            name: "Order",
            component: () => import("../views/statistics/order/index.vue"),
            children: [
              {
                path: "consumeCharging",
                name: "ConsumeCharging",
                component: () =>
                  import("../views/statistics/order/consumeCharging"),
                meta: {
                  title: "消耗充值",
                  mainMenu: "/order",
                  submenu: "/order/consumeCharging"
                }
              },
              {
                path: "orderDetail",
                name: "OrderDetail",
                component: () =>
                  import("../views/statistics/order/orderDetail"),
                meta: {
                  title: "订单明细",
                  mainMenu: "/order",
                  submenu: "/order/orderDetail"
                }
              }
            ]
          },
          // 财务统计
          {
            path: "finance",
            name: "finance",
            component: () => import("../views/statistics/finance/index.vue"),
            children: [
              {
                path: "everydayData",
                name: "EverydayData",
                component: () =>
                  import("../views/statistics/finance/everydayData"),
                meta: {
                  title: "财务日情况报表",
                  mainMenu: "/finance",
                  submenu: "/finance/everydayData"
                }
              },
              // {
              //   path: "bookData",
              //   name: "BookData",
              //   component: () => import("../views/statistics/finance/bookData"),
              //   meta: {
              //     title: "财务书籍统计",
              //     mainMenu: "/finance",
              //     submenu: "/finance/bookData"
              //   }
              // },
              // {
              //   path: "salaryData",
              //   name: "SalaryData",
              //   component: () =>
              //     import("../views/statistics/finance/salaryDataNew"),
              //   // import("../views/statistics/finance/salaryData"),
              //   meta: {
              //     title: "薪资管理",
              //     mainMenu: "/finance",
              //     submenu: "/finance/salaryData"
              //   }
              // },
              // {
              //   path: "profitData",
              //   name: "ProfitData",
              //   component: () =>
              //     import("../views/statistics/finance/profitData"),
              //   meta: {
              //     title: "利润统计",
              //     mainMenu: "/finance",
              //     submenu: "/finance/profitData"
              //   }
              // }
            ]
          },
          {
            path: "platformStatic",
            name: "PlatformStatic",
            component: () =>
              import("../views/statistics/platformStatics/index.vue"),
            meta: {
              title: "平台统计",
              mainMenu: "/platformStatic",
              submenu: "/order/orderDetail"
            }
          },
          {
            path: "channelData",
            name: "ChannelData",
            component: () =>
              import("../views/statistics/channelData/index.vue"),
            children: [
              {
                path: "intervalUser",
                name: "IntervalUser",
                component: () =>
                  import("../views/statistics/channelData/intervalUser"),
                meta: {
                  title: "区间用户消耗",
                  mainMenu: "/intervalUser",
                  submenu: "/channelData/intervalUser"
                }
              },
              {
                path: "intervalChannel",
                name: "IntervalChannel",
                component: () =>
                  import("../views/statistics/channelData/intervalChannel"),
                meta: {
                  title: "区间公众号消耗",
                  mainMenu: "/ChannelData",
                  submenu: "/ChannelData/intervalChannel"
                }
              },
              {
                path: "intervalBook",
                name: "IntervalBook",
                component: () =>
                  import("../views/statistics/channelData/intervalBook"),
                meta: {
                  title: "区间书籍消耗",
                  mainMenu: "/ChannelData",
                  submenu: "/ChannelData/intervalBook"
                }
              },
              // {
              //   path: "testIntervalBook",
              //   name: "TestIntervalBook",
              //   component: () =>
              //     import("../views/statistics/channelData/testIntervalBook"),
              //   meta: {
              //     title: "测试书籍消耗",
              //     mainMenu: "/ChannelData",
              //     submenu: "/ChannelData/testIntervalBook"
              //   }
              // },
              // {
              //   path: "intervalPutAnalysis",
              //   name: "IntervalPutAnalysis",
              //   component: () =>
              //     import("../views/statistics/channelData/intervalPutAnalysis"),
              //   meta: {
              //     title: "区间投放分析",
              //     mainMenu: "/ChannelData",
              //     submenu: "/ChannelData/intervalPutAnalysis"
              //   }
              // }
            ]
          },
          {
            path: "rankingStatistics",
            name: "RankingStatistics",
            component: () => import("../views/statistics/rank/index"),
            redirect: "rankingStatistics/putCrowdRank",
            children: [
              {
                path: "putCrowdRank",
                name: "PutCrowdRank",
                component: () =>
                  import("../views/statistics/rank/put-crowd-rank.vue"),
                meta: {
                  title: "投手实时排行",
                  mainMenu: "/rankingStatistics",
                  submenu: "/rankingStatistics/putCrowdRank"
                }
              },
              {
                path: "incomeRank",
                name: "IncomeRank",
                component: () =>
                  import("../views/statistics/rank/income-rank.vue"),
                meta: {
                  title: "收益排行",
                  mainMenu: "/rankingStatistics",
                  submenu: "/rankingStatistics/incomeRank"
                }
              },
              {
                path: "userRank",
                name: "UserRank",
                component: () =>
                  import("../views/statistics/rank/user-rank.vue"),
                meta: {
                  title: "用户排行",
                  mainMenu: "/rankingStatistics",
                  submenu: "/rankingStatistics/userRank"
                }
              }
            ]
            // meta:{
            //   title:'排行统计',
            //   mainMenu:'/rankingStatistics',

            // }
          },
          {
            path: "multiStatistics",
            name: "MultiStatistics",
            component: () =>
              import("../views/statistics/multiStatistics/index"),
            redirect: "/multiStatistics/multi",
            children: [
              {
                path: "multi",
                name: "Multi",
                component: () =>
                  import("../views/statistics/multiStatistics/multi.vue"),
                meta: {
                  title: "累计统计",
                  mainMenu: "/multiStatistics",
                  submenu: "/multiStatistics/multi"
                }
              },
              {
                path: "month",
                name: "Month",
                component: () =>
                  import("../views/statistics/multiStatistics/month.vue"),
                meta: {
                  title: "月度统计",
                  mainMenu: "/multiStatistics",
                  submenu: "/multiStatistics/month"
                }
              },
              {
                path: "monthDetail",
                name: "MonthDetail",
                component: () =>
                  import("../views/statistics/multiStatistics/monthDetail.vue"),
                meta: {
                  title: "月度统计详情",
                  mainMenu: "/multiStatistics",
                  submenu: "/multiStatistics/monthDetail"
                }
              },
              {
                path: "costRecharge",
                name: "CostRecharge",
                component: () =>
                  import(
                    "../views/statistics/multiStatistics/costRecharge.vue"
                  ),
                meta: {
                  title: "小说成本",
                  mainMenu: "/multiStatistics",
                  submenu: "/multiStatistics/costRecharge"
                }
              },
              // {
              //   path: "yearDetail",
              //   name: "YearDetail",
              //   component: () =>
              //     import("../views/statistics/multiStatistics/yearDetail.vue"),
              //   meta: {
              //     title: "年度统计",
              //     mainMenu: "/multiStatistics",
              //     submenu: "/multiStatistics/yearDetail"
              //   }
              // }
            ],
            meta: {
              title: "累计统计",
              mainMenu: "/multiStatistics"
            }
          },
          {
            path: "yearDetail",
            name: "YearDetail",
            component: () =>
              import("../views/statistics/multiStatistics/yearDetail.vue"),
            meta: {
              title: "年度统计",
              mainMenu: "/multiStatistics",
              submenu: "/multiStatistics/yearDetail"
            }
          },
          {
            path: "userRecharge",
            name: "UserRecharge",
            component: () =>
              import("../views/statistics/userRecharge/index.vue"),
            meta: {
              title: "用户复充",
              mainMenu: "/userRecharge"
            }
          },
          // {
          //   path: "operate",
          //   name: "Operate",
          //   component: () => import("../views/statistics/operate/index.vue"),
          //   children: [
          //     {
          //       path: "assess",
          //       name: "Assess",
          //       component: () => import("../views/statistics/operate/assess"),
          //       meta: {
          //         title: "运营考核",
          //         mainMenu: "/operate",
          //         submenu: "/operate/assess"
          //       }
          //     },
          //     {
          //       path: "channelData",
          //       name: "ChannelData",
          //       component: () =>
          //         import("../views/statistics/operate/channelData"),
          //       meta: {
          //         title: "每日公众号数据",
          //         mainMenu: "/operate",
          //         submenu: "/operate/channelData"
          //       }
          //     },
          //     {
          //       path: "operateData",
          //       name: "OperateData",
          //       component: () =>
          //         import("../views/statistics/operate/operateData"),
          //       meta: {
          //         title: "运营数据",
          //         mainMenu: "/operate",
          //         submenu: "/operate/operateData"
          //       }
          //     },
          //     {
          //       path: "operateStatic",
          //       name: "OperateStatic",
          //       component: () =>
          //         import("../views/statistics/operate/operateStatic"),
          //       meta: {
          //         title: "运营统计",
          //         mainMenu: "/operate",
          //         submenu: "/operate/operateStatic"
          //       }
          //     },
          //     {
          //       path: "intervalOperate",
          //       name: "IntervalOperate",
          //       component: () =>
          //         import("../views/statistics/operate/intervalOperate"),
          //       meta: {
          //         title: "运营区间",
          //         mainMenu: "/operate",
          //         submenu: "/operate/intervalOperate"
          //       }
          //     }
          //   ]
          // },
          // 书籍价值分析
          // {
          //   path: "bookValueAnalysis",
          //   name: "BookValueAnalysis",
          //   component: () =>
          //     import("../views/statistics/bookValueAnalysis/index.vue")
          // },
          // {
          //   path: "wechatKey",
          //   name: "WechatKey",
          //   component: () => import("../views/statistics/wechatKey/index.vue"),
          //   redirect: "wechatKey/keywords1",
          //   children: [
          //     {
          //       path: "keywordsDetail",
          //       name: "KeywordsDetail",
          //       component: () =>
          //         import("../views/statistics/wechatKey/keywordsDetail"),
          //       meta: {
          //         title: "关键字统计明细",
          //         mainMenu: "/wechatKey"
          //       }
          //     },
          //     {
          //       path: "keywordsStatic",
          //       name: "KeywordsStatic",
          //       component: () =>
          //         import("../views/statistics/wechatKey/keywordsStatic"),
          //       meta: {
          //         title: "关键字统计2",
          //         mainMenu: "/wechatKey"
          //       }
          //     }
          //   ]
          // },
          // {
          //   path: "tagLink",
          //   name: "TagLink",
          //   component: () =>
          //     import("../views/statistics/tagLink/index.vue")
          // },
        ]
      },
      // 数据统计相关路由结束--------------》
      {
        path: "/classify",
        name: "Classify",
        component: () => import("../views/classify/index.vue"),
        meta: { title: "分类管理", mainMenu: "/classify" },
        redirect: "/classify/customer",
        children: [
          {
            path: "customer",
            name: "Customer",
            component: () => import("../views/classify/customer/index"),
            redirect: "/classify/customer/title",
            meta: {
              title: "客服消息",
              mainMenu: "/classify",
              submenu: "/classify/customer"
            },
            children: [
              {
                path: "title",
                name: "Title",
                component: () =>
                  import("../views/classify/customer/title/index.vue"),
                meta: {
                  title: "标题管理",
                  mainMenu: "/classify",
                  secondLevelMenu: "/classify/customer",
                  thirdLevelMenu: "/classify/customer/title",
                  submenu: "/classify/customer"
                }
              },
              {
                path: "image",
                name: "Image",
                component: () =>
                  import("../views/classify/customer/image/index"),
                meta: {
                  title: "图片管理",
                  mainMenu: "/classify",
                  submenu: "/classify/customer"
                }
              },
              {
                path: "description",
                name: "Description",
                component: () =>
                  import("../views/classify/customer/description/index"),
                meta: {
                  title: "描述管理",
                  mainMenu: "/classify",
                  submenu: "/classify/customer"
                }
              },
              {
                path: "templateManage",
                name: "TemplateManage",
                component: () =>
                  import("../views/classify/customer/templateManage/index"),
                meta: {
                  title: "模板管理",
                  mainMenu: "/classify",
                  submenu: "/classify/customer"
                }
              }
            ]
          },
          // {
          //   path: 'book',
          //   name: 'Book',
          //   component: () => import('../views/classify/book/index'),
          //   meta: {
          //     title: '书籍管理',
          //     mainMenu: '/classify',
          //     submenu: '/classify/book'
          //   }
          // },
          {
            path: "plate",
            name: "Plate",
            component: () => import("../views/classify/plate/index"),
            meta: {
              title: "板块管理",
              mainMenu: "/classify",
              submenu: "/classify/plate"
            }
          },
          // {
          //   path: "resource-management",
          //   name: "ResourceManagement",
          //   component: () => import("../views/classify/resource-manage/index"),
          //   meta: {
          //     title: "素材管理",
          //     mainMenu: "/classify",
          //     submenu: "/classify/resource-management"
          //   }
          // }
          // {
          //   path: 'extend-management',
          //   name: 'ExtendManagement',
          //   component: () => import('../views/classify/extend-manage/index.vue'),
          //   meta: {
          //     title: '巨量引擎',
          //     mainMenu: '/classify',
          //     submenu: '/classify/extend-management'
          //   }
          // }
        ]
      },
      {
        path: "/user-management",
        name: "UserManagement",
        component: () => import("../views/user-management/index.vue"),
        redirect: "/user-management/user",
        children: [
          {
            path: "user-manage",
            name: "User",
            component: () => import("../views/user-management/user/user.vue")
          },
          // {
          //   path: "book-user",
          //   name: "BookUser",
          //   component: () =>
          //     import("../views/user-management/book-user/index.vue")
          // },
          // {
          //   path: "user-attribute",
          //   name: "userAttribute",
          //   component: () =>
          //     import("../views/user-management/user-attribute/index.vue")
          // },
          // {
          //   path: "user-feedback",
          //   name: "userFeedback",
          //   component: () =>
          //     import("../views/user-management/user-feedback/index.vue")
          // }
        ],
        meta: {
          title: "用户管理",
          mainMenu: "/user-management",
          submenu: "/user-management/user"
        }
      },
      // 用warehouse 区分 material-library
      {
        path: "/material-warehouse",
        name: "MaterialWarehouse",
        component: () => import("../views/material-warehouse/index.vue"),
        redirect: "/material-warehouse/library",
        children: [
          {
            path: "library",
            name: "Library",
            component: () =>
              import("../views/material-warehouse/library/library.vue"),
            meta: {
              title: "素材库",
              mainMenu: "/material-warehouse",
              submenu: "/material-warehouse/library"
            }
          },
          {
            path: "tags",
            name: "Tags",
            component: () =>
              import("../views/material-warehouse/tags/index.vue"),
            redirect: "/material-warehouse/tags/tags-group",
            children: [
              {
                path: "tags-group",
                name: "TagsGroup",
                component: () =>
                  import("../views/material-warehouse/tags/tags-group.vue"),
                meta: {
                  title: "标签组",
                  mainMenu: "/material-warehouse",
                  submenu: "/material-warehouse/tags",
                  secondLevelMenu: "/material-warehouse/tags",
                  thirdLevelMenu: "/material-warehouse/tags/tags-group"
                }
              },
              {
                path: "tags-item",
                name: "TagsItem",
                component: () =>
                  import("../views/material-warehouse/tags/tags-item.vue"),
                meta: {
                  title: "标签库",
                  mainMenu: "/material-warehouse",
                  submenu: "/material-warehouse/tags",
                  secondLevelMenu: "/material-warehouse/tags",
                  thirdLevelMenu: "/material-warehouse/tags/tags-item"
                }
              }
            ],
            meta: {
              title: "标签管理",
              mainMenu: "/material-warehouse",
              submenu: "/material-warehouse/tags"
            }
          },
          {
            path: "doc",
            name: "Doc",
            component: () =>
              import("../views/material-warehouse/doc/index.vue"),
            meta: {
              title: "文案管理",
              mainMenu: "/material-warehouse",
              submenu: "/material-warehouse/doc"
            }
          }
        ],
        meta: {
          title: "素材管理",
          mainMenu: "/material-warehouse",
          submenu: "/material-warehouse/library"
        }
      },
      {
        path: "/user-tags",
        name: "UserTags",
        component: () => import("../views/user-tags/index.vue"),
        meta: {
          title: "用户标签",
          mainMenu: "/user-tags"
        }
      },
      {
        path: "/documentManage",
        name: "DocumentManage",
        component: () => import("../views/documentManage/index.vue"),
        meta: {
          title: "文档管理",
          mainMenu: "/user-tags"
        },
        children: [
          {
            path: "setting",
            name: "Setting",
            component: () => import("../views/documentManage/setting"),
            meta: {
              title: "文档设置",
              mainMenu: "/documentManage",
              submenu: "/documentManage/setting"
            }
          },
          {
            path: "readOnly",
            name: "ReadOnly",
            component: () => import("../views/documentManage/readOnly"),
            meta: {
              title: "文档查看",
              mainMenu: "/documentManage",
              submenu: "/documentManage/readOnly"
            }
          }
        ]
      },
      {
        path: "/extend",
        name: "Extend",
        component: () => import("../views/extend/index.vue"),
        meta: {
          title: "巨量引擎",
          mainMenu: "/extend"
        }
      },
      {
        path: "/system-management",
        name: "SystemManagement",
        component: () =>
          import("../views/system-management/system-management.vue"),
        redirect: "/system-management/organize",
        children: [
          {
            path: "organize",
            name: "Organize",
            component: () =>
              import("../views/system-management/organize/organize.vue"),
            meta: {
              title: "组织架构",
              mainMenu: "/system-management",
              submenu: "/system-management/organize"
            }
          },
          {
            path: "auth",
            name: "Auth",
            component: () =>
              import("../views/system-management/auth/index.vue"),
            meta: {
              title: "权限管理",
              mainMenu: "/system-management",
              submenu: "/system-management/auth"
            }
          },
          {
            path: "log",
            name: "Log",
            component: () => import("../views/system-management/log/index.vue"),
            meta: {
              title: "日志管理",
              mainMenu: "/system-management",
              submenu: "/system-management/log"
            }
          },
          {
            path: "field",
            name: "Field",
            component: () =>
              import("../views/system-management/field/index.vue"),
            meta: {
              title: "字段管理",
              mainMenu: "/system-management",
              submenu: "/system-management/field"
            }
          },
          {
            path: "sync",
            name: "Sync",
            component: () =>
              import("../views/system-management/data-sync/index.vue"),
            meta: {
              title: "数据同步",
              mainMenu: "/system-management",
              submenu: "/system-management/sync"
            }
          },
          {
            path: "label",
            name: "label",
            component: () =>
              import("../views/system-management/label/index.vue"),
            meta: {
              title: "标签基数",
              mainMenu: "/system-management",
              submenu: "/system-management/label"
            }
          }
        ],
        meta: {
          title: "系统管理",
          mainMenu: "/system-management",
          submenu: "/system-management/organize"
        }
      },
      {
        path: "consume-management",
        name: "ConsumeManagement",
        component: () =>
          import("../views/consume-management/consume-management.vue"),
        meta: {
          title: "消耗管理",
          mainMenu: "/consume-management"
        }
      },
      {
        path: "bookManagement",
        name: "BookManagement",
        component: () => import("../views/classify/book/index"),
        meta: {
          title: "书籍管理",
          mainMenu: "/classify"
        }
      },
      {
        path: "/groundPage",
        name: "GroundPage",
        component: () => import("../views/groundPage/index.vue"),
        meta: {
          title: "落地页管理",
          mainMenu: "/groundPage"
        },
        redirect: "/groundPage/landingPage",
        meta: {
          title: "落地页管理",
          mainMenu: "/groundPage"
        },
        children: [
          {
            path: "landingPageTT",
            name: "LandingPageTT",
            component: () => import("../views/groundPage/landingPage/index.vue"),
            meta: {
              title: "落地页",
              mainMenu: "/groundPage"
            }
          },
          {
            path: "landingPageBD",
            name: "LandingPageBD",
            component: () => import("../views/groundPage/landingPage/index.vue"),
            meta: {
              title: "落地页",
              mainMenu: "/groundPage"
            }
          },
          {
            path: "quckApp",
            name: "QuckApp",
            component: () => import("../views/groundPage/quckApp/index.vue"),
            redirect: "/groundPage/quckApp/quckAppSet",
            meta: {
              title: "快应用",
              mainMenu: "/groundPage"
            },
            children: [
              {
                path: "quckAppSet",
                name: "QuckApp",
                component: () => import("../views/groundPage/quckApp/quckAppSet.vue"),
                meta: {
                  title: "快应用设置",
                  mainMenu: "/groundPage"
                }
              },
              {
                path: "callBackData",
                name: "CallBackData",
                component: () =>
                  import("../views/groundPage/quckApp/callBackData.vue"),
                meta: {
                  title: "回传数据",
                  mainMenu: "/groundPage"
                }
              }
            ]
          }
          // {
          //   path: 'callBackData',
          //   name: 'CallBackData',
          //   component: () => import('../views/groundPage/callBackData.vue'),
          //   meta: {
          //     title: '回传数据',
          //     mainMenu: '/groundPage',
          //   }
          // }
        ]
      },
      // {
      //   path: "wx-link",
      //   name: "WxLink",
      //   component: () => import("../views/wx-link/index"),
      //   meta: {
      //     title: "微链管理",
      //     mainMenu: "/wx-link"
      //   }
      // }
    ]
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/login"),
    beforeEnter: (to, from, next) => {
      getCookie("cps_token") ? next("/") : next();
    }
  },
  {
    path: "/test",
    name: "test",
    component: () => import("@/components/test"),
    meta: { title: "测试", mainMenu: "/test" }
  }
];

const router = new VueRouter({
  routes
});
// 获取原型对象上的push函数
const originalPush = VueRouter.prototype.push;
// 修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};
router.beforeEach(function (to, from, next) {
  // 导航栏年设置
  if (to.path == '/statistics/order/consumeCharging') {
    let tmpYear = new Date().getFullYear()
    store.state.globalYear = tmpYear + ''
  }
  next()
})
export default router;
