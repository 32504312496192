<template>
  <div style="padding: 10px">
    <!-- 搜索条件 -->
    <div class="filter-box">
      <div class="row">
        <select-platform-channel :params="params" />
        <el-select
          v-model="params.booksId"
          filterable
          remote
          clearable
          style="width: 150px"
          reserve-keyword
          placeholder="书籍名称"
          :remote-method="getBook"
        >
          <el-option
            v-for="item in bookList"
            :key="item.id"
            :label="item.bookName"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <!-- 单独用户 -->
        <el-select
          v-model="params.adminUserId"
          filterable
          clearable
          placeholder="用户名称"
        >
          <el-option
            v-for="item in operateUserList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <el-button type="board" @click="handlePageChange"> 搜索 </el-button>
        <el-button type="info" @click="handleReset"> 重置 </el-button>
      </div>
    </div>
    <el-tabs type="card">
      <el-tab-pane label="人员维度">
        <el-table :data="userTable" style="width: 100%" height="780">
          <el-table-column prop="adminUserName" label="姓名"> </el-table-column>
          <el-table-column prop="typeName" label="类型"> </el-table-column>
          <el-table-column prop="channelNum" label="账号个数">
          </el-table-column>
          <el-table-column prop="successNum" label="成功个数">
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="公众号维度">
        <el-table
          :data="channelTable"
          style="width: 100%"
          height="780"
          :row-class-name="tableRowClassName"
          :span-method="objectSpanMethod"
        >
          <el-table-column prop="adminUserName" label="用户"> </el-table-column>
          <el-table-column prop="channelName" label="公众号"> </el-table-column>
          <el-table-column prop="bookName" label="书籍"> </el-table-column>
          <el-table-column prop="platformStr" label="平台"> </el-table-column>
          <el-table-column prop="fans" label="粉丝数"></el-table-column>
          <el-table-column prop="morning" label="早上"></el-table-column>
          <el-table-column prop="noon" label="中午"></el-table-column>
          <el-table-column prop="afternoon" label="下午"></el-table-column>
          <el-table-column prop="atNight" label="晚上"></el-table-column>
          <el-table-column prop="total" label="合计"></el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

  <script>
import { getBookList } from "@/api/account";
import { mapGetters } from "vuex";
import SelectPlatformChannel from "@/components/SelectPlatformChannel";
import { operateCustomerMessage } from "@/api/monitor";
export default {
  props: {
    lastParams: Object,
  },
  data() {
    return {
      userTable: [],
      channelTable: [],
      params: {},
      bookList: [],
      spanArr: [],
    };
  },
  components: {
    SelectPlatformChannel,
  },
  computed: {
    ...mapGetters(["operateUserList"]),
  },
  methods: {
    // 合并同名数据
    tableRowClassName({ rowIndex }) {
      if (this.spanArr[rowIndex] !== 0 && rowIndex !== 0) {
        return "table-top-red";
      }
      return "";
    },
    objectSpanMethod({ rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        const _row = this.spanArr[rowIndex];
        const _col = _row > 0 ? 1 : 0;
        return {
          rowspan: _row,
          colspan: _col,
        };
      }
    },
    getSpanArr(data) {
      // spanArr和pos需要定义
      this.spanArr = [];
      // this.contentSpanArr = [];
      for (let i = 0; i < data.length; i++) {
        if (i === 0) {
          this.spanArr.push(1);
          this.pos = 0;
        } else {
          // 判断当前元素与上一个元素是否相同 (type要根据要合并的元素更改)
          if (data[i].adminUserName === data[i - 1].adminUserName) {
            this.spanArr[this.pos] += 1;
            this.spanArr.push(0);
          } else {
            this.spanArr.push(1);
            this.pos = i;
          }
        }
      }
    },
    // 获取书籍列表by id
    getBookById(booksIds) {
      getBookList({
        page: 1,
        pageSize: 50,
        booksIds,
      }).then((res) => {
        this.bookList = res.list;
      });
    },
    arraySpanMethod({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === 0) {
        if (columnIndex === 0) {
          return [1, 2];
        } else if (columnIndex === 1) {
          return [0, 0];
        }
      }
    },
    getBook(bookName) {
      getBookList({
        page: 1,
        pageSize: 50,
        bookName,
      }).then((res) => {
        this.bookList = res.list;
      });
    },
    handlePageChange() {
      this.getData();
    },
    getData() {
      operateCustomerMessage(this.params).then((res) => {
        let tmpUserTable = res.messageUserDtos;
        tmpUserTable.unshift({
          adminUserName: "合计",
          typeName: " ",
          channelNum: res.sumUsers.sumChannelNum,
          successNum: res.sumUsers.sumSuccessNum,
        });
        let tmpChannelTable = res.messageChannelDtos;
        tmpChannelTable.unshift({
          adminUserName: "合计",
          channelName: "",
          bookName: "",
          platformStr: "",
          afternoon: res.sumChannels.afternoon,
          atNight: res.sumChannels.atNight,
          fans: res.sumChannels.fans,
          morning: res.sumChannels.morning,
          noon: res.sumChannels.noon,
          platform: res.sumChannels.platform,
          total: res.sumChannels.total,
        });
        this.getSpanArr(tmpChannelTable);
        this.userTable = tmpUserTable;
        this.channelTable = tmpChannelTable;
      });
    },
    // 重置操作
    handleReset() {
      this.params.originPlatform = null;
      this.params.accountChannelId = null;
      this.params.booksId = null;
      this.handlePageChange();
    },
  },
  created() {
    if (this.lastParams) {
      this.params = {
        ...this.lastParams,
      };
      this.getBookById(this.params.booksId);
    }
    this.getData();
  },
};
</script>

<style scoped lang="scss">
.el-tabs--border-card{
  border-bottom:none;
  box-shadow:none;
}
/deep/ .el-tabs--border-card > .el-tabs__header{
  background-color:#fff
}
</style>