<template>
  <el-drawer
    title="缓存列表"
    :visible.sync="show"
    size="650px"
    append-to-body
    destroy-on-close
    :before-close="handleClose"
  >
    <div class="body">
      <el-button @click="getList" style="margin-bottom: 15px">刷新</el-button>
      <el-table
        :data="dataList"
        v-loading="loading"
        border
        stripe
        height="80vh"
      >
        <el-table-column property="key" label="查询条件">
          <template slot-scope="scope">
            <div class="tag-box">
              <el-tag v-for="(item, index) in scope.row.keyArr" :key="index"
                >{{ item.label }}：{{ item.text }}</el-tag
              >
            </div>
          </template>
        </el-table-column>
        <el-table-column property="process" label="进度" width="80">
          <template slot-scope="scope">
            <span>{{ scope.row.process }}%</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="120">
          <template slot-scope="scope">
            <div class="action-box">
              <el-button type="text" @click="handleClick(scope.row)"
                >查看</el-button
              >
              <el-button type="text" @click="handleExport(scope.row)"
                >导出</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </el-drawer>
</template>

<script>
import { getCacheList, exportFile } from "@/api/statics";

export default {
  name: "cacheList",
  props: {
    show: Boolean,
    type: Number,
    hiddenType: Boolean,
  },
  data() {
    return {
      dataList: [],
      loading: false,
    };
  },
  watch: {
    show(newVal) {
      if (newVal) {
        this.getList();
      }
    },
  },
  methods: {
    handleExport(row) {
      exportFile(
        "/statistics/export/investmentReturn/long",
        {
          param: row.key,
        },
        "平台统计"
      ).then((res) => {
        this.$message.success("导出成功");
      });
    },
    getList() {
      this.loading = true;
      getCacheList({ dataType: this.type })
        .then((res) => {
          // 测书号隐藏未筛选测书号条件
          if (this.hiddenType) {
            this.dataList = [];
            res.forEach((item) => {
              if (item.key.includes("测书号")) {
                this.dataList.push({
                  process: item.progress,
                  keyArr: JSON.parse(item.key),
                  key: item.key,
                });
              }
            });
          } else {
            this.dataList = res.map((item) => {
              return {
                process: item.progress,
                keyArr: JSON.parse(item.key),
                key: item.key,
              };
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    handleClose() {
      this.$emit("close");
    },
    handleClick(item) {
      this.$emit("select", item.key);
      this.handleClose();
    },
  },
};
</script>

<style lang='scss' scoped>
.tag-box {
  padding-top: 10px;
}
.el-tag {
  margin-right: 10px;
  margin-bottom: 10px;
}

.body {
  padding: 20px;
}
.action-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  button {
    margin: 0 !important;
    padding: 4px 10px !important;
  }
}
</style>
