<template>
  <el-popover placement="bottom" width="300" trigger="click">
    <el-form label-width="60px">
      <el-form-item label="平台">
        <el-select
          :value="params.originPlatform"
          @change="handlePlatformChange"
          clearable
          style="width: 100%"
        >
          <el-option
            v-for="(item, index) in platformList"
            :label="item"
            :value="index"
            :key="index"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="公众号" v-if="dataType === 'key'">
        <el-select
          :value="params.accountChannelId"
          style="width: 100%"
          @change="handleChannelChange"
          clearable
          filterable
        >
          <el-option
            v-for="item in channelList"
            :key="item.id"
            :label="item.channelName"
            :value="item.id"
          >
            <span style="float: left">{{ item.channelName }}</span>
            <span style="float: right; margin-left: 15px; font-size: 13px"
              >{{ item.platformStr }} - {{ item.uname }}</span
            >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="公众号" v-if="dataType === 'obj'">
        <el-select
          :value="params.currentOfficial"
          style="width: 100%"
          @change="handleChannelChange"
          clearable
          value-key="id"
          filterable
        >
          <el-option
            v-for="item in channelList"
            :key="item.id"
            :label="item.channelName"
            :value="item"
          >
            <span style="float: left">{{ item.channelName }}</span>
            <span style="float: right; margin-left: 15px; font-size: 13px"
              >{{ item.platformStr }} - {{ item.uname }}</span
            >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <el-input
      slot="reference"
      placeholder="平台 / 公众号"
      clearable
      @clear="handleClear"
      style="width: 150px"
      :value="showText"
    />
  </el-popover>
</template>

<script>
import { platformList } from '../assets/js/options'
import { mapGetters } from 'vuex'

export default {
  name: 'SelectPlatformChannel',
  props: {
    params: Object,
    dataType: {
      type: String,
      default: 'key'
    }
  },
  data () {
    return {
      platformList
    }
  },
  created () {
  },
  computed: {
    channelList () {
      const { officialList } = this
      const { originPlatform } = this.params
      return typeof originPlatform === 'number'
        ? officialList.filter((item) => item.platform === originPlatform)
        : officialList
    },
    showText () {
      const { originPlatform, accountChannelId, currentOfficial } = this.params
      let selectChannel = null
      if (this.dataType === 'key') {
        selectChannel = this.channelList.find(
          (channel) => channel.id === accountChannelId
        )
      } else {
        selectChannel = currentOfficial
      }
      return typeof originPlatform === 'number'
        ? selectChannel
          ? platformList[originPlatform] + ' / ' + selectChannel.channelName
          : platformList[originPlatform]
        : selectChannel
          ? selectChannel.channelName
          : ''
    },
    ...mapGetters(['officialList'])
  },
  methods: {
    handleClear () {
      this.$set(
        this.params,
        this.dataType === 'key' ? 'accountChannelId' : 'currentOfficial',
        null
      )
      this.$set(this.params, 'originPlatform', null)
    },
    handlePlatformChange (platform) {
      this.$set(
        this.params,
        this.dataType === 'key' ? 'accountChannelId' : 'currentOfficial',
        null
      )
      this.$set(this.params, 'originPlatform', platform)
    },
    handleChannelChange (channel) {
      this.$set(
        this.params,
        this.dataType === 'key' ? 'accountChannelId' : 'currentOfficial',
        channel
      )
    }
  }
}
</script>

<style scoped>
</style>
