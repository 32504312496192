<template>
  <div class="ad-consume padding-20 box-shadow-light-grey">
    <!-- 条件搜索 -->
    <div class="filter-box">
      <!-- <h4 class="filter-title">今日广告</h4> -->
      <div class="row">
        <exclude-query :params="params" />
        <el-date-picker
          :clearable="false"
          v-model="params.queryDate"
          type="date"
          style="width: 130px"
          value-format="yyyy-MM-dd"
          :picker-options="{
            disabledDate(time) {
              return time > Date.now();
            },
          }"
          placeholder="选择日期"
        >
        </el-date-picker>
        <el-select
          v-model="params.labelTypes"
          collapse-tags
          multiple
          clearable
          placeholder="标签类型"
          style="width: 170px"
        >
          <el-option
            v-for="(item, index) in labelListShow"
            :key="index"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <select-platform-channel :params="params" />
        <el-select
          v-model="params.booksId"
          filterable
          remote
          clearable
          style="width: 150px"
          reserve-keyword
          placeholder="书籍名称"
          :remote-method="getBook"
        >
          <el-option
            v-for="item in bookList"
            :key="item.id"
            :label="item.bookName"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <select-dept-user :params="params" />
        <el-select
          v-model="params.isTest"
          clearable
          placeholder="类型"
          style="width: 100px"
        >
          <el-option label="普通号" :value="0"></el-option>
          <el-option label="测书号" :value="1"></el-option>
        </el-select>
        <el-input
          placeholder="回本率"
          v-model="params.returnRate"
          type="number"
          style="width: 170px"
          clearable
        >
          <el-select
            v-model="params.comperingType"
            slot="prepend"
            style="width: 75px"
            clearable
            placeholder="比较"
          >
            <el-option label="无" :value="0"></el-option>
            <el-option label="<=" :value="1"></el-option>
            <el-option label=">=" :value="2"></el-option>
          </el-select>
        </el-input>
        <!-- 字段选择 -->
        <!-- <el-cascader
          :options="fieldsData"
          placeholder="选择展示字段"
          :props="{
            multiple: true,
            label: 'fieldName',
            value: 'fieldCode',
            checkStrictly: true,
          }"
          v-model="selectFields"
          collapse-tags
          clearable
          @change="handleChangeFields"
        >
        </el-cascader>
        <el-checkbox
          style="margin-left: 10px"
          v-model="selectAllFields"
          @change="handleCheckAllFields"
          >全选</el-checkbox
        > -->
        <select-field
          :fieldsData="fieldsData"
          :originFeild="originFeild"
          :EchoField="sendField"
          @fieldChange="handleFieldChange"
        />
        <el-button
          type="primary"
          @click="handlePageChange(1)"
          style="margin-left: 10px"
          :loading="loading"
        >
          搜索
        </el-button>
        <el-button type="info" @click="handleReset"> 重置 </el-button>
      </div>
    </div>

    <!-- 表格数据 -->
    <div class="book-data">
      <div class="report-cont">
        <!-- <el-popover placement="right" width="400" trigger="hover">
          <div class="report">
            <p v-for="(item, index) in report" :key="index">{{ item }}</p>
          </div>
          <el-button type="success" @click="handleCopy" slot="reference"
            >一键复制汇总文本</el-button
          >
        </el-popover> -->
        <div class="report-box">
          <p v-for="(item, index) in report" :key="index">{{ item }}</p>
        </div>
        <div
          id="bookConsume"
          style="width: 100%; height: 510px; z-index: 1"
        ></div>
      </div>
      <el-table
        header-row-class-name="table-header"
        ref="bookConsumeTable"
        class="consume-table"
        :data="dataList"
        style="width: 100%"
        height="690px"
        v-loading="loading"
        empty-text="暂无数据"
        @sort-change="handleSortChange"
        @cell-click="handleCellClick"
      >
        <el-table-column
          v-for="(item, index) in showField"
          :key="index"
          :prop="item.fieldCode"
          :label="item.fieldName"
          :sortable="sortableType(item)"
          :align="filAlign(item)"
          :min-width="item.width + 'px' || '100px'"
        >
          <template slot-scope="scope">
            <div v-if="item.hasChild">
              <div v-for="(item2, index2) in item.children" :key="index2">
                {{ item2.fieldName }}:
                <span :style="'color:' + item.fontColor">{{
                  scope.row[item2.fieldCode]
                }}</span>
              </div>
            </div>
            <span
              :style="'color:' + item.fontColor"
              :class="item.fieldCode === 'bookName' ? 'drawer-text' : ''"
              v-else
              >{{ scope.row[item.fieldCode] | sortField(item) }}</span
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 公共抽屉 -->
      <common-drawer
        :showDrawer="showCommonDrawer"
        @close="showCommonDrawer = false"
        :template="showTemplate"
        :nextParams="nextParams"
      />
    </div>
  </div>
</template>

<script>
import {
  formatThousandsDots,
  formatThousands,
  formatTime,
} from "@/assets/js/utils";
import ExcludeQuery from "@/components/excludeQuery";
import SelectDeptUser from "@/components/SelectDeptUser";
import SelectField from "@/components/SelectField";
import SelectPlatformChannel from "@/components/SelectPlatformChannel";
import { getBookList } from "@/api/account";
import { getBooksCostGraph, getBooksCost, getFields } from "@/api/statics";
import { yesterdayOptions, labelList } from "@/assets/js/options";
import echarts from "echarts";
import { mapActions } from "vuex";
let timer = null;
export default {
  name: "bookConsume",
  props: {
    lastParams: Object,
  },
  components: {
    ExcludeQuery,
    SelectDeptUser,
    SelectPlatformChannel,
    SelectField,
  },
  watch: {
    lastParams: function () {
      this.getData("drawer");
    },
  },
  computed: {
    labelListShow() {
      return this.labelList.filter((item) => {
        return item.show;
      });
    },
    // 排序处理
    sortableType() {
      return function (value) {
        if (value.autoFields === 0) {
          return false;
        } else if (value.autoFields === 2 || value.autoFields === 4) {
          return true;
        } else if (value.autoFields === 1 || value.autoFields === 3) {
          return "custom";
        }
      };
    },
    // 文字对齐处理
    filAlign() {
      return function (value) {
        switch (value.fontAlign) {
          case 0:
            return "left";
          case 1:
            return "center";
          case 2:
            return "right";
          default:
            return "center";
        }
      };
    },
  },
  filters: {
    sortField: function (value, item) {
      if (item.autoFields === 4 || item.autoFields === 5) {
        if (value !== undefined) {
          return formatThousandsDots(value.toString());
        }
      } else {
        return value;
      }
    },
  },
  data() {
    return {
      params: {},
      bookList: null,
      fieldsData: [],
      originFeild: [],
      selectFields: null,
      selectAllFields: false,
      statistic: {},
      dataList: [],
      totalData: [],
      loading: false,
      graphLoading: false,
      sortName: null,
      sortDesc: null,
      todayAdData: {},
      showRecharge: false,
      yesterdayOptions,
      labelList,
      report: [],
      graphDataList: null,
      costChart: null,
      sendField: [],
      showField: [],
      tmpShowField: [],
      showCommonDrawer: false,
      showTemplate: null,
      nextParams: {},
    };
  },
  methods: {
    // 表格点击事件
    handleCellClick(row, column, cell, event) {
      if (column.label !== "书籍") {
        return false;
      }
      this.nextParams = {
        queryDate: this.params.queryDate,
        booksId: row.booksId,
        labelTypes: this.params.labelTypes,
      };
      this.showTemplate = "channelConsume";
      this.showCommonDrawer = true;
    },
    ...mapActions({
      addRouterHistory: "addRouterHistory",
    }),
    // 组件字段变化
    // handleFieldChange (value) {
    //   if (value.length === 0) {
    //     this.showField = this.originFeild
    //   } else {
    //     this.showField = value
    //   }
    //   this.$nextTick(() => {
    //     this.$refs.bookConsumeTable.doLayout()
    //   })
    // },
    // 获取书籍列表
    getBook(bookName) {
      getBookList({
        page: 1,
        pageSize: 50,
        bookName,
      }).then((res) => {
        this.bookList = res.list;
      });
    },
    // 排序处理
    handleSortChange({ column, prop, order }) {
      if (column.sortable !== "custom") {
        return;
      }
      this.sortName = prop;
      this.sortDesc = order === "descending";
      this.params.sortName = this.sortName;
      this.params.sortDesc = this.sortDesc;
      this.handlePageChange(1);
    },
    // 重置操作
    handleReset() {
      this.params.queryDate = formatTime(new Date(), "yyyy-MM-dd");
      this.params.labelTypes = null;
      this.params.comperingType = null;
      this.params.returnRate = null;
      this.params.originPlatform = null;
      this.params.accountChannelId = null;
      this.params.booksId = null;
      this.params.adminUserId = null;
      this.params.deptId = null;
      this.params.isTest = null;
      this.params.ruledOutBooksIds = [];
      this.params.ruledOutAccountChannelIds = [];
      this.sortName = null;
      this.sortDesc = null;
      this.sendField = [];
      this.showField = this.originFeild;
      this.tmpShowField = [];
      this.handlePageChange(1);
      // this.showField = this.originFeild;
    },
    // 分页操作
    handlePageChange(page) {
      this.page = page;
      this.getData();
    },
    // 选择字段变化
    handleFieldChange(send, show) {
      // if (value.length === 0) {
      //   this.showField = this.originFeild;
      // } else {
      //   this.showField = value;
      // }
      // 请求格式field
      this.sendField = send;
      // 展示格式field
      this.tmpShowField = show;
      this.$nextTick(() => {
        this.$refs.bookConsumeTable.doLayout();
      });
    },
    // 请求前处理字段
    handleFields(item) {
      if (item.length === 0) {
        return null;
      }
      let tmpSend = [];
      const _that = this;
      tmpSend = item.reduce(function (a, b) {
        return a.concat(b);
      });
      // 判断勾选项 推入其下所有子级
      for (let i = 0; i < tmpSend.length; i++) {
        const flagIndex = _that.originFeild.findIndex((item2) => {
          return item2.fieldCode === tmpSend[i];
        });
        if (flagIndex !== -1 && "children" in _that.originFeild[flagIndex]) {
          _that.originFeild[flagIndex].children.forEach((item3) => {
            tmpSend.push(item3.fieldCode);
          });
        }
      }
      // 去重
      tmpSend = Array.from(new Set(tmpSend));
      return tmpSend.join(",");
    },
    // 广告数据获取
    getData(flag) {
      this.loading = true;
      // 处理后的请求字段 如果选择过字段则使用选择字段sendfield 否则传null
      let tmpFields = null;
      if (this.sendField.length > 0) {
        tmpFields = this.handleFields(this.sendField);
      } else {
        this.showField = this.originFeild;
      }
      let tmpform = {};
      // 如果是创建过程 参数默认取缓存参数 否则使用筛选参数
      if (flag === "created") {
        tmpform = {
          queryDate: this.params.queryDate,
          labelTypes: this.params.labelTypes
            ? this.params.labelTypes.join(",")
            : "",
          comperingType: this.params.comperingType,
          returnRate: this.params.returnRate,
          originPlatform: this.params.originPlatform,
          accountChannelId: this.params.accountChannelId,
          booksId: this.params.booksId,
          adminUserId: this.params.adminUserId,
          sortName: this.sortName,
          sortDesc: this.sortDesc,
          deptId: this.params.deptId,
          isTest: this.params.isTest,
          ruledOutBooksIds:
            this.params.ruledOutBooksIds && this.params.ruledOutBooksIds.length
              ? this.params.ruledOutBooksIds
              : null,
          ruledOutAccountChannelIds:
            this.params.ruledOutAccountChannelIds &&
            this.params.ruledOutAccountChannelIds.length
              ? this.params.ruledOutAccountChannelIds
              : null,
          showFieldCodes: tmpFields,
        };
      } else if (flag === "drawer") {
        tmpform = {
          ...this.lastParams,
          ruledOutAccountChannelIds: this.params.ruledOutAccountChannelIds,
          ruledOutBooksIds: this.params.ruledOutBooksIds,
        };

        this.params = { ...this.lastParams };
      } else {
        tmpform = {
          queryDate: this.params.queryDate,
          labelTypes: this.params.labelTypes
            ? this.params.labelTypes.join(",")
            : "",
          comperingType: this.params.comperingType,
          returnRate: this.params.returnRate,
          originPlatform: this.params.originPlatform,
          accountChannelId: this.params.accountChannelId,
          booksId: this.params.booksId,
          adminUserId: this.params.adminUserId,
          sortName: this.sortName,
          sortDesc: this.sortDesc,
          deptId: this.params.deptId,
          isTest: this.params.isTest,
          ruledOutBooksIds:
            this.params.ruledOutBooksIds && this.params.ruledOutBooksIds.length
              ? this.params.ruledOutBooksIds.map((item) => item.id).join()
              : null,
          ruledOutAccountChannelIds:
            this.params.ruledOutAccountChannelIds &&
            this.params.ruledOutAccountChannelIds.length
              ? this.params.ruledOutAccountChannelIds.join()
              : null,
          showFieldCodes: tmpFields,
        };
      }
      // 获取表格数据
      getBooksCost(tmpform)
        .then((res) => {
          // 如果用户选择过字段则表格展示用户选择字段
          if (this.tmpShowField.length > 0) {
            this.showField = this.tmpShowField;
          }
          this.dataList = res;
          // 如果不是抽屉 缓存参数
          if (flag !== "drawer") {
            this.addRouterHistory({
              path: this.$route.path,
              params: {
                ...tmpform,
                cacheShowField: this.showField,
                cacheSendField: this.sendField,
              },
            });
          }
        })
        .finally(() => (this.loading = false));
      // 获取echart数据
      getBooksCostGraph(tmpform)
        .then((res) => {
          this.graphDataList = res;
          this.report = res.report;
          this.setPieOption(res.graphs);
          this.graphLoading = false;
        })
        .finally(() => (this.graphLoading = false));
    },
    // 获取字段
    handleGetFeilds(flag = "created") {
      getFields({ dataType: "everydayBooksCost" }).then((res) => {
        this.originFeild = res;
        const tmpdata = [];
        // 处理掉子级
        for (let i = 0; i < res.length; i++) {
          tmpdata.push({
            fieldName: res[i].fieldName,
            fieldCode: res[i].fieldCode,
          });
        }
        this.fieldsData = tmpdata;
        // 若显示字段为空则显示全部
        if (this.showField.length === 0) {
          this.showField = res;
        }
        this.selectAllFields = true;
        this.getData(flag);
        // 判断字段所选长度是否为0 是则全选 否则不全选
      });
    },
    // 处理字段
    // 初始化echart
    init() {
      this.costChart = echarts.init(document.getElementById("bookConsume"));
      window.onresize = this.costChart.resize;
      // clearInterval(timer);
      // timer = setInterval(this.handleSearch, 1000 * 5 * 60);
      // this.getData();
    },
    // 设置图形数据
    setPieOption(data) {
      this.costChart.setOption(
        {
          title: {
            text: "书籍消耗金额",
            left: "center",
          },
          tooltip: {
            trigger: "item",
            // formatter: `{b} : {c} ({d}%)`
            formatter: (params) => {
              // params为悬浮框上的全部数据
              return (
                params.name +
                "：" +
                formatThousands(params.value) +
                `(${params.percent}%)`
              );
            },
          },
          legend: {
            show: false,
            data: data.map((item) => item.name),
          },
          series: [
            {
              name: "消耗",
              type: "pie",
              radius: "55%",
              center: ["50%", "60%"],
              data: data,
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: "rgba(0, 0, 0, 0.5)",
                },
              },
            },
          ],
        },
        true
      );
    },
    // 复制文本
    handleCopy() {
      const textarea = document.createElement("textarea");
      textarea.value = this.report.join("\n");
      document.body.appendChild(textarea);
      textarea.select();
      if (document.execCommand("copy")) {
        document.execCommand("copy");
        this.$message.success("复制成功");
      }
      document.body.removeChild(textarea);
    },
    // 获取书籍列表by id
    getBookById(booksIds) {
      getBookList({
        page: 1,
        pageSize: 50,
        booksIds,
      }).then((res) => {
        this.bookList = res.list;
      });
    },
  },
  created() {
    if (this.lastParams) {
      this.getBookById(this.lastParams.booksId);
      this.handleGetFeilds("drawer");
      return false;
    }
    const tody = formatTime(new Date(), "yyyy-MM-dd");
    this.$set(this.params, "queryDate", tody);
    // 缓存获取
    const tmpIndex = this.$store.state.routerHistory.findIndex(
      (item) => item.path === this.$route.path
    );
    // 若存在缓存
    if (tmpIndex !== -1) {
      this.params = this.$store.state.routerHistory[tmpIndex].params;
      // 获取缓存展示字段
      this.showField =
        this.$store.state.routerHistory[tmpIndex].params.cacheShowField;
      // 获取缓存发送前格式字段
      this.sendField =
        this.$store.state.routerHistory[tmpIndex].params.cacheSendField;
    }
    this.handleGetFeilds();
    this.getBook();
    // this.getData();
    clearInterval(timer);
    timer = setInterval(this.getData, 1000 * 5 * 60);
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    clearInterval(timer);
    timer = null;
  },
};
</script>

<style lang="scss" scoped>
.ad-consume {
  background-color: #fff;
  border-radius: 6px;
}
// 筛选条件框
.filter-box {
  border-radius: 6px;
  // border: 1px solid #eceff2;
  .filter-title {
    color: $main-text;
    margin-bottom: 20px;
    .iconfont {
      font-size: 12px !important;
    }
  }
}
.consume-table {
  .adId {
    text-decoration: underline;
    cursor: pointer;
  }
  .min-img {
    width: 70px;
    height: 70px;
  }
}
.report-box {
  height: 160px;
  overflow-y: auto;
  p {
    line-height: 24px;
    font-size: 15px;
    color: #808080;
  }
}
.report-cont {
  width: 500px;
  height: 690px;
  margin-right: 10px;
  border: 1px solid #ebeef5;
  border-radius: 6px;
  background-color: #fff;
  padding: 10px;
}
.book-data {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: center;
}
</style>
