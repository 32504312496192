<script>
export default {
  name: "tinytInput",
  props: {
    value: [String, Number],
    isNum: Boolean, // 是否只能输入数字
    type: {
      type: String,
      default: "text",
    },
    width: {
      type: [Number, String],
      default: "100%",
    },
    clearable: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
    size: {
      type: String,
      default: "small",
    },
    isInter: Boolean, // 是否为整数
    min: [Number, String], // 设置最小值
    max: [Number, String], // 设置最大值
    maxlength: [Number, String], // 长度限制
    showLimit: Boolean, // 显示长度限制
    showPassword: Boolean, // 显示密码开关
    disabled: Boolean, // 是否停用
    readOnly: Boolean, // 显示仅读
    decimal: {
      type: [Number, String], //小数点精度
      default: 2,
    },
  },
  render() {
    return (
      <el-input
        clearable={this.clearable}
        ref="tinyTInput"
        value={this.value}
        placeholder={this.$attrs.placeholder}
        maxlength={this.maxlength}
        type={this.type}
        disabled={this.disabled}
        size={this.size}
        readOnly={this.readOnly ? "readonly" : false}
        show-word-limit={this.showLimit}
        show-password={this.showPassword}
        style={{
          width: this.width.includes("%") ? this.width : this.width + "px",
        }}
        on={{
          input: this.handleInput,
          change: this.handleChange,
          clear: this.handleClear,
          blur: this.handleBlur,
          focus: this.handleFocus,
        }}
      >
        <template slot={"prefix"}>{this.$slots.prefix}</template>
        <template slot={"suffix"}>{this.$slots.suffix}</template>
        <template slot={"append"}>{this.$slots.append}</template>
        <template slot={"prepend"}>{this.$slots.prepend}</template>
      </el-input>
    );
  },
  methods: {
    // 处理数字类型
    handleInput(val) {
      if (this.isNum) {
        if (this.isInter) {
          this.$emit("input", val.replace(/[^\d+]/gi, ""));
        } else {
          this.$emit("input", val.replace(/[^\d.]+/gi, ""));
        }
      } else {
        return this.$emit("input", val);
      }
    },
    // 处理最大、最小值及多个小数点问题
    handleChange(val) {
      let newVal = val;
      if (this.isNum) {
        // 去除多个小数点
        if (!this.isInter) {
          newVal = val.replace(/[^\d+\.]/gi, "");
          if (/\.+/gi.test(newVal)) {
            newVal = newVal.split(/\.+/g);
            this.$emit(
              "input",
              [...newVal[0], ["."], ...newVal[1].slice(0, this.decimal)].join(
                ""
              )
            );
          }
        }
        if (this.min !== undefined) {
          if (parseFloat(val) < parseFloat(this.min)) {
            this.$emit("input", `${this.min}`);
          }
        }
        if (this.max !== undefined) {
          if (parseFloat(val) > parseFloat(this.max)) {
            this.$emit("input", `${this.max}`);
          }
        }
      }
      this.$emit("change");
    },
    // 清空操作
    handleClear() {
      this.$emit("clear");
    },
    // 失去焦点操作
    handleBlur() {
      this.$emit("blur");
    },
    // 获取焦点操作
    handleFocus() {
      this.$emit("focus");
    },

    /*组件提供方法 失焦 获焦 选中 */
    handleGetBlur() {
      this.$refs.tinyTInput.blur();
    },
    handleGetFocus() {
      this.$refs.tinyTInput.focus();
    },
    handleGetSelect() {
      this.$refs.tinyTInput.select();
    },
  },
};
</script>