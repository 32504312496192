<template>
<!-- 主要加入了loading占位动画 -->
  <div class="custom-image" :style="{ minHeight: `${height}px` }">
    <img
      :src="src"
      class="real-image"
      alt=""
      :style="{ height: `${height}px` }"
      v-show="!loadingStatus"
      @load="handleImageLoad"
      ref="image"
    />
    <template v-if="loadingStatus">
      <div>
        <div class="loading-animation" :class="getClassName()"></div>
        <p style="color: #ccc; margin-top: 10px">图片加载中</p>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'CImage',
  props: {
    src: String,
    height: {
      type: [Number, String]
    }
  },
  data () {
    return {
      loadingStatus: true,
      animationNum: Math.floor(Math.random() * 3)
    }
  },
  methods: {
    handleImageLoad () {
      this.loadingStatus = false
    },
    getClassName () {
      return `loader-0${this.animationNum}`
    }
  },
  components: {}
}
</script>
<style lang='scss' scoped>
.custom-image {
  display: flex;
  justify-content: center;
  align-items: center;
  > img {
    width: 100%;
    object-fit: cover;
  }
  .loading-animation {
    margin: 0 auto;
    width: 24px;
    height: 24px;
    background: #ccc;
  }
  .loader-00 {
    border-radius: 50%;
    animation: loader-00 1s infinite ease-in-out;
  }
  .loader-01 {
    animation: loader-01 1s infinite ease-in-out;
  }
  .loader-02 {
    position: relative;
  }
  .loader-02:before,
  .loader-02:after {
    content: "";
    display: block;
    position: absolute;
    background-color: currentcolor;
    left: 50%;
    right: 0;
    top: 0;
    bottom: 50%;
    box-shadow: -0.5em 0 0 currentcolor;
    -webkit-animation: loader-02 1s linear infinite;
    animation: loader-02 1s linear infinite;
  }
  .loader-02:after {
    top: 50%;
    bottom: 0;
    -webkit-animation-delay: 0.25s;
    animation-delay: 0.25s;
  }

  @keyframes loader-02 {
    0%,
    100% {
      box-shadow: -0.7em 0 0 transparent;
      background-color: currentcolor;
    }
    50% {
      box-shadow: -0.7em 0 0 currentcolor;
      background-color: transparent;
    }
  }
  @keyframes loader-01 {
    0% {
      -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
      transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    }
    50% {
      -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
      transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    }
    100% {
      -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
      transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    }
  }
  @keyframes loader-00 {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 0;
    }
  }
}
</style>
