
<script>
export default {
  name: 'DyRender',
  props: {
    scope: Object,
    render: Function,
    column: Object,
  },
  data() {
    return {
      colorList: ['success', 'info', 'danger', 'warning', ''],
    }
  },
  render(h) {
    // ! 针对自定义表头
    if (this.render) {
      return this.render()
    }
    if (this.scope.row) {
      // 针对表格最右边操作栏位

      if (this.column.action === 'operation') {
        return (
          <div class="button-list">
            {this.$scopedSlots.operation({
              row: this.scope.row,
            })}
          </div>
        )
      }
      if (this.column.action === 'dyoperation') {
        return (
          <div class="button-list">
            {this.$scopedSlots.dyoperation({
              row: this.scope.row,
            })}
          </div>
        )
      }
      // !针对需要自定义的slot
      if (this.column.slot === true) {
        return this.$scopedSlots[this.column.prop]({
          row: this.scope.row,
        })
      }
      // ! 链接格式
      if (this.column.link) {
        return (
          <a
            class="line-overflow"
            target="_blank"
            href={this.scope.row[this.column.prop]}
          >
            {this.scope.row[this.column.prop]}
          </a>
        )
      }
      // !渲染el-switch
      if (this.column.switch) {
        return (
          <el-switch
            change={this.handleEvent(
              this.scope.row,
              this.scope.row[this.column.prop]
            )}
            v-model={this.scope.row[this.column.prop]}
            active-value={1}
            inactive-value={0}
            active-text="开启"
            inactive-text="关闭"
          ></el-switch>
        )
      }
      // !渲染el-tag
      if (this.column.tag) {
        return (
          <el-tag
            type={
              this.getTagType(
                this.column.prop,
                this.scope.row[this.column.prop]
              ).type
            }
          >
            {
              this.getTagType(
                this.column.prop,
                this.scope.row[this.column.prop]
              ).text
            }
          </el-tag>
        )
      }

      // !渲染el-popover 弹出层
      if (this.column.popover) {
        let group = []
        if (this.column.mediaType === 'image') {
          if (this.scope.row[this.column.prop] instanceof Array) {
            group = this.scope.row[this.column.prop]
          } else {
            group = [this.scope.row[this.column.prop]]
          }
        }
        return (
          <el-popover placement="right">
            <div class="origin-resource">
              <h3>原内容</h3>
            </div>
            {this.column.mediaType === 'image' ? (
              h(
                'div',
                group.map((item) => {
                  return h('img', {
                    attrs: {
                      src: item,
                    },
                  })
                })
              )
            ) : this.column.mediaType === 'video' ? (
              <video
                controls
                muted
                src={this.scope.row[this.column.prop]}
              ></video>
            ) : this.column.mediaType === 'audio' ? (
              <audio
                controls
                muted
                src={this.scope.row[this.column.prop]}
              ></audio>
            ) : this.column.rowHtml === true ? (
              <div domPropsInnerHTML={this.scope.row[this.column.prop]}></div>
            ) : (
              <div>{this.scope.row[this.column.prop]}</div>
            )}
            <div slot="reference" class="thumbnail">
              {this.column.mediaType === 'image' ? (
                h(
                  'div',
                  group.map((item) => {
                    return h('img', {
                      attrs: {
                        src: item,
                      },
                    })
                  })
                )
              ) : this.column.mediaType === 'video' ? (
                <video
                  controls
                  muted
                  src={this.scope.row[this.column.prop]}
                ></video>
              ) : this.column.mediaType === 'audio' ? (
                <audio
                  controls
                  muted
                  src={this.scope.row[this.column.prop]}
                ></audio>
              ) : (
                <div class="line-overflow">
                  {this.scope.row[this.column.prop]}
                </div>
              )}
            </div>
          </el-popover>
        )
      }
      // 图片不需要popover
      if (!this.column.popover && this.column.mediaType === 'image') {
        let group = []
        if (this.column.mediaType === 'image') {
          if (this.scope.row[this.column.prop] instanceof Array) {
            group = this.scope.row[this.column.prop]
          } else {
            group = [this.scope.row[this.column.prop]]
          }
        }

        return h(
          'div',
          {
            class: 'thumbnail',
          },
          group.map((item, index) => {
            return h('el-image', {
              attrs: {
                src: item,
                previewSrcList: group,
              },
            })
          })
        )
      }

      // 普通渲染
      if (this.scope.row[this.column.prop] === undefined) {
        return <span>--</span>
      }
      return <span>{this.scope.row[this.column.prop]}</span>

      // 其它普通组件
    }
  },
  methods: {
    handleEvent(info, val) {
      this.$emit('handleEvent', {
        info,
        val,
      })
    },
    getTagType(prop, val) {
      // !不是this.tagInfo
      if (
        Array.isArray(this.$attrs.tagInfo) &&
        this.$attrs.tagInfo.length > 0
      ) {
        const tagList = this.$attrs.tagInfo.find(
          (item) => item.tagName === prop
        )
        let targetIndex
        if (typeof val === 'number') {
          targetIndex = val
        }
        if (typeof val === 'string') {
          targetIndex = tagList.tagVal.findIndex((item) => item.val === val)
        }
        return {
          type: this.colorList[targetIndex - 1],
          text: tagList.tagVal[targetIndex - 1].text,
        }
      }
    },
  },
}
</script>
<style lang='scss' scoped>
a {
  color: #40a9ff;
}
.filter-list {
  margin-bottom: 20px;
}
/deep/.el-pagination {
  text-align: right;
}
.banner-img,
.banner-video {
  width: 100px;
}

.origin-resource {
  max-width: 600px;
}
.thumbnail {
  width: 100%;
  .el-image {
    max-width: 60px;
    max-height: 60px;
  }
  img {
    width: 100%;
    object-fit: contain;
    max-width: 60px;
    max-height: 60px;
  }
}
.thumbnail-video {
  width: 100px;
}
.resource-video {
  max-width: 300px;
}
</style>
